import React, { useState, useEffect, useRef, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/solid';

const EnhancedListingSelector = ({ listings, onSelectListing, dashboardData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownStyle, setDropdownStyle] = useState({});
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const filteredListings = useMemo(() => {
    return listings.filter(listing => 
      listing.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      listing.propertyType.toLowerCase().includes(searchTerm.toLowerCase()) ||
      listing.price.toString().includes(searchTerm)
    );
  }, [searchTerm, listings]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const updateDropdownPosition = () => {
      if (inputRef.current) {
        const rect = inputRef.current.getBoundingClientRect();
        setDropdownStyle({
          position: 'absolute',
          top: `${rect.bottom + window.scrollY}px`,
          left: `${rect.left + window.scrollX}px`,
          width: `${rect.width}px`,
          maxHeight: '300px',
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', updateDropdownPosition);
    window.addEventListener('resize', updateDropdownPosition);

    updateDropdownPosition();

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', updateDropdownPosition);
      window.removeEventListener('resize', updateDropdownPosition);
    };
  }, [isOpen]);

  const handleListingClick = (listing) => {
    onSelectListing(listing);
    setIsOpen(false);
    setSearchTerm(listing.title);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    onSelectListing(null);
    inputRef.current.focus();
  };

  const Dropdown = () => (
    createPortal(
      <div
        className="absolute z-50 bg-white dark:bg-gray-800 rounded-md shadow-lg overflow-auto border border-gray-300 dark:border-gray-600"
        style={dropdownStyle}
      >
        {filteredListings.map((listing) => (
          <div
            key={listing.listingId}
            className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100 dark:hover:bg-gray-700"
            onMouseDown={() => handleListingClick(listing)}  // Changed from onClick to onMouseDown
          >
            <div className="flex items-center">
              <img 
                src={listing.photos[0].url} 
                alt={listing.title} 
                className="h-10 w-10 flex-shrink-0 rounded-full"
              />
              <div className="ml-3 overflow-hidden">
                <p className="text-sm font-medium text-gray-900 dark:text-white truncate">{listing.title}</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {listing.propertyType} - {listing.price.toLocaleString('en-MY', { style: 'currency', currency: 'MYR' })}
                </p>
                <p className="text-xs text-gray-400 dark:text-gray-500">
                  Views: {dashboardData.listingPerformance[listing.listingId]?.views || 0} | 
                  Enquiries: {dashboardData.listingPerformance[listing.listingId]?.enquiries || 0}
                </p>
              </div>
            </div>
          </div>
        ))}
        {filteredListings.length === 0 && (
          <div className="px-3 py-2 text-sm text-gray-500 dark:text-gray-400">
            No listings found
          </div>
        )}
      </div>,
      document.body
    )
  );

  return (
    <div ref={containerRef} className="relative">
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          className="w-full px-4 py-2 pr-20 text-left bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500"
          placeholder="Search or select a listing"
          value={searchTerm}
          onChange={handleInputChange}
          onClick={() => setIsOpen(true)}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 space-x-1">
          {searchTerm && (
            <button
              type="button"
              onClick={handleClearSearch}
              className="p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500"
            >
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          )}
          <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500"
          >
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      {isOpen && <Dropdown />}
    </div>
  );
};

export default EnhancedListingSelector;