import { useContext, useCallback, useRef, useEffect } from 'react';
import { useAuth } from '../../context/auth/AuthContext';
import io from 'socket.io-client';

let socket = null;
const MAX_RECONNECTION_ATTEMPTS = 5;
const INITIAL_RECONNECTION_DELAY = 1000;
const MAX_RECONNECTION_DELAY = 30000;

export const useSocketService = () => {
  const { auth, refreshAccessToken } = useAuth();
  const reconnectionAttempts = useRef(0);
  const reconnectionDelay = useRef(INITIAL_RECONNECTION_DELAY);

  const initializeSocket = useCallback(() => {
    if (!auth.isAuthenticated || socket) return socket;

    const url = `${process.env.REACT_APP_API_URL}/chat`;

    socket = io(url, {
      auth: {
        token: auth.accessToken,
      },
      transports: ['websocket'],
      reconnection: false,
      timeout: 10000,
    });

    socket.on('connect', () => {
      reconnectionAttempts.current = 0;
      reconnectionDelay.current = INITIAL_RECONNECTION_DELAY;
      socket.emit('authenticate', auth.user._id);
    });

    socket.on('connect_error', async (error) => {
      console.error('Socket connection error:', error);

      if (error.message === 'jwt expired') {
        try {
          const newAccessToken = await refreshAccessToken();
          socket.auth.token = newAccessToken;
          socket.connect();
        } catch (refreshError) {
          console.error('Failed to refresh token:', refreshError);
          handleReconnection();
        }
      } else {
        handleReconnection();
      }
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    socket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    return socket;
  }, [auth.isAuthenticated, auth.accessToken, auth.user, refreshAccessToken]);

  const handleReconnection = () => {
    if (reconnectionAttempts.current < MAX_RECONNECTION_ATTEMPTS) {
      reconnectionAttempts.current += 1;

      setTimeout(() => {
        if (socket) socket.connect();
      }, reconnectionDelay.current);

      reconnectionDelay.current = Math.min(
        reconnectionDelay.current * 2,
        MAX_RECONNECTION_DELAY
      );
    } else {
      console.error('Max reconnection attempts reached. Please try again later.');
      disconnectSocket();
    }
  };

  const getSocket = useCallback(() => {
    return socket;
  }, []);

  const disconnectSocket = useCallback(() => {
    if (socket) {
      socket.disconnect();
      socket = null;
      reconnectionAttempts.current = 0;
      reconnectionDelay.current = INITIAL_RECONNECTION_DELAY;
    }
  }, []);

  // Use useEffect to ensure this runs only on the client side
  useEffect(() => {
    if (!socket && typeof window !== 'undefined') {
      initializeSocket();
    }

    return () => {
      disconnectSocket();
    };
  }, [initializeSocket, disconnectSocket]);

  const onNewMessage = useCallback((callback) => {
    if (socket) {
      socket.on('new_message', callback);
    }
  }, []);

  const offNewMessage = useCallback((callback) => {
    if (socket) {
      socket.off('new_message', callback);
    }
  }, []);

  const onMessageUpdated = useCallback((callback) => {
    if (socket) {
      socket.on('message_updated', callback);
    }
  }, []);

  const offMessageUpdated = useCallback((callback) => {
    if (socket) {
      socket.off('message_updated', callback);
    }
  }, []);

  const onUnreadCountUpdated = useCallback((callback) => {
    if (socket) {
      socket.on('unread_count_updated', callback);
    }
  }, []);

  const offUnreadCountUpdated = useCallback((callback) => {
    if (socket) {
      socket.off('unread_count_updated', callback);
    }
  }, []);

  const onTotalUnreadCountUpdated = useCallback((callback) => {
    if (socket) {
      socket.on('total_unread_count_updated', callback);
    }
  }, []);

  const offTotalUnreadCountUpdated = useCallback((callback) => {
    if (socket) {
      socket.off('total_unread_count_updated', callback);
    }
  }, []);

  const onNewNotification = useCallback((callback) => {
    if (socket) socket.on('new_notification', callback);
  }, []);

  const offNewNotification = useCallback((callback) => {
    if (socket) socket.off('new_notification', callback);
  }, []);

  return {
    initializeSocket,
    getSocket,
    disconnectSocket,
    onNewMessage,
    offNewMessage,
    onMessageUpdated,
    offMessageUpdated,
    onUnreadCountUpdated,
    offUnreadCountUpdated,
    onTotalUnreadCountUpdated,
    offTotalUnreadCountUpdated,
    onNewNotification,
    offNewNotification,
  };
};
