// src/pages/LocationHomePage/LocationHomePage.jsx

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import backendApi from '../../api/backendApi';
import Header from '../../components/global/Header';
import LocationCard from '../../components/card/LocationCard';

const Location = () => {
  const [regions, setRegions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchRegions();
  }, []);

  const fetchRegions = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await backendApi.location.getRootLocations();
      setRegions(response);
    } catch (error) {
      console.error('Error fetching regions:', error);
      setError('Failed to load regions. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Header title="Explore Regions" />
      <div className="container mx-auto py-8 px-4">
        <Helmet>
          <title>Explore Malaysia Properties</title>
          <meta name="description" content={`Discover properties, amenities, and listings in Malayisa`} />
        </Helmet>
        {regions.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {regions.map(region => (
              <LocationCard 
                key={region._id} 
                location={region} 
                showNextLevel={true}
              />
            ))}
          </div>
        ) : (
          <p className="text-gray-600 dark:text-gray-400 text-center">No regions available.</p>
        )}
      </div>
    </div>
  );
};

export default Location;