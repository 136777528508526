import React from 'react';
import PhotoUpload from '../cms/shared/PhotoUpload';

const ImagesForm = ({ images, onChange }) => {
  const handlePhotosUpdate = (updatedPhotos) => {
    onChange(updatedPhotos);
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Location Images</h3>
      <PhotoUpload
        photos={images}
        updatePhotos={handlePhotosUpdate}
        maxPhotos={10}
        photosPerRow={5}
        containerWidth="100%"
        containerHeight="auto"
      />
    </div>
  );
};

export default ImagesForm;