import React, { useState } from 'react';
import { CheckCircleIcon, PlusIcon } from '@heroicons/react/24/solid';

const templates = [
  {
    id: 'empty',
    name: 'Empty Template',
    description: 'Start from scratch',
    image: '/images/templates/empty-template.jpg',
  },
  {
    id: 'modern-urban',
    name: 'Modern Urban',
    description: 'Sleek design for city developments',
    image: '/images/templates/modern-urban-thumb.jpg',
  },
  {
    id: 'luxury-residence',
    name: 'Luxury Residence',
    description: 'Elegant template for high-end properties',
    image: '/images/templates/luxury-residence-thumb.jpg',
  }
];

const TemplateSelector = ({ onSelectTemplate }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleSelectTemplate = (template) => {
    setSelectedTemplate(template.id);
    onSelectTemplate(template);
  };

  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-6">
      <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-gray-100">Choose a Template</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
        {templates.map((template) => (
          <div
            key={template.id}
            className={`border rounded-lg overflow-hidden cursor-pointer transition-all duration-200 ${
              selectedTemplate === template.id
                ? 'border-primary-500 shadow-lg transform scale-105'
                : 'border-gray-200 dark:border-gray-700 hover:shadow-md'
            }`}
            onClick={() => handleSelectTemplate(template)}
          >
            <div className="relative">
              {template.id === 'empty' ? (
                <div className="w-full h-48 bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                  <PlusIcon className="h-12 w-12 text-gray-400 dark:text-gray-500" />
                </div>
              ) : (
                // <img
                //   src={template.image}
                //   alt={template.name}
                //   className="w-full h-48 object-cover"
                // />
                <div className="w-full h-48 bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                  <PlusIcon className="h-12 w-12 text-gray-400 dark:text-gray-500" />
                </div>
              )}
              {selectedTemplate === template.id && (
                <div className="absolute top-2 right-2 bg-primary-500 text-white rounded-full p-1">
                  <CheckCircleIcon className="h-6 w-6" />
                </div>
              )}
            </div>
            <div className="p-4 bg-white dark:bg-gray-800">
              <h3 className="font-semibold text-lg mb-2 text-gray-800 dark:text-gray-100">{template.name}</h3>
              <p className="text-sm text-gray-600 dark:text-gray-300">{template.description}</p>
            </div>
          </div>
        ))}
      </div>
      {selectedTemplate && (
        <div className="mt-8 text-center">
          <button
            className="bg-primary-500 text-white px-6 py-2 rounded-lg hover:bg-primary-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
            onClick={() => onSelectTemplate(templates.find(t => t.id === selectedTemplate))}
          >
            Use Selected Template
          </button>
        </div>
      )}
    </div>
  );
};

export default TemplateSelector;