import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/global/Header';
import ProjectForm from '../../components/cms/project/ProjectForm';
import backendApi from '../../api/backendApi';

const EditProject = () => {
  const [project, setProject] = useState(null);
  const [originalProject, setOriginalProject] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const fetchedProject = await backendApi.projects.fetchById(id);
        setProject(fetchedProject);
        setOriginalProject(fetchedProject);
      } catch (error) {
        console.error('Error fetching project:', error);
        // TODO: Add error notification
      }
    };

    fetchProject();
  }, [id]);

  const handleSaveProject = async (projectData, status) => {
    try {
      const updatedProject = await backendApi.projects.update(project._id, projectData);
      alert("Project updated");
      setProject(updatedProject.project);
      setOriginalProject(updatedProject.project);
      navigate('/project/manage-project');
    } catch (error) {
      console.error('Error updating project:', error);
      // TODO: Add error notification
    }
  };

  const handleDiscardChanges = () => {
    setProject({ ...originalProject });
    // TODO: Add notification that changes have been discarded
  };

  if (!project) {
    return <div>Loading...</div>; // Or a more sophisticated loading component
  }

  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900">
      <Header title="Edit Project" />
      <div className="p-4 md:p-6 lg:p-8">
        <ProjectForm
          initialProject={project}
          onSave={handleSaveProject}
          onDiscard={handleDiscardChanges}
        />
      </div>
    </div>
  );
};

export default EditProject;
