import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';

const ListingIntentInput = memo(({ input, updateInput }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = useCallback((e) => {
    updateInput(e.target.value);
  }, [updateInput]);

  return (
    <div className="space-y-4 mx-auto">
      <textarea
        value={input}
        onChange={handleInputChange}
        placeholder="Describe your listing (e.g., 'Selling a landed house in <Put in your address> for RM 125000')"
        rows="4"
        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 transition duration-200 ease-in-out resize-none"
        disabled={isProcessing}
      />
      {error && (
        <p className="text-red-500 text-sm">{error}</p>
      )}
      {isProcessing && (
        <p className="text-primary-500 text-sm">Processing your input...</p>
      )}
    </div>
  );
});

ListingIntentInput.propTypes = {
  input: PropTypes.string.isRequired,
  updateInput: PropTypes.func.isRequired,
  updateListingDetails: PropTypes.func.isRequired,
  onProcessComplete: PropTypes.func.isRequired,
};

ListingIntentInput.displayName = 'ListingIntentInput';

export default ListingIntentInput;