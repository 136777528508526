import React, { useContext } from 'react';
import { useTheme } from '../../../context/ui/ThemeContext';
import Chart from 'react-apexcharts';
import { ScanEye, Eye, Heart, Scale, MessageSquare } from 'lucide-react';

const ConversionFunnelChart = ({ data }) => {
  const { isDarkMode } = useTheme();

  const funnelData = [
    // { x: 'Impressions', y: data.totalImpressions, icon: ScanEye },
    { x: 'Views', y: data.totalViews, icon: Eye },
    { x: 'Pins', y: data.totalPins, icon: Heart },
    { x: 'Compares', y: data.totalComparisons, icon: Scale },
    { x: 'Enquiries', y: data.totalEnquiries, icon: MessageSquare }
  ];

  // Generate a gradient of colors
  const startColor = '#3b82f6'; // Blue
  const endColor = '#10b981'; // Green
  const colors = funnelData.map((_, index) => {
    const ratio = index / (funnelData.length - 1);
    return interpolateColor(startColor, endColor, ratio);
  });

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      background: 'transparent',
      toolbar: {
        show: false
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        barHeight: '80%',
        isFunnel: true,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function(val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ": " + val;
      },
      style: {
        fontSize: '12px',
        colors: ['#fff'],
        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)'
      },
      dropShadow: {
        enabled: true
      }
    },
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: isDarkMode ? 'dark' : 'light',
    //     type: 'vertical',
    //     shadeIntensity: 0.5,
    //     gradientToColors: undefined,
    //     inverseColors: false,
    //     opacityFrom: 1,
    //     opacityTo: 0.8,
    //     stops: [0, 100]
    //   }
    // },
    colors: colors,
    xaxis: {
      categories: funnelData.map(item => item.x),
      labels: {
        show: false
      },
    },
    yaxis: {
      labels: {
        show: false
      },
    },
    grid: {
      show: false
    },
    tooltip: {
      theme: isDarkMode ? 'dark' : 'light',
      y: {
        formatter: function(value) {
          return value;
        },
        title: {
          formatter: function() {
            return '';
          }
        },
      },
    },
    legend: {
      show: false
    }
  };

  const series = [{
    data: funnelData.map(item => item.y)
  }];

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Engagement Funnel</h2>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

// Helper function to interpolate between two colors
function interpolateColor(color1, color2, factor) {
  const c1 = hexToRgb(color1);
  const c2 = hexToRgb(color2);
  const result = c1.map((c, i) => Math.round(c + factor * (c2[i] - c)));
  return `rgb(${result.join(',')})`;
}

// Helper function to convert hex to RGB
function hexToRgb(hex) {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
}

export default ConversionFunnelChart;