import React from 'react';

const CallToActionView = ({ content }) => {
  return (
    <div className="bg-blue-600 text-white py-16">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-4">{content.title}</h2>
        <p className="text-xl mb-8">{content.description}</p>
        <a href={content.buttonLink} className="bg-white text-blue-600 font-bold py-3 px-8 rounded-full text-lg hover:bg-blue-100 transition duration-300">
          {content.buttonText}
        </a>
      </div>
    </div>
  );
};

export default CallToActionView;