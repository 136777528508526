import React, { useState, useEffect } from 'react';
import LocationTree from '../../components/location/LocationTree';
import LocationForm from '../../components/location/LocationForm';
import LocationPreview from '../../components/location/LocationPreview';
import backendApi from '../../api/backendApi';
import { PlusIcon, PencilIcon, EyeIcon } from '@heroicons/react/24/solid';
import Header from '../../components/global/Header';

const ManageLocation = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [parentLocation, setParentLocation] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formKey, setFormKey] = useState(0);  // Add this line
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [locationData, setLocationData] = useState(null);

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await backendApi.location.getAllRegionsWithHierarchy();
      setLocations(response || []);
    } catch (error) {
      console.error('Error fetching locations:', error);
      setError('Failed to fetch locations. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const clearForm = () => {
    setSelectedLocation(null);
    setParentLocation(null);
    setIsAdding(false);
  };

  const handleAddLocation = (parentId = null) => {
    const parent = parentId ? locations.find(loc => loc._id === parentId) : null;
    const newLocation = {
      name: '',
      type: parent ? getNextLocationType(parent.type) : 'region',
      intro: '',
      population: '',
      amenities: [],
      statistics: {
        medianIncome: '',
        employmentRate: ''
      },
      images: [],
      mapImage: { url: '', caption: '' },
      featuredImage: { url: '', caption: '' },
      address: {
        street: '',
        postalCode: '',
        city: '',
        coordinates: {
          lat: '',
          lng: ''
        }
      },
      units: []
    };
    setSelectedLocation(null);
    setParentLocation(parent);
    setIsAdding(true);
    setLocationData(newLocation);
    setFormKey(prevKey => prevKey + 1);
  };

  const handleEditLocation = (location) => {
    const initializedLocation = {
      name: '',
      type: 'region',
      intro: '',
      population: '',
      amenities: [],
      statistics: {
        medianIncome: '',
        employmentRate: ''
      },
      images: [],
      mapImage: { url: '', caption: '' },
      featuredImage: { url: '', caption: '' },
      address: {
        street: '',
        postalCode: '',
        city: '',
        coordinates: {
          lat: '',
          lng: ''
        }
      },
      units: [],
      // Spread the selected location data over the initialized structure
      ...location
    };

    setSelectedLocation(location);
    setParentLocation(null);
    setIsAdding(false);
    setLocationData(initializedLocation);
    setFormKey(prevKey => prevKey + 1);
  };

  const handleFormChange = (updatedData) => {
    setLocationData(updatedData);
  };

  const getNextLocationType = (parentType) => {
    const types = ['region', 'district', 'township', 'area', 'building'];
    if (!parentType) return 'region';
    const currentIndex = types.indexOf(parentType);
    return types[currentIndex + 1] || 'building';
  };

  const handleSubmit = async (formData) => {
    if (!formData) {
      clearForm();
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const submitData = new FormData();

      // Create a copy of formData without sublocations
      const { sublocations, ...locationDataWithoutSublocations } = formData;

      // Append all non-file data
      submitData.append('locationData', JSON.stringify(locationDataWithoutSublocations));

      // Handle images
      locationDataWithoutSublocations.images.forEach((image, index) => {
        if (image.file) {
          submitData.append(`images`, image.file);
        } else if (image.url) {
          submitData.append(`images`, image);
        }
      });

      if (locationDataWithoutSublocations.mapImage?.file) {
        submitData.append('mapImage', locationDataWithoutSublocations.mapImage.file);
      } else if (locationDataWithoutSublocations.mapImage?.url) {
        submitData.append('mapImage', locationDataWithoutSublocations.mapImage);
      }

      if (locationDataWithoutSublocations.featuredImage?.file) {
        submitData.append('featuredImage', locationDataWithoutSublocations.featuredImage.file);
      } else if (locationDataWithoutSublocations.featuredImage?.url) {
        submitData.append('featuredImage', locationDataWithoutSublocations.featuredImage);
      }

      if (isAdding) {
        submitData.append('parentId', parentLocation?._id || '');
        await backendApi.location.createLocation(submitData);
      } else {
        await backendApi.location.updateLocation(selectedLocation._id, submitData);
      }

      await fetchLocations();
      clearForm();
    } catch (error) {
      console.error('Error saving location:', error);
      setError('Failed to save location. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteLocation = async (locationId) => {
    if (window.confirm('Are you sure you want to delete this location? This action cannot be undone.')) {
      setIsLoading(true);
      setError(null);
      try {
        await backendApi.location.deleteLocation(locationId);
        await fetchLocations();
        if (selectedLocation && selectedLocation._id === locationId) {
          clearForm();
        }
      } catch (error) {
        console.error('Error deleting location:', error);
        setError('Failed to delete location. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const togglePreviewMode = () => {
    setIsPreviewMode(!isPreviewMode);
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Header title="Manage Locations" />
      <div className="container mx-auto py-8">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline"> {error}</span>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-800 dark:text-white">Location Structure</h2>
              <button
                onClick={() => handleAddLocation()}
                className="flex items-center px-3 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
                disabled={isLoading}
              >
                <PlusIcon className="w-4 h-4 mr-2" />
                Add Root Location
              </button>
            </div>
            {isLoading ? (
              <div className="flex justify-center items-center h-32">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
              </div>
            ) : locations.length === 0 ? (
              <div className="text-center text-gray-500 dark:text-gray-400">
                No locations found. Click 'Add Root Location' to get started.
              </div>
            ) : (
              <LocationTree
                locations={locations}
                onAdd={handleAddLocation}
                onEdit={handleEditLocation}
                onDelete={handleDeleteLocation}
              />
            )}
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-800 dark:text-white">
                {isAdding ? 'Add New Location' : (selectedLocation ? 'Edit Location' : 'Select a Location')}
              </h2>
              {(isAdding || selectedLocation) && (
                <button
                  onClick={togglePreviewMode}
                  className="flex items-center px-3 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                >
                  {isPreviewMode ? (
                    <>
                      <PencilIcon className="w-4 h-4 mr-2" />
                      Edit
                    </>
                  ) : (
                    <>
                      <EyeIcon className="w-4 h-4 mr-2" />
                      Preview
                    </>
                  )}
                </button>
              )}
            </div>
            {(isAdding || selectedLocation) && (
              isPreviewMode ? (
                <LocationPreview location={locationData || {}} />
              ) : (
                <LocationForm
                  key={formKey}
                  location={locationData}
                  parentLocation={parentLocation}
                  onSubmit={handleSubmit}
                  onChange={handleFormChange}
                  isAdding={isAdding}
                  isLoading={isLoading}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageLocation;