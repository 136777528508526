import React from 'react';

const PeriodSelector = ({ currentPeriod, onPeriodChange }) => {
  const periods = ['Today', '7 Days', '30 Days', '90 Days'];

  return (
    <div className="flex flex-wrap gap-2">
      {periods.map((period) => (
        <button
          key={period}
          onClick={() => onPeriodChange(period)}
          className={`px-3 py-1 rounded-full text-xs sm:text-sm font-medium transition-colors duration-200 ${
            currentPeriod === period
              ? 'bg-primary-600 text-white dark:bg-primary-500'
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600'
          }`}
        >
          {period}
        </button>
      ))}
    </div>
  );
};

export default PeriodSelector;