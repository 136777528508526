import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PropertyCard from '../card/PropertyCard';
import Pagination from '../multipurpose/Pagination';

const PropertySearchResults = ({ 
  properties, 
  currentPage, 
  totalPages, 
  onPageChange, 
  onViewDetails, 
  onPin, 
  pinnedProperties
}) => {

  // Animation variants for fade-in effect
  const containerVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.9 }
  };

  return (
    <div className="space-y-5">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-5 gap-y-1">
        <AnimatePresence>
          {properties.map((property, index) => (
            <motion.div
              key={property.listingId}
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.3, ease: "easeInOut" }}
            >
              <PropertyCard 
                property={property}
                onViewDetails={() => onViewDetails(property.listingId)}
                onPin={() => onPin(property)}
                isPinned={pinnedProperties?.some(p => p.id === property.id)}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
      {totalPages > 1 && (
        <div className="mt-6">
          <Pagination 
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
};

export default PropertySearchResults;
