import axios from 'axios';
import pako from 'pako';
import storageHelper from '../utils/storageHelper';

// Determine the tool type based on the current application
const TOOL_TYPE = process.env.REACT_APP_CODE_NAME || 'jade';
const AUTH_KEY = 'auth';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001/api',
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'arraybuffer', // Receive response as ArrayBuffer
});

// Helper function to get tokens from storage
const getTokens = () => {
  const auth = storageHelper.getItem(`${TOOL_TYPE}_${AUTH_KEY}`);
  const accessToken = auth?.accessToken;
  const refreshToken = auth?.refreshToken;
  return { accessToken, refreshToken };
};

// Helper function to set tokens in storage
const setTokens = (accessToken, refreshToken) => {
  const auth = storageHelper.getItem(`${TOOL_TYPE}_${AUTH_KEY}`);
  const newAuth = { user: auth.user, accessToken: accessToken, refreshToken: refreshToken };
  storageHelper.setItem(`${TOOL_TYPE}_${AUTH_KEY}`, newAuth);
};

// Helper function to clear tokens from storage
const clearTokens = () => {
  storageHelper.removeItem(`${TOOL_TYPE}_${AUTH_KEY}`);
};

// Request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const { accessToken } = getTokens();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
apiClient.interceptors.response.use(
  (response) => {
    // Check if the response is compressed
    const contentEncoding = response.headers['content-encoding'];
    if (contentEncoding && contentEncoding.includes('gzip')) {
      try {
        // Decompress the response data
        const decompressed = pako.inflate(new Uint8Array(response.data), { to: 'string' });
        response.data = JSON.parse(decompressed);
      } catch (error) {
        console.error('Decompression error:', error);
        // If decompression fails, return the original response
      }
    } else {
      // If not compressed, parse as normal JSON
      response.data = JSON.parse(new TextDecoder().decode(response.data));
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // If the error is due to an expired token
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const { refreshToken } = getTokens();
        const response = await axios.post(`${apiClient.defaults.baseURL}/auth/refresh-token`, { refreshToken });
        const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;

        setTokens(newAccessToken, newRefreshToken);

        // Retry the original request with the new token
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return apiClient(originalRequest);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        // Logout the user or redirect to login page
        clearTokens();
        if (typeof window !== 'undefined') {
          window.location.href = '/login';
        }
        return Promise.reject(refreshError);
      }
    }

    // If the error is not due to an expired token, reject the promise
    return Promise.reject(error);
  }
);

// Helper function to handle API errors
const handleApiError = (error) => {
  if (error.response) {
    console.error('API Error Response:', error.response.data);
    console.error('API Error Status:', error.response.status);
    console.error('API Error Headers:', error.response.headers);
  } else if (error.request) {
    console.error('API Error Request:', error.request);
  } else {
    console.error('API Error Message:', error.message);
  }
  console.error('API Error Config:', error.config);

  throw error;
};

// API methods
const api = {
  get: async (url, config = {}) => {
    try {
      const response = await apiClient.get(url, config);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  post: async (url, data, config = {}) => {
    try {
      if (data instanceof FormData) {
        config.headers = { 'Content-Type': 'multipart/form-data' };
      }
      
      const response = await apiClient.post(url, data, config);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  put: async (url, data, config = {}) => {
    try {
      if (data instanceof FormData) {
        config.headers = { 'Content-Type': 'multipart/form-data' };
      }
      const response = await apiClient.put(url, data, config);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  delete: async (url, config = {}) => {
    try {
      const response = await apiClient.delete(url, config);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  patch: async (url, data, config = {}) => {
    try {
      const response = await apiClient.patch(url, data, config);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
};

export default api;