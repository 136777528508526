import React from 'react';

const BasicInfoForm = ({ formData, handleChange, parentLocation }) => {
  return (
    <div className="space-y-4">
      {parentLocation && (
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Parent Location
          </label>
          <input
            type="text"
            value={`${parentLocation.name} (${parentLocation.type})`}
            readOnly
            className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>
      )}

      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        />
      </div>

      <div>
        <label htmlFor="type" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Type
        </label>
        <input
          type="text"
          id="type"
          name="type"
          value={formData.type}
          readOnly
          className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        />
      </div>

      <div>
        <label htmlFor="intro" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Introduction
        </label>
        <textarea
          id="intro"
          name="intro"
          value={formData.intro}
          onChange={handleChange}
          rows="3"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        ></textarea>
      </div>

      <div>
        <label htmlFor="population" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Population
        </label>
        <input
          type="number"
          id="population"
          name="population"
          value={formData.population}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        />
      </div>
    </div>
  );
};

export default BasicInfoForm;