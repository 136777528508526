// App.js
import React from 'react';
import './global.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Router and Route-related components

import { AuthProvider } from './context/auth/AuthContext';
import { UserSessionProvider } from './context/control/UserSessionContent';
import { ThemeProvider } from './context/ui/ThemeContext';
import { SearchModeProvider } from './context/control/SearchModeContext';
import { ScreenSizeProvider } from './context/ui/ScreenSizeContext';
import { PinnedPropertiesProvider } from './context/control/PinnedPropertiesContext';
import { AnalyticsProvider } from './context/control/AnalyticsContext';
import Layout from './components/global/Layout';
import ProtectedRoute from './hooks/control/ProtectedRoute';

import Home from './pages/Home';
import Search from './pages/Search';
import Profile from './pages/Profile';
import Login from './pages/Login';
import Register from './pages/Register';
import Compare from './pages/Compare';
import Message from './pages/Message';
import CreateListing from './pages/cms/CreateListing';
import ManageListing from './pages/cms/ManageListing';
import CreateContent from './pages/cms/CreateContent';
import ManageContent from './pages/cms/ManageContent';
import EditContent from './pages/cms/EditContent';
import Content from './pages/Content';
import ListingDashboard from './pages/dashboard/ListingDashboard';

import ManageLocation from './pages/admin/ManageLocation';
import Location from './pages/location/Location';
import LocationDetails from './pages/location/LocationDetails';

import CreateProject from './pages/marketers/CreateProject';
import EditProject from './pages/marketers/EditProject';
import ManageProjects from './pages/marketers/ManageProjects';
import Project from './pages/Project';
import Projects from './pages/Projects';

function App() {
  return (
    <UserSessionProvider>
      <AuthProvider toolType="jade">
        <Router>
          <ThemeProvider>
            <ScreenSizeProvider>
              <SearchModeProvider>
                <AnalyticsProvider>
                  <PinnedPropertiesProvider>
                    <Layout>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/register' element={<Register />} />
                        <Route path="/search" element={<Search />} />
                        <Route path="/compare" element={<Compare />} />
                        <Route path="/project" element={<Projects />} />
                        <Route path="/project/:path" element={<Project />} />
                        <Route path="/profile" element={<ProtectedRoute element={<Profile />} authOnly={true} />} />
                        <Route path="/messages" element={<ProtectedRoute element={<Message />} authOnly={true} />} />
                        <Route path="/location" element={<Location />} />
                        <Route path="/content/:id" element={<Content />} />
                        <Route path="/location/:region?/:district?/:township?/:area?/:building?" element={<LocationDetails />} />
                        <Route path="/cms/create-listing" element={<ProtectedRoute element={<CreateListing />} authOnly={true} roles={['lister','admin']} />} />
                        <Route path="/cms/manage-listing" element={<ProtectedRoute element={<ManageListing />} authOnly={true} roles={['lister','admin']} />} />
                        <Route path="/cms/listing-dashboard" element={<ProtectedRoute element={<ListingDashboard />} authOnly={true} roles={['lister','admin']} />} />
                        <Route path='/cms/create-content' element={<ProtectedRoute element={<CreateContent />} authOnly={true} roles={['contributor','admin']} />} />
                        <Route path='/cms/manage-content' element={<ProtectedRoute element={<ManageContent />} authOnly={true} roles={['contributor','admin']} />} />
                        <Route path='/cms/edit-content/:id' element={<ProtectedRoute element={<EditContent />} authOnly={true} roles={['contributor','admin']} />} />
                        <Route path='/project/create-project' element={<ProtectedRoute element={<CreateProject />} authOnly={true} roles={['marketer','admin']} />} />
                        <Route path='/project/edit/:id' element={<ProtectedRoute element={<EditProject />} authOnly={true} roles={['marketer','admin']} />} />
                        <Route path="/project/manage-project" element={<ProtectedRoute element={<ManageProjects />} authOnly={true} roles={['marketer','admin']} />} />
                        <Route path='/admin/manage-location/' element={<ProtectedRoute element={<ManageLocation />} authOnly={true} roles={['admin']} />} />
                      </Routes>
                    </Layout>
                  </PinnedPropertiesProvider>
                  </AnalyticsProvider>
                </SearchModeProvider>
              </ScreenSizeProvider>
            </ThemeProvider>
          </Router>
        </AuthProvider>
      </UserSessionProvider>
  );
}

export default App;
