import React from 'react';

const StatisticsForm = ({ statistics, handleNestedChange }) => {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Statistics</h3>
      
      <div>
        <label htmlFor="medianIncome" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Median Income
        </label>
        <input
          type="number"
          id="medianIncome"
          name="medianIncome"
          value={statistics.medianIncome || ''}
          onChange={(e) => handleNestedChange(e, 'statistics')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        />
      </div>

      <div>
        <label htmlFor="employmentRate" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Employment Rate (%)
        </label>
        <input
          type="number"
          id="employmentRate"
          name="employmentRate"
          value={statistics.employmentRate || ''}
          onChange={(e) => handleNestedChange(e, 'statistics')}
          min="0"
          max="100"
          step="0.1"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        />
      </div>
    </div>
  );
};

export default StatisticsForm;