import React from 'react';

const FeatureListView = ({ content }) => {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-6">Features</h2>
        <ul className="list-disc list-inside">
          {content.features && content.features.map((feature, index) => (
            <li key={index} className="text-lg mb-2">{feature}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FeatureListView;