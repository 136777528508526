import React, { useMemo, useState, useRef, useEffect } from 'react';
import { MessageSquare, MapPin } from 'lucide-react';
import BubbleChart from '../analytics/chart/BubbleChart';

const EnquirerInsights = ({ uniqueEnquirers, listingPerformance, listings }) => {
  const [selectedEnquirer, setSelectedEnquirer] = useState(null);
  const [hoveredEnquirer, setHoveredEnquirer] = useState(null); // State to track hovered enquirer
  const containerRef = useRef(null); // Reference to the container for click outside detection

  const getRandomLetters = () => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return letters.charAt(Math.floor(Math.random() * 26)) + letters.charAt(Math.floor(Math.random() * 26));
  };

  const enquirerData = useMemo(() => {
    if (!uniqueEnquirers || !listingPerformance) return [];

    return uniqueEnquirers.map(enquirerId => {
      const interestedListings = Object.entries(listingPerformance)
        .filter(([, performance]) => performance.uniqueEnquirers && performance.uniqueEnquirers.includes(enquirerId))
        .map(([listingId]) => listingId);

      return {
        label: `Enquirer: ${getRandomLetters()}`,
        value: interestedListings.length,
        listingIds: interestedListings,
      };
    }).sort((a, b) => b.value - a.value);
  }, [uniqueEnquirers, listingPerformance]);

  const locationData = useMemo(() => {
    if (!listingPerformance || !listings) return [];

    if (!selectedEnquirer) {
      const locationCounts = {};
      Object.entries(listingPerformance).forEach(([listingId, performance]) => {
        const listing = listings.find(l => l.listingId === listingId);
        if (listing) {
          const locationString = [listing.township, listing.district, listing.region]
            .filter(Boolean)
            .join(', ');
          locationCounts[locationString] = (locationCounts[locationString] || 0) + performance.uniqueEnquirers.length;
        }
      });

      return Object.entries(locationCounts)
        .map(([name, value]) => ({ label: name, value }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 10);
    }

    const selectedEnquirerData = enquirerData.find(e => e.label === selectedEnquirer);
    if (!selectedEnquirerData) return [];

    const filteredLocations = {};
    selectedEnquirerData.listingIds.forEach(listingId => {
      const listing = listings.find(l => l.listingId === listingId);
      if (listing) {
        const locationString = [listing.township, listing.district, listing.region]
          .filter(Boolean)
          .join(', ');
        filteredLocations[locationString] = (filteredLocations[locationString] || 0) + 1;
      }
    });

    return Object.entries(filteredLocations)
      .map(([name, value]) => ({ label: name, value }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 10);
  }, [listingPerformance, listings, selectedEnquirer, enquirerData]);

  const enquirerGradient = ['#3b82f6', '#1d4ed8'];  // Default blue gradient for enquirers
  const locationGradient = ['#10b981', '#065f46'];

  const handleReset = () => {
    setSelectedEnquirer(null);
  };

  // Handle click outside to reset filter
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        handleReset();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Memoized version of the BubbleChart to prevent re-renders
  const memoizedBubbleChart = useMemo(() => (
    <BubbleChart 
      data={locationData}
      title="Popular Locations"
      icon={MapPin}
      iconColor="green"
      colorGradient={locationGradient}
    />
  ), [locationData, locationGradient]);

  // Rendering static circles for enquirers
  const renderEnquirerCircles = () => {
    return enquirerData.map((enquirer, index) => (
      <div
        key={index}
        className={`cursor-pointer rounded-full text-center ${
          selectedEnquirer === enquirer.label ? 'text-white' : 'text-white'
        } transition duration-300 ease-in-out`}
        style={{
          width: '60px',
          height: '60px',
          display: 'inline-block',
          margin: '10px',
          lineHeight: '60px',  // Center text vertically
          textAlign: 'center',  // Center text horizontally
          background: 'linear-gradient(145deg, #3b82f6, #1d4ed8)', // Default blue gradient for enquirers
          boxShadow: selectedEnquirer === enquirer.label || hoveredEnquirer === enquirer.label
            ? '0px 8px 15px rgba(0, 128, 255, 0.6)'  // Stronger shadow for selected/hovered
            : '0px 4px 6px rgba(0, 0, 0, 0.1)',  // Default shadow for 3D effect
          border: selectedEnquirer === enquirer.label || hoveredEnquirer === enquirer.label
            ? '2px solid rgba(0, 128, 255, 1)'  // Add a border for selected/hovered enquirer
            : '1px solid rgba(255, 255, 255, 0.4)',  // Subtle border in normal state
          transform: selectedEnquirer === enquirer.label || hoveredEnquirer === enquirer.label ? 'scale(1.1)' : 'scale(1)',  // Scale up when selected/hovered
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',  // Smooth scaling and shadow change
          position: 'relative', // For tooltip positioning
        }}
        onClick={(e) => {
          e.stopPropagation();  // Prevent event from triggering BubbleChart hover effect
          setSelectedEnquirer(enquirer.label === selectedEnquirer ? null : enquirer.label);
        }}
        onMouseEnter={() => setHoveredEnquirer(enquirer.label)}  // Set hovered state on mouse enter
        onMouseLeave={() => setHoveredEnquirer(null)}  // Clear hovered state on mouse leave
      >
        {enquirer.label.substring(9)} {/* Only show the random 2-letter part */}
        {/* Tooltip for number of enquiries */}
        {hoveredEnquirer === enquirer.label && (
          <div
            className="absolute left-full top-full transform translate-x-2 translate-y-2 px-3 py-2 text-sm rounded shadow-lg bg-white dark:bg-gray-700 border border-gray-300 dark:border-white text-black dark:text-white flex items-center space-x-1"
          >
            <span>Enquiries:</span> <span className="font-bold">{enquirer.value}</span>
          </div>
        )}
      </div>
    ));
  };

  return (
    <div ref={containerRef} className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 relative">
      <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Enquirer Insights</h2>

      <div className="flex flex-wrap">
        {/* Enquirer Activity: Render static circles */}
        <div className="w-full md:w-1/2 p-2 z-20">
          <h3 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">
            <MessageSquare className="h-5 w-5 inline mr-2 text-blue-500" /> Enquirer Activity
          </h3>
          <div className="flex flex-wrap">
            {renderEnquirerCircles()}
          </div>
        </div>

        {/* Popular Locations: Memoized BubbleChart */}
        <div className="w-full md:w-1/2 p-2">
          {memoizedBubbleChart}
        </div>
      </div>
    </div>
  );
};

export default EnquirerInsights;
