import React, { useState, useRef } from 'react';
import { PlusIcon, XMarkIcon, CloudArrowUpIcon } from '@heroicons/react/24/solid';

const FloorPlans = ({ content, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [plans, setPlans] = useState(content.plans || []);
  const fileInputRef = useRef(null);

  const handleSave = () => {
    onUpdate({ plans });
    setIsEditing(false);
  };

  const handleDiscard = () => {
    setPlans(content.plans || []);
    setIsEditing(false);
  };

  const handleAddPlan = () => {
    setPlans([...plans, { name: '', image: '', details: '' }]);
  };

  const handlePlanChange = (index, field, value) => {
    const newPlans = [...plans];
    newPlans[index] = { ...newPlans[index], [field]: value };
    setPlans(newPlans);
  };

  const handleRemovePlan = (index) => {
    const newPlans = plans.filter((_, i) => i !== index);
    setPlans(newPlans);
  };

  const handleImageUpload = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        handlePlanChange(index, 'image', reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  if (isEditing) {
    return (
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">Edit Floor Plans</h2>
        {plans.map((plan, index) => (
          <div key={index} className="mb-4 p-4 border dark:border-gray-600 rounded-lg">
            <input
              type="text"
              value={plan.name}
              onChange={(e) => handlePlanChange(index, 'name', e.target.value)}
              placeholder="Plan Name"
              className="w-full p-2 mb-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent"
            />
            <div className="mb-2">
              <label className="flex items-center px-4 py-2 bg-white dark:bg-gray-700 text-primary-500 dark:text-primary-400 rounded-lg shadow-sm tracking-wide border border-primary-500 dark:border-primary-400 cursor-pointer hover:bg-primary-500 hover:text-white dark:hover:bg-primary-600">
                <CloudArrowUpIcon className="w-5 h-5 mr-2" />
                <span>Upload Floor Plan Image</span>
                <input
                  type="file"
                  className="hidden"
                  onChange={(e) => handleImageUpload(index, e)}
                  accept="image/*"
                />
              </label>
            </div>
            {plan.image && (
              <img src={plan.image} alt={plan.name} className="w-full h-48 object-cover mb-2 rounded-md" />
            )}
            <textarea
              value={plan.details}
              onChange={(e) => handlePlanChange(index, 'details', e.target.value)}
              placeholder="Plan Details"
              className="w-full p-2 mb-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent"
              rows="3"
            />
            <button 
              onClick={() => handleRemovePlan(index)} 
              className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        ))}
        <button 
          onClick={handleAddPlan} 
          className="mt-4 flex items-center text-primary-600 hover:text-primary-800 dark:text-primary-400 dark:hover:text-primary-300"
        >
          <PlusIcon className="h-5 w-5 mr-1" />
          Add Floor Plan
        </button>
        <div className="mt-6 flex justify-end space-x-4">
          <button 
            onClick={handleDiscard} 
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-gray-400 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Discard
          </button>
          <button 
            onClick={handleSave} 
            className="px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Save
          </button>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="bg-gray-100 dark:bg-gray-700 p-8 rounded-lg shadow-md cursor-pointer" 
      onClick={() => setIsEditing(true)}
    >
      <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-gray-100">Floor Plans</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {plans.map((plan, index) => (
          <div key={index} className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
            <img src={plan.image} alt={plan.name} className="w-full h-48 object-cover mb-4 rounded-md" />
            <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">{plan.name}</h3>
            <p className="text-gray-600 dark:text-gray-400">{plan.details}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FloorPlans;