import React, { useState, useRef } from 'react';
import { Camera } from 'lucide-react';
import { motion } from 'framer-motion';

const ProfileInformation = ({ profile, updateProfile }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState(profile);
  const [profilePicture, setProfilePicture] = useState(null);
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    setEditedProfile({ ...editedProfile, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(file);
      const previewUrl = URL.createObjectURL(file);
      setEditedProfile({ ...editedProfile, profilePicture: previewUrl });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(editedProfile).forEach(key => {
      if (key !== 'profilePicture') {
        formData.append(key, editedProfile[key]);
      }
    });
    if (profilePicture) {
      formData.append('profilePicture', profilePicture);
    }
    await updateProfile(formData);
    setIsEditing(false);
    if (editedProfile.profilePicture && editedProfile.profilePicture.startsWith('blob:')) {
      URL.revokeObjectURL(editedProfile.profilePicture);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="mb-6 flex justify-center">
        <motion.div 
          className={`relative w-40 h-40 rounded-full overflow-hidden ${isEditing ? 'cursor-pointer' : ''}`}
          whileHover={isEditing ? { scale: 1.05 } : {}}
          whileTap={isEditing ? { scale: 0.95 } : {}}
          onClick={isEditing ? triggerFileInput : undefined}
        >
          <img src={editedProfile.profilePicture || profile.profilePicture} alt="Profile" className="w-full h-full object-cover" />
          {isEditing && (
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <Camera size={32} className="text-white" />
            </div>
          )}
        </motion.div>
      </div>
      <input
        ref={fileInputRef}
        id="profile-picture"
        type="file"
        onChange={handleFileChange}
        className="hidden"
        accept="image/*"
      />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Name</label>
          <input
            type="text"
            name="name"
            value={editedProfile.name || ''}
            onChange={handleChange}
            disabled={!isEditing}
            className="mt-1 block w-full rounded-md border-gray-300 text-primary-600 shadow-sm focus:border-primary-500 focus:ring focus:ring-primary-500 focus:ring-opacity-50 disabled:bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Display Name</label>
          <input
            type="text"
            name="displayName"
            value={editedProfile.displayName || ''}
            onChange={handleChange}
            disabled={!isEditing}
            className="mt-1 block w-full rounded-md border-gray-300 text-primary-600 shadow-sm focus:border-primary-500 focus:ring focus:ring-primary-500 focus:ring-opacity-50 disabled:bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
          <input
            type="email"
            name="email"
            value={editedProfile.email || ''}
            onChange={handleChange}
            disabled={!isEditing}
            className="mt-1 block w-full rounded-md border-gray-300 text-primary-600 shadow-sm focus:border-primary-500 focus:ring focus:ring-primary-500 focus:ring-opacity-50 disabled:bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Phone Number</label>
          <input
            type="tel"
            name="phoneNumber"
            value={editedProfile.phoneNumber || ''}
            onChange={handleChange}
            disabled={!isEditing}
            className="mt-1 block w-full rounded-md border-gray-300 text-primary-600 shadow-sm focus:border-primary-500 focus:ring focus:ring-primary-500 focus:ring-opacity-50 disabled:bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>
      </div>

      <div className="flex justify-end space-x-2">
        {isEditing ? (
          <>
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors duration-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50 transition-colors duration-200"
            >
              Save Changes
            </button>
          </>
        ) : (
          <button
            type="button"
            onClick={() => setIsEditing(true)}
            className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50 transition-colors duration-200"
          >
            Edit Profile
          </button>
        )}
      </div>
    </form>
  );
};

export default ProfileInformation;