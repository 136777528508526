import React, { useState } from 'react';
import MemoizedQuillEditor from '../../shared/MemoizedQuillEditor';

const ProjectOverview = ({ content, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(content.description || '<p>Add your project description here</p>');

  const handleSave = () => {
    onUpdate({ description });
    setIsEditing(false);
  };

  const handleDiscard = () => {
    setDescription(content.description || '<p>Add your project description here</p>');
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <MemoizedQuillEditor
          value={description}
          onChange={setDescription}
          minHeight="200px"
        />
        <div className="mt-4 flex justify-end space-x-4">
          <button 
            onClick={handleDiscard} 
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-gray-400 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Discard
          </button>
          <button 
            onClick={handleSave} 
            className="px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Save
          </button>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="bg-gray-100 dark:bg-gray-700 p-8 rounded-lg cursor-pointer" 
      onClick={() => setIsEditing(true)}
    >
      <h2 className="text-3xl font-bold mb-4 text-gray-900 dark:text-gray-100">Project Overview</h2>
      <div 
        className="text-lg quill-content text-gray-800 dark:text-gray-200" 
        dangerouslySetInnerHTML={{ __html: description }} 
      />
    </div>
  );
};

export default ProjectOverview;