import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { CameraIcon, ClipboardDocumentListIcon, EyeIcon } from '@heroicons/react/24/outline';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropertyCard from '../../components/card/PropertyCard';
import PropertyDetailsModal from '../../components/modal/PropertyDetailModal';
import Pagination from '../../components/multipurpose/Pagination';
import StepNavigation from '../../components/cms/shared/StepNavigation';
import ListingDetails from '../../components/cms/listing/ListingDetails';
import PhotoUpload from '../../components/cms/shared/PhotoUpload';
import ListingPreview from '../../components/cms/listing/ListingPreview';
import backendApi from '../../api/backendApi';
import Header from '../../components/global/Header';

const steps = [
    { label: 'Upload Photo', icon: CameraIcon },
    { label: 'Listing Details', icon: ClipboardDocumentListIcon },
    { label: 'Preview', icon: EyeIcon },
  ]

const ManageListing = () => {
  const navigate = useNavigate();
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentFilters, setCurrentFilters] = useState({
    searchText: '',
    listingType: '',
    propertyType: '',
    status: '',
    priceMin: '',
    priceMax: '',
    creationDateStart: null,
    creationDateEnd: null,
    expirationDateStart: null,
    expirationDateEnd: null,
    sort: 'date_desc'
  });
  const [appliedFilters, setAppliedFilters] = useState({...currentFilters});
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedListing, setSelectedListing] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingStep, setEditingStep] = useState(0);
  const [editingListing, setEditingListing] = useState(null);
  const [isGeneratingDescription, setIsGeneratingDescription] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const fetchListings = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await backendApi.listings.fetchListerListings(true, {
        ...appliedFilters,
        page: currentPage,
        limit: 12
      });
      setListings(result.listings);
      setTotalPages(result.totalPages);
      setCurrentPage(result.currentPage);
    } catch (err) {
      setError('Failed to fetch listings. Please try again.');
      console.error('Error fetching listings:', err);
    } finally {
      setIsLoading(false);
    }
  }, [appliedFilters, currentPage]);

  useEffect(() => {
    fetchListings();
  }, [fetchListings]);

  const handleFilterChange = useCallback((e) => {
    const { name, value } = e.target;
    setCurrentFilters(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleDateChange = useCallback((date, name) => {
    setCurrentFilters(prev => ({ ...prev, [name]: date }));
  }, []);

  const handleViewDetails = useCallback((listingId) => {
    const listing = listings.find(l => l.listingId === listingId);
    setSelectedListing(listing);
    setIsModalOpen(true);
  }, [listings]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedListing(null);
  }, []);

  const resetFilters = useCallback(() => {
    const initialFilters = {
      searchText: '',
      listingType: '',
      propertyType: '',
      status: '',
      priceMin: '',
      priceMax: '',
      creationDateStart: null,
      creationDateEnd: null,
      expirationDateStart: null,
      expirationDateEnd: null,
      sort: 'date_desc'
    };
    setCurrentFilters(initialFilters);
    setAppliedFilters(initialFilters);
    setCurrentPage(1);
    fetchListings();
  }, [fetchListings]);

  const handleStatusToggle = useCallback(async (listingId, currentStatus) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
    const message = newStatus === 'active' ? ' Listing is now online' : ' Listing is now offline';
    try {
      await backendApi.listings.updateStatus(listingId, newStatus);
      alert(message);
      fetchListings();
      setIsModalOpen(false);
    } catch (err) {
      console.error('Error updating listing status:', err);
      alert('Failed to update listing status. Please try again.');
    }
  }, [fetchListings]);

  const handleDelete = useCallback(async (listingId) => {
    if (window.confirm('Are you sure you want to delete this listing?')) {
      try {
        await backendApi.listings.deleteListing(listingId);
        fetchListings();
      } catch (err) {
        console.error('Error deleting listing:', err);
        alert('Failed to delete listing. Please try again.');
      }
    }
  }, [fetchListings]);

  const handleEdit = useCallback((listingId) => {
    const listing = listings.find(l => l.listingId === listingId);
    setEditingListing(listing);
    setIsEditing(true);
    setEditingStep(0);
    setIsModalOpen(false);
    setHasChanges(false);  // Reset hasChanges when starting to edit
  }, [listings]);

  const handleCreateNew = useCallback(() => {
    navigate('/cms/create-listing');
  }, [navigate]);

  const handleUpdateListing = useCallback((updatedDetails) => {
    setEditingListing(prevListing => {
      const newListing = {
        ...prevListing,
        ...updatedDetails
      };
      
      // Check if there are any changes
      const hasChanges = Object.keys(updatedDetails).some(key => 
        JSON.stringify(prevListing[key]) !== JSON.stringify(newListing[key])
      );
      
      setHasChanges(hasChanges);
      
      return newListing;
    });
  }, []);

  const handleFinishEditing = useCallback(async () => {
    if (!hasChanges) {
      setIsEditing(false);
      setEditingListing(null);
      return;
    }

    try {
      setIsLoading(true);
      const formData = new FormData();
      const { poi, ...listingDataWithoutPOI } = editingListing;
      const cleanedHashtags = listingDataWithoutPOI.hashtags
        ? listingDataWithoutPOI.hashtags.filter(tag => tag.trim() !== '')
        : [];
      const listingDataToSend = {
        ...listingDataWithoutPOI,
        hashtags: cleanedHashtags,
      };
      formData.append('listingData', JSON.stringify(listingDataToSend));
      editingListing.photos.forEach((photo, index) => {
        if(photo.file) {
          formData.append('photos', photo.file);
        }
        else {
          formData.append('photos', photo);
        }
      });
      await backendApi.listings.update(listingDataToSend.listingId, formData);
      setIsEditing(false);
      setEditingListing(null);
      alert('Listing updated successfully');
      setHasChanges(false);
      await fetchListings();
    } catch (error) {
      console.error('Error updating listing:', error);
      alert('Failed to update listing. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [editingListing, fetchListings, hasChanges]);

  const handleApplyFilters = useCallback(() => {
    setAppliedFilters(currentFilters);
    setCurrentPage(1);
    fetchListings();
  }, [currentFilters, fetchListings]);

  const renderEditingProcess = () => {
    switch (editingStep) {
      case 0:
        return <PhotoUpload photos={editingListing.photos} updatePhotos={(photos) => handleUpdateListing({photos})} />;
      case 1:
        return <ListingDetails 
          details={editingListing} 
          updateDetails={handleUpdateListing}
          isEditMode={true}
          setIsGeneratingDescription={setIsGeneratingDescription}
        />;
      case 2:
        return <ListingPreview listingData={editingListing} />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900">
      <Header title={"Manage Listings"} />
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 p-4">
        <main className="flex-grow container mx-auto p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 sm:p-6">
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-3xl font-bold text-primary-800 dark:text-white">Listings</h1>
              <button
                onClick={handleCreateNew}
                className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 transition-colors duration-300 flex items-center"
              >
                <PlusIcon className="w-5 h-5 mr-2" />
                  New
              </button>
            </div>
            
            {!isEditing ? (
              <>
                <div className="mb-6 bg-white dark:bg-gray-800 rounded-lg shadow p-4">
                  <div className="flex items-center justify-between mb-4">
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-white">Filters</h2>
                    <button
                      onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                      className="text-primary-600 hover:text-primary-700 dark:text-primary-400 dark:hover:text-primary-300 transition-colors duration-300"
                    >
                      {showAdvancedFilters ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
                    </button>
                  </div>
                    
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                    <div className="relative">
                      <input
                        type="text"
                        name="searchText"
                        placeholder="Search listings..."
                        value={currentFilters.searchText}
                        onChange={handleFilterChange}
                        className="w-full p-2 pl-10 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                      />
                      <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    </div>
                    <select
                      name="status"
                      value={currentFilters.status}
                      onChange={handleFilterChange}
                      className="w-full p-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                    >
                      <option value="">All Statuses</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                      <option value="expired">Expired</option>
                    </select>
                    <select
                      name="sort"
                      value={currentFilters.sort}
                      onChange={handleFilterChange}
                      className="w-full p-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                    >
                      <option value="date_desc">Newest First</option>
                      <option value="date_asc">Oldest First</option>
                      <option value="price_desc">Price High to Low</option>
                      <option value="price_asc">Price Low to High</option>
                    </select>
                  </div>
                    
                  {showAdvancedFilters && (
                    <>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                      <select
                        name="listingType"
                        value={currentFilters.listingType}
                        onChange={handleFilterChange}
                        className="w-full p-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                      >
                        <option value="">All Listing Types</option>
                        <option value="Sale">Sale</option>
                        <option value="Rent">Rent</option>
                      </select>
                      <select
                        name="propertyType"
                        value={currentFilters.propertyType}
                        onChange={handleFilterChange}
                        className="w-full p-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                      >
                        <option value="">All Property Types</option>
                        <option value="Apartment">Apartment</option>
                        <option value="Terrace House">Terrace House</option>
                        <option value="Condominium">Condominium</option>
                        <option value="Serviced Residence">Serviced Residence</option>
                        <option value="Bungalow">Bungalow</option>
                      </select>
                      <div className="flex space-x-2">
                        <input
                          type="number"
                          name="priceMin"
                          placeholder="Min Price"
                          value={currentFilters.priceMin}
                          onChange={handleFilterChange}
                          className="w-1/2 p-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                        />
                        <input
                          type="number"
                          name="priceMax"
                          placeholder="Max Price"
                          value={currentFilters.priceMax}
                          onChange={handleFilterChange}
                          className="w-1/2 p-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mb-4">
                      <div className="relative w-full z-30">
                        <div className="flex space-x-2">
                          <div className="relative">
                            <DatePicker
                              selected={currentFilters.creationDateStart}
                              onChange={(date) => handleDateChange(date, 'creationDateStart')}
                              placeholderText="Creation Date From"
                              className="w-full p-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white gap-4 mb-4"
                            />
                          </div>
                          <div className="relative">
                            <DatePicker
                              selected={currentFilters.creationDateEnd}
                              onChange={(date) => handleDateChange(date, 'creationDateEnd')}
                              placeholderText="Creation Date To"
                              className="w-full p-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white gap-4 mb-4"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    </>
                  )}
                    
                  <div className="flex justify-end space-x-2">
                    <button
                      onClick={resetFilters}
                      className="px-4 py-2 bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-white rounded-md hover:bg-gray-300 dark:hover:bg-gray-500 transition-colors duration-300"
                    >
                      Reset Filters
                    </button>
                    <button
                      onClick={handleApplyFilters}
                      className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 transition-colors duration-300"
                    >
                      Apply Filters
                    </button>
                  </div>
                </div>

                {isLoading ? (
                  <div className="flex justify-center items-center h-screen">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-600"></div>
                  </div>
                ) : error ? (
                  <div className="text-center py-10 text-red-600 dark:text-red-400">{error}</div>
                ) : listings.length === 0 ? (
                  <div className="text-center py-10 text-gray-600 dark:text-gray-400">No listings found.</div>
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {listings.map(listing => (
                      <PropertyCard
                        key={listing.listingId}
                        property={listing}
                        onViewDetails={() => handleViewDetails(listing.listingId)}
                        onEdit={() => handleEdit(listing.listingId)}
                        showEditOptions={true}
                        onToggleStatus={() => handleStatusToggle(listing.listingId, listing.status)}
                        onDelete={() => handleDelete(listing.listingId)}
                      />
                    ))}
                  </div>
                )}

              {totalPages > 1 && (
                <div className="mt-6">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                  />
                </div>
              )}
            </>
            ) : (
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4">
                <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">Edit Listing</h2>
                <StepNavigation
                  steps={steps}
                  currentStep={editingStep}
                  setCurrentStep={setEditingStep}
                  maxStepReached={steps.length - 1}
                />
                {renderEditingProcess()}
                  <div className="mt-4 flex justify-between">
                  {editingStep > 0 && (
                    <button
                      onClick={() => setEditingStep(prev => prev - 1)}
                      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-300"
                    >
                      Previous
                    </button>
                  )}
                  <button
                    onClick={() => setIsEditing(false)}
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors duration-300"
                  >
                    Cancel
                  </button>
                  {editingStep < steps.length - 1 ? (
                    <button
                      onClick={() => setEditingStep(prev => prev + 1)}
                      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-300"
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      onClick={handleFinishEditing}
                      className={`px-4 py-2 text-white rounded transition-colors duration-300 ${
                        hasChanges 
                          ? 'bg-green-500 hover:bg-green-600' 
                          : 'bg-gray-400 cursor-not-allowed'
                      }`}
                      disabled={isLoading || !hasChanges}
                    >
                    {isLoading ? 'Saving...' : 'Save Changes'}
                      </button>
                  )}
                </div>
                {editingStep === steps.length - 1 && !hasChanges && (
                  <p className="text-sm text-gray-500 mt-2">
                    No changes detected. Make some changes to enable saving.
                </p>
              )}
            </div>
          )}

            <PropertyDetailsModal
              propertyId={selectedListing?.listingId}
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onEdit={() => handleEdit(selectedListing?.listingId)}
              showEditOption={true}
              onToggleStatus={() => handleStatusToggle(selectedListing?.id, selectedListing?.status)}
            />
          </div>
        </main>
      </div>
    </div>
  );
};

export default ManageListing;