import React, { useState } from 'react';
import PhotoUpload from '../../shared/PhotoUpload';

const ImageGallery = ({ content, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [images, setImages] = useState(content.images || []);

  const handleSave = () => {
    onUpdate({ images });
    setIsEditing(false);
  };

  const handleDiscard = () => {
    setImages(content.images || []);
    setIsEditing(false);
  };

  const updatePhotos = (newPhotos) => {
    setImages(newPhotos);
  };

  if (isEditing) {
    return (
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <PhotoUpload
          photos={images}
          updatePhotos={updatePhotos}
          maxPhotos={20}
          photosPerRow={5}
          containerWidth="100%"
          containerHeight="auto"
        />
        <div className="mt-4 flex justify-end space-x-4">
          <button 
            onClick={handleDiscard} 
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-gray-400 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Discard
          </button>
          <button 
            onClick={handleSave} 
            className="px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Save
          </button>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="relative overflow-hidden h-64 cursor-pointer bg-gray-100 dark:bg-gray-700 rounded-lg" 
      onClick={() => setIsEditing(true)}
    >
      {images.length > 0 ? (
        <div className="flex animate-carousel">
          {images.concat(images).map((image, index) => (
            <img 
              key={index} 
              src={image.url || image.preview} 
              alt={`Gallery image ${index + 1}`} 
              className="w-64 h-64 object-cover flex-shrink-0"
            />
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center h-full text-gray-500 dark:text-gray-400">
          Click to add images
        </div>
      )}
    </div>
  );
};

export default ImageGallery;