import React, { useState, useEffect, Suspense, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/global/Header';
import AdvancedSearch from '../components/search/AdvancedSearch';
import AIChat from '../components/search/AISearch';
import PropertySearchResults from '../components/search/PropertySearchResults';
import PropertyDetailsModal from '../components/modal/PropertyDetailModal';
import { useSearchMode } from '../context/control/SearchModeContext';
import { useScreenSize } from '../context/ui/ScreenSizeContext';
import { MagnifyingGlassIcon, SparklesIcon, ChevronDoubleDownIcon, LockClosedIcon, LockOpenIcon, MapIcon, ListBulletIcon, NoSymbolIcon } from '@heroicons/react/24/outline';
import backendApi from '../api/backendApi';
import { motion, AnimatePresence } from 'framer-motion';
import { usePinnedProperties } from '../context/control/PinnedPropertiesContext';
import ScrollableCardSection from '../components/multipurpose/ScrollableCardSection';
import { useAnalytics } from '../context/control/AnalyticsContext';

const MapView = React.lazy(() => import('../components/search/MapView'));

const SearchPageContent = () => {
  const { isAIMode, toggleAIMode } = useSearchMode();
  const [searchPerformed, setSearchPerformed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const scrollPositionRef = useRef(0);
  const [isMobileSidebarCollapsed, setIsMobileSidebarCollapsed] = useState(false);
  const [isSidebarLocked, setIsSidebarLocked] = useState(false); // New state to lock the sidebar
  const { isMobile } = useScreenSize();
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState({ properties: [], articles: [], guides: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortOption, setSortOption] = useState('relevance');
  const [categories, setCategories] = useState({
    property: true,
    article: true,
    guide: true
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);

  const [isListView, setIsListView] = useState(true);
  const [isMapView, setIsMapView] = useState(false);
  const { pinnedProperties, pinProperty, unpinProperty } = usePinnedProperties();
  const { gaService, trackingService } = useAnalytics();

  const searchParams = new URLSearchParams(location.search);
  const listingType = searchParams.get('listingType') || '';
  const propertyType = searchParams.get('propertyType') || '';
  const region = searchParams.get('region') || '';
  const district = searchParams.get('district') || '';
  const township = searchParams.get('township') || '';
  const area = searchParams.get('area') || '';
  const building = searchParams.get('building') || '';
  const query = searchParams.get('query') || '';

  const [filters, setFilters] = useState({
    query,
    property: true,
    article: true,
    guide: true,
    listingType,
    propertyType,
    region: '',
    district: '',
    township: '',
    area: '',
    buildingName: '',
    priceMin: '',
    priceMax: '',
    bedrooms: '',
    bathrooms: '',
    size: '',
  });

  const [trackedImpressions, setTrackedImpressions] = useState(new Set());

  const handleImpressions = useCallback((impressedProperties, pageNumber) => {
    if (pageNumber !== currentPage || impressedProperties.length === 0) return;

    const newImpressions = impressedProperties.filter(
      property => !trackedImpressions.has(property.listingId)
    );

    if (newImpressions.length === 0) return;

    console.log('Tracking impressions:', newImpressions.length); // Debug log

    trackingService.trackListingImpression({
      listingIds: newImpressions.map(listing => listing.listingId),
      searchQuery: filters.query,
      filters: filters,
      page: pageNumber,
      sortOption: sortOption,
      viewType: isListView ? 'list' : 'map',
      impressionCount: newImpressions.length
    });

    gaService.trackEvent({
      action: 'listing_impression',
      category: 'listing_interaction',
      label: `Page ${pageNumber} | ${sortOption} | ${isListView ? 'List' : 'Map'}`,
      value: newImpressions.length
    });

    setTrackedImpressions(prev => new Set([...prev, ...newImpressions.map(p => p.listingId)]));
  }, [currentPage, filters, sortOption, isListView, trackingService, gaService, trackedImpressions]);

  const fetchResults = useCallback(async (currentFilters, page = 1, sort = sortOption) => {
    setIsLoading(true);
    try {
      const response = await backendApi.listings.fetch({
        ...currentFilters,
        page,
        limit: 10,
        sort
      });

      setSearchResults({
        properties: response.listings,
        articles: [],
        guides: [],
      });
      setTotalPages(response.totalPages);
      setCurrentPage(page);
      setSearchPerformed(true);

      // Call handleImpressions here instead of in a separate useEffect
      handleImpressions(response.listings, page);
    } catch (error) {
      console.error('Error fetching results:', error);
    } finally {
      setIsLoading(false);
    }
  }, [sortOption, handleImpressions]);

  useEffect(() => {
    if (searchPerformed) {
      fetchResults(filters, currentPage);
    }
  }, [currentPage, fetchResults, searchPerformed, filters]);

  const toggleSearchMode = () => {
    toggleAIMode(!isAIMode);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchResults(filters, 1, sortOption);
    const searchQuery = new URLSearchParams(filters).toString();
    navigate(`/search?${searchQuery}`);
  };

  const handleFilterChange = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const handleSortChange = (e) => {
    const newSortOption = e.target.value;
    setSortOption(newSortOption);
    if (searchPerformed) {
      fetchResults(filters, currentPage, newSortOption);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleMobileSidebar = () => {
    if (!isSidebarLocked) {
      setIsMobileSidebarCollapsed(!isMobileSidebarCollapsed);
    }
  };

  const toggleSidebarLock = () => {
    setIsSidebarLocked(!isSidebarLocked);
  };

  useEffect(() => {
    if (!isMobile) {
      // Reset sidebar for desktop view
      setIsMobileSidebarCollapsed(false);
      setIsSidebarLocked(false); // Unlock the sidebar when on desktop view
    }
  }, [isMobile]);

  const toggleListView = () => {
    if (isListView && !isMapView) return; // Prevent disabling the only active view
    setIsListView(prev => !prev);
    if (!isListView && !isMapView) {
      setIsMapView(true);
    }
  };

  const toggleMapView = () => {
    if (isMapView && !isListView) return; // Prevent disabling the only active view
    setIsMapView(prev => !prev);
    if (!isListView && !isMapView) {
      setIsListView(true);
    }
  };

  const handleViewDetails = useCallback((propertyId) => {
    // Capture the scroll position before opening the modal
    scrollPositionRef.current = window.scrollY;

    // Lock the body scroll
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollPositionRef.current}px`;
    document.body.style.width = '100%';

    setSelectedPropertyId(propertyId);
    setIsModalOpen(true);

    gaService.trackEvent({
      action: 'view_property_details',
      category: 'listing_interaction',
      label: propertyId
    });
    trackingService.trackListingView(propertyId);
  }, [gaService, trackingService]);

  const handleCloseModal = () => {
    // Restore the body styles immediately
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = '';
  
    // Defer the scroll restoration until after the DOM updates
    setTimeout(() => {
      window.scrollTo(0, scrollPositionRef.current);
    }, 0);
  
    setIsModalOpen(false);
  };

  const handleSendAIMessage = async (message, aiResponse) => {
    try {
      if (aiResponse.showListings) {
        setIsLoading(true);
        const propertyListings = aiResponse.listings;
        setSearchResults(prevResults => ({
          ...prevResults,
          properties: propertyListings
        }));
        setTotalPages(Math.ceil(propertyListings.length / 10));
        setCurrentPage(1);
        setSearchPerformed(true);

        if (aiResponse.queryParams) {
          const searchQuery = new URLSearchParams(aiResponse.queryParams).toString();
          navigate(`/search?${searchQuery}`);
          setFilters(prevFilters => ({
            ...prevFilters,
            ...aiResponse.queryParams
          }));
        }
      } else {
        console.log("AI response didn't include listings");
      }
    } catch (error) {
      console.error('Failed to process AI response:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900">
      <Header title={"Search"} />
      <main className="w-full px-4 sm:px-6 lg:px-8 py-8 flex flex-col md:flex-row gap-8">
        {/* Sidebar */}
        <motion.aside
          className={`w-full md:w-1/3 transition-all duration-300 ${
            isMobileSidebarCollapsed ? 'overflow-hidden' : ''
          }`}
          initial="expanded"
          animate={isMobileSidebarCollapsed && isMobile ? 'collapsed' : 'expanded'}
        >
          <AnimatePresence>
            {!isMobileSidebarCollapsed && (
              <motion.div
                className="bg-white dark:bg-neutral-800 p-4 rounded-lg shadow-md"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <button
                  onClick={toggleSearchMode}
                  className={`w-full mb-4 py-2 px-4 rounded-md flex items-center justify-center transition-colors duration-200 ${
                    isAIMode
                      ? 'bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white'
                      : 'bg-gradient-to-r from-primary-400 to-primary-600 hover:from-primary-500 hover:to-primary-700 text-white'
                  }`}
                >
                  {isAIMode ? (
                    <>
                      <MagnifyingGlassIcon className="h-5 w-5 mr-2" />
                      Switch to Traditional Search
                    </>
                  ) : (
                    <>
                      <SparklesIcon className="h-5 w-5 mr-2" />
                      Switch to AI Search
                    </>
                  )}
                </button>
                {isAIMode ? (
                  <AIChat onSendMessage={handleSendAIMessage} />
                ) : (
                  <AdvancedSearch
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onSearch={handleSearch}
                  />
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </motion.aside>

        {/* Expand/Collapse and Lock/Unlock Buttons for mobile */}
        {isMobile && (
          <div className="md:hidden flex justify-center mt-2 relative">
            {/* Wrap both buttons together */}
            <div className="flex space-x-4 absolute bottom-5">
              {/* Lock/Unlock Button */}
              <motion.button
                onClick={toggleSidebarLock}
                className="p-2 rounded-full shadow-md bg-white dark:bg-neutral-800 text-primary-600 dark:text-primary-400"
              >
                {isSidebarLocked ? <LockClosedIcon className="h-4 w-4" /> : <LockOpenIcon className="h-4 w-4" />}
              </motion.button>

              {/* Expand/Collapse Button */}
              {!isSidebarLocked && (
                <motion.button
                  onClick={toggleMobileSidebar}
                  className={`p-2 rounded-full shadow-md bg-white dark:bg-neutral-800 text-primary-600 dark:text-primary-400 ${
                    isSidebarLocked ? 'cursor-not-allowed opacity-50' : ''
                  }`} // Disable when locked
                  animate={{ rotate: isMobileSidebarCollapsed ? 0 : 180 }} // Rotate animation for the icon
                  transition={{ duration: 0.5 }}
                >
                  <ChevronDoubleDownIcon className="h-4 w-4" />
                </motion.button>
              )}
            </div>
          </div>
        )}

        {/* Main Content */}
        <motion.div
          className="flex-grow"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
        >
          {searchPerformed ? (
            <>
              {isLoading ? (
                <div className="flex justify-center items-center h-screen">
                  <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-600"></div>
                </div>
              ) : (
                <>
                {searchResults.properties.length > 0 ? (
                  <div>
                    <div className="flex justify-between items-center mb-6">
                      <h2 className="text-2xl font-semibold text-primary-700 dark:text-neutral-200">Results</h2>
                      <div className="flex items-center space-x-4">
                      <div className="relative">
                        <select
                          value={sortOption}
                          onChange={handleSortChange}
                          className="appearance-none bg-white dark:bg-neutral-700 border border-neutral-300 dark:border-neutral-600 text-neutral-700 dark:text-neutral-200 py-2 px-4 pr-8 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                        >
                          <option value="relevance">Sort: Relevance</option>
                          <option value="price_asc">Price: Low to High</option>
                          <option value="price_desc">Price: High to Low</option>
                          <option value="date_desc">Newest First</option>
                          <option value="date_asc">Oldest First</option>
                          </select>
                        </div>
                        <div className="flex space-x-2">
                          <button
                            onClick={toggleMapView}
                            className={`p-2 rounded-full shadow ${isMapView ? 'bg-primary-600 text-white' : 'bg-white text-primary-600'} ${isMapView && !isListView ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={isMapView && !isListView}
                          >
                            <MapIcon className="h-5 w-5" />
                          </button>
                          <button
                            onClick={toggleListView}
                            className={`p-2 rounded-full shadow ${isListView ? 'bg-primary-600 text-white' : 'bg-white text-primary-600'} ${isListView && !isMapView ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={isListView && !isMapView}
                          >
                            <ListBulletIcon className="h-5 w-5" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {pinnedProperties.length > 0 && (
                      <ScrollableCardSection
                        title="Pinned"
                        cards={pinnedProperties}
                        isPinnedSection={true}
                      />
                    )}

                    {isMapView && (
                      <div className="w-full flex-grow mb-4 rounded-full">
                        <Suspense fallback={<div>Loading Map...</div>}>
                          <MapView properties={searchResults.properties} onViewDetails={handleViewDetails} />
                        </Suspense>
                      </div>
                    )}

                    {isListView && (
                      <div className="w-full flex-grow mb-4 rounded-full">
                        <PropertySearchResults
                          properties={searchResults.properties}
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                          onViewDetails={handleViewDetails}
                          onPin={pinProperty}
                          onUnpin={unpinProperty}
                        />
                      </div>
                    )}

                    {categories.article && searchResults.articles.length > 0 && (
                      <ScrollableCardSection
                        title="Related Articles"
                        cards={searchResults.articles}
                        moreLink="/articles"
                      />
                    )}

                    {categories.guide && searchResults.guides.length > 0 && (
                      <ScrollableCardSection
                        title="Helpful Guides"
                        cards={searchResults.guides}
                        moreLink="/guides"
                      />
                    )}
                  </div>
                ) : (
                  <div className="bg-white dark:bg-neutral-800 rounded-lg shadow-md p-8 flex flex-col items-center justify-center text-center h-full">
                    <NoSymbolIcon className="h-24 w-24 text-primary-600 dark:text-primary-400 mx-auto mb-6" />
                    <h2 className="text-2xl font-bold mb-4 text-neutral-800 dark:text-neutral-200">No Property Found</h2>
                    <p className="text-lg text-neutral-600 dark:text-neutral-400">
                      Please try some other search criteria
                    </p>
                  </div>
                )}
                </>
              )}
            </>
          ) : (
            <div className="bg-white dark:bg-neutral-800 rounded-lg shadow-md p-8 flex flex-col items-center justify-center text-center h-full">
              {isAIMode ? (
                <SparklesIcon className="h-24 w-24 text-primary-600 dark:text-primary-400 mx-auto mb-6" />
              ) : (
                <MagnifyingGlassIcon className="h-24 w-24 text-primary-600 dark:text-primary-400 mx-auto mb-6" />
              )}
              <h2 className="text-2xl font-bold mb-4 text-neutral-800 dark:text-neutral-200">Ready to start your search?</h2>
              <p className="text-lg text-neutral-600 dark:text-neutral-400">
                {isAIMode
                  ? "Use the AI chat to describe what you're looking for. Our AI will help you find the perfect property!"
                  : "Use the advanced search panel to set your preferences and find your ideal property."}
              </p>
            </div>
          )}
        </motion.div>
      </main>
      <PropertyDetailsModal 
        propertyId={selectedPropertyId}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default function Search() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SearchPageContent />
    </Suspense>
  );
}