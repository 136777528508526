import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import backendApi from '../api/backendApi';
import SideNavigation from '../components/profile/SideNavigation';
import ProfileInformation from '../components/profile/ProfileInformation'
import PasswordChange from '../components/profile/PasswordChange';
import UserPreferences from '../components/profile/UserPreferences';
import { useAuth } from '../context/auth/AuthContext';
import Header from '../components/global/Header';

export default function UserProfilePage() {
  const [profile, setProfile] = useState(null);
  const [preferences, setPreferences] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('profile');
  const { auth } = useAuth();

  useEffect(() => {
    fetchProfileAndPreferences();
  }, []);

  const fetchProfileAndPreferences = async () => {
    setIsLoading(true);
    try {
      const [profileResponse, preferencesResponse] = await Promise.all([
        backendApi.user.getUserProfile(),
        backendApi.preferences.getUserPreferences()
      ]);
      setProfile(profileResponse);
      setPreferences(preferencesResponse);
    } catch (error) {
      toast.error('Failed to load profile and preferences');
    } finally {
      setIsLoading(false);
    }
  };

  const updateProfile = async (updatedProfile) => {
    try {
      const response = await backendApi.user.updateUserProfile(updatedProfile);
      setProfile(response.data);
      toast.success('Profile updated successfully');
    } catch (error) {
      toast.error('Failed to update profile');
    }
  };

  const updatePreferences = async (updatedPreferences) => {
    try {
      await backendApi.preferences.updateExplicitPreferences(updatedPreferences);
      setPreferences(updatedPreferences);
      toast.success('Preferences updated successfully');
    } catch (error) {
      toast.error('Failed to update preferences');
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (!profile || !preferences) {
    return <div className="text-center mt-8 text-gray-600 dark:text-gray-400">No profile data available</div>;
  }

  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900">
    <Header title={"User Profile"} />
      <div className="max-w-7xl mx-auto mt-8 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md">        
        <div className="flex flex-col md:flex-row gap-8">
          <div className="md:w-1/4">
            <SideNavigation activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
            
          <div className="md:w-3/4">
            {activeTab === 'profile' && (
              <ProfileInformation profile={profile} updateProfile={updateProfile} />
            )}
            {activeTab === 'preferences' && (
              <UserPreferences preferences={preferences} updatePreferences={updatePreferences} />
            )}
            {activeTab === 'password' && (
              <PasswordChange />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}