import React from 'react';

const AddressForm = ({ address, handleNestedChange }) => {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Address</h3>
      
      <div>
        <label htmlFor="street" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Street
        </label>
        <input
          type="text"
          id="street"
          name="street"
          value={address?.street || ''}
          onChange={(e) => handleNestedChange(e, 'address')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        />
      </div>

      <div>
        <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Postal Code
        </label>
        <input
          type="text"
          id="postalCode"
          name="postalCode"
          value={address?.postalCode || ''}
          onChange={(e) => handleNestedChange(e, 'address')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        />
      </div>

      <div>
        <label htmlFor="city" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          City
        </label>
        <input
          type="text"
          id="city"
          name="city"
          value={address?.city || ''}
          onChange={(e) => handleNestedChange(e, 'address')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        />
      </div>

      <div>
        <label htmlFor="lat" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Latitude
        </label>
        <input
          type="number"
          id="lat"
          name="lat"
          value={address?.coordinates?.lat || ''}
          onChange={(e) => handleNestedChange(e, 'address.coordinates')}
          step="any"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        />
      </div>

      <div>
        <label htmlFor="lng" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Longitude
        </label>
        <input
          type="number"
          id="lng"
          name="lng"
          value={address?.coordinates?.lng || ''}
          onChange={(e) => handleNestedChange(e, 'address.coordinates')}
          step="any"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        />
      </div>
    </div>
  );
};

export default AddressForm;