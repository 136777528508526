import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

import HeroSection from './block/HeroSection';
import ImageGallery from './block/ImageGallery';
import ProjectOverview from './block/ProjectOverview';
import FeatureList from './block/FeatureList';
import FloorPlans from './block/FloorPlans';
import DocumentUpload from './block/DocumentUpload';
import LocationMap from './block/LocationMap';
import ContactForm from './block/ContactForm';
import Testimonials from './block/Testimonials';
import PriceList from './block/PriceList';
import CallToAction from './block/CallToAction';
import { PlusIcon, XMarkIcon, ArrowLeftIcon, TrashIcon, ArrowDownTrayIcon, DocumentCheckIcon } from '@heroicons/react/24/solid';

const blockTypes = {
    hero: { name: 'Hero Section', component: HeroSection, isResizable: false },
    gallery: { name: 'Image Gallery', component: ImageGallery, isResizable: false },
    overview: { name: 'Project Overview', component: ProjectOverview, isResizable: true },
    features: { name: 'Feature List', component: FeatureList, isResizable: true },
    floorPlans: { name: 'Floor Plans', component: FloorPlans, isResizable: false },
    documents: { name: 'Document Upload', component: DocumentUpload, isResizable: false },
    location: { name: 'Location Map', component: LocationMap, isResizable: true },
    contact: { name: 'Contact Form', component: ContactForm, isResizable: true },
    testimonials: { name: 'Testimonials', component: Testimonials, isResizable: true },
    priceList: { name: 'Price List', component: PriceList, isResizable: true },
    cta: { name: 'Call to Action', component: CallToAction, isResizable: true },
  };
  
  const generateUniqueId = () => uuidv4();
  
  const DroppableArea = ({ blocks, setBlocks }) => {
    const [isMounted, setIsMounted] = useState(false);
    const [resizing, setResizing] = useState(null);
    const blockRefs = useRef({}); // Refs to store block heights dynamically
    const initialHeights = useRef({}); // Store initial heights here
  
    useEffect(() => {
      setIsMounted(true);
    }, []);

  // Handle the start of resizing
  const handleResizeStart = (e, blockId) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent drag from starting
    setResizing(blockId);
  };

  // Handle the resizing logic
  const handleResizing = (e) => {
    if (!resizing) return;
    const newBlocks = blocks.map((block) => {
      if (block.id === resizing) {
        const minHeight = initialHeights.current[block.id] || 100; // Use the initially captured height as min height
        const newHeight = Math.max(minHeight, e.clientY - blockRefs.current[block.id].getBoundingClientRect().top);
        return { ...block, height: newHeight };
      }
      return block;
    });
    setBlocks(newBlocks);
  };

  // Handle the end of resizing
  const handleResizeEnd = () => {
    setResizing(null);
  };

  useEffect(() => {
    if (resizing) {
      window.addEventListener('mousemove', handleResizing);
      window.addEventListener('mouseup', handleResizeEnd);
    }
    return () => {
      window.removeEventListener('mousemove', handleResizing);
      window.removeEventListener('mouseup', handleResizeEnd);
    };
  }, [resizing]);

  if (!isMounted) {
    return null;
  }

  return (
    <Droppable droppableId="page-builder">
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="min-h-screen"
        >
          {blocks.length > 0
            ? blocks.map((block, index) => {
                const BlockComponent = blockTypes[block.type].component;
                return (
                  <Draggable key={block.id} draggableId={String(block.id)} index={index}>
                    {(provided, snapshot) => (
                      <div
                        id={block.id}
                        ref={(el) => {
                          provided.innerRef(el);
                          blockRefs.current[block.id] = el; // Store reference to each block
                          if (!initialHeights.current[block.id] && el) {
                            // Capture the initial height only once
                            initialHeights.current[block.id] = el.offsetHeight;
                          }
                        }}
                        {...provided.draggableProps}
                        className="mb-4 border border-gray-300 dark:border-gray-600 rounded relative"
                        style={{ 
                          ...provided.draggableProps.style,
                          height: block.height || 'auto',
                        }}
                      >
                        <div 
                          {...provided.dragHandleProps}
                          className="bg-gray-200 dark:bg-gray-700 p-2 flex justify-between items-center cursor-move"
                        >
                          <span className="text-gray-700 dark:text-gray-300">{blockTypes[block.type].name}</span>
                          <button
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent drag from starting
                              setBlocks(prevBlocks => prevBlocks.filter(b => b.id !== block.id));
                            }}
                            className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                          >
                            Remove
                          </button>
                        </div>
                        <div className="p-4">
                          <BlockComponent
                            content={block.content}
                            onUpdate={newContent =>
                              setBlocks(prevBlocks =>
                                prevBlocks.map(b =>
                                  b.id === block.id ? { ...b, content: newContent } : b
                                )
                              )
                            }
                          />
                        </div>
                        {blockTypes[block.type].isResizable && !snapshot.isDragging && (
                          <div
                            className="absolute bottom-0 left-0 right-0 h-2 bg-blue-500 cursor-ns-resize"
                            onMouseDown={(e) => handleResizeStart(e, block.id)}
                          />
                        )}
                      </div>
                    )}
                  </Draggable>
                );
              })
            : <p className="text-gray-500 dark:text-gray-400">No blocks available</p>}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const ProjectForm = ({ initialProject, onSave, onDiscard }) => {
    const [blocks, setBlocks] = useState(initialProject?.blocks || []);
    const [projectName, setProjectName] = useState(initialProject?.name || '');
    const [isEditingName, setIsEditingName] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const projectNameInputRef = useRef(null);
    const [saveStatus, setSaveStatus] = useState('disabled'); // 'disabled', 'draft', 'publish'
  
    const handleDiscardCreation = () => {
      setBlocks([]);
      setProjectName('');
    };
  
    const onDragEnd = (result) => {
      if (!result.destination) return;
  
      const items = Array.from(blocks);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
  
      setBlocks(items);
    };
  
    const addBlock = (blockType) => {
      const newBlock = {
        id: generateUniqueId(),
        type: blockType,
        content: {}
      };
      setBlocks(prevBlocks => [...prevBlocks, newBlock]);
    };
  
    const handleProjectNameClick = () => {
      setIsEditingName(true);
    };
  
    const handleProjectNameBlur = () => {
      setIsEditingName(false);
    };
  
    const handleProjectNameKeyDown = (e) => {
      if (e.key === 'Enter') {
        setIsEditingName(false);
      }
    };
  
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
  
    const handleDiscardBlocks = () => {
      if (window.confirm('Are you sure you want to discard all blocks? This action cannot be undone.')) {
        setBlocks([]);
      }
    };
  
    const validateBlocks = () => {
      if (blocks.length === 0) {
        setSaveStatus('disabled');
        return;
      }
  
      let isComplete = true;
      let hasContent = false;
  
      for (const block of blocks) {
        switch (block.type) {
          case 'hero':
            if (!block.content.title || !block.content.subtitle || !block.content.backgroundImage) {
              isComplete = false;
            }
            if (block.content.title !== 'Welcome to our project' || block.content.subtitle !== 'Welcome to our project') {
              hasContent = true;
            }
            break;
          case 'gallery':
            if (!block.content.images || block.content.images.length === 0) {
              isComplete = false;
            } else {
              hasContent = true;
            }
            break;
          case 'overview':
            if (!block.content.description) {
              isComplete = false;
            }
            if (block.content.description !== 'This is an overview of our project.') {
              hasContent = true;
            }
            break;
          case 'features':
            if (!block.content.features || block.content.features.length === 0) {
              isComplete = false;
            } else {
              hasContent = true;
            }
            break;
          case 'floorPlans':
            if (!block.content.plans || block.content.plans.length === 0) {
              isComplete = false;
            } else {
              hasContent = true;
            }
            break;
          case 'documents':
            if (!block.content.documents || block.content.documents.length === 0) {
              isComplete = false;
            } else {
              hasContent = true;
            }
            break;
          case 'location':
            if (!block.content.address) {
              isComplete = false;
            } else {
              hasContent = true;
            }
            break;
          case 'contact':
            // Assuming the contact form is always complete as it's typically pre-structured
            hasContent = true;
            break;
          case 'testimonials':
            if (!block.content.testimonials || block.content.testimonials.length === 0) {
              isComplete = false;
            } else {
              hasContent = true;
            }
            break;
          case 'priceList':
            if (!block.content.prices || block.content.prices.length === 0) {
              isComplete = false;
            } else {
              hasContent = true;
            }
            break;
          case 'cta':
            if (!block.content.title || !block.content.description || !block.content.buttonText || !block.content.buttonLink) {
              isComplete = false;
            } else {
              hasContent = true;
            }
            break;
          default:
            console.warn(`Unknown block type: ${block.type}`);
        }
  
        if (!isComplete) break;
      }
  
      if (isComplete && hasContent) {
        setSaveStatus('publish');
      } else if (blocks.length > 0) {
        setSaveStatus('draft');
      } else {
        setSaveStatus('disabled');
      }
    };
  
    useEffect(() => {
      validateBlocks();
    }, [blocks]);
  
    const handleSaveProject = async (status) => {
      try {
        const projectData = {
          name: projectName,
          status: status,
          blocks: blocks.map(block => {
            const blockElement = document.getElementById(block.id);
            let width, height, x, y;
  
            if (blockElement) {
              const rect = blockElement.getBoundingClientRect();
              width = rect.width;
              height = rect.height;
              x = blockElement.offsetLeft;
              y = blockElement.offsetTop;
            }
  
            const blockData = { 
              ...block,
              width: width,
              height: height,
              x: x,
              y: y
            };
            
            // Handle file conversions for each block type
            switch (block.type) {
              case 'hero':
                if (block.content.backgroundImage && block.content.backgroundImage.startsWith('data:')) {
                  blockData.content.backgroundImage = dataURLtoFile(block.content.backgroundImage, 'hero_background.jpg');
                }
                break;
              case 'gallery':
                if (block.content.images) {
                  blockData.content.images = block.content.images.map((image, index) => {
                    if (image.startsWith('data:')) {
                      return dataURLtoFile(image, `gallery_image_${index}.jpg`);
                    }
                    return image;
                  });
                }
                break;
              case 'floorPlans':
                if (block.content.plans) {
                  blockData.content.plans = block.content.plans.map((plan, index) => {
                    if (plan.image && plan.image.startsWith('data:')) {
                      return {
                        ...plan,
                        image: dataURLtoFile(plan.image, `floorplan_${index}.jpg`)
                      };
                    }
                    return plan;
                  });
                }
                break;
              case 'documents':
                // Documents are already handled as File objects in the DocumentUpload component
                break;
              default:
            }
            return blockData;
          })
        };
  
        await onSave(projectData, status);
        // TODO: Add success notification
      } catch (error) {
        console.error('Error saving project:', error);
        // TODO: Add error notification
      }
    };
  
    // Helper function to convert data URLs to File objects
    const dataURLtoFile = (dataurl, filename) => {
      let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
    };
  
    useEffect(() => {
      if (isEditingName && projectNameInputRef.current) {
        projectNameInputRef.current.focus();
      }
    }, [isEditingName]);
  
    return (
      <div className="p-4 md:p-6 lg:p-8">
        <div className="flex flex-col md:flex-row">
          {/* Sidebar for larger screens */}
          <div className="hidden md:block w-64 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md mr-6">
            <h2 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Content Blocks</h2>
            {Object.entries(blockTypes).map(([type, { name }]) => (
              <button
                key={type}
                onClick={() => addBlock(type)}
                className="block w-full text-left p-2 mb-2 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 rounded text-gray-800 dark:text-gray-200"
              >
                {name}
              </button>
            ))}
            <button
              onClick={handleDiscardBlocks}
              className="mt-4 flex items-center text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
            >
              <TrashIcon className="h-5 w-5 mr-1" />
              Discard
            </button>
            <button
              onClick={() => handleSaveProject('draft')}
              disabled={saveStatus === 'disabled'}
              className={`mt-4 flex items-center ${
              saveStatus === 'disabled'
                  ? 'text-gray-400 cursor-not-allowed'
                  : 'text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300'
              }`}
            >
              <ArrowDownTrayIcon className="h-5 w-5 mr-1" />
              Save as Draft
            </button>
            <button
              onClick={() => handleSaveProject('published')}
              disabled={saveStatus !== 'publish'}
              className={`mt-4 flex items-center ${
              saveStatus !== 'publish'
                  ? 'text-gray-400 cursor-not-allowed'
                  : 'text-green-600 hover:text-green-800 dark:text-green-400 dark:hover:text-green-300'
              }`}
            >
              <DocumentCheckIcon className="h-5 w-5 mr-1" />
              Publish
            </button>
            <button
              onClick={onDiscard}
              className="mt-4 flex items-center text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
            >
              <ArrowLeftIcon className="h-5 w-5 mr-1" />
              Discard Changes
            </button>
          </div>

          {/* Mobile add button */}
          <button
            className="p-3 md:hidden bg-primary-600 text-white hover:bg-primary-700 transition-colors duration-200 bottom-4 left-4 z-20 rounded-full shadown-lg fixed"
            onClick={toggleSidebar}
          >
            <PlusIcon className="h-5 w-5" />
          </button>

          {/* Mobile sidebar */}
          {isSidebarOpen && (
            <div className="md:hidden fixed inset-0 bg-gray-600 bg-opacity-75 z-20">
              <div className="fixed inset-y-0 right-0 max-w-xs w-full bg-white dark:bg-gray-800 shadow-xl p-6">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100">Content Blocks</h2>
                <button onClick={toggleSidebar}>
                  <XMarkIcon className="h-6 w-6 text-gray-600 dark:text-gray-400" />
                </button>
              </div>
              {Object.entries(blockTypes).map(([type, { name }]) => (
                <button
                  key={type}
                  onClick={() => {
                    addBlock(type);
                    toggleSidebar();
                  }}
                  className="block w-full text-left p-2 mb-2 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 rounded text-gray-800 dark:text-gray-200"
                >
                  {name}
                </button>
              ))}
              <button
                onClick={handleDiscardBlocks}
                className="mt-4 flex items-center text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
              >
                <TrashIcon className="h-5 w-5 mr-1" />
                Discard
              </button>
              <button
                onClick={() => handleSaveProject('draft')}
                disabled={saveStatus === 'disabled'}
                className={`mt-4 flex items-center ${
                saveStatus === 'disabled'
                  ? 'text-gray-400 cursor-not-allowed'
                  : 'text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300'
                }`}
              >
                <ArrowDownTrayIcon className="h-5 w-5 mr-1" />
                Save as Draft
              </button>
              <button
                onClick={() => handleSaveProject('published')}
                disabled={saveStatus !== 'publish'}
                className={`mt-4 flex items-center ${
                saveStatus !== 'publish'
                  ? 'text-gray-400 cursor-not-allowed'
                  : 'text-green-600 hover:text-green-800 dark:text-green-400 dark:hover:text-green-300'
                }`}
              >
                <DocumentCheckIcon className="h-5 w-5 mr-1" />
                Publish
              </button>
              <button
                onClick={onDiscard}
                className="mt-4 flex items-center text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
              >
                <ArrowLeftIcon className="h-5 w-5 mr-1" />
                Discard Changes
              </button>
            </div>
          </div>
        )}

        {/* Main content area */}
        <div className="flex-1">
          {isEditingName ? (
            <input
              ref={projectNameInputRef}
              type="text"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              onBlur={handleProjectNameBlur}
              onKeyDown={handleProjectNameKeyDown}
              className="text-2xl font-bold mb-4 w-full bg-transparent border-b border-gray-300 dark:border-gray-600 focus:outline-none focus:border-blue-500 dark:focus:border-blue-400 text-gray-900 dark:text-gray-100"
            />
          ) : (
            <h1 
              onClick={handleProjectNameClick}
              className="text-2xl font-bold mb-4 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700 p-1 rounded text-gray-900 dark:text-gray-100"
            >
              {projectName || 'Click to edit project name'}
            </h1>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <DroppableArea blocks={blocks} setBlocks={setBlocks} />
          </DragDropContext>
        </div>
      </div>
    </div>
    );
  };
  
  export default ProjectForm;
  