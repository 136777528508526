import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useUserSession } from '../../context/control/UserSessionContent';
import { MagnifyingGlassIcon, SparklesIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import backendApi from '../../api/backendApi';

const AIChat = ({ onSendMessage }) => {
  const [userInput, setUserInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const chatEndRef = useRef(null);
  const inputRef = useRef(null);
  const { sessionId } = useUserSession();

  const suggestedQueries = [
    { icon: '🏠', text: 'Find apartments in Kuala Lumpur' },
    { icon: '💰', text: 'What is the average house price in Penang?' },
    { icon: '🏙️', text: 'Best neighborhoods for families in Johor Bahru' },
    { icon: '🏫', text: 'Properties near international schools' }
  ];

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const handleSubmit = async (e, suggestedQuery = '') => {
    e?.preventDefault();
    const input = suggestedQuery || userInput.trim();
    if (input) {
      setIsLoading(true);
      const newUserMessage = { role: 'user', content: input };
  
      // Immediately update chat history with the user's message using functional update
      setChatHistory((prev) => [...prev, newUserMessage]);
      setUserInput('');
  
      try {
        const response = await backendApi.aiChat(input, sessionId);
        let aiMessageContent = response.message;
        
        if (response.showListings) {
          aiMessageContent = "I've found some properties that match your criteria. You can see them in the results panel.";
        }
        
        const newAIMessage = { role: 'assistant', content: aiMessageContent };
        
        // Update chat history with the assistant's message
        setChatHistory((prev) => [...prev, newAIMessage]);
  
        if (onSendMessage) {
          onSendMessage(input, response);
        }
  
        if (!response.showListings) {
          setTimeout(() => {
            inputRef.current?.focus();
          }, 100);
        }
      } catch (error) {
        console.error('Error in AI chat:', error);
        const errorMessage = { role: 'assistant', content: 'Sorry, I encountered an error. Please try again.' };
        
        // Update chat history with the error message
        setChatHistory((prev) => [...prev, errorMessage]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderMessage = (content) => {
    return { __html: content };
  };

  return (
    <div className="flex flex-grow flex-col bg-gray-100 dark:bg-neutral-800 rounded-lg shadow-inner" style={{height: 'calc(100vh - 14rem)'}}>
      <div className="flex-grow overflow-y-auto p-2 sm:p-4 space-y-2 sm:space-y-4 scrollbar-hide" ref={chatContainerRef}>
        {chatHistory.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full">
            <h2 className="text-2xl font-bold mb-4 text-gray-700 dark:text-gray-300">Nice to Meet You</h2>
            <p className="text-gray-600 dark:text-gray-400 mb-6">Here are some questions you can ask:</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {suggestedQueries.map((query, index) => (
                <motion.button
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-white dark:bg-gray-700 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 text-left"
                  onClick={(e) => handleSubmit(e, query.text)}
                >
                  <span className="text-2xl mr-2">{query.icon}</span>
                  <span className="text-gray-800 dark:text-gray-200">{query.text}</span>
                </motion.button>
              ))}
            </div>
          </div>
        ) : (
          chatHistory.map((message, index) => (
            <div
              key={index}
              className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
            >
              <div className={`inline-block p-2 rounded-lg ${
                  message.role === 'user' 
                    ? 'bg-primary-100 text-primary-800' 
                    : 'bg-gray-100 text-gray-800'
                }`}
                dangerouslySetInnerHTML={renderMessage(message.content)}
              >
              </div>
            </div>
          ))
        )}
        {isLoading && (
          <div className="text-center">
            <div className="inline-block p-2 rounded-lg bg-gray-100 text-gray-800">
              Thinking...
            </div>
          </div>
        )}
        <div ref={chatEndRef} />
      </div>

      <form onSubmit={handleSubmit} className="p-2 bg-transparent">
        <div className="flex items-center space-x-2">
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            className="flex-grow px-4 py-3 text-black rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-gray-800 dark:text-white dark:border-gray-600"
            placeholder="Type your message..."
            disabled={isLoading}
            ref={inputRef}
          />
          <button
            type="submit"
            className="bg-primary-600 text-white rounded-full hover:bg-primary-700 dark:bg-primary-500 dark:hover:bg-primary-600 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            disabled={isLoading}
          >
            <ArrowUpCircleIcon className="h-10 w-10" />
            {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 transform rotate-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
            </svg> */}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AIChat;