import { useEffect, useRef } from 'react';

const useClickOutside = (callback, excludeSelector) => {
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      const excludedElement = excludeSelector && document.querySelector(excludeSelector);
      
      // Check if the click is outside the component and also not on an excluded element
      if (ref.current && !ref.current.contains(event.target) && !(excludedElement && excludedElement.contains(event.target))) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [callback, excludeSelector]);

  return ref;
};

export default useClickOutside;
