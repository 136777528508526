import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ContentForm from '../../components/cms/content/ContentForm';
import Header from '../../components/global/Header';
import backendApi from '../../api/backendApi';

const EditContent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchContent = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await backendApi.content.fetchById(id);
      setContent(response);
    } catch (err) {
      console.error('Error fetching content:', err);
      setError('Failed to load content. Please try again.');
      toast.error('Failed to load content');
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  const handleUpdateContent = useCallback(async (updatedContent, status) => {
    try {
      const response = await backendApi.content.update(id, updatedContent);
      console.log('Updated content:', response); // For debugging
      toast.success(`${updatedContent.contentType} updated successfully`);
      navigate('/manage-content');
    } catch (err) {
      console.error('Error updating content:', err);
      toast.error('Failed to update content. Please try again.');
    }
  }, [id, navigate]);

  const handleDiscard = useCallback(() => {
    if (window.confirm('Are you sure you want to discard your changes? All unsaved progress will be lost.')) {
      navigate('/manage-content');
    }
  }, [navigate]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex items-center justify-center">
        <div className="text-2xl font-semibold text-gray-700 dark:text-gray-300">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex items-center justify-center">
        <div className="text-2xl font-semibold text-red-600 dark:text-red-400">{error}</div>
      </div>
    );
  }

  if (!content) {
    return (
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex items-center justify-center">
        <div className="text-2xl font-semibold text-red-600 dark:text-red-400">No content found</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900">
      <Header title={"Edit Content"} />
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col">
        <main className="flex-grow container mx-auto p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 sm:p-6">
            <h1 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">
              Edit {content.contentType}
            </h1>
            <ContentForm contentType={content.contentType} mode={"edit"} />
          </div>
        </main>
      </div>
    </div>
  );
};

export default EditContent;