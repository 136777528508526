import React, { useMemo, useRef, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from '../../context/ui/ThemeContext';
import { ScanEye, Heart, Eye, MessageSquare, Link, Scale } from 'lucide-react';

const OverviewSection = ({ listings, listingPerformance, onFilterChange, currentFilter }) => {
  const { isDarkMode } = useTheme();

  // Store refs for charts
  const chartRefs = useRef({
    listingType: null,
    propertyType: null
  });

  // Use ref to store chart context and config for event handling
  const chartStateRef = useRef(null);

  // Ref to store the latest currentFilter
  const currentFilterRef = useRef(currentFilter);

  // State to manage which segment is selected
  const [selectedSegment, setSelectedSegment] = useState(null);

  // Keep track of currentFilter changes
  useEffect(() => {
    currentFilterRef.current = currentFilter;
  }, [currentFilter]);

  // Memoized data processing
  const overviewData = useMemo(() => {
    const totalListings = listings.length;
    const listingsWithEnquiries = listings.filter(listing => 
      (listingPerformance[listing.listingId]?.enquiries || 0) > 0
    ).length;
    const listingsWithImpressions = listings.filter(listing =>
    (listingPerformance[listing.listingId]?.impressions || 0) > 0
    ).length;
    const listingsWithViews = listings.filter(listing => 
      (listingPerformance[listing.listingId]?.views || 0) > 0
    ).length;
    const listingsWithPins = listings.filter(listing => 
      (listingPerformance[listing.listingId]?.pins || 0) > 0
    ).length;
    const listingsWithComparisons = listings.filter(listing => 
      (listingPerformance[listing.listingId]?.comparisons || 0) > 0
    ).length;

    const propertyTypeCounts = listings.reduce((acc, listing) => {
      acc[listing.propertyType] = (acc[listing.propertyType] || 0) + 1;
      return acc;
    }, {});

    const listingTypeCounts = listings.reduce((acc, listing) => {
      acc[listing.listingType] = (acc[listing.listingType] || 0) + 1;
      return acc;
    }, {});

    return {
      totalListings,
      listingsWithEnquiries,
      listingsWithImpressions,
      listingsWithViews,
      listingsWithPins,
      listingsWithComparisons,
      propertyTypeCounts,
      listingTypeCounts
    };
  }, [listings, listingPerformance]);

  // Reset chart selection and state
  const resetChartSelection = () => {
    if (chartStateRef.current) {
      const { chartContext, config } = chartStateRef.current;
      
      // Prevent toggling if it's already been toggled
      if (selectedSegment !== null) {
        chartContext.toggleDataPointSelection(config.dataPointIndex);
        chartStateRef.current = null;
        setSelectedSegment(null);
      }
    }
  };
  // Handle chart clicks
  const handleChartClick = (chart, chartContext, config) => {
    const newFilter = { type: chart, value: config.w.config.labels[config.dataPointIndex] };

    // If clicked segment is already selected, reset the selection
    if (
      chartStateRef.current &&
      chartStateRef.current.config.dataPointIndex === config.dataPointIndex &&
      JSON.stringify(newFilter) === JSON.stringify(currentFilterRef.current)
    ) {
      onFilterChange(null);
      resetChartSelection();
    } else {
      // Set new selection
      onFilterChange(newFilter);
      chartStateRef.current = { chartContext, config };
      setSelectedSegment(config.dataPointIndex);
    }
  };

  // Chart options with event handling
  const chartOptions = (chartType) => ({
    chart: {
      type: 'donut',
      background: 'transparent',
      animations: {
        enabled: false, // Change to false if you want to disable animations completely
        easing: 'linear', // Use a simpler easing function ('linear', 'easein', 'easeout', 'easeinout')
        speed: 400, // Reduce the animation speed (default is 800ms)
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          handleChartClick(chartType, chartContext, config);
        }
      }
    },
    labels: chartType === 'listingType' ? Object.keys(overviewData.listingTypeCounts) : Object.keys(overviewData.propertyTypeCounts),
    colors: ['#3b82f6', '#10b981', '#f59e0b', '#ef4444', '#8b5cf6'],
    legend: {
      position: 'bottom',
      labels: {
        colors: isDarkMode ? '#e5e7eb' : '#4b5563'
      }
    },
    tooltip: {
      theme: isDarkMode ? 'dark' : 'light'
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
          labels: {
            show: true,
            name: {
              show: true,
              color: isDarkMode ? '#e5e7eb' : '#4b5563'
            },
            value: {
              show: true,
              color: isDarkMode ? '#e5e7eb' : '#4b5563'
            },
            total: {
              show: true,
              label: 'Total',
              color: isDarkMode ? '#e5e7eb' : '#4b5563'
            }
          }
        },
        expandOnClick: false
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'horizontal',
        shadeIntensity: 0.75,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [0, 100]
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [isDarkMode ? '#e5e7eb' : '#4b5563']
      },
      background: {
        enabled: true,
        foreColor: isDarkMode ? '#1f2937' : '#ffffff',
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: isDarkMode ? '#374151' : '#e5e7eb',
        opacity: 0.9,
      }
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.9,
        }
      },
      active: {
        filter: {
          type: 'darken',
          value: 0.5,
        }
      }
    }
  });

  const EngagementItem = ({ icon: Icon, label, value, color }) => (
    <div className="flex items-center mb-2">
      <Icon className={`h-5 w-5 mr-2 ${color}`} />
      <span className="text-sm text-gray-600 dark:text-gray-400">{label}:</span>
      <span className="ml-1 font-semibold text-gray-800 dark:text-gray-200">{value}%</span>
    </div>
  );

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Overview</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="flex flex-col justify-between h-full">
          <div>
            <h3 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">Engagement</h3>
            <p className="text-3xl font-bold text-primary-600 dark:text-primary-400">{overviewData.totalListings}</p>
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-4"> Listings</p>
          </div>
          <div>
            <EngagementItem 
              icon={ScanEye} 
              label="with impressions" 
              value={((overviewData.listingsWithImpressions / overviewData.totalListings) * 100).toFixed(1)}
              color="text-red-500"
            />
            <EngagementItem 
              icon={Eye} 
              label="with views" 
              value={((overviewData.listingsWithViews / overviewData.totalListings) * 100).toFixed(1)}
              color="text-blue-500"
            />
            <EngagementItem 
              icon={MessageSquare} 
              label="with enquiries" 
              value={((overviewData.listingsWithEnquiries / overviewData.totalListings) * 100).toFixed(1)}
              color="text-green-500"
            />
            {/* <EngagementItem 
              icon={Link} 
              label="being pinned" 
              value={((overviewData.listingsWithPins / overviewData.totalListings) * 100).toFixed(1)}
              color="text-purple-500"
            /> */}
            <EngagementItem 
              icon={Scale} 
              label="being compared" 
              value={((overviewData.listingsWithComparisons / overviewData.totalListings) * 100).toFixed(1)}
              color="text-yellow-500"
            />
          </div>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">Listing Types</h3>
          <div ref={el => chartRefs.current.listingType = el}>
            <Chart
              ref={el => chartRefs.current.listingType = el} // Attach chart ref dynamically
              options={chartOptions('listingType')}
              series={Object.values(overviewData.listingTypeCounts)}
              type="donut"
              height={250}
            />
          </div>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">Property Types</h3>
          <div ref={el => chartRefs.current.propertyType = el}>
            <Chart
              ref={el => chartRefs.current.propertyType = el} // Attach chart ref dynamically
              options={chartOptions('propertyType')}
              series={Object.values(overviewData.propertyTypeCounts)}
              type="donut"
              height={250}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewSection;
