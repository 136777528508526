import React, { useState } from 'react';
import { ChevronRightIcon, ChevronDownIcon, PlusIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/solid';

const LocationTree = ({ locations, onAdd, onEdit, onDelete }) => {
  const [expandedLocations, setExpandedLocations] = useState({});

  const toggleExpand = (locationId) => {
    setExpandedLocations(prev => ({
      ...prev,
      [locationId]: !prev[locationId]
    }));
  };

  const renderLocation = (location) => (
    <div key={location._id} className="mb-2">
      <div className="flex items-center space-x-2">
        {location.subLocations && location.subLocations.length > 0 ? (
          <button onClick={() => toggleExpand(location._id)} className="focus:outline-none">
            {expandedLocations[location._id] ? (
              <ChevronDownIcon className="w-4 h-4 text-gray-500 dark:text-gray-400" />
            ) : (
              <ChevronRightIcon className="w-4 h-4 text-gray-500 dark:text-gray-400" />
            )}
          </button>
        ) : (
          <span className="w-4" />
        )}
        <span className="text-gray-800 dark:text-gray-200">{location.name} ({location.type})</span>
        <button
          onClick={() => onEdit(location)}
          className="p-1 text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300"
        >
          <PencilIcon className="w-4 h-4" />
        </button>
        <button
          onClick={() => onAdd(location._id)}
          className="p-1 text-green-500 hover:text-green-600 dark:text-green-400 dark:hover:text-green-300"
        >
          <PlusIcon className="w-4 h-4" />
        </button>
        <button
          onClick={() => onDelete(location._id)}
          className="p-1 text-red-500 hover:text-red-600 dark:text-red-400 dark:hover:text-red-300"
        >
          <TrashIcon className="w-4 h-4" />
        </button>
      </div>
      {expandedLocations[location._id] && location.subLocations && location.subLocations.length > 0 && (
        <div className="ml-6 mt-2">
          {location.subLocations.map(renderLocation)}
        </div>
      )}
    </div>
  );

  return (
    <div className="max-h-96 overflow-y-auto">
      {locations.length === 0 ? (
        <div className="text-center text-gray-500 dark:text-gray-400">
          No locations available.
        </div>
      ) : (
        locations.map(renderLocation)
      )}
    </div>
  );
};

export default LocationTree;