import React from 'react';

const AmenitiesForm = ({ amenities, handleAmenitiesChange }) => {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Amenities</h3>
      
      <div>
        <label htmlFor="amenities" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Amenities (comma-separated)
        </label>
        <input
          type="text"
          id="amenities"
          name="amenities"
          value={amenities ? amenities.join(', ') : ''}
          onChange={handleAmenitiesChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          placeholder="e.g., Parks, Schools, Shopping Centers"
        />
      </div>
      
      <p className="text-sm text-gray-500 dark:text-gray-400">
        Enter amenities separated by commas. These will be used to describe the location's features.
      </p>
    </div>
  );
};

export default AmenitiesForm;