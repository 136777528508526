import React from 'react';
import Slider from 'react-slick';
import PropertyAnalysisCard from '../analytics/card/PropertyAnalysisCard';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useTheme } from '../../context/ui/ThemeContext';

const TopPerformingListingsCarousel = ({ listingPerformance, listings, metric, title }) => {
  const { isDarkMode } = useTheme();

  const sortedListings = Object.entries(listingPerformance)
    .sort(([, a], [, b]) => b[metric] - a[metric])
    .slice(0, 3);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div className="h-full flex flex-col">
      <h3 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">{title}</h3>
      <div className="flex-grow overflow-hidden">
        <Slider {...settings}>
          {sortedListings.map(([listingId, performance]) => {
            const listing = listings.find(l => l.listingId === listingId);
            return (
              <div key={listingId} className="h-full">
                <PropertyAnalysisCard
                  property={listing}
                  performance={performance}
                  focusMetric={metric}
                  compact={true} // Add a compact prop for a more condensed view
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default TopPerformingListingsCarousel;