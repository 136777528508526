import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { MessageCircle, X, ArrowLeft } from 'lucide-react';
import ChatList from './ChatList';
import ChatWindow from './ChatWindow';
import backendApi from '../../api/backendApi';
import { useSocketService } from '../../hooks/chat/socketService';
import { useAuth } from '../../context/auth/AuthContext';
import useClickOutside from '../../hooks/control/useClickOutside';

const ChatSystem = ({ 
  mode = 'inline', // 'inline', 'popup', or 'bubble'
  onClose,
  className = '',
  style = {},
  onUnreadCountUpdate,
  onMessagesRead
}) => {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [isNewChatModalOpen, setIsNewChatModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(mode !== 'bubble');
  const [isMobileView, setIsMobileView] = useState(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  
  const { auth } = useAuth();
  const { initializeSocket, onNewMessage, offNewMessage, disconnectSocket } = useSocketService();
  
  const socketInitialized = useRef(false);
  const scrollPosition = useRef(0);

  const handleResize = useCallback(() => {
    setIsMobileView(window.innerWidth <= 768);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    if (isOpen && mode !== 'inline') {
      scrollPosition.current = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosition.current}px`;
      document.body.style.width = '100%';
      document.body.style.overflowY = 'scroll';
    } else {
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      document.body.style.overflowY = '';
      window.scrollTo(0, scrollPosition.current);
    }

    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      document.body.style.overflowY = '';
    };
  }, [isOpen, mode]);

  const fetchChats = useCallback(async () => {
    try {
      const fetchedChats = await backendApi.chat.getUserChats();
      setChats(fetchedChats);
    } catch (error) {
      console.error('Failed to fetch chats:', error);
    }
  }, []);

  const handleNewMessage = useCallback(({ chatId, message }) => {
    setChats(prevChats => 
      prevChats.map(chat => 
        chat.id === chatId 
          ? { 
              ...chat, 
              lastMessage: message.text, 
              timestamp: message.timestamp,
              messages: [...(chat.messages || []), message]
            }
          : chat
      )
    );
  
    setSelectedChat(prevSelectedChat => {
      if (prevSelectedChat && prevSelectedChat.id === chatId) {
        return {
          ...prevSelectedChat,
          messages: [...(prevSelectedChat.messages || []), message]
        };
      }
      return prevSelectedChat;
    });
  
    setIsChatWindowOpen(true);
    setIsOpen(true);
  }, []);

  const handleMessageRead = useCallback(() => {
    // Your existing logic for marking messages as read
    // ...

    // Call the onMessagesRead callback
    if (onMessagesRead) {
      onMessagesRead();
    }
  }, [onMessagesRead]);

  useEffect(() => {
    if (isOpen && auth.isAuthenticated && !socketInitialized.current) {
      fetchChats();
      const socket = initializeSocket();
      socketInitialized.current = true;

      if (socket) {
        onNewMessage(handleNewMessage);
      }

      return () => {
        if (socket) {
          offNewMessage(handleNewMessage);
          disconnectSocket();
        }
        socketInitialized.current = false;
      };
    }
  }, [isOpen, auth.isAuthenticated, initializeSocket, onNewMessage, offNewMessage, disconnectSocket, fetchChats, handleNewMessage]);

  const handleSendMessage = useCallback(async (chatId, message, recipientId) => {
    try {
      const response = await backendApi.chat.sendMessage(chatId, message, recipientId);
      if (response.success) {
        handleNewMessage({ chatId, message: response.message });
      }
      return response;
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  }, [handleNewMessage]);

  const handleChatSelection = (chatId) => {
    const chat = chats.find(c => c.id === chatId);
    setSelectedChat(chat);
    
    if (isMobileView) {
      setIsChatWindowOpen(true);
    }

    setIsOpen(true);
    handleMessageRead(); // Call handleMessageRead when a chat is selected
  };

  const handleBackToList = () => {
    setIsChatWindowOpen(false);
  };

  const handleCloseChatWindow = () => {
    setSelectedChat(null);
    if (isMobileView) {
      setIsChatWindowOpen(false);
    }
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = useCallback(() => {
    if (mode !== 'inline' && isOpen && onClose) {
      onClose();
    }
  }, [mode, isOpen, onClose]);

  const chatRef = useClickOutside(handleClickOutside, '.chat-toggle-button');

  const chatContent = (
    <div ref={chatRef} className={`flex flex-col h-full rounded-lg bg-white dark:bg-gray-800 ${className}`} style={style}>
      <div className="bg-primary-700 text-white p-2 flex justify-between items-center rounded-t-lg">
        {isMobileView && isChatWindowOpen && (
          <button 
            onClick={handleBackToList} 
            className="p-1 hover:bg-primary-600 rounded transition-colors"
          >
            <ArrowLeft className="h-4 w-4" />
          </button>
        )}
        <div className="flex items-center">
          <MessageCircle className="h-4 w-4 mr-2" />
          <span className="font-bold">Messages</span>
        </div>
        {onClose && mode !== 'inline' && (
          <button 
            onClick={onClose} 
            className="p-1 hover:bg-primary-600 rounded transition-colors"
          >
            <X className="h-5 w-5" />
          </button>
        )}
      </div>
      <div className="flex-grow flex overflow-hidden">
        {(!isMobileView || !isChatWindowOpen) && (
          <div className="w-full md:w-1/3 border-r border-gray-200 dark:border-gray-700 overflow-y-auto">
            <ChatList 
              chats={chats}
              currentUser={auth.user}
              onSelectChat={handleChatSelection} 
              selectedChatId={selectedChat?.id}
            />
          </div>
        )}
        {(!isMobileView || isChatWindowOpen) && selectedChat ? (
          <div className="w-full md:w-2/3 flex-grow overflow-y-auto relative">
            <ChatWindow 
              chat={selectedChat} 
              currentUser={auth.user}
              onClose={handleCloseChatWindow}
              onSendMessage={handleSendMessage}
              onMessageRead={handleMessageRead} // Pass handleMessageRead to ChatWindow
            />
          </div>
        ) : (
          !isMobileView && (
            <div className="h-full flex-grow flex items-center justify-center text-gray-500 dark:text-gray-400">
              Select a chat to start messaging
            </div>
          )
        )}
      </div>
    </div>
  );

  if (mode === 'bubble') {
    return (
      <>
        {isOpen && (
          <div className="fixed bottom-16 right-16 w-300 h-96 bg-white dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden">
            {chatContent}
          </div>
        )}
        <button
          onClick={toggleChat}
          className="fixed bottom-4 right-16 bg-primary-600 text-white p-3 rounded-full shadow-lg hover:bg-primary-600 transition-colors duration-200 z-50"
        >
          <MessageCircle size={20} />
        </button>
      </>
    );
  }

  if (mode === 'popup') {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="w-full max-w-4xl h-[80vh] bg-white dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden">
          {chatContent}
        </div>
      </div>
    );
  }

  // Inline mode
  return chatContent;
};

export default ChatSystem;