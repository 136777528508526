import React, { useMemo, useRef, useImperativeHandle, forwardRef, useEffect, useCallback } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';

// Register modules
Quill.register('modules/imageResize', ImageResize);

if (typeof window !== 'undefined') {
  window.Quill = Quill;
}

const MemoizedQuillEditor = forwardRef(({ 
  value, 
  onChange, 
  onBlur,
  minHeight = '200px', 
  disableImageUpload = false,
  simpleToolbar = false
}, ref) => {
  const quillRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getEditor: () => quillRef.current.getEditor(),
    focus: () => quillRef.current.focus(),
  }));

  const applyStyles = useCallback(() => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      const editorContainer = editor.root;
      const toolbar = document.querySelector('.ql-toolbar');

      const isDarkMode = document.documentElement.classList.contains('dark');

      if (isDarkMode) {
        editorContainer.style.setProperty('color', '#e0e0e0', 'important');
        editorContainer.style.setProperty('background-color', '#1f2937', 'important');
        editorContainer.style.setProperty('caret-color', '#e0e0e0', 'important');

        if (toolbar) {
          toolbar.style.setProperty('background-color', '#374151', 'important');
          toolbar.style.setProperty('border-color', '#4b5563', 'important');
          toolbar.querySelectorAll('button').forEach(button => {
            button.style.setProperty('color', '#d1d5db', 'important');
          });
          toolbar.querySelectorAll('svg').forEach(svg => {
            svg.style.setProperty('filter', 'invert(0.8)', 'important');
          });
          toolbar.querySelectorAll('.ql-picker').forEach(picker => {
            picker.style.setProperty('color', '#d1d5db', 'important');
            picker.querySelector('.ql-picker-options').style.setProperty('background-color', '#374151', 'important');
            picker.querySelectorAll('.ql-picker-item').forEach(item => {
              item.style.setProperty('color', '#d1d5db', 'important');
              item.style.setProperty('background-color', '#374151', 'important');
              item.addEventListener('mouseover', () => {
                item.style.setProperty('background-color', '#4b5563', 'important');
              });
              item.addEventListener('mouseout', () => {
                item.style.setProperty('background-color', '#374151', 'important');
              });
            });
          });
        }
      } else {
        editorContainer.style.setProperty('color', '#1f2937', 'important');
        editorContainer.style.setProperty('background-color', '#ffffff', 'important');
        editorContainer.style.setProperty('caret-color', '#1f2937', 'important');

        if (toolbar) {
          toolbar.style.setProperty('background-color', '#f3f4f6', 'important');
          toolbar.style.setProperty('border-color', '#e5e7eb', 'important');
          toolbar.querySelectorAll('button').forEach(button => {
            button.style.setProperty('color', '#4b5563', 'important');
          });
          toolbar.querySelectorAll('svg').forEach(svg => {
            svg.style.setProperty('filter', 'none', 'important');
          });
          toolbar.querySelectorAll('.ql-picker').forEach(picker => {
            picker.style.setProperty('color', '#4b5563', 'important');
            picker.querySelector('.ql-picker-options').style.setProperty('background-color', '#ffffff', 'important');
            picker.querySelectorAll('.ql-picker-item').forEach(item => {
              item.style.setProperty('color', '#1f2937', 'important');
              item.style.setProperty('background-color', '#ffffff', 'important');
              item.addEventListener('mouseover', () => {
                item.style.setProperty('background-color', '#f3f4f6', 'important');
              });
              item.addEventListener('mouseout', () => {
                item.style.setProperty('background-color', '#ffffff', 'important');
              });
            });
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    applyStyles();

    const observer = new MutationObserver(() => {
      applyStyles();
    });

    observer.observe(document.documentElement, { attributes: true, attributeFilter: ['class'] });

    return () => {
      observer.disconnect();
    };
  }, [applyStyles]);

  const handleImageInsertion = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection(true);
      const tempUrl = e.target.result;
      editor.insertEmbed(range.index, 'image', tempUrl);
      editor.setSelection(range.index + 1);
    };
    reader.readAsDataURL(file);
  };

  const handlePaste = useCallback((e) => {
    const clipboard = e.clipboardData || window.clipboardData;
    if (clipboard && clipboard.items) {
      for (let i = 0; i < clipboard.items.length; i++) {
        if (clipboard.items[i].type.indexOf('image') !== -1) {
          e.preventDefault();
          const file = clipboard.items[i].getAsFile();
          handleImageInsertion(file);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (quillRef.current && !disableImageUpload) {
      const editor = quillRef.current.getEditor();
      editor.root.addEventListener('paste', handlePaste);
      return () => {
        editor.root.removeEventListener('paste', handlePaste);
      };
    }
  }, [disableImageUpload, handlePaste]);

  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      handleImageInsertion(file);
    };
  };

  const quillModules = useMemo(() => {
    const modules = {
      toolbar: {
        container: simpleToolbar
          ? [
              ['bold', 'italic', 'underline'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              ['clean']
            ]
          : [
              [{ 'header': [1, 2, 3, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              ['blockquote', 'code-block'],
              ['link'],
              ['clean']
            ],
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize']
      }
    };

    if (!disableImageUpload) {
      modules.toolbar.container.push(['image']);
      modules.toolbar.handlers = { image: imageHandler };
    }

    return modules;
  }, [disableImageUpload, simpleToolbar]);

  const quillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'blockquote', 'code-block',
    'link',
    'image'
  ];

  return (
    <div className="quill-editor-container">
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder="Start writing..."
        modules={quillModules}
        formats={quillFormats}
        theme="snow"
        style={{ minHeight }}
      />
      <style jsx global>{`
        .ql-editor img {
          max-width: 100%;
          height: auto;
          display: block;
          margin: 0 auto;
        }
        .ql-editor {
          min-height: ${minHeight};
          max-height: 500px;
          overflow-y: auto;
        }
        .ql-toolbar button,
        .ql-toolbar .ql-picker {
          color: inherit !important;
        }
        .ql-toolbar button:hover,
        .ql-toolbar button:focus,
        .ql-toolbar .ql-picker:hover,
        .ql-toolbar .ql-picker:focus {
          color: inherit !important;
        }
        .ql-toolbar button svg,
        .ql-toolbar .ql-picker svg {
          filter: inherit !important;
        }
        .ql-editor.ql-blank::before {
          color: inherit;
        }

        .ql-picker-options {
          border: none !important;
          box-shadow: 0 2px 8px rgba(0,0,0,0.15) !important;
        }
        .ql-picker-options .ql-picker-item {
          padding: 5px 10px !important;
        }
        .ql-picker-options .ql-picker-item:hover {
          background-color: rgba(0,0,0,0.1) !important;
        }

        .dark .ql-picker-options .ql-picker-item {
          color: #d1d5db !important;
        }
        .dark .ql-picker-options {
          background-color: #374151 !important;
        }
        .dark .ql-picker-options .ql-picker-item:hover {
          background-color: #4b5563 !important;
        }

        .light .ql-picker-options .ql-picker-item {
          color: #1f2937 !important;
        }
        .light .ql-picker-options {
          background-color: #ffffff !important;
        }
        .light .ql-picker-options .ql-picker-item:hover {
          background-color: #f3f4f6 !important;
        }
      `}</style>
    </div>
  );
});

export default MemoizedQuillEditor;