import React, { useState, useContext, useEffect, Suspense } from 'react';
import { PhoneIcon, EnvelopeIcon, ChatBubbleOvalLeftIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useAuth } from '../../context/auth/AuthContext';
import backendApi from '../../api/backendApi';

// Use React lazy for dynamically importing the EmailModal
const EmailModal = React.lazy(() => import('../modal/EmailModal'));

const AgentContactInfo = ({ property, isComparison = false, onInitiateChat }) => {
  const [isPhoneRevealed, setIsPhoneRevealed] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isProfilePictureZoomed, setIsProfilePictureZoomed] = useState(false);
  const { auth } = useAuth();
  
  const revealPhone = () => setIsPhoneRevealed(true);

  const handleWhatsApp = () => {
    const message = encodeURIComponent(`Hi, I'm interested in the property: ${property.title}`);
    const url = `https://wa.me/${property.lister.phoneNumber.replace(/\D/g, '')}?text=${message}`;
    window.open(url, '_blank');
  };

  const openEmailModal = () => setIsEmailModalOpen(true);

  const toggleProfilePictureZoom = () => setIsProfilePictureZoomed(!isProfilePictureZoomed);

  const handleChat = async () => {
    try {
      const locationString = [property.buildingName, property.street, property.area, property.township, property.district, property.region]
        .filter(Boolean)
        .join(', ');

      const initialMessage = `Hi, I'm interested in the property:
        ID: ${property.id}
        Type: ${property.listingType} - ${property.propertyType}
        Location: ${locationString}
        Price: ${property.price.toLocaleString('en-MY', { style: 'currency', currency: 'MYR' })}`;

      const response = await backendApi.chat.createChat(property.lister._id, initialMessage);
      
      if (onInitiateChat) {
        onInitiateChat(response.chat);
      }
    } catch (error) {
      console.error('Failed to start chat:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const ProfilePictureModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={toggleProfilePictureZoom}>
      <div className="relative max-w-3xl max-h-3xl">
        <img
          src={property.lister.profilePicture} 
          alt={`${property.lister.name}'s profile`} 
          className="max-w-full max-h-full object-contain"
          onClick={(e) => e.stopPropagation()} // Prevent closing when clicking on the image
        />
        <button
          onClick={toggleProfilePictureZoom}
          className="absolute top-2 right-2 bg-black bg-opacity-50 text-white p-1 rounded-full hover:bg-opacity-75 transition-all duration-200"
          aria-label="Close profile picture"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>
    </div>
  );

  return (
    <div className={`bg-gray-100 dark:bg-gray-700 p-3 rounded-lg ${isComparison ? 'text-sm' : ''}`}>
      <div className="flex items-center mb-3">
        <div 
          className="w-10 h-10 mr-3 overflow-hidden rounded-full flex-shrink-0 cursor-pointer"
          onClick={toggleProfilePictureZoom}
        >
          {property.lister.profilePicture ? (
            <img 
              src={property.lister.profilePicture} 
              alt={`${property.lister.name}'s profile`} 
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full bg-gray-300 dark:bg-gray-600 flex items-center justify-center text-gray-500 dark:text-gray-400 text-lg font-bold">
              {property.lister.name.charAt(0)}
            </div>
          )}
        </div>
        <div className="flex-grow">
          <p className="font-medium text-gray-700 dark:text-gray-300 text-sm">{property.lister.name}</p>
        </div>
      </div>
      <div className={`space-y-2 flex flex-col sm:flex-row sm:space-y-0 sm:space-x-2`}>
        <button
          onClick={revealPhone}
          className={`flex items-center justify-center px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 w-full sm:flex-1`}
        >
          <PhoneIcon className="h-5 w-5 mr-2" />
          {isPhoneRevealed ? property.lister.phoneNumber : 'Reveal Phone'}
        </button>
        
        <button
          onClick={handleWhatsApp}
          className={`flex items-center justify-center px-3 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-200 w-full sm:flex-1`}
        >
          <svg className="h-5 w-5 mr-2" viewBox="0 0 24 24" fill="currentColor">
            <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"/>
          </svg>
          WhatsApp
        </button>
        
        <button
          onClick={openEmailModal}
          className={`flex items-center justify-center px-3 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600 transition-colors duration-200 w-full sm:flex-1`}
        >
          <EnvelopeIcon className="h-5 w-5 mr-2" />
          Email
        </button>

        {auth.user && auth.user._id !== property.lister._id && (
          <button
            onClick={handleChat}
            className={`flex items-center justify-center px-3 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 transition-colors duration-200w-full sm:flex-1`}
          >
            <ChatBubbleOvalLeftIcon className="h-5 w-5 mr-2" />
            Chat
          </button>
        )}
      </div>
      
      <Suspense fallback={<div>Loading...</div>}>
        {isEmailModalOpen && (
          <EmailModal
            property={property}
            agent={property.lister}
            onClose={() => setIsEmailModalOpen(false)}
          />
        )}
      </Suspense>
      
      {isProfilePictureZoomed && property.lister.profilePicture && <ProfilePictureModal />}
    </div>
  );
};

export default AgentContactInfo;