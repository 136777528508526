import React from 'react';
import { ScanEye, Eye, MessageSquare, Link, Scale } from 'lucide-react';
import StatCard from './StatCard';

const PerformanceOverviewCard = ({ data }) => {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Performance Overview</h2>
      <div className="grid grid-cols-2 gap-4">
        <StatCard icon={ScanEye} title="Impressions" value={data.totalImpressions} color="bg-red-500" />
        <StatCard icon={Eye} title="Views" value={data.totalViews} color="bg-blue-500" />
        <StatCard icon={MessageSquare} title="Enquiries" value={data.totalEnquiries} color="bg-green-500" />
        {/* <StatCard icon={Link} title="Pins" value={data.totalPins} color="bg-purple-500" /> */}
        <StatCard icon={Scale} title="Compares" value={data.totalComparisons} color="bg-yellow-500" />
      </div>
    </div>
  );
};

export default PerformanceOverviewCard;