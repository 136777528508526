import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PencilIcon, TrashIcon, CubeIcon, StarIcon } from '@heroicons/react/24/solid';

const ProjectCard = ({ project, onDelete, showEditOptions = false, onViewDetails }) => {
  const navigate = useNavigate();
  const heroBlock = project.blocks.find(block => block.type === 'hero');
  const heroContent = heroBlock ? heroBlock.content : {};

  const blockCount = project.blocks.length;
  const blockTypes = [...new Set(project.blocks.map(block => block.type))];

  const handleViewDetails = () => {
    if (onViewDetails) {
      onViewDetails(project._id);
    } else {
      navigate(`/project${project.path}`);
    }
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    navigate(`/project/edit/${project._id}`);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(project._id);
  };

  return (
    <div 
      className="relative rounded-lg shadow-md overflow-hidden h-80 w-full text-left group cursor-pointer"
      onClick={handleViewDetails}
    >
      <div className="absolute inset-0 z-0">
        {heroContent.backgroundImage ? (
          <img 
            src={heroContent.backgroundImage} 
            alt={heroContent.title || project.name} 
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-300 dark:bg-gray-700 flex items-center justify-center">
            <span className="text-gray-500 dark:text-gray-400">No image available</span>
          </div>
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-black via-black/70 to-transparent opacity-80 group-hover:opacity-90 transition-opacity duration-300"></div>
      </div>
      <div className="relative z-20 flex h-full p-4 flex-col justify-between">
        <div>
          <div className="flex justify-between items-center mb-2">
            {showEditOptions && (
              <span className={`inline-block px-2 py-1 text-xs font-semibold rounded ${
                project.status === 'published' ? 'bg-green-200 text-green-800' :
                project.status === 'draft' ? 'bg-yellow-200 text-yellow-800' :
                'bg-red-200 text-red-800'
              }`}>
                {project.status.charAt(0).toUpperCase() + project.status.slice(1)}
              </span>
            )}
            {project.isFeatured && (
              <span className="inline-flex items-center px-2 py-1 rounded bg-purple-200 text-purple-800 text-xs font-semibold">
                <StarIcon className="h-3 w-3 mr-1" />
                Featured
              </span>
            )}
          </div>
          <h3 className="text-xl font-bold mb-2 text-white text-shadow">{heroContent.title || project.name}</h3>
          <p className="text-white text-sm mb-2 text-shadow line-clamp-3">{heroContent.subtitle || 'No description available'}</p>
        </div>
        <div className="flex justify-between items-end">
          {showEditOptions && (
            <div className="flex items-center space-x-2">
              <CubeIcon className="h-5 w-5 text-white" />
              <span className="text-white text-sm">{blockCount} blocks</span>
              <span className="text-white text-xs">({blockTypes.join(', ')})</span>
            </div>
          )}
          {showEditOptions && (
            <div className="flex space-x-2">
              <button onClick={handleEdit} className="p-2 bg-yellow-500 rounded-full">
                <PencilIcon className="h-5 w-5 text-white" />
              </button>
              <button onClick={handleDelete} className="p-2 bg-red-500 rounded-full">
                <TrashIcon className="h-5 w-5 text-white" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;