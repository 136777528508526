import React, { useState, useRef } from 'react';

const HeroSection = ({ content, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(content.title || 'Your Title Here');
  const [subtitle, setSubtitle] = useState(content.subtitle || 'Your Subtitle Here');
  const [backgroundImage, setBackgroundImage] = useState(content.backgroundImage || 'https://example.com/default-bg.jpg');
  const fileInputRef = useRef(null);

  const handleSave = () => {
    onUpdate({ title, subtitle, backgroundImage });
    setIsEditing(false);
  };

  const handleDiscard = () => {
    setTitle(content.title || 'Your Title Here');
    setSubtitle(content.subtitle || 'Your Subtitle Here');
    setBackgroundImage(content.backgroundImage || 'https://example.com/default-bg.jpg');
    setIsEditing(false);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackgroundImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div 
      className="relative h-screen flex items-center justify-center text-white cursor-pointer"
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      onClick={() => !isEditing && setIsEditing(true)}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50 dark:bg-opacity-70"></div>
      <div className="z-10 text-center w-full px-4">
        {isEditing ? (
          <>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full text-5xl font-bold mb-4 bg-transparent border-b border-white text-white text-center"
              onClick={(e) => e.stopPropagation()}
            />
            <input
              type="text"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              className="w-full text-xl bg-transparent border-b border-white text-white text-center"
              onClick={(e) => e.stopPropagation()}
            />
          </>
        ) : (
          <>
            <h1 className="text-5xl font-bold mb-4">{title}</h1>
            <p className="text-xl">{subtitle}</p>
          </>
        )}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload}
        accept="image/*"
        className="hidden"
      />
      {isEditing && (
        <>
          <div className="absolute bottom-4 right-4 flex space-x-4">
            <button 
              onClick={handleDiscard} 
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-gray-400 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
            >
              Discard
            </button>
            <button 
              onClick={handleSave} 
              className="px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
            >
              Save
            </button>
          </div>
          <button
            onClick={() => fileInputRef.current.click()}
            className="absolute top-4 right-4 px-4 py-2 text-sm font-medium text-white bg-primary-700 bg-opacity-50 dark:bg-opacity-70 rounded-md hover:bg-opacity-75 dark:hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-gray-400 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Change Background
          </button>
        </>
      )}
    </div>
  );
};

export default HeroSection;