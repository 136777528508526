import React, { useRef, useState, useEffect, useCallback } from 'react';
import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/solid';
import { Link, useNavigate } from 'react-router-dom'; // Assuming you're using react-router
import ContentPreviewCard from '../card/ContentPreviewCard';
import { useScreenSize } from '../../context/ui/ScreenSizeContext';
import { usePinnedProperties } from '../../context/control/PinnedPropertiesContext';

const ViewMoreCard = ({ link }) => (
  <Link to={link} className="flex-shrink-0 w-64 sm:w-72 h-80 rounded-lg shadow-lg mr-4 overflow-hidden relative bg-primary-100 dark:bg-primary-800 flex items-center justify-center">
    <div className="text-center p-4">
      <h3 className="text-xl font-bold mb-2 text-primary-800 dark:text-primary-200">View More</h3>
      <p className="text-primary-600 dark:text-primary-300">Click to see all items</p>
    </div>
  </Link>
);

const ScrollableCardSection = ({ title, cards, moreLink, onViewDetails, isPinnedSection = false }) => {
  const navigate = useNavigate();  // Use navigate from react-router
  const scrollRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const { isMobile } = useScreenSize();
  const { pinProperty, unpinProperty, clearAllPinned, pinnedProperties } = usePinnedProperties();

  const displayCards = cards.slice(0, 19);
  const hasMoreCards = cards.length > 19;

  const checkScroll = useCallback(() => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 10);
    }
  }, []);

  useEffect(() => {
    checkScroll();
  }, [checkScroll, isMobile, cards]);

  const scroll = useCallback((direction) => {
    if (scrollRef.current) {
      const cardWidth = scrollRef.current.querySelector('.flex-shrink-0').offsetWidth;
      const scrollAmount = direction === 'left' ? -cardWidth : cardWidth;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  }, []);

  const handleCompare = () => {
    navigate('/compare');  // Use navigate for redirection
  };

  const canCompare = pinnedProperties.length >= 2;

  if (isPinnedSection && cards.length === 0) return null;

  return (
    <div className="py-12 bg-neutral-50 dark:bg-neutral-900 relative">
      <div className={`ml-auto pr-4 mb-14 relative ${isMobile || isPinnedSection ? 'w-full' : 'w-[70%]'}`}>
        <h2 className={`text-2xl font-bold text-primary-800 dark:text-primary-200 absolute ${isPinnedSection ? 'left-0' : 'left-3'}`}>
          {title}
        </h2>
        <div className="h-8 flex space-x-2 absolute right-2 top-0">
          {isPinnedSection && (
            <>
              <button
                onClick={handleCompare}
                className={`flex items-center justify-center px-3 py-2 rounded-md transition-colors duration-200 ${
                  canCompare
                    ? 'bg-primary-600 text-white hover:bg-primary-700'
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
                disabled={!canCompare}
              >
                <ArrowsPointingOutIcon className="h-4 w-4" />
                Compare
              </button>
              <button
                onClick={clearAllPinned}
                className="flex items-center justify-center px-3 py-2 bg-red-100 text-red-600 rounded-md hover:bg-red-200 transition-colors duration-200"
              >
                <XMarkIcon className="h-4 w-4" />
                Clear
              </button>
            </>
          )}
          <button
            className={`w-8 h-8 p-2 flex items-center justify-center rounded-full shadow-md ${
              canScrollLeft
                ? 'bg-primary-500 text-white hover:bg-primary-600'
                : 'bg-neutral-300 dark:bg-neutral-700 text-neutral-500 dark:text-neutral-400 cursor-not-allowed'
            }`}
            onClick={() => canScrollLeft && scroll('left')}
            disabled={!canScrollLeft}
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </button>
          <button
            className={`w-8 h-8 p-2 flex items-center justify-center rounded-full shadow-md ${
              canScrollRight
                ? 'bg-primary-500 text-white hover:bg-primary-600'
                : 'bg-neutral-300 dark:bg-neutral-700 text-neutral-500 dark:text-neutral-400 cursor-not-allowed'
            }`}
            onClick={() => canScrollRight && scroll('right')}
            disabled={!canScrollRight}
          >
            <ChevronRightIcon className="h-4 w-4" />
          </button>
        </div>
      </div>

      <div ref={scrollRef} className="flex overflow-x-auto scrollbar-hide scroll-smooth" onScroll={checkScroll}>
        <div className={`flex ${isMobile || isPinnedSection ? isPinnedSection ? '' : 'pl-4' : 'pl-[30vw]'}`}>
          {cards.map((card, index) => (
            <ContentPreviewCard
              key={index}
              content={card}
              onViewDetails={onViewDetails}
              isPinned={isPinnedSection || pinnedProperties.some(p => p.listingId === card.listingId || p.id === card.id)}
              onPin={() => pinProperty(card)}
              onUnpin={() => unpinProperty(card.listingId || card.id)}
            />
          ))}
          {cards.length > 19 && <ViewMoreCard link={moreLink} />}
        </div>
      </div>
    </div>
  );
};

export default ScrollableCardSection;
