import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { XCircleIcon, ArrowUpCircleIcon, StarIcon } from '@heroicons/react/24/solid';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const SortableItem = React.memo(({ photo, index, removePhoto, makeCover, itemWidth, itemHeight }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: photo.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: itemWidth,
    height: itemHeight,
  };

  const handleRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    removePhoto(photo.id);
  };

  const handleMakeCover = (e) => {
    e.preventDefault();
    e.stopPropagation();
    makeCover(photo.id);
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} className="relative group">
      <img
        src={photo.preview || photo.url}
        alt={`Upload ${index + 1}`}
        className="w-full h-full object-cover rounded-lg"
      />
      <div className="absolute inset-x-0 bottom-0 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded-b-lg flex justify-between items-center">
        <span>{index === 0 ? 'Cover' : `Photo ${index + 1}`}</span>
        <div className="flex items-center">
          <button
            onClick={handleRemove}
            className="text-white hover:text-red-500 transition-colors duration-200 mx-1"
            title="Remove photo"
          >
            <XCircleIcon className="h-5 w-5" />
          </button>
          {index !== 0 && (
            <button
              onClick={handleMakeCover}
              className="text-white hover:text-yellow-500 transition-colors duration-200 mx-1"
              title="Make cover photo"
            >
              <StarIcon className="h-5 w-5" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

const PhotoUpload = ({ 
  photos: externalPhotos, 
  updatePhotos, 
  maxPhotos = 10,
  photosPerRow = 5,
  containerWidth = '100%',
  containerHeight = 'auto',
}) => {
  const [internalPhotos, setInternalPhotos] = useState([]);
  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    const processedPhotos = externalPhotos.map(photo => {
      if (typeof photo.url) {
        return { ...photo, id: photo._id || Math.random().toString(36).substr(2, 9), isCDN: true };
      } else if (photo.file) {
        return photo;
      } else {
        return { id: Math.random().toString(36).substr(2, 9), file: photo, preview: URL.createObjectURL(photo) };
      }
    });
    setInternalPhotos(processedPhotos);
  }, [externalPhotos]);

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const onDrop = useCallback((acceptedFiles) => {
    if (internalPhotos.length + acceptedFiles.length > maxPhotos) {
      alert(`You can only upload a maximum of ${maxPhotos} photos.`);
      return;
    }
    const newPhotos = acceptedFiles.map(file => ({
      id: Math.random().toString(36).substr(2, 9),
      file,
      preview: URL.createObjectURL(file)
    }));
    const updatedPhotos = [...internalPhotos, ...newPhotos];
    setInternalPhotos(updatedPhotos);
    updatePhotos(updatedPhotos);
    setRenderKey(prev => prev + 1);
  }, [internalPhotos, updatePhotos, maxPhotos]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {'image/*': []},
    multiple: maxPhotos > 1
  });

  const removePhoto = useCallback((photoId) => {
    setInternalPhotos(prevPhotos => {
      const newPhotos = prevPhotos.filter(photo => photo.id !== photoId);
      updatePhotos(newPhotos);
      return newPhotos;
    });
    setRenderKey(prev => prev + 1);
  }, [updatePhotos]);

  const makeCover = useCallback((photoId) => {
    setInternalPhotos(prevPhotos => {
      const draggedIndex = prevPhotos.findIndex(photo => photo.id === photoId);
      if (draggedIndex > 0) {
        const newPhotos = arrayMove(prevPhotos, draggedIndex, 0);
        updatePhotos(newPhotos);
        return newPhotos;
      }
      return prevPhotos;
    });
    setRenderKey(prev => prev + 1);
  }, [updatePhotos]);

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setInternalPhotos(prevPhotos => {
        const oldIndex = prevPhotos.findIndex((photo) => photo.id === active.id);
        const newIndex = prevPhotos.findIndex((photo) => photo.id === over.id);
        const newPhotos = arrayMove(prevPhotos, oldIndex, newIndex);
        updatePhotos(newPhotos);
        return newPhotos;
      });
    }
  }, [updatePhotos]);

  const photoIds = useMemo(() => internalPhotos.map(photo => photo.id), [internalPhotos]);

  const itemWidth = `calc(${100 / photosPerRow}% - 1rem)`;
  const itemHeight = maxPhotos === 1 ? containerHeight : 'auto';

  return (
    <div className="space-y-4" style={{ width: containerWidth }}>
      {internalPhotos.length < maxPhotos && (
        <div {...getRootProps()} className={`p-6 border-2 border-dashed rounded-lg text-center cursor-pointer transition-colors duration-200 ${isDragActive ? 'border-primary-500 bg-primary-50 dark:bg-primary-900' : 'border-gray-300 dark:border-gray-700 hover:border-primary-500 dark:hover:border-primary-500'}`}>
          <input {...getInputProps()} />
          <ArrowUpCircleIcon className="mx-auto h-12 w-12 text-gray-400" />
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            Drag &amp; drop photos here, or click to select files
          </p>
          <p className="mt-1 text-xs text-gray-500 dark:text-gray-500">
            (Max {maxPhotos} photos, landscape orientation recommended)
          </p>
        </div>
      )}
      <DndContext 
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext 
          items={photoIds}
          strategy={horizontalListSortingStrategy}
        >
          <div className="flex flex-wrap gap-4" key={renderKey} style={{ height: containerHeight }}>
            {internalPhotos.map((photo, index) => (
              <SortableItem
                key={photo.id}
                photo={photo}
                index={index}
                removePhoto={removePhoto}
                makeCover={makeCover}
                itemWidth={itemWidth}
                itemHeight={itemHeight}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default PhotoUpload;