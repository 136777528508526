import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import backendApi from '../../api/backendApi';
import Header from '../../components/global/Header';
import ProjectCard from '../../components/card/ProjectCard';

const ManageProjects = () => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    setIsLoading(true);
    try {
      const fetchedProjects = await backendApi.projects.fetchAll();
      setProjects(fetchedProjects);
    } catch (err) {
      console.error('Error fetching projects:', err);
      setError('Failed to load projects. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteProject = async (projectId) => {
    if (window.confirm('Are you sure you want to delete this project?')) {
      try {
        await backendApi.projects.delete(projectId);
        setProjects(projects.filter(project => project._id !== projectId));
      } catch (err) {
        console.error('Error deleting project:', err);
        alert('Failed to delete project. Please try again.');
      }
    }
  };

  const handleViewDetails = (projectId) => {
    const project = projects.find(p => p._id === projectId);
    if (project) {
      navigate(`/project${project.path}`);
    }
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Header title="Manage Projects" />
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-6">
          <Link
            to="/project/create-project"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Create New Project
          </Link>
        </div>
        {projects.length === 0 ? (
          <p className="text-center text-gray-500 dark:text-gray-400">No projects found.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {projects.map(project => (
              <ProjectCard
                key={project._id}
                project={project}
                onDelete={handleDeleteProject}
                onViewDetails={handleViewDetails}
                showEditOptions={true}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageProjects;