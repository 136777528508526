// src/components/NotificationBadge/NotificationBadge.jsx

import React from 'react';
import { motion } from 'framer-motion';

const NotificationBadge = ({ count }) => {
  if (count === 0) return null;

  return (
    <motion.span
      layout
      className="absolute -top-1 -right-1 bg-red-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center"
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
    >
      {count > 9 ? '9+' : count}
    </motion.span>
  );
};

export default NotificationBadge;