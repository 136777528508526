import React from 'react';
import { motion } from 'framer-motion';
import { BedIcon, BathIcon, SquareIcon } from 'lucide-react';
import ImageCarousel from '../../multipurpose/ImageCarousel';
import FormattedText from '../../multipurpose/FormattedText';
import AgentContactInfo from '../../multipurpose/AgentContactInfo';

const ListingPreview = ({ listingData }) => {
  const locationString = [listingData?.township, listingData?.district, listingData?.region]
    .filter(Boolean)
    .join(', ');

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="bg-white dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden w-full h-full flex flex-col"
    >
      <div className="relative p-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
          {listingData.title || 'Listing Preview'}
        </h2>
      </div>

      <div className="flex-grow overflow-y-auto scrollbar-hide">
        <div className="h-200">
          <ImageCarousel images={listingData.photos} />
        </div>
        <div className="p-6">
          <p className="text-xl text-gray-600 dark:text-gray-300 mb-4">{locationString}</p>
          <p className="text-2xl font-bold text-primary-600 dark:text-primary-400 mb-6">
            {new Intl.NumberFormat('en-MY', { style: 'currency', currency: 'MYR' }).format(listingData.price)}
          </p>

          <div className="flex flex-wrap gap-4 mb-6">
            <div className="flex items-center">
              <BedIcon className="h-5 w-5 mr-2 text-gray-500" />
              <span>{listingData.bedroom} Bedrooms</span>
            </div>
            <div className="flex items-center">
              <BathIcon className="h-5 w-5 mr-2 text-gray-500" />
              <span>{listingData.bathroom} Bathrooms</span>
            </div>
            <div className="flex items-center">
              <SquareIcon className="h-5 w-5 mr-2 text-gray-500" />
              <span>{listingData.size} sqft</span>
            </div>
          </div>

          <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-3">Description</h2>
          <div className="text-gray-600 dark:text-gray-300 mb-6">
            <FormattedText content={listingData.description} />
          </div>

          <div className="grid grid-cols-2 gap-4 mb-6">
            <div>
              <span className="font-semibold">Property Type:</span> {listingData.propertyType}
            </div>
            <div>
              <span className="font-semibold">Size:</span> {listingData.size} sqft
            </div>
            <div>
              <span className="font-semibold">Furnishing:</span> {listingData.furnishing}
            </div>
            <div>
              <span className="font-semibold">Listing Type:</span> {listingData.listingType}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ListingPreview;