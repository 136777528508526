import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { jwtDecode } from 'jwt-decode';
import storageHelper from '../../utils/storageHelper';
import { refreshToken } from '../../services/auth/authService';
import backendApi from '../../api/backendApi';

const AuthContext = createContext();

const AUTH_KEY = 'auth';

export const AuthProvider = ({ children, toolType, useSessionStorage = false }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    accessToken: null,
    refreshToken: null,
    user: null,
  });
  const [isInitialized, setIsInitialized] = useState(false);

  const updateAuth = useCallback((newAuth) => {
    setAuth(newAuth);
    if (newAuth.isAuthenticated) {
      storageHelper.setItem(`${toolType}_${AUTH_KEY}`, newAuth, useSessionStorage);
    } else {
      storageHelper.removeItem(`${toolType}_${AUTH_KEY}`, useSessionStorage);
    }
  }, [toolType, useSessionStorage]);

  const login = useCallback((accessToken, refreshToken, user) => {
    updateAuth({ isAuthenticated: true, accessToken, refreshToken, user });
  }, [updateAuth]);

  const logout = useCallback(() => {
    updateAuth({ isAuthenticated: false, accessToken: null, refreshToken: null, user: null });
  }, [updateAuth]);

  const refreshAccessToken = useCallback(async () => {
    try {
      const currentAuth = storageHelper.getItem(`${toolType}_${AUTH_KEY}`, useSessionStorage);
      if (!currentAuth || !currentAuth.refreshToken) {
        throw new Error('No refresh token available');
      }
      const result = await backendApi.auth.refreshToken(currentAuth.refreshToken);
      updateAuth({ 
        ...currentAuth, 
        accessToken: result.accessToken, 
        refreshToken: result.refreshToken || currentAuth.refreshToken 
      });
      return result.accessToken;
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
      throw error;
    }
  }, [toolType, updateAuth, logout, useSessionStorage]);

  const checkTokenValidity = useCallback((token) => {
    if (!token) return false;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp > currentTime;
    } catch {
      return false;
    }
  }, []);

  const initialize = useCallback(async () => {
    const storedAuth = storageHelper.getItem(`${toolType}_${AUTH_KEY}`, useSessionStorage);
    if (storedAuth && storedAuth.accessToken) {
      if (checkTokenValidity(storedAuth.accessToken)) {
        updateAuth({ isAuthenticated: true, ...storedAuth });
      } else if (storedAuth.refreshToken) {
        try {
          await refreshAccessToken();
        } catch {
          logout();
        }
      } else {
        logout();
      }
    }
    setIsInitialized(true);
  }, [toolType, checkTokenValidity, refreshAccessToken, logout, updateAuth, useSessionStorage]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const isLister = useCallback(() => auth.user?.role === 'lister', [auth]);
  const isSeeker = useCallback(() => auth.user?.role === 'seeker', [auth]);

  const contextValue = {
    auth,
    login,
    logout,
    refreshAccessToken,
    isInitialized,
    getAccessToken: useCallback(() => auth.accessToken, [auth]),
    getRefreshToken: useCallback(() => auth.refreshToken, [auth]),
    isLister,
    isSeeker,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};