// src/services/gaService.js

const GA_TRACKING_ID = 'G-C6KT53PDNY';

const gaService = {
  init: async () => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      window.gtag = gtag;
      gtag('js', new Date());
      gtag('config', GA_TRACKING_ID);

      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
      script.async = true;
      document.head.appendChild(script);
    }
  },

  pageview: (url) => {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('config', GA_TRACKING_ID, {
        page_path: url,
      });
    }
  },

  event: ({ action, category, label, value }) => {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
      });
    }
  },

  setCustomDimension: (dimensionIndex, value) => {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('config', GA_TRACKING_ID, {
        custom_map: { [`dimension${dimensionIndex}`]: `dimension${dimensionIndex}` }
      });
      window.gtag('event', 'custom_dimension_set', {
        [`dimension${dimensionIndex}`]: value
      });
    }
  },

  eventWithCustomDimensions: ({ action, category, label, value, customDimensions }) => {
    if (typeof window !== 'undefined' && window.gtag) {
      const eventParams = {
        event_category: category,
        event_label: label,
        value: value,
      };

      if (customDimensions) {
        Object.keys(customDimensions).forEach(key => {
          eventParams[`dimension${key}`] = customDimensions[key];
        });
      }

      window.gtag('event', action, eventParams);
    }
  },

  trackContentView: (contentId, contentType, title) => {
    gaService.eventWithCustomDimensions({
      action: 'content_view',
      category: 'engagement',
      label: title,
      customDimensions: {
        1: contentId,
        2: contentType
      }
    });
  },

  trackLike: (contentId, contentType, title, isLiked) => {
    gaService.eventWithCustomDimensions({
      action: isLiked ? 'content_like' : 'content_unlike',
      category: 'engagement',
      label: title,
      customDimensions: {
        1: contentId,
        2: contentType
      }
    });
  },

  trackShare: (contentId, contentType, title, platform) => {
    gaService.eventWithCustomDimensions({
      action: 'content_share',
      category: 'engagement',
      label: title,
      customDimensions: {
        1: contentId,
        2: contentType,
        4: platform
      }
    });
  },

  trackSearch: (query, resultCount) => {
    gaService.eventWithCustomDimensions({
      action: 'search',
      category: 'engagement',
      label: query,
      value: resultCount,
      customDimensions: {
        5: query
      }
    });
  },

  trackListingView: (listingId, listingType) => {
    gaService.eventWithCustomDimensions({
      action: 'listing_view',
      category: 'engagement',
      label: listingId,
      customDimensions: {
        6: listingId,
        7: listingType
      }
    });
  },

  trackListingEnquiry: (listingId, listingType, enquiryType) => {
    gaService.eventWithCustomDimensions({
      action: 'listing_enquiry',
      category: 'conversion',
      label: listingId,
      customDimensions: {
        6: listingId,
        7: listingType,
        8: enquiryType
      }
    });
  },

  trackUserRegistration: (userType) => {
    gaService.event({
      action: 'user_registration',
      category: 'conversion',
      label: userType
    });
  },

  trackUserLogin: (userType) => {
    gaService.event({
      action: 'user_login',
      category: 'engagement',
      label: userType
    });
  }
};

export default gaService;