import React from 'react';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';

const UnitsForm = ({ units, handleUnitChange, addUnit, removeUnit }) => {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Units</h3>
      
      {units && units.map((unit, index) => (
        <div key={index} className="p-4 border border-gray-200 dark:border-gray-700 rounded-md space-y-2">
          <div className="flex justify-between items-center">
            <h4 className="text-md font-medium">Unit {index + 1}</h4>
            <button
              type="button"
              onClick={() => removeUnit(index)}
              className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
          
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Unit Number</label>
              <input
                type="text"
                value={unit.unitNumber || ''}
                onChange={(e) => handleUnitChange(index, 'unitNumber', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Floor</label>
              <input
                type="number"
                value={unit.floor || ''}
                onChange={(e) => handleUnitChange(index, 'floor', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Size (sq ft)</label>
              <input
                type="number"
                value={unit.size || ''}
                onChange={(e) => handleUnitChange(index, 'size', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Price</label>
              <input
                type="number"
                value={unit.price || ''}
                onChange={(e) => handleUnitChange(index, 'price', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Bedrooms</label>
              <input
                type="number"
                value={unit.bedrooms || ''}
                onChange={(e) => handleUnitChange(index, 'bedrooms', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Bathrooms</label>
              <input
                type="number"
                value={unit.bathrooms || ''}
                onChange={(e) => handleUnitChange(index, 'bathrooms', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
          </div>
        </div>
      ))}
      
      <button
        type="button"
        onClick={addUnit}
        className="flex items-center px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:bg-primary-600 dark:hover:bg-primary-700"
      >
        <PlusIcon className="h-5 w-5 mr-2" />
        Add Unit
      </button>
    </div>
  );
};

export default UnitsForm;