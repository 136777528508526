import React, { useMemo, useCallback } from 'react';
import { LinkIcon, LinkSlashIcon, MapPinIcon, HomeIcon, PencilIcon, EyeIcon } from '@heroicons/react/24/outline';
import { usePinnedProperties } from '../../context/control/PinnedPropertiesContext';
import { useAuth } from '../../context/auth/AuthContext';
// import { AuthContext } from '../../auth/contexts/AuthContext';
// import { useAnalytics } from '../../contexts/AnalyticsContext';

const getRandomColor = () => `hsl(${Math.random() * 360}, 70%, 60%)`;

const AbstractBackground = () => {
  const colors = useMemo(() => [
    getRandomColor(),
    getRandomColor(),
    getRandomColor(),
    getRandomColor()
  ], []);

  return (
    <svg className="absolute inset-0 w-full h-full" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id={`grad-${colors[0]}`} x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{stopColor: colors[0], stopOpacity: 1}} />
          <stop offset="100%" style={{stopColor: colors[1], stopOpacity: 1}} />
        </linearGradient>
      </defs>
      <rect width="100%" height="100%" fill={`url(#grad-${colors[0]})`} />
      <circle cx="70%" cy="20%" r="30%" fill={colors[2]} opacity="0.5" />
      <path d="M0,50 Q50,0 100,50 T200,50" stroke={colors[3]} strokeWidth="8" fill="none" />
    </svg>
  );
};

const PropertyCard = ({ 
  property, 
  onViewDetails, 
  onEdit, 
  showEditOptions = false, 
  onToggleStatus,
  isPinnable = true
}) => {
  const { auth } = useAuth();
  const hasRealPhoto = property.photos && property.photos.length > 0;
  
  const locationString = [property.township, property.district, property.region]
    .filter(Boolean)
    .join(', ');

  const { pinnedProperties, pinProperty, unpinProperty } = usePinnedProperties();
  const isPinned = pinnedProperties.some(p => p.listingId === property.listingId);
  // const { trackEvent } = useAnalytics();

  const handlePinToggle = useCallback((e) => {
    e.stopPropagation();
    if (property) {
      if (isPinned) {
        // trackEvent('click', 'listing_interaction', 'listing_unpin', property.id);
        unpinProperty(property.listingId);
      } else {
        // trackEvent('click', 'listing_interaction', 'listing_pin', property.id);
        pinProperty(property);
      }
    }
  }, [property, isPinned, pinProperty, unpinProperty]);

  const handleEdit = useCallback((e) => {
    e.stopPropagation();
    onEdit(property.id);
  }, [onEdit, property.id]);

  const handleToggleStatus = useCallback((e) => {
    e.stopPropagation();
    onToggleStatus(property.id, property.status);
  }, [onToggleStatus, property.id, property.status]);

  const canEdit = useMemo(() => {
    return showEditOptions && auth.isAuthenticated && auth.user &&
           (auth.user.role === 'lister' || auth.user.role === 'admin');
  }, [showEditOptions, auth.isAuthenticated, auth.user]);

  return (
    <div className="relative mb-4 rounded-lg shadow-lg overflow-hidden h-80 w-full text-left group">
      {onViewDetails && (
        <button
          onClick={() => onViewDetails(property.id)}
          className="absolute inset-0 w-full h-full z-10"
        >
          <span className="sr-only">View details</span>
        </button>
      )}      
      <div className="absolute inset-0 z-0">
        {hasRealPhoto ? (
          <img 
            src={property.photos[0].url} 
            alt={property.title} 
            className="w-full h-full object-cover"
          />
        ) : (
          <AbstractBackground />
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-black via-black/70 to-transparent opacity-80 group-hover:opacity-90 transition-opacity duration-300"></div>
      </div>
      <div className="relative z-20 flex h-full p-4 flex-col justify-between pointer-events-none">
        <div>
          <div className={`inline-block px-2 py-1 text-sm font-semibold text-white rounded ${property.listingType === 'Sale' ? 'bg-blue-600' : 'bg-green-600'} mb-2`}>
            {property.listingType}
          </div>
          <h3 className="text-xl font-bold mb-2 text-white text-shadow">{property.title}</h3>
          <p className="text-white text-sm flex items-center mb-2 text-shadow">
            <MapPinIcon className="h-4 w-4 mr-1" />
            {locationString}
          </p>
          <p className="text-white text-sm flex items-center text-shadow">
            <HomeIcon className="h-4 w-4 mr-1" />
            {property.propertyType}
          </p>
        </div>
        <div>
          <p className="text-2xl font-bold text-white mb-2 text-shadow">{new Intl.NumberFormat('en-MY', { style: 'currency', currency: 'MYR' }).format(property.price)}</p>
          <div className="flex justify-between text-sm text-white text-shadow">
            <span>{property.bedroom} bed</span>
            <span>{property.bathroom} bath</span>
            <span>{property.size} sqft</span>
          </div>
        </div>
      </div>
      <div className="absolute top-2 right-2 flex space-x-2 z-20">
        {!canEdit ? (
          <>
            {isPinnable && (
              <button
                onClick={handlePinToggle}
                className={`p-2 rounded-full shadow-md transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-primary-500 pointer-events-auto ${
                  isPinned
                    ? 'bg-primary-500 hover:bg-primary-600 text-white'
                    : 'bg-white hover:bg-primary-100 text-primary-600'
                }`}
                aria-label={isPinned ? "Unpin property" : "Pin property"}
              >
                {isPinned ? <LinkSlashIcon className='h-5 w-5' /> : <LinkIcon className='h-5 w-5' />}
              </button>
            )}
          </>
        ) : (
          <>
            <button
              onClick={handleEdit}
              className="p-2 rounded-full shadow-md bg-yellow-600 hover:bg-yellow-700 text-white transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 pointer-events-auto"
              aria-label="Edit property"
            >
              <PencilIcon className='h-5 w-5' />
            </button>
            <button
              onClick={handleToggleStatus}
              className={`p-2 rounded-full shadow-md transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-primary-500 pointer-events-auto ${
                property.status === 'active'
                  ? 'bg-green-500 hover:bg-red-500 text-white'
                  : 'bg-red-500 hover:bg-green-500 text-white'
              }`}
              aria-label={property.status === 'active' ? "Take offline" : "Make online"}
            >
              <EyeIcon className='h-5 w-5' />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PropertyCard;