import { mockFeaturedProjects, mockRecentArticles, mockAgentStories, mockRelatedAnalysis, mockRelatedArticles, mockAnalysis } from '../utils/mockData';
// import { getRandomAbstractImage } from '../utils/randomBackground';

// Simulate API call delay
const simulateApiCall = (data, delay = 500) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(data), delay);
  });
};

// Helper function to add type and random properties to mock data
const enhanceMockData = (data, type) => {
  return data.map((item, index) => ({
    ...item,
    type,
    price: type === 'property' ? Math.floor(Math.random() * 1000000) + 100000 : undefined,
    bedroom: type === 'property' ? Math.floor(Math.random() * 5) + 1 : undefined,
    bathroom: type === 'property' ? Math.floor(Math.random() * 3) + 1 : undefined,
    score: Math.random() * 100,
    area: type === 'property' ? Math.floor(Math.random() * 1000) + 500 : undefined,
    yearBuilt: type === 'property' ? Math.floor(Math.random() * 30) + 1990 : undefined,
    features: type === 'property' ? [
      { name: "Hardwood floors", isStandout: Math.random() > 0.5 },
      { name: "Granite countertops", isStandout: Math.random() > 0.5 },
      { name: "In-unit laundry", isStandout: Math.random() > 0.5 },
      { name: "Central air conditioning", isStandout: Math.random() > 0.5 }
    ] : undefined,
    nearbyAmenities: type === 'property' ? [
      "Shopping center",
      "Public transportation",
      "Parks",
      "Restaurants"
    ] : undefined,
    analysis: type === 'property' ? {
      priceIndex: Math.random() * 50 + 75,
      areaIndex: Math.random() * 50 + 75,
      investmentPotential: Math.random() > 0.5 ? "High" : "Moderate",
      neighborhoodTrend: Math.random() > 0.5 ? "Up and coming" : "Stable"
    } : undefined,
  }));
};

const allMockData = [
  ...enhanceMockData(mockFeaturedProjects, 'property'),
  ...enhanceMockData(mockRecentArticles, 'article'),
  ...enhanceMockData(mockAgentStories, 'guide'),
  ...enhanceMockData(mockRelatedAnalysis, 'analysis'),
  ...enhanceMockData(mockRelatedArticles, 'article')
];

// export const fetchFeaturedProjects = async (limit = 20) => {
//   const featuredProjects = mockFeaturedProjects
//     .map(project => ({
//       ...project,
//       id: project.id,
//       listingType: Math.random() > 0.5 ? 'Sale' : 'Rent',
//       propertyType: ['House', 'Apartment', 'Condo'][Math.floor(Math.random() * 3)],
//       price: Math.floor(Math.random() * 1000000) + 100000,
//       bedroom: Math.floor(Math.random() * 5) + 1,
//       bathroom: Math.floor(Math.random() * 3) + 1,
//       size: Math.floor(Math.random() * 1000) + 500,
//       township: 'Sample Township',
//       district: 'Sample District',
//       region: 'Sample Region',
//       photos: [
//         { url: getRandomAbstractImage() }
//       ],
//       formatPrice: function() {
//         return new Intl.NumberFormat('en-MY', { style: 'currency', currency: 'MYR' }).format(this.price);
//       }
//     }))
//     .sort(() => 0.5 - Math.random())
//     .slice(0, limit);

//   return simulateApiCall(featuredProjects);
// };

// export const fetchRecentArticles = async (limit = 20) => {
//   const recentArticles = mockRecentArticles
//     .map(article => ({
//       ...article,
//       contenType: 'Article',
//       id: article.id,
//       category: ['Market Trends', 'Property Tips', 'Investment Advice', 'Legal', 'Lifestyle'][Math.floor(Math.random() * 5)],
//       summary: article.subtitle,
//       author: {
//         name: 'John Doe'
//       },
//       publishDate: new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString(),
//       featuredImage: article.image || getRandomAbstractImage()
//     }))
//     .sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate))
//     .slice(0, limit);

//   return simulateApiCall(recentArticles);
// };

// export const fetchAgentStories = async (limit = 20) => {
//   const agentStories = allMockData
//     .filter(item => item.type === 'guide')
//     .sort(() => 0.5 - Math.random()) // Random sort for variety
//     .slice(0, limit);
//   return simulateApiCall(agentStories);
// };

// export const fetchRecentGuides = async (limit = 20) => {
//   const recentGuides = mockAgentStories
//     .map(guide => ({
//       ...guide,
//       contenType: 'Guide',
//       id: guide.id,
//       difficulty: ['beginner', 'intermediate', 'advanced'][Math.floor(Math.random() * 3)],
//       summary: guide.subtitle,
//       estimatedReadTime: Math.floor(Math.random() * 20) + 5,
//       featuredImage: guide.image || getRandomAbstractImage()
//     }))
//     .sort(() => 0.5 - Math.random())
//     .slice(0, limit);

//   return simulateApiCall(recentGuides);
// };

// export const searchProperties = async (filters, page = 1) => {
//   let filteredResults = allMockData.filter(item => 
//     item.type === 'property' &&
//     (filters.query === '' || item.title.toLowerCase().includes(filters.query.toLowerCase()) ||
//      item.subtitle.toLowerCase().includes(filters.query.toLowerCase())) &&
//     (!filters.propertyType || item.propertyType === filters.propertyType) &&
//     (!filters.priceRange || (item.price >= filters.priceRange[0] && item.price <= filters.priceRange[1])) &&
//     (!filters.bedroom || item.bedroom >= filters.bedrooms) &&
//     (!filters.bathroom || item.bathroom >= filters.bathroom)
//   );

//   // Sort results
//   switch (filters.sortBy) {
//     case 'score':
//       filteredResults.sort((a, b) => b.score - a.score);
//       break;
//     case 'date_desc':
//       filteredResults.sort((a, b) => new Date(b.date) - new Date(a.date));
//       break;
//     case 'price_asc':
//       filteredResults.sort((a, b) => a.price - b.price);
//       break;
//     case 'price_desc':
//       filteredResults.sort((a, b) => b.price - a.price);
//       break;
//     default:
//       filteredResults.sort((a, b) => b.score - a.score);
//   }

//   const resultsPerPage = 10;
//   const totalPages = Math.ceil(filteredResults.length / resultsPerPage);
//   const paginatedResults = filteredResults.slice((page - 1) * resultsPerPage, page * resultsPerPage);

//   return simulateApiCall({
//     results: paginatedResults,
//     totalPages,
//     currentPage: page,
//   });
// };

// export const searchSupplementaryContent = async (query, page = 1) => {
//   const supplementaryData = allMockData.filter(item => item.type !== 'property');
//   let filteredResults = supplementaryData.filter(item =>
//     item.title.toLowerCase().includes(query.toLowerCase()) ||
//     item.subtitle.toLowerCase().includes(query.toLowerCase())
//   );

//   const resultsPerPage = 5;
//   const totalPages = Math.ceil(filteredResults.length / resultsPerPage);
//   const paginatedResults = filteredResults.slice((page - 1) * resultsPerPage, page * resultsPerPage);

//   return simulateApiCall({
//     results: paginatedResults,
//     totalPages,
//     currentPage: page,
//   });
// };

// export const getAIResponse = async (message, context) => {
//   // Simulate AI response
//   const mockResponse = { message: `Here's what I found related to "${message}"... [AI-generated content]`,
//   results: {
//     properties: allMockData.filter(item => item.type === 'property').slice(0, 5),
//     articles: allMockData.filter(item => item.type === 'article').slice(0, 3),
//     guides: allMockData.filter(item => item.type === 'guide').slice(0, 2)
//   }}
//   return simulateApiCall(mockResponse);
// };

// export const extractFiltersFromMessage = async (message) => {
//   // Simulate filter extraction
//   const mockFilters = {
//     propertyType: message.toLowerCase().includes('house') ? 'house' : 
//                   message.toLowerCase().includes('apartment') ? 'apartment' : '',
//     priceRange: message.match(/\$\d+/) ? [parseInt(message.match(/\$\d+/)[0].slice(1)), Infinity] : undefined,
//     bedrooms: message.match(/(\d+)\s*bed/) ? parseInt(message.match(/(\d+)\s*bed/)[1]) : undefined,
//     bathrooms: message.match(/(\d+)\s*bath/) ? parseInt(message.match(/(\d+)\s*bath/)[1]) : undefined,
//   };
//   return simulateApiCall({ filters: mockFilters });
// };

// export const searchArticles = async (query, page = 1) => {
//   // Filter and paginate articles
//   let filteredResults = allMockData.filter(item =>
//     item.type === 'article' &&
//     (item.title.toLowerCase().includes(query.toLowerCase()) ||
//      item.subtitle.toLowerCase().includes(query.toLowerCase()))
//   );

//   const resultsPerPage = 5;
//   const totalPages = Math.ceil(filteredResults.length / resultsPerPage);
//   const paginatedResults = filteredResults.slice((page - 1) * resultsPerPage, page * resultsPerPage);

//   return simulateApiCall({
//     results: paginatedResults,
//     totalPages,
//     currentPage: page,
//   });
// };

// export const searchGuides = async (query, page = 1) => {
//   // Filter and paginate guides
//   let filteredResults = allMockData.filter(item =>
//     item.type === 'guide' &&
//     (item.title.toLowerCase().includes(query.toLowerCase()) ||
//      item.subtitle.toLowerCase().includes(query.toLowerCase()))
//   );

//   const resultsPerPage = 5;
//   const totalPages = Math.ceil(filteredResults.length / resultsPerPage);
//   const paginatedResults = filteredResults.slice((page - 1) * resultsPerPage, page * resultsPerPage);

//   return simulateApiCall({
//     results: paginatedResults,
//     totalPages,
//     currentPage: page,
//   });
// };

// export const fetchPropertyComparisons = async (propertyIds) => {
//   const detailedProperties = allMockData.filter(property => 
//     property.type === 'property' && propertyIds.includes(property.id)
//   ).map(property => {
//     const priceIndex = property.analysis.priceIndex;
//     const areaIndex = property.analysis.areaIndex;
//     return {
//       ...property,
//       analysis: {
//         ...property.analysis,
//         priceComparisonLabel: priceIndex > 100 ? "Above market average" : "Below market average",
//         areaComparisonLabel: areaIndex > 100 ? "Larger than average" : "Smaller than average",
//       }
//     };
//   });

//   if (detailedProperties.length === 0) {
//     throw new Error("No properties found for comparison");
//   }

//   return simulateApiCall(detailedProperties);
// };

// export const fetchRelatedAnalysis = async (propertyId, limit = 3) => {
//   // In a real API, you would use the propertyId to fetch relevant analysis
//   // For this mock, we'll just return random items from mockRelatedAnalysis
//   const relatedAnalysis = mockRelatedAnalysis
//     .sort(() => 0.5 - Math.random()) // Shuffle the array
//     .slice(0, limit); // Take the first 'limit' items

//   return simulateApiCall(relatedAnalysis);
// };

// export const fetchRelatedArticles = async (propertyId, limit = 3) => {
//   // In a real API, you would use the propertyId to fetch relevant articles
//   // For this mock, we'll just return random items from mockRelatedArticles
//   const relatedArticles = mockRelatedArticles
//     .sort(() => 0.5 - Math.random()) // Shuffle the array
//     .slice(0, limit); // Take the first 'limit' items

//   return simulateApiCall(relatedArticles);
// };

export const fetchPropertyDetails = async (propertyId) => {
  const property = allMockData.find(item => item.type === 'property' && item.id === propertyId);

  if (!property) {
    throw new Error('Property not found');
  }

  // Add more details to the property object
  const enhancedProperty = {
    ...property,
    description: property.subtitle, // Use subtitle as description for now
    popularity: Math.floor(Math.random() * 100) + 50, // Random number of views in last 7 days
    priceHistory: [
      { date: '2023-01-01', price: property.price * 0.95 },
      { date: '2023-03-15', price: property.price * 0.97 },
      { date: '2023-06-01', price: property.price * 0.99 },
      { date: '2023-09-01', price: property.price },
    ],
  };

  return simulateApiCall(enhancedProperty);
};

export const fetchPropertyAnalysis = async (property) => {
    // Simulate API call delay
    await new Promise(resolve => setTimeout(resolve, 500));
  
    // Helper function to generate a random number within a range
    const randomInRange = (min, max) => Math.random() * (max - min) + min;
  
    // Generate a base market trend
    const marketTrendPercentage = Math.round(randomInRange(-3, 3) * 10) / 10; // -3.0% to +3.0%
  
    // Determine market trend based on percentage
    const getTrend = (change) => {
      if (change > 1) return 'Slightly Increasing';
      if (change < -1) return 'Slightly Decreasing';
      return 'Stable';
    };
  
    // Calculate price comparison based on market trend
    const priceComparisonPercentage = marketTrendPercentage + randomInRange(-1, 1);
    const medianPrice = Math.round(property.price * (1 + priceComparisonPercentage / 100));
  
    // Calculate PSF comparison based on market trend
    const psfComparisonPercentage = marketTrendPercentage + randomInRange(-1, 1);
    const listingPsf = Math.round(property.price / property.size);
    const medianPsf = Math.round(listingPsf * (1 + psfComparisonPercentage / 100));
  
    // Generate investment score based on market trend and property details
    const baseInvestmentScore = 50 + (marketTrendPercentage * 5);
    const locationBonus = randomInRange(0, 20); // Assume some random location factor
    const investmentScore = Math.min(100, Math.max(0, Math.round(baseInvestmentScore + locationBonus)));
  
    // Generate mock analysis data
    const mockAnalysis = {
      priceComparison: {
        listingPrice: property.price,
        medianPrice: medianPrice,
        percentDifference: Math.round((medianPrice / property.price - 1) * 1000) / 10,
      },
      psfComparison: {
        listingPsf: listingPsf,
        medianPsf: medianPsf,
        percentDifference: Math.round((medianPsf / listingPsf - 1) * 1000) / 10,
      },
      recentTransactions: Array(5).fill().map(() => {
        const transactionDate = new Date(Date.now() - randomInRange(0, 180) * 24 * 60 * 60 * 1000);
        const monthsSinceTransaction = (Date.now() - transactionDate.getTime()) / (30 * 24 * 60 * 60 * 1000);
        const trendAdjustment = (marketTrendPercentage / 12) * monthsSinceTransaction;
        const transactionPrice = Math.round(property.price * (1 + (trendAdjustment / 100) + randomInRange(-0.05, 0.05)));
        const transactionSize = Math.round(property.size * randomInRange(0.9, 1.1));
        return {
          date: transactionDate.toISOString().split('T')[0],
          price: transactionPrice,
          size: transactionSize,
          pricePerSqFt: Math.round(transactionPrice / transactionSize),
        };
      }),
      marketTrend: {
        trend: getTrend(marketTrendPercentage),
        percentageChange: marketTrendPercentage,
      },
      investmentPotential: {
        score: investmentScore,
        factors: [
          'Location accessibility',
          'Nearby amenities',
          'Historical price trends',
          'Rental demand in the area',
          'Upcoming infrastructure projects',
          'Economic growth in the region'
        ].sort(() => Math.random() - 0.5).slice(0, 3), // Randomly select 3 factors
      },
    };
  
    return mockAnalysis;
  };