import React, { useState, useEffect, useRef, useCallback } from 'react';
import backendApi from '../../api/backendApi';
import { useSocketService } from '../../hooks/chat/socketService';
import { Check, CheckCheck } from 'lucide-react';
import { arrayBufferToString } from '../../utils/arrayBufferHelper';

const ChatWindow = ({ chat, currentUser, onClose, onSendMessage, onMessageRead }) => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [lastMessageId, setLastMessageId] = useState(null);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const { onNewMessage, offNewMessage, onMessageUpdated, offMessageUpdated } = useSocketService();

  const markMessagesAsRead = async (messagesToMark) => {
    const unreadMessages = messagesToMark.filter(
      message => arrayBufferToString(message.senderId) !== arrayBufferToString(currentUser._id) && !message.read
    );

    if (unreadMessages.length > 0) {
      const latestTimestamp = new Date().toISOString();
      try {
        await backendApi.chat.markMessagesAsRead(chat.id, latestTimestamp);
        setMessages(prevMessages =>
          prevMessages.map(msg =>
            unreadMessages.some(unreadMsg => unreadMsg.id === msg.id)
              ? { ...msg, read: true }
              : msg
          )
        );
      } catch (error) {
        console.error('Error marking messages as read:', error);
      }
    }
  };

  const fetchMessages = async (beforeMessageId = null) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await backendApi.chat.getChatMessages(chat.id, beforeMessageId);
      const { messages, hasMore, lastMessageId } = response;
      setHasMoreMessages(hasMore);
      setLastMessageId(lastMessageId);
      return messages;
    } catch (err) {
      console.error('Error fetching messages:', err);
      setError('Failed to load messages. Please try again.');
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreMessages = async () => {
    if (!hasMoreMessages || isLoading) return;
  
    const lastVisibleMessageId = messages[0]?.id;
  
    const olderMessages = await fetchMessages(lastMessageId);
  
    if (olderMessages.length > 0) {
      setMessages(prevMessages => [...olderMessages, ...prevMessages]);
  
      setTimeout(() => {
        const lastMessageElement = document.getElementById(`message-${lastVisibleMessageId}`);
        if (lastMessageElement) {
          lastMessageElement.scrollIntoView({ behavior: 'auto', block: 'start' });
        }
      }, 0);
    }
  };

  const isScrolledToBottom = () => {
    if (messagesContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
      return scrollTop + clientHeight >= scrollHeight - 10; // 10px tolerance
    }
    return false;
  };

  useEffect(() => {
    const initialLoad = async () => {
      const initialMessages = await fetchMessages();
      setMessages(initialMessages);

      setTimeout(() => {
        requestAnimationFrame(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
        });
      }, 0);

      await markMessagesAsRead(initialMessages);
    };

    initialLoad();
  }, [chat.id]);

  useEffect(() => {
    const handleNewMessage = ({ chatId, message }) => {
      if (chatId === chat.id) {
        const wasAtBottom = isScrolledToBottom();

        setMessages((prevMessages) => {
          if (!prevMessages.some(msg => msg.id === message.id)) {
            return [...prevMessages, message];
          }
          return prevMessages;
        });

        if (wasAtBottom) {
          setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
          }, 0);
        }

        markMessagesAsRead([message]);
      }
    };

    const handleMessageUpdated = ({ chatId, message }) => {
      if (chatId === chat.id) {
        setMessages((prevMessages) =>
          prevMessages.map((msg) => (msg.id === message.id ? { ...msg, ...message } : msg))
        );
      }
    };

    onNewMessage(handleNewMessage);
    onMessageUpdated(handleMessageUpdated);

    return () => {
      offNewMessage(handleNewMessage);
      offMessageUpdated(handleMessageUpdated);
    };
  }, [chat.id, onNewMessage, offNewMessage, onMessageUpdated, offMessageUpdated]);

  useEffect(() => {
    const handleScroll = () => {
      if (messagesContainerRef.current.scrollTop === 0 && hasMoreMessages && !isLoading) {
        loadMoreMessages();
      }
    };

    const containerRef = messagesContainerRef.current;
    if (containerRef) {
      containerRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerRef) {
        containerRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMoreMessages, isLoading]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    const tempId = Date.now().toString();
    const tempMessage = {
      id: tempId,
      senderId: currentUser._id,
      recipientId: chat.otherUser.id,
      text: newMessage,
      timestamp: new Date().toISOString(),
      read: false,
    };

    setNewMessage('');
    setMessages((prevMessages) => [...prevMessages, tempMessage]);

    try {
      const response = await onSendMessage(chat.id, newMessage, chat.otherUser?.id);
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === tempId ? { ...msg, id: response.message.id, timestamp: response.message.timestamp } : msg
        )
      );
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.error('Failed to send message:', error);
      setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== tempId));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const renderMessage = (message) => {
    const isCurrentUser = arrayBufferToString(message.senderId) === arrayBufferToString(currentUser._id);
    return (
      <div
        key={message.id}
        id={`message-${message.id}`}
        className={`mb-2 flex ${isCurrentUser ? 'justify-end' : 'justify-start'}`}
      >
        <div
          className={`max-w-[70%] p-2 rounded-lg ${
            isCurrentUser
              ? 'bg-primary-700 text-white rounded-br-none'
              : 'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded-bl-none'
          }`}
        >
          <p>{message.text}</p>
          <div className="flex items-center justify-end mt-1 space-x-1">
            <span className="text-xs opacity-70">
              {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            </span>
            {isCurrentUser && (
              <span className={`text-sm ${message.read ? 'text-blue-300' : 'text-gray-300'}`}>
                {message.read ? 
                  <CheckCheck className="w-5 h-5" /> : <Check className="w-5 h-5" />
                }
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleRead = async () => {
      if (onMessageRead) {
        onMessageRead();
      }
    };

    handleRead();
  }, [onMessageRead]);

  return (
    <div className="flex flex-col h-full">
      <div className="bg-gradient-to-b from-primary-600 to-primary-800 text-white p-3 flex items-center">
        <div className="w-11 h-11 rounded-full mr-3 bg-gradient-to-t from-primary-400 to-primary-800 flex items-center justify-center text-white font-bold text-lg shadow-lg shadow-primary-900">
          {chat.otherUser?.profilePicture ? (
            <img
              src={chat.otherUser.profilePicture}
              alt={chat.otherUser?.displayName || chat.otherUser?.name || 'User'}
              className="w-full h-full rounded-full object-cover"
            />
          ) : (
            <span>
              {getInitials(chat.otherUser?.displayName || chat.otherUser?.name || 'U')}
            </span>
          )}
        </div>
        <h2 className="font-semibold">
          {chat.otherUser?.displayName || chat.otherUser?.name || 'Unknown User'}
        </h2>
      </div>
      <div 
        ref={messagesContainerRef} 
        className="flex-grow overflow-y-auto p-4 scrollbar-hide"
      >
        {isLoading && messages.length === 0 ? (
          <div className="flex items-center justify-center h-full">Loading messages...</div>
        ) : error ? (
          <div className="text-red-500 text-center mt-8">{error}</div>
        ) : (
          <>
            {hasMoreMessages && (
              <div className="text-center py-2">
                <button 
                  onClick={loadMoreMessages} 
                  className="text-primary-600 hover:text-primary-800"
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Load More'}
                </button>
              </div>
            )}
            {messages.map(renderMessage)}
            <div ref={messagesEndRef} />
          </>
        )}
      </div>
      <div className="border-t border-gray-200 dark:border-gray-700 p-3">
        <form onSubmit={handleSendMessage} className="flex">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            className="flex-grow border border-gray-300 dark:border-gray-600 rounded-l-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
            placeholder="Type a message..."
          />
          <button
            type="submit"
            className="bg-primary-600 text-white px-4 py-2 rounded-r-lg hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatWindow;