import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { motion } from 'framer-motion';
import { PlusCircle, MinusCircle } from 'lucide-react';
import MemoizedQuillEditor from '../shared/MemoizedQuillEditor';
import { useAuth } from '../../../context/auth/AuthContext';
import backendApi from '../../../api/backendApi';

const ListingDetails = ({ details, updateDetails, setIsGeneratingDescription, isEditMode = false }) => {
  const { auth } = useAuth();
  const [error, setError] = useState(null);
  const quillRef = useRef(null);
  const [localDetails, setLocalDetails] = useState({
    title: '',
    description: '',
    region: '',
    district: '',
    township: '',
    buildingName: '',
    street: '',
    unitNumber: '',
    area: '',
    listingType: '',
    propertyType: '',
    furnishing: '',
    price: '',
    size: '',
    bedroom: '',
    bathroom: '',
    hashtags: '',
    facilities: [],
    ...details
  });

  useEffect(() => {
    setLocalDetails(prevDetails => ({
      ...prevDetails,
      ...details,
      hashtags: Array.isArray(details.hashtags) ? details.hashtags.join(', ') : ''
    }));
  }, [details]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setLocalDetails(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleDescriptionChange = useCallback((content) => {
    setLocalDetails(prev => ({ ...prev, description: content }));
    updateDetails({ description: content });
  }, [updateDetails]);

  const handleBlur = useCallback(() => {
    const updatedDetails = {
      ...localDetails,
      hashtags: localDetails.hashtags.split(',').map(tag => tag.trim())
    };
    updateDetails(updatedDetails);
  }, [localDetails, updateDetails]);

  const handleGenerateContent = useCallback(async () => {
    setIsGeneratingDescription(true);
    setError(null);
    try {
      const response = await backendApi.listings.generateListingContent(localDetails);
      if (!response.title && !response.description) {
        throw new Error('Generated content is empty. Please try again.');
      }
      const newTitle = response.title || localDetails.title;
      const newDescription = response.description || localDetails.description;

      setLocalDetails(prev => ({
        ...prev,
        title: newTitle,
        description: newDescription
      }));
      
      updateDetails({ 
        title: newTitle,
        description: newDescription
      });
      
    } catch (err) {
      console.error('Error generating listing content:', err);
      setError(err.message || 'Failed to generate listing content. Please try again.');
    } finally {
      setIsGeneratingDescription(false);
    }
  }, [localDetails, setIsGeneratingDescription, updateDetails]);

  const handleHashtagChange = useCallback((e) => {
    const hashtagInput = e.target.value;
    setLocalDetails(prev => ({ ...prev, hashtags: hashtagInput }));
  }, []);
  
  const handleHashtagBlur = useCallback(() => {
    const cleanedHashtags = localDetails.hashtags
      .split(',')
      .map(tag => tag.trim())
      .filter(tag => tag !== '')
      .join(', ');
    
    setLocalDetails(prev => ({ ...prev, hashtags: cleanedHashtags }));
    updateDetails({ hashtags: cleanedHashtags.split(', ') });
  }, [localDetails.hashtags, updateDetails]);

  const handleFacilityToggle = useCallback((facility) => {
    setLocalDetails(prev => {
      const updatedFacilities = prev.facilities.includes(facility)
        ? prev.facilities.filter(f => f !== facility)
        : [...prev.facilities, facility];
      return { ...prev, facilities: updatedFacilities };
    });
  }, []);

  const facilityOptions = [
    'Swimming Pool', 'Gym', 'Parking', 'Security', 'Air Conditioning',
    'Balcony', 'Pet-Friendly', 'Elevator', 'Laundry Facilities'
  ];

  const isLocationEditable = !isEditMode;

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full space-y-6 px-4 sm:px-6 lg:px-8 py-6 bg-white dark:bg-gray-800"
    >
      <h2 className="text-2xl font-semibold text-gray-800 dark:text-white">
        Listing Details
      </h2>
      
      <div className="space-y-6">
        <div>
          <label htmlFor="title" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={localDetails.title}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            placeholder="Charming 3-Bedroom Apartment in City Center"
          />
        </div>
  
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Description</label>
          <MemoizedQuillEditor
            value={localDetails.description}
            onChange={handleDescriptionChange}
            onBlur={handleBlur}
            ref={quillRef}
            disableImageUpload={true}
            simpleToolbar={true}
          />
        </div>
  
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="propertyType" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Property Type</label>
            <select
              id="propertyType"
              name="propertyType"
              value={localDetails.propertyType}
              onChange={handleInputChange}
              onBlur={handleBlur}
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            >
              <option value="">Select Property Type</option>
              <option value="Apartment">Apartment</option>
              <option value="Terrace House">Terrace House</option>
              <option value="Condominium">Condominium</option>
              <option value="Serviced Residence">Serviced Residence</option>
              <option value="Bungalow">Bungalow</option>
            </select>
          </div>
  
          <div>
            <label htmlFor="listingType" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Listing Type</label>
            <select
              id="listingType"
              name="listingType"
              value={localDetails.listingType}
              onChange={handleInputChange}
              onBlur={handleBlur}
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            >
              <option value="">Select Listing Type</option>
              <option value="Sale">Sale</option>
              <option value="Rent">Rent</option>
            </select>
          </div>
  
          <div>
            <label htmlFor="price" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Price (RM)</label>
            <input
              type="number"
              id="price"
              name="price"
              value={localDetails.price}
              onChange={handleInputChange}
              onBlur={handleBlur}
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              placeholder="500000"
            />
          </div>
  
          <div>
            <label htmlFor="size" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Size (sq ft)</label>
            <input
              type="number"
              id="size"
              name="size"
              value={localDetails.size}
              onChange={handleInputChange}
              onBlur={handleBlur}
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              placeholder="1000"
            />
          </div>
  
          <div>
            <label htmlFor="bedroom" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Bedrooms</label>
            <input
              type="number"
              id="bedroom"
              name="bedroom"
              value={localDetails.bedroom}
              onChange={handleInputChange}
              onBlur={handleBlur}
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              placeholder="0"
            />
          </div>
  
          <div>
            <label htmlFor="bathroom" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Bathrooms</label>
            <input
              type="number"
              id="bathroom"
              name="bathroom"
              value={localDetails.bathroom}
              onChange={handleInputChange}
              onBlur={handleBlur}
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              placeholder="0"
            />
          </div>
  
          <div>
            <label htmlFor="furnishing" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Furnishing</label>
            <select
              id="furnishing"
              name="furnishing"
              value={localDetails.furnishing}
              onChange={handleInputChange}
              onBlur={handleBlur}
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            >
              <option value="">Select Furnishing</option>
              <option value="Fully Furnished">Fully Furnished</option>
              <option value="Partially Furnished">Partially Furnished</option>
              <option value="Unfurnished">Unfurnished</option>
            </select>
          </div>
        </div>
  
        {isLocationEditable && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {['region', 'district', 'township', 'buildingName', 'street', 'unitNumber', 'area'].map((field) => (
              <div key={field}>
                <label htmlFor={field} className="block text-sm font-medium text-gray-700 dark:text-gray-300 capitalize">{field.replace(/([A-Z])/g, ' $1').trim()}</label>
                <input
                  type="text"
                  id={field}
                  name={field}
                  value={localDetails[field]}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                  placeholder={field.replace(/([A-Z])/g, ' $1').trim()}
                />
              </div>
            ))}
          </div>
        )}
  
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Facilities</label>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {facilityOptions.map((facility) => (
              <button
                key={facility}
                type="button"
                onClick={() => handleFacilityToggle(facility)}
                className={`flex items-center justify-between px-4 py-2 border rounded-md ${
                  localDetails.facilities.includes(facility)
                    ? 'bg-primary-100 dark:bg-primary-800 border-primary-500 text-primary-700 dark:text-primary-300'
                    : 'bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300'
                } hover:bg-primary-50 dark:hover:bg-primary-900 transition-colors duration-150`}
              >
                {facility}
                {localDetails.facilities.includes(facility) ? (
                  <MinusCircle className="h-5 w-5 text-primary-500 dark:text-primary-400" />
                ) : (
                  <PlusCircle className="h-5 w-5 text-gray-400 dark:text-gray-500" />
                )}
              </button>
            ))}
          </div>
        </div>
  
        <div>
          <label htmlFor="hashtags" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Hashtags</label>
          <input
            type="text"
            id="hashtags"
            name="hashtags"
            value={localDetails.hashtags}
            onChange={handleHashtagChange}
            onBlur={handleHashtagBlur}
            placeholder="Hashtags (comma-separated)"
            className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-primary-500 focus:ring-primary-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
          />
        </div>
  
        <div>
          <button
            onClick={handleGenerateContent}
            className="w-full md:w-auto px-4 py-2 bg-green-500 dark:bg-green-600 text-white rounded hover:bg-green-600 dark:hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 dark:focus:ring-green-400 disabled:opacity-50 transition-colors duration-150"
          >
            Generate Title and Description
          </button>
          {error && <p className="mt-2 text-red-500 dark:text-red-400">{error}</p>}
        </div>
      </div>
    </motion.div>
  );
};

export default React.memo(ListingDetails);