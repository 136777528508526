import React, { useState, useRef, useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { DocumentPlusIcon, EyeIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { ChevronLeft, ChevronRight, Edit } from 'lucide-react';
import MemoizedQuillEditor from '../shared/MemoizedQuillEditor';
import TagInput from '../shared/TagInput';
import ContentPreviewModal from '../../modal/ContentPreviewModal';
import StepNavigation from '../shared/StepNavigation';
import ImageUploadPreview from '../shared/ImageUploadPreview';
import { useAuth } from '../../../context/auth/AuthContext';
import backendApi from '../../../api/backendApi';
import { useScreenSize } from '../../../context/ui/ScreenSizeContext';
import { renderPhotoSrc } from '../../../utils/photoUrlHelpers';

const steps = [
    { label: 'Create Content', icon: DocumentPlusIcon },
    { label: 'Preview', icon: EyeIcon },
  ]

const ContentForm = ({ contentType, mode = 'create' }) => {
  const { auth } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const quillRef = useRef(null);

  const { control, handleSubmit, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {
      title: '',
      summary: '',
      content: '',
      tags: [],
      hashtags: [],
      metaDescription: '',
      isFeatured: false,
      // Content type specific fields
      category: '',
      difficulty: '',
      estimatedReadTime: '',
    }
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [maxStepReached, setMaxStepReached] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mainPhoto, setMainPhoto] = useState(null);
  const [mainPhotoPreview, setMainPhotoPreview] = useState(null);
  const [previewContent, setPreviewContent] = useState(null);
  const [isContentPosted, setIsContentPosted] = useState(false);
  const [isStepValid, setIsStepValid] = useState(false);
  const [contentStatus, setContentStatus] = useState(mode === 'edit' ? 'published' : 'draft');
  const { isMobile } = useScreenSize();

  const watchedFields = watch();

  useEffect(() => {
    if (mode === 'edit' && id) {
      fetchContent(id);
    }
  }, [mode, id]);

  useEffect(() => {
    validateStep();
  }, [watchedFields, currentStep]);

  const fetchContent = async (contentId) => {
    try {
      const content = await backendApi.content.fetchById(contentId);
      Object.keys(content).forEach(key => setValue(key, content[key]));
      setMainPhotoPreview(content.featuredImage);
      setContentStatus(content.status);
    } catch (error) {
      console.error('Error fetching content:', error);
      toast.error('Failed to fetch content data');
    }
  };

  const validateStep = () => {
    if (currentStep === 0) {
      const { title, summary, content, tags } = watchedFields;
      let isValid = title && title.trim() !== '' &&
                    summary && summary.trim() !== '' &&
                    content && content.trim() !== '' &&
                    tags && tags.length > 0;

      if (contentType === 'Article') {
        isValid = isValid && watchedFields.category && watchedFields.category.trim() !== '';
      } else if (contentType === 'Guide') {
        isValid = isValid && 
                  watchedFields.difficulty && watchedFields.difficulty.trim() !== '' &&
                  watchedFields.estimatedReadTime && watchedFields.estimatedReadTime > 0;
      }

      setIsStepValid(isValid);
    } else {
      setIsStepValid(true);
    }
  };

  const onSubmit = async (data, status) => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      const contentData = {
        ...data,
        status: status,
        featuredImage: mainPhotoPreview,
        author: auth.user ? { _id: auth.user._id, name: auth.user.name } : null,
        publishDate: status === 'published' ? new Date() : null,
        lastModified: new Date(),
        contentType: contentType,
      };
  
      formData.append('contentData', JSON.stringify(contentData));
      
      if (mainPhoto) {
        formData.append('featuredImage', mainPhoto);
      }
      
      let response;
      if (mode === 'edit') {
        response = await backendApi.content.update(id, formData);
      } else {
        response = await backendApi.content.create(contentType, formData);
      }

      toast.success(`${contentType} ${status === 'published' ? 'published' : 'saved as draft'} successfully`);
      setPreviewContent(response.content);
      setIsContentPosted(true);
    } catch (error) {
      console.error('Error submitting content:', error);
      toast.error('Failed to submit content. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleMainPhotoChange = (file) => {
    setMainPhoto(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setMainPhotoPreview(reader.result);
      reader.readAsDataURL(file);
    } else {
      setMainPhotoPreview(null);
    }
  };

  const handleNext = () => {
    if (isStepValid) {
      if (currentStep === 0) {
        const formData = watch();
        setPreviewContent({
          ...formData,
          featuredImage: mainPhotoPreview,
          author: auth.user ? { _id: auth.user._id, name: auth.user.name } : null,
          status: 'draft',
          publishDate: new Date(),
          lastModified: new Date(),
          views: 0,
          likes: 0,
          contentType: contentType
        });
      }
      setCurrentStep(prevStep => {
        const nextStep = Math.min(prevStep + 1, steps.length - 1);
        setMaxStepReached(prevMax => Math.max(prevMax, nextStep));
        return nextStep;
      });
    } else {
      toast.error('Please fill all required fields before proceeding.');
    }
  };

  const handleBack = () => setCurrentStep(prev => Math.max(prev - 1, 0));

  const handleDiscard = () => {
    const message = mode === 'edit' 
      ? `Are you sure you want to discard your changes? All unsaved progress will be lost.`
      : `Are you sure you want to discard this ${contentType.toLowerCase()}? All progress will be lost.`;
    
    const navigateUrl = mode === 'edit'
      ? '/cms/manage-content'
      : '/cms/create-content';
    
    if (window.confirm(message)) {
      navigate(navigateUrl);
    }
  };

  const renderForm = () => (
    <form className="space-y-6">
      <div>
        <label htmlFor="title" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Title</label>
        <Controller
          name="title"
          control={control}
          rules={{ required: 'Title is required' }}
          render={({ field }) => (
            <input
              {...field}
              type="text"
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 text-gray-900 dark:text-white"
              placeholder={`Enter ${contentType.toLowerCase()} title`}
            />
          )}
        />
        {errors.title && <p className="mt-1 text-sm text-red-600 dark:text-red-400">{errors.title.message}</p>}
      </div>

      <div>
        <label htmlFor="summary" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Summary</label>
        <Controller
          name="summary"
          control={control}
          rules={{ required: 'Summary is required' }}
          render={({ field }) => (
            <textarea
              {...field}
              rows={3}
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 text-gray-900 dark:text-white"
              placeholder={`Enter a brief summary of the ${contentType.toLowerCase()}`}
            />
          )}
        />
        {errors.summary && <p className="mt-1 text-sm text-red-600 dark:text-red-400">{errors.summary.message}</p>}
      </div>

      {contentType === 'Article' && (
        <div>
          <label htmlFor="category" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Category</label>
          <Controller
            name="category"
            control={control}
            rules={{ required: 'Category is required' }}
            render={({ field }) => (
              <select
                {...field}
                className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 text-gray-900 dark:text-white"
              >
                <option value="">Select a category</option>
                <option value="Market Trends">Market Trends</option>
                <option value="Property Tips">Property Tips</option>
                <option value="Investment Advice">Investment Advice</option>
                <option value="Legal">Legal</option>
                <option value="Lifestyle">Lifestyle</option>
              </select>
            )}
          />
          {errors.category && <p className="mt-1 text-sm text-red-600 dark:text-red-400">{errors.category.message}</p>}
        </div>
      )}

      {contentType === 'Guide' && (
        <>
          <div>
            <label htmlFor="difficulty" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Difficulty</label>
            <Controller
              name="difficulty"
              control={control}
              rules={{ required: 'Difficulty is required' }}
              render={({ field }) => (
                <select
                  {...field}
                  className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 text-gray-900 dark:text-white"
                >
                  <option value="">Select difficulty</option>
                  <option value="beginner">Beginner</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="advanced">Advanced</option>
                </select>
              )}
            />
            {errors.difficulty && <p className="mt-1 text-sm text-red-600 dark:text-red-400">{errors.difficulty.message}</p>}
          </div>

          <div>
            <label htmlFor="estimatedReadTime" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Estimated Read Time (minutes)</label>
            <Controller
              name="estimatedReadTime"
              control={control}
              rules={{ required: 'Estimated read time is required', min: 1 }}
              render={({ field }) => (
                <input
                  {...field}
                  type="number"
                  min="1"
                  className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 text-gray-900 dark:text-white"
                />
              )}
            />
            {errors.estimatedReadTime && <p className="mt-1 text-sm text-red-600 dark:text-red-400">{errors.estimatedReadTime.message}</p>}
          </div>
        </>
      )}

      <div>
        <label htmlFor="mainPhoto" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Featured Image</label>
        <ImageUploadPreview
          initialImage={mainPhotoPreview}
          onImageChange={handleMainPhotoChange}
        />
      </div>

      <div>
        <label htmlFor="content" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Content</label>
        <Controller
          name="content"
          control={control}
          rules={{ required: 'Content is required' }}
          render={({ field }) => (
            <MemoizedQuillEditor
              {...field}
              minHeight="300px"
              ref={quillRef}
            />
          )}
        />
        {errors.content && <p className="mt-1 text-sm text-red-600 dark:text-red-400">{errors.content.message}</p>}
      </div>

      <div>
        <label htmlFor="tags" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Tags</label>
        <Controller
          name="tags"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <TagInput
              tags={field.value}
              onTagsChange={(newTags) => field.onChange(newTags)}
            />
          )}
        />
      </div>

      <div>
        <label htmlFor="hashtags" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Hashtags</label>
        <Controller
          name="hashtags"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <TagInput
              tags={field.value}
              onTagsChange={(newHashtags) => field.onChange(newHashtags)}
            />
          )}
        />
      </div>

      <div>
        <label htmlFor="metaDescription" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Meta Description</label>
        <Controller
          name="metaDescription"
          control={control}
          render={({ field }) => (
            <textarea
              {...field}
              rows={2}
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 text-gray-900 dark:text-white"
              placeholder="Enter meta description for SEO (optional)"
            />
          )}
        />
      </div>

      <div>
        <label htmlFor="isFeatured" className="flex items-center">
          <Controller
            name="isFeatured"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="checkbox"
                className="form-checkbox h-5 w-5 text-primary-600 transition duration-150 ease-in-out mr-2"
              />
            )}
          />
          <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Featured {contentType}</span>
        </label>
      </div>
    </form>
  );

  const renderActionButtons = () => {
    const buttonBaseClasses = "px-4 py-2 rounded transition-colors duration-300 flex items-center justify-center";
    const mobileFullWidthClass = isMobile ? "w-full" : "";
    const nextButtonClasses = `${buttonBaseClasses} ${mobileFullWidthClass} bg-green-500 text-white ${isStepValid ? 'hover:bg-green-600' : 'opacity-50 cursor-not-allowed'}`;
    
    return (
      <div className={`${isMobile ? 'flex flex-col space-y-2' : 'flex justify-between items-center'} mt-6`}>
        {currentStep === 0 ? (
          <>
            <div className={isMobile ? 'w-full' : ''}>
              <button
                onClick={handleDiscard}
                className={`${buttonBaseClasses} ${mobileFullWidthClass} bg-gray-500 hover:bg-gray-600 text-white`}
              >
                Discard
              </button>
            </div>
            <div className={isMobile ? 'w-full' : ''}>
              <button
                onClick={handleNext}
                disabled={!isStepValid}
                className={nextButtonClasses}
              >
                Next
                {!isMobile && <ChevronRight className="w-5 h-5 ml-2" />}
              </button>
            </div>
          </>
        ) : currentStep === steps.length - 1 ? (
          <>
            <div className={isMobile ? 'w-full' : ''}>
              <button
                onClick={handleBack}
                className={`${buttonBaseClasses} ${mobileFullWidthClass} bg-blue-500 hover:bg-blue-600 text-white`}
              >
                {!isMobile && <ChevronLeft className="w-5 h-5 mr-2" />}
                Back
              </button>
            </div>
            <div className={isMobile ? 'w-full' : ''}>
              <button
                onClick={handleDiscard}
                className={`${buttonBaseClasses} ${mobileFullWidthClass} bg-gray-500 hover:bg-gray-600 text-white`}
              >
                Discard
              </button>
            </div>
            <div className={isMobile ? 'w-full' : ''}>
              <button
                onClick={() => handleSubmit(data => onSubmit(data, 'draft'))()}
                disabled={isSubmitting}
                className={`${buttonBaseClasses} ${mobileFullWidthClass} bg-yellow-500 hover:bg-yellow-600 text-white`}
              >
                Save Draft
              </button>
            </div>
            <div className={isMobile ? 'w-full' : ''}>
              <button
                onClick={() => handleSubmit(data => onSubmit(data, 'published'))()}
                disabled={isSubmitting}
                className={`${buttonBaseClasses} ${mobileFullWidthClass} bg-green-500 hover:bg-green-600 text-white`}
              >
                Post {contentType}
              </button>
            </div>
          </>
        ) : null}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col">
      <main className="flex-grow container mx-auto p-4">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 sm:p-6">
          {!isContentPosted && (
            <StepNavigation
              steps={steps}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              maxStepReached={maxStepReached}
            />
          )}
          <div className="mt-6">
            {isContentPosted ? (
              <div className="text-center py-8">
                <h2 className="text-2xl font-bold text-green-600 dark:text-green-400 mb-4">
                  {contentType} {previewContent.status === 'published' ? 'Published' : 'Saved as Draft'} Successfully!
                </h2>
                <div className="flex justify-center space-x-4">
                  <button
                    onClick={() => navigate('/cms/manage-content')}
                    className="px-6 py-3 bg-blue-500 hover:bg-blue-600 text-white rounded transition-colors duration-300 flex items-center justify-center"
                  >
                    <Edit className="w-5 h-5 mr-2" />
                    <span>Manage Content</span>
                  </button>
                </div>
              </div>
            ) : (
              <>
                <h1 className="text-3xl font-bold text-gray-800 dark:text-white mb-6">
                  {mode === 'edit' ? `Edit ${contentType}` : `Create ${contentType}`}
                </h1>
                {currentStep === 0 ? renderForm() : (
                  <ContentPreviewModal 
                    content={previewContent} 
                    isOpen={true} 
                    onClose={() => {}} 
                    isPreviewStep={true}
                  />
                )}
              </>
            )}
          </div>
          {!isContentPosted && renderActionButtons()}
        </div>
      </main>
    </div>
  );
};

export default ContentForm;