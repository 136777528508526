import React from 'react';
import { motion } from 'framer-motion';
import { XMarkIcon, PencilIcon } from '@heroicons/react/24/solid';
import DOMPurify from 'dompurify';
import '../../styles/ArticleContent.css';
import useEscKey from '../../hooks/control/useEscKey';
import useDisableScroll from '../../hooks/control/useDisableScroll';

const ContentPreviewModal = ({ content, isOpen, onClose, onEdit, isPreviewStep = false }) => {
  useEscKey(onClose); // Assuming this hook handles Esc key presses
  useDisableScroll(isOpen);
  
  if (!content || (!isOpen && !isPreviewStep)) return null;

  const sanitizedContent = DOMPurify.sanitize(content.content);
  const isArticle = content.contentType === 'Article';

  const modalContent = (
    <div className={`bg-white dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden w-full ${isPreviewStep ? 'max-w-full' : 'max-w-4xl max-h-[90vh]'} flex flex-col`}>
      {!isPreviewStep && (
        <div className="relative p-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">{content.title}</h2>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => onEdit(content.id)}
              className="p-2 rounded-full bg-yellow-600 hover:bg-yellow-700 text-white transition-colors duration-200"
            >
              <PencilIcon className="h-5 w-5" />
            </button>
            <button
              onClick={onClose}
              className="p-2 rounded-full bg-gray-200 text-gray-600 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 transition-colors duration-200"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      )}

      <div className={`flex-grow overflow-y-auto scrollbar-hide p-4 sm:p-6 ${isPreviewStep ? 'max-h-full' : ''}`}>
        {content.featuredImage && (
          <img src={content.featuredImage} alt={content.title} className="w-full h-64 object-cover rounded-lg mb-4" />
        )}

        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">{content.title}</h1>

        <div className="mb-4">
          <span className="text-sm font-medium text-primary-600 dark:text-primary-400">{content.category}</span>
        </div>

        <p className="text-gray-600 dark:text-gray-300 mb-4">{content.summary}</p>

        <div 
          className="prose dark:prose-invert max-w-none article-content"
          dangerouslySetInnerHTML={{ __html: sanitizedContent }}
        />

        {content.tags && content.tags.length > 0 && (
          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Tags</h3>
            <div className="flex flex-wrap gap-2">
              {content.tags.map((tag) => (
                <span key={tag.id} className="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 px-2 py-1 rounded-full text-sm">
                  {tag.name}
                </span>
              ))}
            </div>
          </div>
        )}

        {content.hashtags && content.hashtags.length > 0 && (
          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Hashtags</h3>
            <div className="flex flex-wrap gap-2">
              {content.hashtags.map((hashtag) => (
                <span key={hashtag.id} className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 px-2 py-1 rounded-full text-sm">
                  #{hashtag.name}
                </span>
              ))}
            </div>
          </div>
        )}

        {content.metaDescription && (
          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Meta Description</h3>
            <p className="text-gray-600 dark:text-gray-300">{content.metaDescription}</p>
          </div>
        )}

        <div className="mt-4 text-sm text-gray-500 dark:text-gray-400">
          <p>Views: {content.views}</p>
          <p>Likes: {content.likes}</p>
          <p>Status: {content.status}</p>
          <p>Last Modified: {new Date(content.lastModified).toLocaleString()}</p>
          {!isArticle && <p>Difficulty: {content.difficulty}</p>}
        </div>
      </div>
    </div>
  );

  if (isPreviewStep) {
    return modalContent;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 z-30 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 50, opacity: 0 }}
        onClick={(e) => e.stopPropagation()}
      >
        {modalContent}
      </motion.div>
    </motion.div>
  );
};

export default ContentPreviewModal;