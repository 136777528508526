import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAnalytics } from './AnalyticsContext';
import storageHelper from '../../utils/storageHelper';

const PinnedPropertiesContext = createContext();

export const PinnedPropertiesProvider = ({ children }) => {
  const { gaService, trackingService } = useAnalytics();
  const [pinnedProperties, setPinnedProperties] = useState([]);
  // Ensure sessionStorage is only accessed on the client side
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedProperties = sessionStorage.getItem('pinnedProperties');
      if (storedProperties) {
        setPinnedProperties(JSON.parse(storedProperties));
      }
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('pinnedProperties', JSON.stringify(pinnedProperties));
    }
  }, [pinnedProperties]);

  const pinProperty = (property) => {
    setPinnedProperties((prevPinned) => {
      if (!prevPinned.some(p => p.listingId === property.listingId)) {
        return [...prevPinned, property];
      }
      return prevPinned;
    });

    trackingService.trackPinListing(property.listingId);
  };

  const unpinProperty = (propertyId) => {
    setPinnedProperties((prevPinned) => prevPinned.filter(p => p.listingId !== propertyId));
    trackingService.trackUnpinListing(propertyId);
  };

  const clearAllPinned = () => {
    setPinnedProperties([]);
  };

  return (
    <PinnedPropertiesContext.Provider
      value={{ pinnedProperties, pinProperty, unpinProperty, clearAllPinned }}
    >
      {children}
    </PinnedPropertiesContext.Provider>
  );
};

export const usePinnedProperties = () => useContext(PinnedPropertiesContext);