// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/auth/AuthContext';

const ProtectedRoute = ({ element, authOnly = false, roles = [] }) => {
  const { auth, isInitialized } = useAuth();

  // Wait until the authentication state is initialized
  if (!isInitialized) {
    return null; // Or you can return a loading spinner or fallback UI
  }

  // Redirect if the route requires authentication but the user is not authenticated
  if (authOnly && !auth.isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Redirect if the userRole is not included in the roles array
  if (roles.length > 0 && !roles.includes(auth.user?.role)) {
    return <Navigate to="/" />;
  }

  // Render the element if all conditions are met
  return element;
};

export default ProtectedRoute;