import React from 'react';
import { NewspaperIcon, UserIcon, HeartIcon } from '@heroicons/react/24/outline';
import { getRandomBackground } from '../../utils/backgroundHelper';

const ArticlePreviewCard = ({ article, onClick, className }) => {
  const backgroundImage = article.featuredImage || getRandomBackground();

  return (
    <div onClick={onClick} className={`relative overflow-hidden rounded-lg shadow-lg ${className}`}>
      <div className="absolute inset-0 overflow-hidden">
        <img
          src={backgroundImage}
          alt={article?.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-black/70 to-transparent opacity-80 hover:opacity-90 transition-opacity duration-300"></div>
      </div>
      <div className="relative z-10 p-4 flex flex-col justify-between h-full">
        <div>
          <span className="inline-block px-2 py-1 text-sm font-semibold text-white bg-primary-600 rounded mb-2">
            {article?.category}
          </span>
          <h3 className="text-xl font-bold mb-2 text-white text-shadow">{article?.title}</h3>
          <p className="text-gray-300 text-sm line-clamp-3">{article?.summary}</p>
        </div>
        <div className="mt-4">
          <div className="flex items-center justify-between text-sm text-gray-300 mb-2">
            <div className="flex items-center">
              <NewspaperIcon className="h-4 w-4 mr-1" />
              <span>Article</span>
            </div>
            <div className="flex items-center">
              <UserIcon className="h-4 w-4 mr-1" />
              <span>{article?.author.displayName}</span>
            </div>
          </div>
          <div className="flex items-center justify-between text-sm text-gray-300">
            <div className="flex items-center">
              <HeartIcon className="h-4 w-4 mr-1" />
              <span>{article?.likes} Likes</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlePreviewCard;
