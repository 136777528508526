import React, { useState } from 'react';

const TagInput = ({ tags, onTagsChange, predefinedOptions = [] }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault();
      addTag();
    }
  };

  const handleInputBlur = () => {
    if (inputValue.trim()) {
      addTag();
    }
  };

  const addTag = () => {
    // Check if the tag is predefined
    const existingTag = predefinedOptions.find(
      (option) => option.name.toLowerCase() === inputValue.trim().toLowerCase()
    );

    const newTag = existingTag
      ? existingTag
      : { id: Date.now(), name: inputValue.trim() }; // Create a new tag object if not predefined

    const newTags = [...tags, newTag];
    onTagsChange(newTags);
    setInputValue('');
  };

  const removeTag = (idToRemove) => {
    const newTags = tags.filter((tag) => tag.id !== idToRemove); // Filter out by id
    onTagsChange(newTags);
  };

  return (
    <div className="flex flex-wrap items-center border border-gray-300 dark:border-gray-600 rounded-md p-2">
      {tags.map((tag) => (
        <span key={tag.name} className="bg-primary-100 text-primary-800 px-2 py-1 rounded mr-2 mb-2 flex items-center">
          {tag.name}
          <button
            type="button"
            onClick={() => removeTag(tag.id)}
            className="ml-1 text-primary-600 hover:text-primary-800 focus:outline-none"
          >
            &times;
          </button>
        </span>
      ))}
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        onBlur={handleInputBlur} // Handle blur event to add tag/hashtag
        placeholder="Add a tag or hashtag..."
        className="flex-grow outline-none bg-transparent"
      />
    </div>
  );
};

export default TagInput;
