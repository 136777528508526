import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Home, FileText, Bell, ClipboardList, Trash2 } from 'lucide-react';
import backendApi from '../../api/backendApi';

const UserPreferences = () => {
  const [preferences, setPreferences] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPreferences();
  }, []);

  const fetchPreferences = async () => {
    try {
      setLoading(true);
      const data = await backendApi.preferences.getUserPreferences();
      setPreferences(data);
      setError(null);
    } catch (err) {
      console.error("Error fetching preferences:", err);
      setError('Failed to load preferences');
      toast.error('Failed to load preferences');
    } finally {
      setLoading(false);
    }
  };

  const handleListingPreferenceChange = (listingType, field, value) => {
    setPreferences(prev => ({
      ...prev,
      listingPreferences: {
        ...prev.listingPreferences,
        [listingType]: {
          ...prev.listingPreferences[listingType],
          preferences: {
            ...prev.listingPreferences[listingType].preferences,
            [field]: field === 'priceRange'
              ? { ...prev.listingPreferences[listingType].preferences[field], ...value }
              : value
          },
          // Handling isActive separately
          isActive: field === 'isActive' ? value : prev.listingPreferences[listingType].isActive
        }
      }
    }));
  };

  const handleMultipleSelectionChange = (listingType, field, value) => {
    setPreferences(prev => ({
      ...prev,
      listingPreferences: {
        ...prev.listingPreferences,
        [listingType]: {
          ...prev.listingPreferences[listingType],
          preferences: {
            ...prev.listingPreferences[listingType].preferences,
            [field]: prev.listingPreferences[listingType].preferences[field].includes(value)
              ? prev.listingPreferences[listingType].preferences[field].filter(item => item !== value)
              : [...prev.listingPreferences[listingType].preferences[field], value]
          }
        }
      }
    }));
  };

  const handleContentPreferenceChange = (category) => {
    setPreferences(prev => ({
      ...prev,
      contentPreferences: {
        ...prev.contentPreferences,
        preferredCategories: prev.contentPreferences.preferredCategories.includes(category)
          ? prev.contentPreferences.preferredCategories.filter(c => c !== category)
          : [...prev.contentPreferences.preferredCategories, category]
      }
    }));
  };

  const handleNotificationSettingChange = (notificationType, field, value) => {
    setPreferences(prev => ({
      ...prev,
      notificationSettings: {
        ...prev.notificationSettings,
        [notificationType]: {
          ...prev.notificationSettings[notificationType],
          [field]: value
        }
      }
    }));
  };

  const handleRemoveSavedSearch = async (savedSearchId) => {
    try {
      await backendApi.preferences.removeSavedSearch(savedSearchId);
      await fetchPreferences();
      toast.success('Saved search removed successfully');
    } catch (err) {
      console.error("Error removing saved search:", err);
      toast.error('Failed to remove saved search');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await backendApi.preferences.updateExplicitPreferences(preferences);
      toast.success('Preferences updated successfully');
    } catch (err) {
      console.error("Error updating preferences:", err);
      toast.error('Failed to update preferences');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
    </div>;
  }

  if (error) {
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  }

  if (!preferences) {
    return <div className="text-gray-500 text-center mt-8">No preferences found.</div>;
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-8 max-w-4xl mx-auto">
      {/* Listing Preferences */}
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
        <h3 className="text-xl font-semibold mb-4 flex items-center text-primary-600 dark:text-primary-400">
          <Home className="mr-2" size={24} />
          Listing Preferences
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {['buy', 'rent'].map(listingType => (
            <div key={listingType} className="border border-gray-200 dark:border-gray-700 p-4 rounded-md">
              <h4 className="font-medium mb-3 capitalize text-gray-700 dark:text-gray-300">{listingType}</h4>
              <label className="flex items-center mb-3">
                <input
                  type="checkbox"
                  checked={preferences.listingPreferences[listingType].isActive}
                  onChange={(e) => handleListingPreferenceChange(listingType, 'isActive', e.target.checked)}
                  className="form-checkbox h-5 w-5 text-primary-600 rounded border-gray-300 focus:ring-primary-500"
                />
                <span className="ml-2 text-gray-700 dark:text-gray-300">Active</span>
              </label>
              {preferences.listingPreferences[listingType].isActive && (
                <>
                  <label className="block mb-3">
                    <span className="text-gray-700 dark:text-gray-300">Price Range:</span>
                    <div className="flex items-center mt-1">
                      <input
                        type="number"
                        value={preferences.listingPreferences[listingType].preferences.priceRange.min}
                        onChange={(e) => handleListingPreferenceChange(listingType, 'priceRange', { ...preferences.listingPreferences[listingType].preferences.priceRange, min: Number(e.target.value) })}
                        className="w-1/2 mr-2 p-2 border border-gray-300 rounded-md text-primary-600 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      />
                      <span className="text-gray-700 dark:text-gray-300">to</span>
                      <input
                        type="number"
                        value={preferences.listingPreferences[listingType].preferences.priceRange.max}
                        onChange={(e) => handleListingPreferenceChange(listingType, 'priceRange', { ...preferences.listingPreferences[listingType].preferences.priceRange, max: Number(e.target.value) })}
                        className="w-1/2 ml-2 p-2 border border-gray-300 rounded-md text-primary-600 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      />
                    </div>
                  </label>

                  <div className="mb-3">
                    <span className="text-gray-700 dark:text-gray-300 block mb-2">Property Types:</span>
                    <div className="space-y-2">
                      {['Apartment', 'House', 'Townhouse', 'Villa', 'Land'].map(type => (
                        <label key={type} className="flex items-center">
                          <input
                            type="checkbox"
                            checked={preferences.listingPreferences[listingType].preferences.propertyTypes.includes(type)}
                            onChange={() => handleMultipleSelectionChange(listingType, 'propertyTypes', type)}
                            className="form-checkbox h-5 w-5 text-primary-600 rounded border-gray-300 focus:ring-primary-500"
                          />
                          <span className="ml-2 text-gray-700 dark:text-gray-300">{type}</span>
                        </label>
                      ))}
                    </div>
                  </div>

                  {/* Other preference fields here */}
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Content Preferences */}
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
        <h3 className="text-xl font-semibold mb-4 flex items-center text-primary-600 dark:text-primary-400">
          <FileText className="mr-2" size={24} />
          Content Preferences
        </h3>
        <div>
          <span className="text-gray-700 dark:text-gray-300 block mb-2">Preferred Categories:</span>
          <div className="space-y-2">
            {['Market Trends', 'Property Tips', 'Investment Advice', 'Legal', 'Lifestyle'].map(category => (
              <label key={category} className="flex items-center">
                <input
                  type="checkbox"
                  checked={preferences.contentPreferences.preferredCategories.includes(category)}
                  onChange={() => handleContentPreferenceChange(category)}
                  className="form-checkbox h-5 w-5 text-primary-600 rounded border-gray-300 focus:ring-primary-500"
                />
                <span className="ml-2 text-gray-700 dark:text-gray-300">{category}</span>
              </label>
            ))}
          </div>
        </div>
      </div>

      {/* Notification Settings */}
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
        <h3 className="text-xl font-semibold mb-4 flex items-center text-primary-600 dark:text-primary-400">
          <Bell className="mr-2" size={24} />
          Notification Settings
        </h3>
        {Object.entries(preferences.notificationSettings).map(([notificationType, settings]) => (
          <div key={notificationType} className="mb-4">
            <h4 className="font-medium mb-2 text-gray-700 dark:text-gray-300">
              {notificationType.replace(/([A-Z])/g, ' $1').trim().charAt(0).toUpperCase() + notificationType.replace(/([A-Z])/g, ' $1').trim().slice(1)}
            </h4>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                checked={settings.isEnabled}
                onChange={(e) => handleNotificationSettingChange(notificationType, 'isEnabled', e.target.checked)}
                className="form-checkbox h-5 w-5 text-primary-600 rounded border-gray-300 focus:ring-primary-500"
              />
              <span className="ml-2 text-gray-700 dark:text-gray-300">Enabled</span>
            </label>
            {settings.isEnabled && (
              <>
                <label className="block mb-2">
                  <span className="text-gray-700 dark:text-gray-300">Frequency:</span>
                  <select
                    value={settings.frequency}
                    onChange={(e) => handleNotificationSettingChange(notificationType, 'frequency', e.target.value)}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  >
                    <option value="immediate">Immediate</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                </label>
                <div>
                  <span className="text-gray-700 dark:text-gray-300 block mb-2">Channels:</span>
                  {Object.entries(settings.channels).map(([channel, isEnabled]) => (
                    <label key={channel} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        checked={isEnabled}
                        onChange={(e) => handleNotificationSettingChange(notificationType, 'channels', {
                          ...settings.channels,
                          [channel]: e.target.checked
                        })}
                        className="form-checkbox h-5 w-5 text-primary-600 rounded border-gray-300 focus:ring-primary-500"
                      />
                      <span className="ml-2 text-gray-700 dark:text-gray-300 capitalize">{channel}</span>
                    </label>
                  ))}
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      {/* Saved Searches */}
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
        <h3 className="text-xl font-semibold mb-4 flex items-center text-primary-600 dark:text-primary-400">
          <ClipboardList className="mr-2" size={24} />
          Saved Searches
        </h3>
        {preferences.savedSearches.length > 0 ? (
          <ul className="space-y-2">
            {preferences.savedSearches.map((search) => (
              <li key={search._id} className="flex items-center justify-between py-2 border-b border-gray-200 dark:border-gray-700">
                <span className="text-gray-700 dark:text-gray-300">{search.name}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveSavedSearch(search._id)}
                  className="text-red-500 hover:text-red-700 dark:hover:text-red-400 transition-colors duration-200"
                >
                  <Trash2 size={20} />
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500 dark:text-gray-400">No saved searches yet.</p>
        )}
      </div>

      {/* Submit Button */}
      <div className="flex justify-end">
        <button
          type="submit"
          className="px-6 py-3 bg-primary-600 text-white rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-colors duration-200"
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save Preferences'}
        </button>
      </div>
    </form>
  );
};

export default UserPreferences;
