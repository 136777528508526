import React from 'react';

const FloorPlansView = ({ content }) => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-6">Floor Plans</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {content.plans && content.plans.map((plan, index) => (
            <div key={index} className="bg-white p-4 rounded-lg shadow">
              <img src={plan.image} alt={plan.name} className="w-full h-64 object-cover mb-4 rounded" />
              <h3 className="text-xl font-semibold mb-2">{plan.name}</h3>
              <p className="text-gray-600">{plan.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FloorPlansView;