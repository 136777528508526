import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { renderPhotoSrc } from '../../utils/photoUrlHelpers';

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollRef = useRef(null);

  const scrollTo = (index) => {
    if (scrollRef.current) {
      const newIndex = Math.max(0, Math.min(index, images.length - 1));
      const scrollWidth = scrollRef.current.scrollWidth;
      const clientWidth = scrollRef.current.clientWidth;
      const scrollTo = (scrollWidth / images.length) * newIndex;
      
      scrollRef.current.scrollTo({
        left: scrollTo,
        behavior: 'smooth',
      });

      setCurrentIndex(newIndex);
    }
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      const newIndex = Math.round((scrollLeft / (scrollWidth - clientWidth)) * (images.length - 1));
      setCurrentIndex(newIndex);
    }
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
      return () => scrollElement.removeEventListener('scroll', handleScroll);
    }
  }, []);

  if (!images || images.length === 0) {
    return (
      <div className="h-64 bg-gray-200 dark:bg-gray-700 flex items-center justify-center rounded-t-lg">
        <p className="text-gray-500 dark:text-gray-400">No images available</p>
      </div>
    );
  }

  return (
    <div className="relative h-[30vh] md:h-[50vh] overflow-hidden rounded-t-lg">
      <div
        ref={scrollRef}
        className="flex overflow-x-auto scrollbar-hide scroll-smooth h-full"
        style={{ scrollSnapType: 'x mandatory' }}
      >
        {images.map((src, index) => (
          <div
            key={index}
            className="flex-shrink-0 w-full h-full relative"
            style={{ scrollSnapAlign: 'start' }}
          >
            <img
              src={renderPhotoSrc(src)}
              alt={`Property ${index + 1}`}
              className="w-full h-full object-cover"
              style={{ objectFit: 'cover' }} // Ensure the image fills the container properly
            />
          </div>
        ))}
      </div>
      {currentIndex > 0 && (
        <button
          onClick={() => scrollTo(currentIndex - 1)}
          className="absolute left-2 top-1/2 transform -translate-y-1/2 p-1
                     bg-black bg-opacity-30 hover:bg-opacity-50 text-white rounded-full
                     transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white"
          aria-label="Previous image"
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </button>
      )}
      {currentIndex < images.length - 1 && (
        <button
          onClick={() => scrollTo(currentIndex + 1)}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1
                     bg-black bg-opacity-30 hover:bg-opacity-50 text-white rounded-full
                     transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white"
          aria-label="Next image"
        >
          <ChevronRightIcon className="h-5 w-5" />
        </button>
      )}
      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex items-center space-x-2">
        <span className="bg-black bg-opacity-50 text-white px-2 py-1 rounded-full text-xs">
          {currentIndex + 1}/{images.length}
        </span>
      </div>
    </div>
  );
};

export default ImageCarousel;
