import React from 'react';
import { MapPin, Home, DollarSign, Bed, Bath, Ruler, Eye, MessageSquare, User, Link, Scale } from 'lucide-react';

const PropertyAnalysisCard = ({ property, performance, focusMetric, compact = false, forSingleListing = false }) => {
  const locationString = [property.township, property.district, property.region]
    .filter(Boolean)
    .join(', ');

  const metrics = [
    { key: 'views', icon: Eye, label: 'views', userLabel: 'viewers', count: performance?.views, unique: performance?.uniqueViewersCount },
    { key: 'enquiries', icon: MessageSquare, label: 'enquiries', userLabel: 'enquirers', count: performance?.enquiries, unique: performance?.uniqueEnquirersCount },
    { key: 'pins', icon: Link, label: 'pins', userLabel: 'pinners',  count: performance?.pins, unique: performance?.uniquePinnersCount },
    { key: 'comparisons', icon: Scale, label: 'compares', userLabel: 'comparers',  count: performance?.comparisons, unique: performance?.uniqueComparersCount },
  ];

  const highlightStyle = "font-bold text-yellow-400";

  return (
    <div className="relative rounded-lg shadow-lg overflow-hidden h-full w-full text-left">
      <div className="absolute inset-0 z-0">
        <img 
          src={property.photos[0].url} 
          alt={property.title} 
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-black/70 to-transparent opacity-80"></div>
      </div>
      <div className="relative z-20 flex h-full p-4 flex-col justify-between text-sm">
        <div>
          <div className={`inline-block px-2 py-1 text-xs font-semibold text-white rounded ${property.listingType === 'Sale' ? 'bg-blue-600' : 'bg-green-600'} mb-2`}>
            {property.listingType}
          </div>
          <h3 className="text-lg font-bold mb-1 text-white text-shadow line-clamp-1">{property.title}</h3>
          <p className="text-white text-xs flex items-center mb-1 text-shadow">
            <MapPin className="h-3 w-3 mr-1" />
            {locationString}
          </p>
        </div>
        <div>
          <p className="text-xl font-bold text-white mb-2 text-shadow">
            {property.price.toLocaleString('en-MY', { style: 'currency', currency: 'MYR' })}
            {property.listingType === 'Rent' && '/month'}
          </p>
          <div className="grid grid-cols-2 gap-x-2 gap-y-1 text-xs text-white text-shadow">
            {metrics.map((metric) => (
              <React.Fragment key={metric.key}>
                <span className={`flex items-center ${focusMetric === metric.key ? highlightStyle : ''}`}>
                  <metric.icon className="h-3 w-3 mr-1" />
                  {metric.count} {metric.label}
                </span>
                <span className={`flex items-center ${focusMetric === metric.key ? highlightStyle : ''}`}>
                  <User className="h-3 w-3 mr-1" />
                  {metric.unique} {metric.userLabel}
                </span>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyAnalysisCard;