import React, { useMemo, useContext } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { useTheme } from '../../../context/ui/ThemeContext';

const BubbleChart = ({ data, title, icon: IconComponent, iconColor, colorGradient, onClick, selectedBubble }) => {
  const { isDarkMode } = useTheme();

  const option = useMemo(() => ({
    tooltip: {
      trigger: 'item',
      formatter: '{b}',
      backgroundColor: isDarkMode ? '#1f2937' : '#fff',
      borderColor: isDarkMode ? '#4b5563' : '#ccc',
      textStyle: {
        color: isDarkMode ? '#e5e7eb' : '#333',
      },
      borderWidth: 1,
      shadowBlur: 10,
      shadowColor: 'rgba(0, 0, 0, 0.2)',
    },
    series: [{
      type: 'graph',
      layout: 'force',
      force: {
        repulsion: 100,
        gravity: 0.05,
        edgeLength: [30, 80],
      },
      roam: false,
      animationDurationUpdate: 800,
      animationEasingUpdate: 'quinticInOut',
      data: data.map(item => ({
        name: item.label,
        value: item.value,
        symbolSize: Math.max(Math.sqrt(item.value) * 30, 30),
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
            { offset: 0, color: colorGradient[0] },
            { offset: 1, color: colorGradient[1] },
          ]),
          shadowBlur: selectedBubble === item.label ? 55 : 15,  // Increase shadow for the selected bubble
          shadowColor: selectedBubble === item.label ? 'rgba(0, 128, 255, 100)' : 'rgba(0, 0, 0, 0.2)',  // Change shadow color for selected bubble
          shadowOffsetX: 5,
          shadowOffsetY: 5,
          opacity: item.value === 0 ? 0.3 : 0.85,
          borderColor: selectedBubble === item.label ? 'rgba(0, 128, 255, 1)' : '#fff',  // Change border color for selected bubble
          borderWidth: selectedBubble === item.label ? 3 : 1,  // Increase border width for the selected bubble
        },
        label: {
          show: true,
          formatter: '{c}',
          fontSize: 14,
          color: isDarkMode ? '#e5e7eb' : 'white',
        },
      })),
    }],
  }), [data, colorGradient, isDarkMode, selectedBubble]);

  const onEvents = {
    click: (params) => {
      if (onClick) {
        onClick(params.name);  // Pass the clicked bubble label to the parent component
      }
    },
    touchstart: (params) => {
      if (onClick) {
        onClick(params.name);
      }
    },
  };

  return (
    <div className="w-full p-2 relative">
      <h3 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300 flex items-center">
      <IconComponent 
        className={`h-5 w-5 inline mr-2 ${
          iconColor === 'blue' ? 'text-blue-500 dark:text-blue-300' :
          iconColor === 'green' ? 'text-green-500 dark:text-green-300' :
          iconColor === 'yellow' ? 'text-yellow-500 dark:text-yellow-300' :
          iconColor === 'gray' ? 'text-gray-500 dark:text-gray-300' : ''
        }`} 
      />
        {title}
      </h3>
      <ReactECharts option={option} onEvents={onEvents} style={{ height: 300, width: '100%' }} />
    </div>
  );
};

export default BubbleChart;