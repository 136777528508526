import { createContext, useContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import storageHelper from '../../utils/storageHelper'

const ANONYMOUS_ID_KEY = 'anonymousId';
const SESSION_ID_KEY = 'sessionId';
const SESSION_EXPIRY_TIME = 30 * 60 * 1000; // 30 minutes session expiry

// Create a context
const UserSessionContext = createContext();

// Utility functions to get and set IDs
const generateAnonymousId = () => uuidv4();
const generateSessionId = () => uuidv4();

// Provider component
export const UserSessionProvider = ({ children }) => {
  const [anonymousId, setAnonymousId] = useState(null);
  const [sessionId, setSessionId] = useState(null);

  // Load or create anonymousId and sessionId
  useEffect(() => {
    // Get anonymousId from localStorage or create one if it doesn't exist
    let storedAnonymousId = storageHelper.getItem(ANONYMOUS_ID_KEY);
    if (!storedAnonymousId) {
      storedAnonymousId = generateAnonymousId();
      storageHelper.setItem(ANONYMOUS_ID_KEY, storedAnonymousId);
    }
    setAnonymousId(storedAnonymousId);

    // Get sessionId from sessionStorage or create one if it doesn't exist
    let storedSessionId = storageHelper.getItem(SESSION_ID_KEY, true);
    const lastActivity = storageHelper.getItem('lastActivity');

    // If no sessionId or session expired, create a new sessionId
    if (!storedSessionId || (lastActivity && Date.now() - lastActivity > SESSION_EXPIRY_TIME)) {
      storedSessionId = generateSessionId();
      storageHelper.setItem(SESSION_ID_KEY, storedSessionId, true);
    }
    storageHelper.setItem('lastActivity', Date.now(), true);
    setSessionId(storedSessionId);
  }, []);

  return (
    <UserSessionContext.Provider value={{ anonymousId, sessionId }}>
      {children}
    </UserSessionContext.Provider>
  );
};

// Hook to use session IDs in any component
export const useUserSession = () => useContext(UserSessionContext);
