import React from 'react';

const ProjectOverviewView = ({ content }) => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-6">Project Overview</h2>
        <div 
          className="text-lg leading-relaxed"
          dangerouslySetInnerHTML={{ __html: content.description }}
        />
      </div>
    </div>
  );
};

export default ProjectOverviewView;