import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Replace Next.js router with useNavigate from react-router-dom
import { motion } from 'framer-motion';
import { Sun, Moon, Home, Search, BarChart2, Wrench, Users, Building, Building2, LogIn, LogOut, 
  HousePlus, FilePlus, FileChartColumn, ChartNoAxesCombined, ChartSpline, MessageCircleIcon,
  Globe2, MapPin, Hotel
} from 'lucide-react';
import { useAuth } from '../../context/auth/AuthContext';

const publicMenuItems = [
  { name: 'Home', icon: Home, section: 'home' },
  { name: 'Search', icon: Search, section: 'search' },
  { name: 'Listings', icon: Building2, section: 'listings' },
  { name: 'Insights', icon: BarChart2, section: 'insights' },
  { name: 'Location', icon: MapPin, section: 'location' },
  { name: 'Projects', icon: Hotel, section: 'project' },
];

const seekerMenuItems = [
  { name: 'Tools', icon: Wrench, section: 'tools' },
  { name: 'Profile', icon: Users, section: 'profile' },
  { name: 'Messages', icon: MessageCircleIcon, section: 'messages' },
]

const listerMenuItems = [
  { name: 'Create Listing', icon: HousePlus, section: 'cms/create-listing' },
  { name: 'Manage Listings', icon: Building, section: 'cms/manage-listing' },
  { name: 'Listing Dashboard', icon: ChartSpline, section: 'cms/listing-dashboard' },
];

const contributorMenuItems = [
  { name: 'Create Content', icon: FilePlus, section: 'cms/create-content' },
  { name: 'Manage Content', icon: FileChartColumn, section: 'cms/manage-content' },
  { name: 'Content Dashboard', icon: ChartNoAxesCombined, section: 'cms/content-dashboard' },
];

const marketerMenuItems = [
  { name: 'Create Project', icon: Hotel, section: 'project/create-project' },
  { name: 'Manage Projects', icon: Hotel, section: 'project/manage-project' },
]

const adminMenuItems = [
  { name: 'Manage Location', icon: Globe2, section: 'admin/manage-location'}
]

const BurgerMenu = ({ isOpen, onClose, onDarkModeToggle, isDarkMode, hideButtons }) => {
  const { auth, logout } = useAuth();
  const navigate = useNavigate(); // Replacing Next.js useRouter with useNavigate
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [userRole, setUserRole] = useState(auth.user?.role);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth <= 768);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsAuthenticated(auth.isAuthenticated);
    setUserRole(auth.user?.role);
  }, [auth.isAuthenticated, auth.user]);

  const handleNavClick = (section) => {
    if (window.location.pathname !== '/' && section !== 'home')
      {
        if(!window.location.pathname.includes(section)) {
          hideButtons(); // Hide the action buttons when a menu item is clicked
        }
      }

    if (section === 'home') {
      navigate('/');
    } else {
      // hideButtons(); // Hide buttons on navigation
      navigate(`/${section}`);
    }
    onClose();
  };

  const handleLogout = () => {
    logout();
    hideButtons(); // Hide buttons on logout
    navigate('/');
    onClose();
  };

  const renderMenuItems = () => {
    let items = [...publicMenuItems];
    if (isAuthenticated) {
      if (userRole === 'admin') {
        items = [...items, ...seekerMenuItems, ...listerMenuItems, ...contributorMenuItems, ...marketerMenuItems, ...adminMenuItems];
      } else if (userRole === 'lister') {
        items = [...items, ...seekerMenuItems, ...listerMenuItems];
      } else if (userRole === 'contributor') {
        items = [...items, ...seekerMenuItems, ...contributorMenuItems];
      } else if (userRole === 'marketer') {
        items = [...items, ...seekerMenuItems, ...marketerMenuItems];
      } else if (userRole === 'seeker') {
        items = [...items, ...seekerMenuItems];
      } 
    }

    return items.map((item) => (
      <button
        key={item.name}
        onClick={() => handleNavClick(item.section)}
        className="flex items-center w-full text-left px-4 py-2 text-neutral-800 dark:text-neutral-100 hover:bg-primary-100 dark:hover:bg-primary-700 hover:text-primary-600 dark:hover:text-primary-200 transition-colors duration-200 rounded-lg"
      >
        <item.icon className="h-5 w-5 mr-3" />
        <span>{item.name}</span>
      </button>
    ));
  };

  const renderIconList = () => {
    const menuItems = publicMenuItems.concat(
      isAuthenticated
        ? userRole === 'admin'
          ? listerMenuItems.concat(contributorMenuItems)
          : userRole === 'lister'
          ? listerMenuItems
          : contributorMenuItems
        : []
    );

    return (
      <motion.div
        className={`fixed bottom-20 right-4 z-50 ${isMobile ? 'grid grid-cols-2' : 'grid grid-cols-1'}`}
        style={{ columnGap: isMobile ? '0.515rem' : '0rem', rowGap: '0.75rem' }}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ type: 'spring', stiffness: 300, damping: 20 }}
      >
        {menuItems.map((item) => (
          <button
            key={item.name}
            onClick={() => handleNavClick(item.section)}
            className="p-3 rounded-full bg-primary-600 text-white shadow-md hover:bg-primary-700 transition-colors duration-200"
          >
            <item.icon className="h-5 w-5" />
          </button>
        ))}
        <button
          onClick={onDarkModeToggle}
          className="p-3 rounded-full bg-primary-600 text-white shadow-md hover:bg-primary-700 transition-colors duration-200"
        >
          {isDarkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
        </button>
        {isAuthenticated ? (
          <button
            onClick={handleLogout}
            className="p-3 rounded-full bg-primary-600 text-white shadow-md hover:bg-primary-700 transition-colors duration-200"
          >
            <LogOut className="h-5 w-5" />
          </button>
        ) : (
          <button
            onClick={() => handleNavClick('login')}
            className="p-3 rounded-full bg-primary-600 text-white shadow-md hover:bg-primary-700 transition-colors duration-200"
          >
            <LogIn className="h-5 w-5" />
          </button>
        )}
      </motion.div>
    );
  };

  const renderFullMenu = () => (
    <motion.div
      className="fixed top-0 right-0 h-full w-full sm:w-80 bg-neutral-100 dark:bg-neutral-800 shadow-lg z-50 overflow-y-auto"
      initial={{ x: '100%' }}
      animate={{ x: isOpen ? 0 : '100%' }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className="p-4">
        <nav className="mt-8">
          {renderMenuItems()}
          {isAuthenticated ? (
            <button
              onClick={handleLogout}
              className="flex items-center w-full text-left px-4 py-2 text-neutral-800 dark:text-neutral-100 hover:bg-primary-100 dark:hover:bg-primary-700 hover:text-primary-600 dark:hover:text-primary-200 transition-colors duration-200 rounded-lg"
            >
              <LogOut className="h-5 w-5 mr-3" />
              <span>Logout</span>
            </button>
          ) : (
            <button
              onClick={() => handleNavClick('login')}
              className="flex items-center w-full text-left px-4 py-2 text-neutral-800 dark:text-neutral-100 hover:bg-primary-100 dark:hover:bg-primary-700 hover:text-primary-600 dark:hover:text-primary-200 transition-colors duration-200 rounded-lg"
            >
              <LogIn className="h-5 w-5 mr-3" />
              <span>Login</span>
            </button>
          )}
          <button
            onClick={onDarkModeToggle}
            className="flex items-center w-full text-left px-4 py-2 text-neutral-800 dark:text-neutral-100 hover:bg-primary-100 dark:hover:bg-primary-700 hover:text-primary-600 dark:hover:text-primary-200 transition-colors duration-200 mt-2 rounded-lg"
          >
            {isDarkMode ? (
              <>
                <Sun className="h-5 w-5 mr-3" />
                <span>Light Mode</span>
              </>
            ) : (
              <>
                <Moon className="h-5 w-5 mr-3" />
                <span>Dark Mode</span>
              </>
            )}
          </button>
        </nav>
      </div>
    </motion.div>
  );

  const useIconList = false;

  return <>{isOpen && (useIconList ? renderIconList() : renderFullMenu())}</>;
};

export default BurgerMenu;
