import React from 'react';
import { MapPinIcon, HomeIcon, Square2StackIcon, BeakerIcon, ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import { PinIcon, PinOffIcon } from 'lucide-react';

const PropertyPreviewCard = ({ property, onClick, className, isPinned, onPin, onUnpin }) => {
  const locationString = [property.township, property.district, property.region]
    .filter(Boolean)
    .join(', ');

  const handlePinToggle = (e) => {
    e.stopPropagation();
    if (isPinned) {
      onUnpin();
    } else {
      onPin();
    }
  };

  return (
    <div onClick={onClick} className={`${className} relative`}>
      <div className="absolute inset-0 z-0">
        {property.photos && property.photos.length > 0 ? (
          <img 
            src={property.photos[0].url} 
            alt={property.title} 
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-300" />
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-black via-black/70 to-transparent opacity-80 group-hover:opacity-90 transition-opacity duration-300"></div>
      </div>
      <div className="relative z-10 flex flex-col justify-between h-full p-4">
        <div>
          <div className={`inline-block px-2 py-1 text-xs font-semibold text-white rounded ${property.listingType === 'Sale' ? 'bg-blue-600' : 'bg-green-600'} mb-2`}>
            {property.listingType}
          </div>
          <h3 className="text-lg font-bold mb-1 text-white text-shadow line-clamp-2">{property.title}</h3>
          <p className="text-white text-xs flex items-center mb-1 text-shadow">
            <MapPinIcon className="h-3 w-3 mr-1" />
            <span className="truncate">{locationString}</span>
          </p>
          <p className="text-white text-xs flex items-center text-shadow">
            <HomeIcon className="h-3 w-3 mr-1" />
            {property.propertyType}
          </p>
        </div>
        <div>
          <p className="text-xl font-bold text-white mb-2 text-shadow">{new Intl.NumberFormat('en-MY', { style: 'currency', currency: 'MYR' }).format(property.price)}</p>
          <div className="flex justify-between text-xs text-white text-shadow">
            <span className="flex items-center"><Square2StackIcon className="h-3 w-3 mr-1" />{property.bedroom}</span>
            <span className="flex items-center"><BeakerIcon className="h-3 w-3 mr-1" />{property.bathroom}</span>
            <span className="flex items-center"><ArrowsRightLeftIcon className="h-3 w-3 mr-1" />{property.size} sqft</span>
          </div>
        </div>
      </div>
      {(onPin || onUnpin) && (
        <button
          onClick={handlePinToggle}
          className={`absolute top-2 right-2 p-2 rounded-full shadow-md transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-primary-500 z-20 ${
            isPinned
              ? 'bg-primary-500 hover:bg-primary-600 text-white'
              : 'bg-white hover:bg-primary-100 text-primary-600'
          }`}
        >
          {isPinned ? <PinOffIcon className='h-5 w-5 rotate-45' strokeWidth={1.5} /> : <PinIcon className='h-5 w-5 rotate-45' strokeWidth={1.5} />}
        </button>
      )}
    </div>
  );
};

export default PropertyPreviewCard;
