import React from 'react';
import PropertyPreviewCard from './PropertyPreviewCard';
import ArticlePreviewCard from './ArticlePreviewCard';
import GuidePreviewCard from './GuidePreviewCard';
import ProjectPreviewCard from './ProjectPreviewCard';
import YoutubePreviewCard from './YoutubePreviewCard';

const ContentPreviewCard = ({ content, onViewDetails, isPinned, onPin, onUnpin }) => {
  const handleClick = () => {
    if (onViewDetails && typeof onViewDetails === 'function') {
      onViewDetails(content.listingId || content.id);
    }
  };

  function checkIsMocked(item) {
    const prefixes = ["article-", "project-", "guide-"];
    for (const prefix of prefixes) {
      if (item?.startsWith(prefix)) {
        return true;
      }
    }
    return false;
  }

  const commonProps = {
    onClick: onViewDetails ? handleClick : undefined,
    className: `flex-shrink-0 w-64 sm:w-72 h-80 rounded-lg shadow-lg mr-4 overflow-hidden relative ${
      onViewDetails && !checkIsMocked(content.id) ? 'cursor-pointer' : ''
    }`
  };

  if (content.contentType === 'Article') {
    return <ArticlePreviewCard article={content} {...commonProps} />;
  } else if (content.contentType === 'Guide') {
    return <GuidePreviewCard guide={content} {...commonProps} />;
  } else if (content.listingType) {
    return (
      <PropertyPreviewCard 
        property={content} 
        {...commonProps} 
        isPinned={isPinned}
        onPin={() => onPin(content)}
        onUnpin={() => onUnpin(content.listingId || content.id)}
      />
    );
  } else if (content.contentType === 'YoutubeVideo') {
    return <YoutubePreviewCard video={content} {...commonProps} />;
  } else if (content) {
    return <ProjectPreviewCard project={content} {...commonProps} />;
  } else {
    // Fallback for unknown content types
    return (
      <div className="flex-shrink-0 w-64 sm:w-72 h-80 rounded-lg shadow-lg mr-4 overflow-hidden relative bg-gray-200 flex items-center justify-center">
        <p className="text-gray-500">Unknown content type</p>
      </div>
    );
  }
};

export default ContentPreviewCard;
