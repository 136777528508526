import React, { useState } from 'react';
import { UploadCloud, File, Trash2 } from 'lucide-react';

const DocumentUpload = ({ content, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [documents, setDocuments] = useState(content.documents || []);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // In a real application, you would upload the file to your server or a cloud storage service here
      // For this example, we'll just add the file information to the state
      const newDocument = {
        id: Date.now(),
        name: file.name,
        size: file.size,
        type: file.type,
        url: URL.createObjectURL(file) // This is temporary and won't persist
      };
      const updatedDocuments = [...documents, newDocument];
      setDocuments(updatedDocuments);
      onUpdate({ documents: updatedDocuments });
    }
  };

  const removeDocument = (id) => {
    const updatedDocuments = documents.filter(doc => doc.id !== id);
    setDocuments(updatedDocuments);
    onUpdate({ documents: updatedDocuments });
  };

  const handleSave = () => {
    onUpdate({ documents });
    setIsEditing(false);
  };

  const handleDiscard = () => {
    setDocuments(content.documents || []);
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <h3 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">Edit Project Documents</h3>
        <div className="mb-4">
          <label className="flex flex-col items-center px-4 py-6 bg-white dark:bg-gray-700 text-primary-500 dark:text-primary-400 rounded-lg shadow-lg tracking-wide uppercase border border-primary-500 dark:border-primary-400 cursor-pointer hover:bg-primary-500 hover:text-white dark:hover:bg-primary-600">
            <UploadCloud className="w-8 h-8" />
            <span className="mt-2 text-base leading-normal">Upload a document</span>
            <input type='file' className="hidden" onChange={handleFileUpload} accept=".pdf,.doc,.docx,.xls,.xlsx" />
          </label>
        </div>
        <div className="space-y-2">
          {documents.map(doc => (
            <div key={doc.id} className="flex items-center justify-between p-2 bg-gray-100 dark:bg-gray-700 rounded">
              <div className="flex items-center">
                <File className="w-5 h-5 mr-2 text-gray-600 dark:text-gray-300" />
                <span className="text-gray-800 dark:text-gray-200">{doc.name}</span>
              </div>
              <div className="flex items-center">
                <a href={doc.url} download className="text-primary-500 hover:text-primary-700 dark:text-primary-400 dark:hover:text-primary-300 mr-2">Download</a>
                <button onClick={() => removeDocument(doc.id)} className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300">
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-6 flex justify-end space-x-4">
          <button 
            onClick={handleDiscard} 
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-gray-400 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Discard
          </button>
          <button 
            onClick={handleSave} 
            className="px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Save
          </button>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="bg-gray-100 dark:bg-gray-700 p-8 rounded-lg shadow-md cursor-pointer" 
      onClick={() => setIsEditing(true)}
    >
      <h3 className="text-3xl font-bold mb-6 text-gray-900 dark:text-gray-100">Project Documents</h3>
      <div className="space-y-2">
        {documents.map(doc => (
          <div key={doc.id} className="flex items-center justify-between p-2 bg-white dark:bg-gray-800 rounded">
            <div className="flex items-center">
              <File className="w-5 h-5 mr-2 text-gray-600 dark:text-gray-300" />
              <span className="text-gray-800 dark:text-gray-200">{doc.name}</span>
            </div>
            <a href={doc.url} download className="text-primary-500 hover:text-primary-700 dark:text-primary-400 dark:hover:text-primary-300">Download</a>
          </div>
        ))}
      </div>
      {documents.length === 0 && (
        <p className="text-gray-500 dark:text-gray-400">No documents uploaded yet. Click to add documents.</p>
      )}
    </div>
  );
};

export default DocumentUpload;