import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from '../../context/ui/ThemeContext';

const DemandInsightsSection = ({ listings, listingPerformance, filter }) => {
  const { isDarkMode } = useTheme();

  const { propertyTypeDemand, priceRangeDemand } = useMemo(() => {
    const propertyTypeDemand = {};
    const priceRangeDemand = {};

    const filteredListings = filter && filter.type === 'listingType'
      ? listings.filter(listing => listing.listingType === filter.value)
      : listings;

    const createDynamicPriceRanges = (prices, isRent) => {
      const sortedPrices = prices.sort((a, b) => a - b);
      const minPrice = sortedPrices[0];
      const maxPrice = sortedPrices[sortedPrices.length - 1];
      
      let rangeSize;
      if (isRent) {
        rangeSize = Math.ceil((maxPrice - minPrice) / 6 / 100) * 100; // Round to nearest 100
      } else {
        rangeSize = Math.ceil((maxPrice - minPrice) / 6 / 10000) * 10000; // Round to nearest 10,000
      }

      const ranges = [];
      let currentMin = Math.floor(minPrice / rangeSize) * rangeSize;
      while (currentMin < maxPrice) {
        ranges.push({
          min: currentMin,
          max: currentMin + rangeSize
        });
        currentMin += rangeSize;
      }
      return ranges;
    };

    const formatPrice = (price, isRent) => {
      return isRent
        ? `RM ${price.toLocaleString()}`
        : `RM ${(price / 1000).toFixed(0)}k`;
    };

    const rentPrices = filteredListings.filter(l => l.listingType === 'Rent').map(l => l.price);
    const salePrices = filteredListings.filter(l => l.listingType === 'Sale').map(l => l.price);

    const rentRanges = createDynamicPriceRanges(rentPrices, true);
    const saleRanges = createDynamicPriceRanges(salePrices, false);

    filteredListings.forEach(listing => {
      const performance = listingPerformance[listing.listingId] || {};
      const enquiries = performance.enquiries || 0;

      // Property type demand
      propertyTypeDemand[listing.propertyType] = (propertyTypeDemand[listing.propertyType] || 0) + enquiries;

      // Price range demand
      const isRent = listing.listingType === 'Rent';
      const priceRanges = isRent ? rentRanges : saleRanges;
      const priceRange = priceRanges.find(range => listing.price >= range.min && listing.price < range.max);
      
      if (priceRange) {
        const rangeKey = `${formatPrice(priceRange.min, isRent)} - ${formatPrice(priceRange.max, isRent)}${isRent ? '/month' : ''}`;
        priceRangeDemand[rangeKey] = (priceRangeDemand[rangeKey] || 0) + enquiries;
      }
    });

    return { propertyTypeDemand, priceRangeDemand };
  }, [listings, listingPerformance, filter]);

  const sortedPriceRangeDemand = useMemo(() => {
    const parsePriceRange = (range) => {
      const [minPrice] = range.split(' - ')[0].replace('RM ', '').replace('k', '000').split('/');
      return parseFloat(minPrice.replace(',', ''));
    };

    return Object.entries(priceRangeDemand)
      .sort((a, b) => parsePriceRange(a[0]) - parsePriceRange(b[0]))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  }, [priceRangeDemand]);

  const chartOptions = (categories) => ({
    chart: {
      type: 'bar',
      background: 'transparent',
      toolbar: { show: false }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
        distributed: false,
        dataLabels: { position: 'top' }
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: 30,
      style: { fontSize: '12px', colors: [isDarkMode ? '#e5e7eb' : '#4b5563'] },
      formatter: (val) => val.toFixed(0)
    },
    xaxis: {
      categories,
      labels: { 
        style: { colors: isDarkMode ? '#9ca3af' : '#4b5563' },
        rotate: 0,
        trim: true,
        maxHeight: 120
      }
    },
    yaxis: {
      labels: { style: { colors: isDarkMode ? '#9ca3af' : '#4b5563' } }
    },
    tooltip: { theme: isDarkMode ? 'dark' : 'light' },
    grid: { borderColor: isDarkMode ? '#374151' : '#e5e7eb' },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'horizontal',
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [0, 100]
      }
    },
    theme: { mode: isDarkMode ? 'dark' : 'light' }
  });

  const createChartSeries = (data, color) => [{
    name: 'Enquiries',
    data: Object.values(data),
    color: color
  }];

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Demand Insights</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h3 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">Most Enquired Property Types</h3>
          <Chart
            options={chartOptions(Object.keys(propertyTypeDemand))}
            series={createChartSeries(propertyTypeDemand, '#3b82f6')}
            type="bar"
            height={300}
          />
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">
            Most Enquired Price Ranges
            {filter && filter.type === 'listingType' && ` (${filter.value})`}
          </h3>
          <Chart
            options={chartOptions(Object.keys(sortedPriceRangeDemand))}
            series={createChartSeries(sortedPriceRangeDemand, '#10b981')}
            type="bar"
            height={300}
          />
        </div>
      </div>
    </div>
  );
};

export default DemandInsightsSection;