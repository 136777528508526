import React from 'react';
import ChatSystem from '../components/chat/ChatSystem';
import Header from '../components/global/Header';

const Message = () => {
  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900">
      <Header title={"Messages"} />
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
        <main>
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
              <div className="rounded-lg h-[calc(100vh-200px)]">
                <ChatSystem />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Message;