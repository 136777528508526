import React from 'react';
import { BookOpenIcon, ClockIcon } from '@heroicons/react/24/outline';
import { getRandomBackground } from '../../utils/backgroundHelper';

const GuidePreviewCard = ({ guide, onClick, className }) => {
  const backgroundImage = guide.featuredImage || getRandomBackground();

  return (
    <div onClick={onClick} className={className}>
      <div className="absolute inset-0 overflow-hidden">
        {/* Standard img element */}
        <img
          src={backgroundImage}
          alt={guide.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-black/70 to-transparent opacity-80 hover:opacity-90 transition-opacity duration-300"></div>
      </div>
      <div className="relative z-10 p-4 flex flex-col justify-between h-full">
        <div>
          <span
            className={`inline-block px-2 py-1 text-sm font-semibold text-white rounded mb-2 ${
              guide.difficulty === 'beginner'
                ? 'bg-green-600'
                : guide.difficulty === 'intermediate'
                ? 'bg-yellow-600'
                : 'bg-red-600'
            }`}
          >
            {guide.difficulty.charAt(0).toUpperCase() + guide.difficulty.slice(1)}
          </span>
          <h3 className="text-xl font-bold mb-2 text-white text-shadow">{guide.title}</h3>
          <p className="text-gray-300 text-sm line-clamp-3">{guide.summary}</p>
        </div>
        <div className="mt-4">
          <div className="flex items-center text-sm text-gray-300">
            <BookOpenIcon className="h-4 w-4 mr-1" />
            <span>Guide</span>
          </div>
          <div className="flex items-center text-sm text-gray-300 mt-1">
            <ClockIcon className="h-4 w-4 mr-1" />
            <span>{guide.estimatedReadTime} min read</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuidePreviewCard;
