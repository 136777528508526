
import React, { createContext, useState, useContext, useEffect } from 'react';

const SearchModeContext = createContext();

export const SearchModeProvider = ({ children }) => {
  const [isAIMode, setIsAIMode] = useState(false);

  useEffect(() => {
    // Check sessionStorage for existing value when component mounts
    const storedMode = sessionStorage.getItem('isAIMode');
    if (storedMode !== null) {
      setIsAIMode(storedMode === 'true');
    }
  }, []);

  const toggleAIMode = () => {
    setIsAIMode(prevMode => {
      const newMode = !prevMode;
      // Store the new mode in sessionStorage
      sessionStorage.setItem('isAIMode', newMode.toString());
      return newMode;
    });
  };

  return (
    <SearchModeContext.Provider value={{ isAIMode, toggleAIMode }}>
      {children}
    </SearchModeContext.Provider>
  );
};

export const useSearchMode = () => useContext(SearchModeContext);