import { v4 as uuidv4 } from 'uuid';
import backendApi from '../../api/backendApi';
import storageHelper from '../../utils/storageHelper';

class TrackingService {
  constructor() {
    this.sessionId = null;
    this.anonymousId = this.getStoredAnonymousId();
    this.userId = null;
    this.platform = this.getPlatform();
    this.initializationPromise = null;
  }
  
  getStoredAnonymousId() {
    let anonymousId = storageHelper.getItem('anonymousId');
    if (!anonymousId) {
      anonymousId = uuidv4();
      storageHelper.setItem('anonymousId', anonymousId);
    }
    return anonymousId;
  }
  
  getPlatform() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return 'mobile';
    }
    return 'web';
  }
  
  async initSession(userId = null) {
    if (this.initializationPromise) {
      return this.initializationPromise;
    }

    this.initializationPromise = (async () => {
      if (this.sessionId && !this.isSessionExpired()) {
        return this.sessionId;
      }
  
      try {
        const response = await backendApi.event.startSession({
          anonymousId: this.anonymousId,
          userId,
          platform: this.platform
        });
        this.sessionId = response.sessionId;
        this.sessionStartTime = Date.now();
        return this.sessionId;
      } catch (error) {
        console.error('Failed to initialize session:', error);
        this.initializationPromise = null; // Reset promise on error
        throw error;
      }
    })();
  
    return this.initializationPromise;
  }

  isSessionExpired() {
    const SESSION_DURATION = 30 * 60 * 1000; // 30 minutes in milliseconds
    return !this.sessionStartTime || (Date.now() - this.sessionStartTime > SESSION_DURATION);
  }
  
  async endSession() {
    if (this.sessionId) {
      try {
        await backendApi.event.endSession(this.sessionId);
        this.sessionId = null;
      } catch (error) {
        console.error('Failed to end session:', error);
      }
    }
  }
  
  async trackEvent(eventType, eventData) {
    if (!this.sessionId) {
      console.warn('No active session. Initializing a new one.');
      await this.initSession(this.userId);
    }
    
    try {
      await backendApi.event.trackEvent({
        sessionId: this.sessionId,
        anonymousId: this.anonymousId,
        userId: this.userId,
        eventType,
        eventData,
        platform: this.platform,
      });
      console.log('Event tracked:', eventType);
    } catch (error) {
      console.error('Failed to track event:', error);
    }
  }
  
  async associateWithUser(userId) {
    try {
      await backendApi.event.associateAnonymousWithUser(this.anonymousId, userId);
      this.userId = userId;
      console.log('Anonymous ID associated with user:', userId);
    } catch (error) {
      console.error('Failed to associate anonymous ID with user:', error);
    }
  }

  // Convenience methods
  async trackPageView(pageName, pageData = {}) {
    await this.trackEvent('page_view', { pageName, ...pageData });
  }

  async trackSearch(searchQuery, filters = {}) {
    await this.trackEvent('listing_search', { searchQuery, filters });
  }

  async trackListingView(listingId) {
    await this.trackEvent('listing_view', { listingId });
  }

  async trackPinListing(listingId) {
    await this.trackEvent('listing_pin', { listingId });
  }

  async trackUnpinListing(listingId) {
    await this.trackEvent('listing_unpin', { listingId });
  }

  async trackEnquiry(listingId, enquiryType) {
    await this.trackEvent('listing_enquiry', { listingId, enquiryType });
  }

  async trackComparison(eventData) {
    await this.trackEvent('listing_comparison', eventData );
  }

  async trackListingImpression(eventData) {
    await this.trackEvent('listing_impression', eventData);
  }

  async trackRegistration(userData) {
    await this.trackEvent('user_registration', userData);
  }

  async trackLogin(userId) {
    await this.trackEvent('user_login', { userId });
    await this.associateWithUser(userId);
    // Reinitialize the session with the user ID
    await this.initSession(userId);
  }

  async trackLogout() {
    await this.trackEvent('user_logout', {});
    // End the current session and start a new anonymous one
    await this.endSession();
    this.userId = null;
    await this.initSession();
  }
}

export const trackingService = new TrackingService();