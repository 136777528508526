import React from 'react';

const StepNavigation = ({ steps, currentStep, setCurrentStep, maxStepReached }) => {
  return (
    <nav aria-label="Progress" className="mb-8">
      <ol className="flex items-center justify-between w-full">
        {steps.map((step, index) => {
          const isActive = index === currentStep;
          const isCompleted = index < currentStep;
          const isClickable = index <= maxStepReached;
          const Icon = step.icon;

          return (
            <li key={step.label} className="relative flex items-center justify-center flex-1">
              {index !== 0 && (
                <div className="absolute left-0 top-1/2 h-0.5 w-full -translate-x-1/2 bg-gray-200 dark:bg-gray-700" aria-hidden="true">
                  <div className={`h-full ${isCompleted ? 'bg-primary-600 dark:bg-primary-400' : ''}`} style={{ width: `${isCompleted ? 100 : 0}%` }} />
                </div>
              )}
              <div className="relative flex flex-col items-center group">
                <span className="h-9 flex items-center">
                  <span
                    className={`relative z-10 w-9 h-9 flex items-center justify-center ${
                      isActive
                        ? 'bg-primary-600 dark:bg-primary-400 rounded-full'
                        : isCompleted
                        ? 'bg-primary-600 dark:bg-primary-400 rounded-full'
                        : 'bg-white dark:bg-gray-800 border-2 border-gray-300 dark:border-gray-600 rounded-full'
                    } ${isClickable ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                  >
                    {isCompleted ? (
                      <svg
                        className="w-5 h-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <Icon className={`w-5 h-5 ${isActive ? 'text-white' : 'text-gray-500 dark:text-gray-400'}`} aria-hidden="true" />
                    )}
                  </span>
                </span>
                <button
                  onClick={() => isClickable && setCurrentStep(index)}
                  className={`mt-3 text-sm font-medium ${
                    isActive ? 'text-primary-600 dark:text-primary-400' : 'text-gray-500 dark:text-gray-400'
                  }`}
                  disabled={!isClickable}
                >
                  {step.label}
                </button>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default StepNavigation;
