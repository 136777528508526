import React, { useState } from 'react';
import { DocumentTextIcon, BookOpenIcon } from '@heroicons/react/24/outline';
import ContentForm from '../../components/cms/content/ContentForm';
import Header from '../../components/global/Header';

const CreateContent = () => {
  const [selectedContentType, setSelectedContentType] = useState(null);

  const handleContentTypeSelect = (contentType) => {
    setSelectedContentType(contentType);
  };

  const handleBack = () => {
    setSelectedContentType(null);
  };

  const renderContentTypeSelection = () => (
    <div className="max-w-4xl mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
      <div className="px-6 py-8">
        <h1 className="text-3xl font-bold text-center mb-8 text-primary-700 dark:text-white">
          Choose Content Type
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[
            { type: 'Article', icon: DocumentTextIcon, description: 'Create an informative article on a specific topic.' },
            { type: 'Guide', icon: BookOpenIcon, description: 'Develop a comprehensive guide or tutorial.' }
          ].map(({ type, icon: Icon, description }) => (
            <div
              key={type}
              onClick={() => handleContentTypeSelect(type)}
              className="group cursor-pointer bg-gray-50 dark:bg-gray-700 rounded-lg p-6 transition-all duration-300 hover:shadow-md hover:bg-primary-50 dark:hover:bg-primary-900"
            >
              <div className="flex items-center mb-4">
                <Icon className="h-8 w-8 text-primary-500 dark:text-primary-400 mr-3" />
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white group-hover:text-primary-600 dark:group-hover:text-primary-400">
                  {type}
                </h2>
              </div>
              <p className="text-gray-600 dark:text-gray-300 group-hover:text-primary-700 dark:group-hover:text-primary-300">
                {description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900">
      <Header title={"Create Content"} />
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col">
        <main className="flex-grow container mx-auto p-4">
          {selectedContentType ? (
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
              <button
                onClick={handleBack}
                className="mb-6 px-4 py-2 text-sm font-medium text-primary-600 bg-primary-100 dark:bg-primary-900 dark:text-primary-400 rounded-md hover:bg-primary-200 dark:hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-colors duration-300"
              >
                ← Back to Content Type Selection
              </button>
              <ContentForm contentType={selectedContentType} />
            </div>
          ) : (
            renderContentTypeSelection()
          )}
        </main>
      </div>
    </div>
  );
};

export default CreateContent;