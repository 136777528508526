import React, { useEffect, useState, useCallback } from 'react';
import { useSocketService } from '../../hooks/chat/socketService';
import NotificationBadge from '../../components/chat/NotificationBadge';
import { arrayBufferToString } from '../../utils/arrayBufferHelper';
import backendApi from '../../api/backendApi';
import { AnimatePresence } from 'framer-motion';

const getInitials = (name) => {
  return name
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);
};

const ChatList = ({ chats, onSelectChat, selectedChatId, currentUser }) => {
  const [unreadCounts, setUnreadCounts] = useState({});
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const { onNewMessage, offNewMessage, onMessageUpdated, offMessageUpdated } = useSocketService();

  useEffect(() => {
    const fetchUnreadCounts = async () => {
      try {
        const counts = await Promise.all(chats.map(chat => 
          backendApi.chat.getUnreadCount(chat.id)
        ));
        const newUnreadCounts = chats.reduce((acc, chat, index) => {
          acc[chat.id] = counts[index];
          return acc;
        }, {});
        setUnreadCounts(newUnreadCounts);
        
        const total = counts.reduce((sum, count) => sum + count, 0);
        setTotalUnreadCount(total);
      } catch (error) {
        console.error('Error fetching unread counts:', error);
      }
    };

    fetchUnreadCounts();

    const handleNewMessage = async ({ chatId, message }) => {
      const messageSenderId = arrayBufferToString(message.senderId);
      if (messageSenderId !== currentUser._id && (!selectedChatId || selectedChatId !== chatId)) {
        setUnreadCounts(prev => ({
          ...prev,
          [chatId]: (prev[chatId] || 0) + 1
        }));
        setTotalUnreadCount(prevTotal => prevTotal + 1);
      }
    };

    const handleMessageUpdated = async ({ chatId, message }) => {
      const messageSenderId = arrayBufferToString(message.senderId);
      if (messageSenderId !== currentUser._id && message.read) {
        setUnreadCounts(prev => {
          const newCount = Math.max((prev[chatId] || 0) - 1, 0);
          return { ...prev, [chatId]: newCount };
        });
        setTotalUnreadCount(prevTotal => Math.max(prevTotal - 1, 0));
      }
    };

    onNewMessage(handleNewMessage);
    onMessageUpdated(handleMessageUpdated);

    return () => {
      offNewMessage(handleNewMessage);
      offMessageUpdated(handleMessageUpdated);
    };
  }, [chats, selectedChatId, currentUser._id, onNewMessage, offNewMessage, onMessageUpdated, offMessageUpdated]);

  const handleSelectChat = useCallback((chat) => {
    if (unreadCounts[chat.id] > 0) {
      backendApi.chat.markMessagesAsRead(chat.id, Date.now())
        .then(() => {
          setUnreadCounts(prev => ({ ...prev, [chat.id]: 0 }));
          setTotalUnreadCount(prevTotal => prevTotal - unreadCounts[chat.id]);
        })
        .catch(error => console.error('Error marking messages as read:', error));
    }
    onSelectChat(chat.id);
  }, [unreadCounts, onSelectChat]);

  if (chats.length === 0) {
    return (
      <div className="w-full h-full flex items-center justify-center text-gray-500 dark:text-gray-400">
        No chats yet
      </div>
    );
  }

  return (
    <div className="w-full h-full overflow-y-auto">
      <div className="p-3 border-b border-gray-200 dark:border-gray-700">
        <h2 className="text-lg font-semibold text-gray-800 dark:text-white flex items-center">
          Chats
          {totalUnreadCount > 0 && (
            <span className="ml-2 bg-primary-500 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
              {totalUnreadCount}
            </span>
          )}
        </h2>
      </div>
      {chats.map((chat) => (
        <div 
          key={chat.id} 
          className={`flex items-center p-3 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors ${
            selectedChatId === chat.id ? 'bg-gray-200 dark:bg-gray-600' : ''
          }`}
          onClick={() => handleSelectChat(chat)}
        >
          <div className="w-11 h-11 rounded-full mr-3 bg-gradient-to-t from-primary-400 to-primary-800 flex items-center justify-center text-white font-bold text-lg shadow-lg shadow-primary-900">
            {chat.otherUser?.profilePicture ? (
              <div className="w-11 h-11 rounded-full flex items-center justify-center">
                <img
                  src={chat.otherUser.profilePicture}
                  alt={chat.otherUser?.displayName || chat.otherUser?.name || 'User'}
                  className="w-full h-full rounded-full object-cover"
                />
              </div>
            ) : (
              <div className="w-11 h-11 rounded-full flex items-center justify-center">
              <span>
                {getInitials(chat.otherUser?.displayName || chat.otherUser?.name || 'U')}
              </span>
              </div>
            )}
          </div>
          <div className="flex-grow min-w-0 mr-2">
            <h3 className="font-semibold text-gray-900 dark:text-white truncate">
              {chat.otherUser?.name || 'Unknown User'}
            </h3>
            <p className="text-sm text-gray-600 dark:text-gray-300 truncate">{chat.lastMessage}</p>
          </div>
          <div className="flex-shrink-0 relative">
            <AnimatePresence>
              {unreadCounts[chat.id] > 0 && (
                <NotificationBadge count={unreadCounts[chat.id]} />
              )}
            </AnimatePresence>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatList;