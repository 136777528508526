import React from 'react';

const DocumentUploadView = ({ content }) => {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-6">Documents</h2>
        <ul className="space-y-4">
          {content.documents && content.documents.map((doc, index) => (
            <li key={index} className="flex items-center">
              <a href={doc.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                {doc.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DocumentUploadView;