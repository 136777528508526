// src/components/ListerDashboard/ListerDashboard.jsx

import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '../../context/auth/AuthContext';
import backendApi from '../../api/backendApi';
import PeriodSelector from '../../components/analytics/PeriodSelector';
import OverviewTab from '../../components/analytics/tab/OverviewTab'
import ListingTab from '../../components/analytics/tab/ListingTab';
import Header from '../../components/global/Header';

const ListingDashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [period, setPeriod] = useState('7 Days');
  const [activeTab, setActiveTab] = useState('overview');
  const { auth } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const [analysisData, listingsData] = await Promise.all([
          backendApi.analysis.getListerAnalysis(auth.user?._id, period),
          backendApi.listings.fetchListerListings(auth.user?._id)
        ]);
        setDashboardData(analysisData);
        setListings(listingsData.listings);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setError('Failed to load dashboard data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, [auth.user?._id, period]);

  const handlePeriodChange = (newPeriod) => {
    setPeriod(newPeriod);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-center py-10 text-red-600 dark:text-red-400">{error}</div>;
  }

  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900">
      <Header title={"Listing Dashboard"} />
      <div className="container mx-auto px-4 py-8">        
        <div className="mb-6 flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
          <div className="flex flex-wrap gap-2 w-full sm:w-auto">
            <button
              onClick={() => setActiveTab('overview')}
              className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
              activeTab === 'overview'
                  ? 'bg-primary-600 text-white dark:bg-primary-500'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600'
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('detailed')}
              className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                activeTab === 'detailed'
                  ? 'bg-primary-600 text-white dark:bg-primary-500'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600'
                }`}
            >
              Detailed Listing Analysis
            </button>
          </div>
          <PeriodSelector currentPeriod={period} onPeriodChange={handlePeriodChange} />
        </div>
        
        {activeTab === 'overview' && dashboardData && (
          <OverviewTab dashboardData={dashboardData} listings={listings} />
        )}
        
        { activeTab === 'detailed' && (
          <ListingTab 
            listings={listings}
            period={period}
            dashboardData={dashboardData}
          />
        )}
      </div>
    </div>
  );
};

export default ListingDashboard;