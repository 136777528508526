import React, { useState, useEffect } from 'react';
import { ChartBarIcon } from '@heroicons/react/24/solid';
import EnhancedListingSelector from '../EnhancedListingSelector';
import PerformanceOverviewCard from '../card/PerformanceOverviewCard';
import PropertyAnalysisCard from '../card/PropertyAnalysisCard';
import EngagementOverviewCard from '../card/EngagementOverviewCard';
import DailyPerformanceChart from '../chart/DailyPerformanceChart';
import ConversionFunnelChart from '../chart/ConversionFunnelChart';
import EnquirerInsights from '../EnquirerInsights';
import backendApi from '../../../api/backendApi';

const ListingTab = ({ listings, period, dashboardData }) => {
  const [selectedListing, setSelectedListing] = useState(null);
  const [detailedData, setDetailedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedListing) {
      fetchDetailedData(selectedListing.listingId);
    }
  }, [selectedListing, period]);

  const fetchDetailedData = async (listingId) => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await backendApi.analysis.getListingAnalysis(listingId, period);
      setDetailedData(data);
    } catch (err) {
      setError('Failed to fetch detailed listing data. Please try again.');
      console.error('Error fetching detailed listing data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectListing = (listing) => {
    if (!listing) {
      setSelectedListing();
      setDetailedData();
    }
    setSelectedListing(listing);
  };

  return (
    <div>
      <EnhancedListingSelector 
        listings={listings}
        onSelectListing={handleSelectListing}
        dashboardData={dashboardData}
      />

      {isLoading && <div className="text-center py-4">Loading...</div>}
      {error && <div className="text-center py-4 text-red-600">{error}</div>}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8"></div>

      {detailedData ? (
        <>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <PerformanceOverviewCard data={detailedData} />
          <EngagementOverviewCard listingPerformance={detailedData.listingPerformance} />
        </div>
        <PropertyAnalysisCard property={selectedListing} performance={detailedData.listingPerformance} focusMetric={'enquiries'} forSingleListing={true} />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <DailyPerformanceChart dailyData={detailedData.dailyData} />
          <ConversionFunnelChart data={detailedData} />
        </div>
        {detailedData && detailedData.uniqueEnquirers.length > 0 && (
          <EnquirerInsights 
            uniqueEnquirers={detailedData.uniqueEnquirers}
            listingPerformance={detailedData.listingPerformance}
            listings={listings}
          />
        )}
        </>
      ) : (
        <div className="bg-white dark:bg-neutral-800 rounded-lg shadow-md p-8 flex flex-col items-center justify-center text-center h-full">
          <ChartBarIcon className="h-24 w-24 text-primary-600 dark:text-primary-400 mx-auto mb-6" />
          <h2 className="text-2xl font-bold mb-4 text-neutral-800 dark:text-neutral-200">Ready to start your analysis?</h2>
          <p className="text-lg text-neutral-600 dark:text-neutral-400">
            Please select a listing from the selection
          </p>
        </div>
      )}
    </div>
  );
};

export default ListingTab;