const storageHelper = {
    setItem: (key, value, useSession = false) => {
      const storage = useSession ? sessionStorage : localStorage;
      try {
        storage.setItem(key, JSON.stringify(value));
      } catch (error) {
        console.error('Error storing data:', error);
      }
    },
  
    getItem: (key, useSession = false) => {
      const storage = useSession ? sessionStorage : localStorage;
      try {
        const item = storage.getItem(key);
        return item ? JSON.parse(item) : null;
      } catch (error) {
        console.error('Error retrieving data:', error);
        return null;
      }
    },
  
    removeItem: (key, useSession = false) => {
      const storage = useSession ? sessionStorage : localStorage;
      try {
        storage.removeItem(key);
      } catch (error) {
        console.error('Error removing data:', error);
      }
    },
  
    clear: (useSession = false) => {
      const storage = useSession ? sessionStorage : localStorage;
      try {
        storage.clear();
      } catch (error) {
        console.error('Error clearing storage:', error);
      }
    },
  };
  
  export default storageHelper;