import React, { useState } from 'react';

const LocationMap = ({ content, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [mapUrl, setMapUrl] = useState(content.mapUrl || '');
  const [address, setAddress] = useState(content.address || '');

  const handleSave = () => {
    onUpdate({ mapUrl, address });
    setIsEditing(false);
  };

  const handleDiscard = () => {
    setMapUrl(content.mapUrl || '');
    setAddress(content.address || '');
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">Edit Location</h2>
        <input
          type="text"
          value={mapUrl}
          onChange={(e) => setMapUrl(e.target.value)}
          placeholder="Google Maps Embed URL"
          className="w-full p-2 mb-4 border rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent"
        />
        <textarea
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Address"
          className="w-full p-2 mb-4 border rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent"
          rows="3"
        />
        <div className="flex justify-end space-x-4">
          <button 
            onClick={handleDiscard} 
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-gray-400 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Discard
          </button>
          <button 
            onClick={handleSave} 
            className="px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Save
          </button>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="bg-gray-100 dark:bg-gray-700 p-8 rounded-lg shadow-md cursor-pointer" 
      onClick={() => setIsEditing(true)}
    >
      <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-gray-100">Location</h2>
      <div className="aspect-w-16 aspect-h-9 mb-4 rounded-lg overflow-hidden">
        <iframe 
          src={mapUrl} 
          width="100%" 
          height="450" 
          style={{border:0}} 
          allowFullScreen="" 
          loading="lazy"
          className="w-full h-full"
        ></iframe>
      </div>
      <p className="text-lg text-gray-700 dark:text-gray-300">{address}</p>
    </div>
  );
};

export default LocationMap;