import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import backendApi from '../api/backendApi';
import Header from '../components/global/Header';
import ProjectCard from '../components/card/ProjectCard';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    setIsLoading(true);
    try {
      const fetchedProjects = await backendApi.projects.fetchAll();
      setProjects(fetchedProjects);
    } catch (err) {
      console.error('Error fetching projects:', err);
      setError('Failed to load projects. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewDetails = (projectId) => {
    const project = projects.find(p => p._id === projectId);
    if (project) {
      navigate(`/project${project.path}`);
    }
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Header title="Projects" />
      <div className="container mx-auto px-4 py-8">
        {projects.length === 0 ? (
          <p className="text-center text-gray-500 dark:text-gray-400">No projects found.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {projects.map(project => (
              <ProjectCard
                key={project._id}
                project={project}
                onViewDetails={handleViewDetails}
                showEditOptions={false}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;