import React, { useState } from 'react';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';

const Testimonials = ({ content, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [testimonials, setTestimonials] = useState(content.testimonials || []);

  const handleSave = () => {
    onUpdate({ testimonials });
    setIsEditing(false);
  };

  const handleDiscard = () => {
    setTestimonials(content.testimonials || []);
    setIsEditing(false);
  };

  const handleAddTestimonial = () => {
    setTestimonials([...testimonials, { name: '', quote: '', title: '' }]);
  };

  const handleTestimonialChange = (index, field, value) => {
    const newTestimonials = [...testimonials];
    newTestimonials[index] = { ...newTestimonials[index], [field]: value };
    setTestimonials(newTestimonials);
  };

  const handleRemoveTestimonial = (index) => {
    const newTestimonials = testimonials.filter((_, i) => i !== index);
    setTestimonials(newTestimonials);
  };

  if (isEditing) {
    return (
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">Edit Testimonials</h2>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="mb-4 p-4 border dark:border-gray-600 rounded-lg">
            <input
              type="text"
              value={testimonial.name}
              onChange={(e) => handleTestimonialChange(index, 'name', e.target.value)}
              placeholder="Name"
              className="w-full p-2 mb-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent"
            />
            <input
              type="text"
              value={testimonial.title}
              onChange={(e) => handleTestimonialChange(index, 'title', e.target.value)}
              placeholder="Title"
              className="w-full p-2 mb-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent"
            />
            <textarea
              value={testimonial.quote}
              onChange={(e) => handleTestimonialChange(index, 'quote', e.target.value)}
              placeholder="Quote"
              className="w-full p-2 mb-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent"
              rows="3"
            />
            <button 
              onClick={() => handleRemoveTestimonial(index)} 
              className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        ))}
        <button 
          onClick={handleAddTestimonial} 
          className="mt-4 flex items-center text-primary-600 hover:text-primary-800 dark:text-primary-400 dark:hover:text-primary-300"
        >
          <PlusIcon className="h-5 w-5 mr-1" />
          Add Testimonial
        </button>
        <div className="mt-6 flex justify-end space-x-4">
          <button 
            onClick={handleDiscard} 
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-gray-400 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Discard
          </button>
          <button 
            onClick={handleSave} 
            className="px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Save
          </button>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="bg-gray-100 dark:bg-gray-700 p-8 rounded-lg shadow-md cursor-pointer" 
      onClick={() => setIsEditing(true)}
    >
      <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-gray-100">What Our Clients Say</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
            <p className="text-lg mb-4 text-gray-700 dark:text-gray-300 italic">"{testimonial.quote}"</p>
            <div>
              <p className="font-semibold text-gray-900 dark:text-gray-100">{testimonial.name}</p>
              <p className="text-sm text-gray-600 dark:text-gray-400">{testimonial.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;