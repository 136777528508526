import React from 'react';
import ImageUploadPreview from '../cms/shared/ImageUploadPreview';

const MapImageForm = ({ mapImage, onChange }) => {
  const handleImageChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onChange({
          file: file,
          preview: reader.result,
          caption: mapImage?.caption || ''
        });
      };
      reader.readAsDataURL(file);
    } else {
      onChange(null);
    }
  };

  const handleCaptionChange = (e) => {
    onChange({
      ...mapImage,
      caption: e.target.value
    });
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Map Image</h3>
      <ImageUploadPreview
        initialImage={mapImage?.preview || mapImage?.url}
        onImageChange={handleImageChange}
      />
      {mapImage && (
        <div>
          <label htmlFor="mapImageCaption" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Map Image Caption
          </label>
          <input
            type="text"
            id="mapImageCaption"
            name="caption"
            value={mapImage.caption || ''}
            onChange={handleCaptionChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>
      )}
    </div>
  );
};

export default MapImageForm;