import React from 'react';
import { Link } from 'react-router-dom';
import { MapPinIcon, UsersIcon, CurrencyDollarIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

const LocationCard = ({ location, basePath = '/location', showNextLevel = false }) => {
  const formatUrlPath = (name) => {
    return encodeURIComponent(name.toLowerCase().replace(/\s+/g, '-'));
  };

  const locationPath = `${basePath}/${formatUrlPath(location.name)}`;

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden h-80">
      <Link to={locationPath} className="block h-full relative">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{ backgroundImage: `url(${location.featuredImage?.url || '/default-location-image.jpg'})` }}
        >
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black/70 to-transparent"></div>
        </div>
        <div className="relative h-full flex flex-col justify-end p-6 text-white z-10">
          <h3 className="text-2xl font-bold mb-2 text-shadow">{location.name}</h3>
          <p className="text-sm mb-4 line-clamp-3 text-shadow">{location.intro}</p>
          <div className="space-y-2">
            <div className="flex items-center text-sm text-shadow">
              <MapPinIcon className="h-4 w-4 mr-2" />
              <span>{location.type}</span>
            </div>
            <div className="flex items-center text-sm text-shadow">
              <UsersIcon className="h-4 w-4 mr-2" />
              <span>{location.population?.toLocaleString() || 'N/A'} residents</span>
            </div>
            <div className="flex items-center text-sm text-shadow">
              <CurrencyDollarIcon className="h-4 w-4 mr-2" />
              <span>Median Income: RM {location.statistics?.medianIncome?.toLocaleString() || 'N/A'}</span>
            </div>
          </div>
        </div>
      </Link>
      {showNextLevel && location.hasSubLocations && (
        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 py-2 px-6">
          <Link 
            to={locationPath}
            className="block text-center text-sm text-white hover:underline transition-colors duration-200 text-shadow"
          >
            View Sub-locations <ArrowRightIcon className="inline-block h-4 w-4 ml-1" />
          </Link>
        </div>
      )}
    </div>
  );
};

export default LocationCard;