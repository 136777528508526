import React from 'react';
import TopPerformingListingsCarousel from '../analytics/TopPerformingListingCarousel';

const TopPerformersSection = ({ listings, listingPerformance }) => {
  const metrics = [
    { key: 'views', title: 'Most Viewed' },
    { key: 'enquiries', title: 'Most Enquired' },
    { key: 'pins', title: 'Most Pinned' },
    { key: 'comparisons', title: 'Most Compared' }
  ];

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Top 3 Performers</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {metrics.map(({ key, title }) => (
          <div key={key} className="h-100"> {/* Adjust height as needed */}
            <TopPerformingListingsCarousel
              listingPerformance={listingPerformance}
              listings={listings}
              metric={key}
              title={title}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopPerformersSection;