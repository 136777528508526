import React from 'react';
import { Link } from 'react-router-dom';

const ProjectPreviewCard = ({ project, onClick, className }) => {
  const heroContent = project.blocks.find(block => block.type === 'hero')?.content || {};

  return (
    <Link to={`/project${project.path}`} onClick={onClick} className={className}>
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden h-full relative group">
        {heroContent.backgroundImage ? (
          <img
            src={heroContent.backgroundImage}
            alt={heroContent.title || project.name}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-300 dark:bg-gray-700 flex items-center justify-center">
            <span className="text-gray-500 dark:text-gray-400">No image available</span>
          </div>
        )}
        <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-end p-4 transition-opacity duration-300 group-hover:bg-opacity-50">
          <h3 className="text-xl font-bold text-white mb-2 truncate">{heroContent.title || project.name}</h3>
          <p className="text-sm text-white mb-2 line-clamp-2">{heroContent.subtitle || 'No description available'}</p>
        </div>
      </div>
    </Link>
  );
};

export default ProjectPreviewCard;
