import React from 'react';
import { PlayIcon } from '@heroicons/react/24/solid';

const YoutubePreviewCard = ({ video, onClick, className }) => {
  return (
    <div onClick={() => onClick(video.id)} className={className}>
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden h-full relative group">
        <img
          src={video.thumbnail}
          alt={video.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-end p-4 transition-opacity duration-300 group-hover:bg-opacity-50">
          <h3 className="text-xl font-bold text-white mb-2 truncate">{video.title}</h3>
          <p className="text-sm text-white mb-2 line-clamp-2">{video.description}</p>
          <div className="flex justify-between items-center text-white text-sm">
            <div className="flex items-center space-x-2">
              <PlayIcon className="h-5 w-5" />
              <span>{video.viewCount} views</span>
            </div>
            <span>{new Date(video.publishedAt).toLocaleDateString()}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YoutubePreviewCard;