import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { XMarkIcon, LinkIcon, LinkSlashIcon, PencilIcon, EyeIcon, MapPinIcon } from '@heroicons/react/24/solid';
import { Bed, Bath, Ruler, Armchair, Building } from 'lucide-react';
import ImageCarousel from '../multipurpose/ImageCarousel';
import ScrollableCardSection from '../multipurpose/ScrollableCardSection';
import FormattedText from '../multipurpose/FormattedText';
import AgentContactInfo from '../multipurpose/AgentContactInfo';
import backendApi from '../../api/backendApi';
import { usePinnedProperties } from '../../context/control/PinnedPropertiesContext';
import { useAuth } from '../../context/auth/AuthContext';
import useEscKey from '../../hooks/control/useEscKey';
import useDisableScroll from '../../hooks/control/useDisableScroll';
import PropertyAnalysis from '../analytics/PropertyAnalysis';
import { fetchPropertyAnalysis } from '../../api/mockApi';
// import { fetchRelatedAnalysis, fetchRelatedArticles } from '../../services/api';

const PropertyDetailsModal = ({ propertyId, isOpen, onClose, onEdit, showEditOption = false, onToggleStatus }) => {
  const [property, setProperty] = useState(null);
  const [relatedAnalysis, setRelatedAnalysis] = useState([]);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [propertyAnalysis, setPropertyAnalysis] = useState(null);
  const { pinnedProperties, pinProperty, unpinProperty } = usePinnedProperties();
  const { auth } = useAuth();
  const isPinned = pinnedProperties.some(p => p.listingId === propertyId);
  const [selectedChat, setSelectedChat] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const resetState = useCallback(() => {
    setProperty(null);
    setRelatedAnalysis([]);
    setRelatedArticles([]);
    setIsLoading(true);
    setError(null);
  }, []);

  useEscKey(onClose);
  useDisableScroll(isOpen);

  useEffect(() => {
    if (isOpen && propertyId) {
      setIsLoading(true);
      setError(null);

      backendApi.listings.fetchById(propertyId)
        .then(propertyData => {
          setProperty(propertyData);
          return Promise.all([
            fetchPropertyAnalysis(propertyData) // Pass the entire property object
          ]);
        })
        .then(([propertyAnalysisData]) => {
          setPropertyAnalysis(propertyAnalysisData);
          setIsLoading(false);
        })
        .catch(err => {
          console.error('Error fetching property details:', err);
          setError('Failed to load property details. Please try again.');
          setIsLoading(false);
        });
    } else if (!isOpen) {
      resetState();
    }
  }, [isOpen, propertyId, resetState]);

  const handleInitiateChat = (chat) => {
    setSelectedChat(chat);
    setIsChatOpen(true);
  };

  const handlePinToggle = useCallback((e) => {
    e.stopPropagation();
    if (property) {
      if (isPinned) {
        unpinProperty(propertyId);
      } else {
        pinProperty(property);
      }
    }
  }, [property, isPinned, pinProperty, unpinProperty, propertyId]);

  const handleToggleStatus = useCallback((e) => {
    e.stopPropagation();
    if (property) {
      onToggleStatus(property.listingId, property.status);
    }
  }, [property, onToggleStatus]);

  const handleEdit = useCallback((e) => {
    e.stopPropagation();
    onEdit(propertyId);
    onClose();
  }, [onEdit, propertyId, onClose]);

  const canEdit = showEditOption && auth.isAuthenticated && auth.user && 
                  (auth.user.role === 'lister' || auth.user.role === 'admin');

  if (!property || !isOpen) {
    return null;
  }

  const locationString = [property.buildingName, property.street, property.area, property.township, property.district, property.region]
    .filter(Boolean)
    .join(', ');

    return (
      <motion.div
        key={propertyId}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 z-30 flex items-center justify-center p-4"
        onClick={onClose}
      >
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          className="bg-white dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden w-full max-w-5xl max-h-[90vh] flex flex-col"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="relative p-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
            <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-gray-900 dark:text-white truncate">
              {isLoading ? 'Loading...' : property?.title || 'Property Details'}
            </h2>
            <div className="flex items-center space-x-2">
              {!canEdit ? (
                <button
                  onClick={handlePinToggle}
                  className={`p-2 rounded-full transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-primary-500 ${
                    isPinned
                      ? 'bg-primary-500 hover:bg-primary-600 text-white'
                      : 'bg-white hover:bg-primary-100 text-primary-600'
                  }`}
                  aria-label={isPinned ? "Unpin property" : "Pin property"}
                >
                  {isPinned ? <LinkSlashIcon className="h-5 w-5" /> : <LinkIcon className="h-5 w-5" />}
                </button>
              ) : (
                <>
                  <button
                    onClick={handleEdit}
                    className="p-2 rounded-full bg-yellow-600 hover:bg-yellow-700 text-white transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                    aria-label="Edit property"
                  >
                    <PencilIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={handleToggleStatus}
                    className={`p-2 rounded-full shadow-md transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-primary-500 pointer-events-auto ${
                      property.status === 'active'
                        ? 'bg-green-500 hover:bg-red-500 text-white'
                        : 'bg-red-500 hover:bg-green-500 text-white'
                    }`}
                    aria-label={property.status === 'active' ? "Take offline" : "Make online"}
                  >
                    <EyeIcon className='h-5 w-5' />
                  </button>
                </>
              )}
              <button
                onClick={onClose}
                className="p-2 rounded-full text-primary-400 bg-white hover:bg-primary-100 text-primary-600 dark:text-primary-500 dark:hover:text-primary-300 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-primary-500"
                aria-label="Close modal"
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
  
          <div className="flex-grow overflow-y-auto scrollbar-hide">
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600 dark:border-primary-400"></div>
              </div>
            ) : error ? (
              <div className="p-4 text-center text-red-600 dark:text-red-400">
                {error}
              </div>
            ) : property ? (
              <>
                <div className="relative">
                  <div className={`absolute top-5 left-0 z-10 ${property.listingType === 'Sale' ? 'bg-blue-600' : 'bg-green-600'} text-white text-sm font-semibold px-2 py-1 rounded-br-lg`}>
                    {property.listingType}
                  </div>
                  <ImageCarousel images={property.photos} />
                </div>
                <div className="p-4 sm:p-6">
                  <div className="flex flex-col mb-4">
                    <h3 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white mb-2">{property.title}</h3>
                    <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300 flex items-start">
                      <MapPinIcon className="h-5 w-5 mr-2 text-primary-500 flex-shrink-0 mt-1" />
                      <span className="break-words">{locationString}</span>
                    </p>
                    <p className="text-2xl sm:text-3xl font-bold text-primary-600 dark:text-primary-400 mt-2">
                      {new Intl.NumberFormat('en-MY', { style: 'currency', currency: 'MYR' }).format(property.price)}
                    </p>
                  </div>
  
                  <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-2 sm:gap-4 mb-6 bg-gray-100 dark:bg-gray-700 p-3 sm:p-4 rounded-lg text-xs sm:text-sm">
                    <div className="flex items-center">
                      <Bed className="h-4 w-4 sm:h-5 sm:w-5 mr-1 sm:mr-2 text-primary-500" />
                      <span className="font-semibold">{property.bedroom} Bed</span>
                    </div>
                    <div className="flex items-center">
                      <Bath className="h-4 w-4 sm:h-5 sm:w-5 mr-1 sm:mr-2 text-primary-500" />
                      <span className="font-semibold">{property.bathroom} Bath</span>
                    </div>
                    <div className="flex items-center">
                      <Ruler className="h-4 w-4 sm:h-5 sm:w-5 mr-1 sm:mr-2 text-primary-500" />
                      <span className="font-semibold">{property.size} sqft</span>
                    </div>
                    <div className="flex items-center">
                      <Armchair className="h-4 w-4 sm:h-5 sm:w-5 mr-1 sm:mr-2 text-primary-500" />
                      <span className="font-semibold">{property.furnishing}</span>
                    </div>
                    <div className="flex items-center">
                      <Building className="h-4 w-4 sm:h-5 sm:w-5 mr-1 sm:mr-2 text-primary-500" />
                      <span className="font-semibold">{property.propertyType}</span>
                    </div>
                  </div>
  
                  <h4 className="text-lg sm:text-xl font-semibold text-gray-800 dark:text-gray-200 mb-3">Description</h4>
                  <div className="text-sm sm:text-base text-gray-600 dark:text-gray-300 mb-6">
                    <FormattedText content={property.description} />
                  </div>

                  {propertyAnalysis && (
                    <>
                      <h4 className="text-lg sm:text-xl font-semibold text-gray-800 dark:text-gray-200 mb-3">Analysis</h4>
                      <div className="text-sm sm:text-base text-gray-600 dark:text-gray-300 mb-6">
                        <PropertyAnalysis analysisData={propertyAnalysis} />
                      </div>
                    </>
                  )}

  
                  <div className="mt-6 bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                    <h4 className="text-lg font-semibold mb-2 text-gray-800 dark:text-white">Agent Information</h4>
                    <AgentContactInfo property={property} onInitiateChat={handleInitiateChat} />
                  </div>
  
                  {relatedAnalysis.length > 0 && (
                    <ScrollableCardSection
                      title="Related Analysis"
                      cards={relatedAnalysis}
                      moreLink="/analysis"
                      fullWidth={true}
                    />
                  )}
                  {relatedArticles.length > 0 && (
                    <ScrollableCardSection
                      title="Related Articles"
                      cards={relatedArticles}
                      moreLink="/articles"
                      fullWidth={true}
                    />
                  )}
                </div>
              </>
            ) : (
              <div className="p-4 text-center text-gray-600 dark:text-gray-400">
                No property details available.
              </div>
            )}
          </div>
        </motion.div>
      </motion.div>
    );
  };
  
  export default PropertyDetailsModal;