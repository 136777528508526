import React from 'react';

const ImageGalleryView = ({ content }) => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-4">Image Gallery</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {content.images && content.images.map((image, index) => (
          <img key={index} src={image} alt={`Gallery image ${index + 1}`} className="w-full h-64 object-cover rounded-lg" />
        ))}
      </div>
    </div>
  );
};

export default ImageGalleryView;