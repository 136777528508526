import React from 'react';
import { Home, Settings, Lock } from 'lucide-react';

const SideNavigation = ({ activeTab, setActiveTab }) => {
  const navItems = [
    { id: 'profile', label: 'Profile', icon: Home },
    { id: 'preferences', label: 'Preferences', icon: Settings },
    { id: 'password', label: 'Change Password', icon: Lock },
  ];

  return (
    <nav className="space-y-2">
      {navItems.map((item) => (
        <button
          key={item.id}
          onClick={() => setActiveTab(item.id)}
          className={`w-full text-left px-4 py-2 rounded-md transition-colors duration-200
            ${activeTab === item.id 
              ? 'bg-primary-100 text-primary-800 dark:bg-primary-800 dark:text-primary-100' 
              : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-primary-600'}`}
        >
          <item.icon className="inline-block mr-2" size={18} /> {item.label}
        </button>
      ))}
    </nav>
  );
};

export default SideNavigation;