export function renderPhotoSrc (photo) {
  if (!photo) return null;

    if (photo instanceof File) {
      return URL.createObjectURL(photo);  
    } else if (typeof photo === 'object' && photo.file) {
      return URL.createObjectURL(photo.file);
    } else if (typeof photo === 'object' && photo.url) {
      return photo.url;
    } else if (typeof photo === 'string') {
      return photo;
    }
    return null;
  };