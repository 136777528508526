import React from 'react';
import { motion } from 'framer-motion';

const Hero = ({ onStartJourney }) => {
  return (
    <div id="hero-section" className="relative h-screen flex items-center justify-center overflow-hidden bg-gradient-to-br from-primary-600 to-secondary-600">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 text-center px-4 sm:px-6 lg:px-8">
        <motion.h1 
          className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Discover Your Dream Home with AI-Powered Precision
        </motion.h1>
        <motion.p 
          className="text-xl sm:text-2xl text-neutral-100 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Revolutionizing property search in Malaysia
        </motion.p>
        <motion.button 
          className="bg-white text-primary-600 text-lg px-8 py-3 rounded-lg hover:bg-primary-50 transition-colors duration-200"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={onStartJourney}
        >
          Start Your Property Journey
        </motion.button>
      </div>
    </div>
  );
};

export default Hero;
