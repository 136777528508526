import React, { useEffect } from 'react';
import BasicInfoForm from './BasicInfoForm';
import StatisticsForm from './StatisticsForm';
import AmenitiesForm from './AmenitiesForm';
import ImagesForm from './ImagesForm';
import MapImageForm from './MapImageForm';
import FeaturedImageForm from './FeaturedImageForm';
import AddressForm from './AddressForm';
import UnitsForm from './UnitsForm';

const LocationForm = ({ location, parentLocation, onSubmit, onChange, isAdding, isLoading }) => {
  useEffect(() => {
    if (isAdding) {
      onChange({
        ...location,
        type: getNextLocationType(parentLocation?.type)
      });
    }
  }, [isAdding, parentLocation]);

  const getNextLocationType = (parentType) => {
    const types = ['region', 'district', 'township', 'area', 'building'];
    if (!parentType) return 'region';
    const currentIndex = types.indexOf(parentType);
    return types[currentIndex + 1] || 'building';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange({ ...location, [name]: value });
  };

  const handleNestedChange = (e, nestedField) => {
    const { name, value } = e.target;
    onChange({
      ...location,
      [nestedField]: {
        ...location[nestedField],
        [name]: value
      }
    });
  };

  const handleAmenitiesChange = (e) => {
    const amenities = e.target.value.split(',').map(item => item.trim());
    onChange({ ...location, amenities });
  };

  const handleUnitChange = (index, field, value) => {
    const newUnits = [...location.units];
    newUnits[index] = { ...newUnits[index], [field]: value };
    onChange({ ...location, units: newUnits });
  };

  const addUnit = () => {
    onChange({
      ...location,
      units: [...location.units, { unitNumber: '', floor: '', size: '', price: '', bedrooms: '', bathrooms: '' }]
    });
  };

  const removeUnit = (index) => {
    onChange({
      ...location,
      units: location.units.filter((_, i) => i !== index)
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(location);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <BasicInfoForm
        formData={location}
        handleChange={handleChange}
        parentLocation={parentLocation}
      />
      
      <StatisticsForm
        statistics={location.statistics}
        handleNestedChange={handleNestedChange}
      />
      
      <AmenitiesForm
        amenities={location.amenities}
        handleAmenitiesChange={handleAmenitiesChange}
      />

      <FeaturedImageForm
        featuredImage={location.featuredImage}
        onChange={(featuredImage) => onChange({ ...location, featuredImage })}
      />

      <MapImageForm
        mapImage={location.mapImage}
        onChange={(mapImage) => onChange({ ...location, mapImage })}
      />
      
      <ImagesForm
        images={location.images}
        onChange={(images) => onChange({ ...location, images })}
      />
      
      {location.type === 'building' && (
        <>
          <AddressForm
            address={location.address}
            handleNestedChange={handleNestedChange}
          />
          
          <UnitsForm
            units={location.units}
            handleUnitChange={handleUnitChange}
            addUnit={addUnit}
            removeUnit={removeUnit}
          />
        </>
      )}

      <div className="flex justify-between pt-4">
        <button
          type="button"
          onClick={() => onSubmit(null)}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 dark:bg-gray-600 dark:text-gray-200 dark:hover:bg-gray-700"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isLoading}
          className="px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:bg-primary-600 dark:hover:bg-primary-700 disabled:opacity-50"
        >
          {isLoading ? 'Saving...' : (isAdding ? 'Add Location' : 'Update Location')}
        </button>
      </div>
    </form>
  );
};

export default LocationForm;