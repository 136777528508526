import React from 'react';
import DOMPurify from 'dompurify';

const FormattedText = ({ content, className = '' }) => {
  // Safely sanitizing the HTML content using DOMPurify
  const createMarkup = (html) => {
    // DOMPurify works on the client side, ensuring the HTML is safe
    if (typeof window !== 'undefined') {
      return { __html: DOMPurify.sanitize(html) };
    }
    return { __html: '' }; // Return empty content if running on the server
  };

  return (
    <div
      className={`formatted-text ${className}`}
      dangerouslySetInnerHTML={createMarkup(content)}
    />
  );
};

export default FormattedText;
