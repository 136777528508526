import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Bars3Icon, XMarkIcon, ChatBubbleLeftRightIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import BurgerMenu from './BurgerMenu';
import ChatSystem from '../chat/ChatSystem';
import NotificationBadge from '../chat/NotificationBadge';
import { useAuth } from '../../context/auth/AuthContext';
import { useSocketService } from '../../hooks/chat/socketService';
import backendApi from '../../api/backendApi';
import { useScreenSize } from '../../context/ui/ScreenSizeContext';
import storageHelper from '../../utils/storageHelper';
import { useTheme } from '../../context/ui/ThemeContext';

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [areButtonsVisible, setAreButtonsVisible] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [enabledIcons, setEnabledIcons] = useState(1);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showIcons, setShowIcons] = useState(false);
  const menuRef = useRef(null);
  const chatRef = useRef(null);
  const { auth, isInitialized } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  const prevPathRef = useRef(window.location.pathname);
  const { theme } = useTheme();
  const { onTotalUnreadCountUpdated, offTotalUnreadCountUpdated } = useSocketService();
  const { initializeSocket, onNewMessage, offNewMessage, onMessageUpdated, offMessageUpdated, disconnectSocket } = useSocketService();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
    if (!isChatOpen) {
      fetchTotalUnreadCount();
    }
  };

  const toggleDarkMode = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    document.documentElement.classList.toggle('dark', newMode);
    storageHelper.setItem('theme', newMode ? 'dark' : 'light');
  };

  const fetchTotalUnreadCount = useCallback(async () => {
    try {
      const count = await backendApi.chat.getTotalUnreadCount();
      setNotificationCount(count);
    } catch (error) {
      console.error('Error fetching total unread count:', error);
    }
  }, []);

  const handleMessagesRead = useCallback(() => {
    fetchTotalUnreadCount();
  }, [fetchTotalUnreadCount]);

  useEffect(() => {
    const darkMode = storageHelper.getItem('theme');
    if (darkMode === 'dark') {
      setIsDarkMode(true);
      document.documentElement.classList.add('dark');
    } else {
      setIsDarkMode(false);
      document.documentElement.classList.remove('dark');
    }
  }, []);

  const toggleButtonsVisibility = () => {
    if (areButtonsVisible) {
      setIsChatOpen(false);
    }
    setAreButtonsVisible(!areButtonsVisible);
  };

  useEffect(() => {
    const calculateEnabledIcons = () => {
      const icons = [
        auth.isAuthenticated,
        true,
      ].filter(Boolean).length;

      setEnabledIcons(icons);

      if (isMobile && icons > 1) {
        setAreButtonsVisible(false);
      }

      if (!auth.isAuthenticated) {
        setAreButtonsVisible(true);
      }
    };

    calculateEnabledIcons();
  }, [auth.isAuthenticated, isMobile]);

  useEffect(() => {
    if (isMobile && enabledIcons > 1) {
      setAreButtonsVisible(false);
    }
    setIsMenuOpen(false);
  }, [isMobile, enabledIcons]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setIsMenuOpen(false);
        setIsChatOpen(false);
      }
    };

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && !event.target.closest('.menu-toggle-button')) {
        setIsMenuOpen(false);
      }
      if (chatRef.current && !chatRef.current.contains(event.target) && !event.target.closest('.chat-toggle-button')) {
        setIsChatOpen(false);
      }
    };

    window.addEventListener('keydown', handleEsc);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('keydown', handleEsc);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(isInitialized && auth.isAuthenticated) {
      fetchTotalUnreadCount();
    }
    
  }, [isInitialized, auth.isAuthenticated]);

  useEffect(() => {
    const isLandingPage = window.location.pathname === '/';
    if (!isMobile && enabledIcons > 1) {
      setAreButtonsVisible(true);
    }
    else if (isInitialized && auth.isAuthenticated) {
      setAreButtonsVisible(false);
    }
    else {
      setAreButtonsVisible(true);
    }

    if (isLandingPage) {
      setShowIcons(false);
      const timer = setTimeout(() => setShowIcons(true), 800);
      return () => clearTimeout(timer);
    } else {
      setShowIcons(true);
    }
  }, [window.location.pathname, isMobile, enabledIcons]);

  useEffect(() => {
    if (isInitialized && auth.isAuthenticated) {
      const socket = initializeSocket();
      const handleNewMessage = () => {
        fetchTotalUnreadCount();
      };
      onNewMessage(handleNewMessage);
      onMessageUpdated(handleNewMessage);
      onTotalUnreadCountUpdated(fetchTotalUnreadCount);

      return () => {
        offNewMessage(handleNewMessage);
        offMessageUpdated(handleNewMessage);
        offTotalUnreadCountUpdated(fetchTotalUnreadCount);
        disconnectSocket();
      };
    }
  }, [isInitialized, auth.isAuthenticated, initializeSocket, onNewMessage, offNewMessage, onMessageUpdated, offMessageUpdated, disconnectSocket, fetchTotalUnreadCount, onTotalUnreadCountUpdated, offTotalUnreadCountUpdated]);

  const arrowVariants = {
    visible: { rotate: 0 },
    hidden: { rotate: 180 }
  };

  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900 relative">
      <main className="max-w-full overflow-x-hidden">{children}</main>
      <div ref={menuRef}>
        <BurgerMenu
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          onDarkModeToggle={toggleDarkMode}
          isDarkMode={isDarkMode}
          hideButtons={toggleButtonsVisibility}
        />
      </div>

      {auth.isAuthenticated && isChatOpen && (
        <div ref={chatRef} className="fixed bottom-20 right-4 z-40">
          <ChatSystem
            mode="popup"
            isOpen={isChatOpen}
            onClose={() => setIsChatOpen(false)}
            onUnreadCountUpdate={fetchTotalUnreadCount}
            onMessagesRead={handleMessagesRead}
          />
        </div>
      )}

      {(isMenuOpen || isChatOpen) && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={() => {
            setIsMenuOpen(false);
            setIsChatOpen(false);
          }}
        />
      )}

      <div className="fixed bottom-4 right-4 z-50 flex items-end space-x-2 transition-opacity duration-500 ease-in-out opacity-100">
        {enabledIcons > 1 && (
          <>
          <motion.button
            // initial={{ opacity: 0, x: 20 }}
            animate={areButtonsVisible ? "visible" : "hidden"}
            variants={arrowVariants}
            // transition={{ duration: 0.3, delay: 0.1 }}
            onClick={toggleButtonsVisibility}
            className="p-3 rounded-full bg-primary-600 text-white shadow-md hover:bg-primary-700 transition-colors duration-200 relative"
          >
            <ChevronRightIcon className="h-5 w-5" />
          </motion.button>
          {!areButtonsVisible && auth.isAuthenticated && notificationCount > 0 && (
            <NotificationBadge count={notificationCount} />
          )}
          </>
        )}
        {areButtonsVisible && (
          <>
            {auth.isAuthenticated && (
              <motion.button
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3, delay: 0.2 }}
                onClick={(e) => {
                  e.stopPropagation(); // Stop the event from propagating
                  toggleChat();
                }}
                className="chat-toggle-button p-3 rounded-full bg-primary-600 text-white shadow-md hover:bg-primary-700 transition-colors duration-200 relative"
              >
                <ChatBubbleLeftRightIcon className="h-5 w-5" />
                {notificationCount > 0 && <NotificationBadge count={notificationCount} />}
              </motion.button>
            )}
            <motion.button
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: 0.3 }}
              onClick={toggleMenu}
              className="menu-toggle-button p-3 rounded-full bg-primary-600 text-white shadow-md hover:bg-primary-700 transition-colors duration-200"
            >
              {isMenuOpen ? <XMarkIcon className="h-5 w-5" /> : <Bars3Icon className="h-5 w-5" />}
            </motion.button>
          </>
        )}
      </div>
    </div>
  );
};

export default Layout;