import React, { useState, useMemo } from 'react';
import PerformanceOverviewCard from '../card/PerformanceOverviewCard';
import EngagementOverviewCard from '../card/EngagementOverviewCard';
import DailyPerformanceChart from '../chart/DailyPerformanceChart';
import EnquirerInsights from '../EnquirerInsights';
import OverviewSection from '../OverviewSection';
import DemandInsightsSection from '../DemandInsightsSection';
import TopPerformersSection from '../TopPerformersSection';
import ConversionFunnelChart from '../chart/ConversionFunnelChart';

const OverviewTab = ({ dashboardData, listings }) => {
  const [filter, setFilter] = useState(null);

  const filteredData = useMemo(() => {
    if (!filter || !dashboardData) return dashboardData;

    const filteredListings = listings.filter(listing => 
      filter.type === 'listingType' 
        ? listing.listingType === filter.value
        : listing.propertyType === filter.value
    );

    const filteredListingIds = new Set(filteredListings.map(l => l.listingId));

    return {
      ...dashboardData,
      totalViews: filteredListings.reduce((sum, l) => sum + (dashboardData.listingPerformance[l.listingId]?.views || 0), 0),
      totalEnquiries: filteredListings.reduce((sum, l) => sum + (dashboardData.listingPerformance[l.listingId]?.enquiries || 0), 0),
      totalPins: filteredListings.reduce((sum, l) => sum + (dashboardData.listingPerformance[l.listingId]?.pins || 0), 0),
      totalComparisons: filteredListings.reduce((sum, l) => sum + (dashboardData.listingPerformance[l.listingId]?.comparisons || 0), 0),
      listingPerformance: Object.fromEntries(
        Object.entries(dashboardData.listingPerformance).filter(([id]) => filteredListingIds.has(id))
      ),
      dailyData: Object.fromEntries(
        Object.entries(dashboardData.dailyData).map(([date, data]) => [
          date,
          {
            ...data,
            totalViews: filteredListings.reduce((sum, l) => sum + (data.listingPerformance[l.listingId]?.views || 0), 0),
            totalEnquiries: filteredListings.reduce((sum, l) => sum + (data.listingPerformance[l.listingId]?.enquiries || 0), 0),
            uniqueViewers: Array.isArray(data.uniqueViewers) 
              ? data.uniqueViewers.filter(viewer => 
                  filteredListings.some(l => 
                    Array.isArray(data.listingPerformance[l.listingId]?.uniqueViewers) &&
                    data.listingPerformance[l.listingId]?.uniqueViewers.includes(viewer)
                  )
                )
              : data.uniqueViewers,
            uniqueEnquirers: Array.isArray(data.uniqueEnquirers)
              ? data.uniqueEnquirers.filter(enquirer => 
                  filteredListings.some(l => 
                    Array.isArray(data.listingPerformance[l.id]?.uniqueEnquirers) &&
                    data.listingPerformance[l.listingId]?.uniqueEnquirers.includes(enquirer)
                  )
                )
              : data.uniqueEnquirers,
            listingPerformance: Object.fromEntries(
              Object.entries(data.listingPerformance).filter(([id]) => filteredListingIds.has(id))
            )
          }
        ])
      )
    };
  }, [dashboardData, listings, filter]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <div>
      {filter && (
        <div className="mb-4 p-2 bg-blue-100 dark:bg-blue-900 rounded-md">
          <p className="text-sm text-blue-800 dark:text-blue-200">
            Filtered by: {filter.type === 'listingType' ? 'Listing Type' : 'Property Type'} - {filter.value}
            <button 
              onClick={() => handleFilterChange(null)} 
              className="ml-2 text-blue-600 dark:text-blue-400 hover:underline"
            >
              Clear Filter
            </button>
          </p>
        </div>
      )}
      
      <div className="grid grid-cols-1 gap-6">
        <OverviewSection 
          listings={listings} 
          listingPerformance={filteredData.listingPerformance} 
          onFilterChange={handleFilterChange}
          currentFilter={filter}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <PerformanceOverviewCard data={filteredData} />
          <EngagementOverviewCard listingPerformance={filteredData.listingPerformance} />
        </div>
        <TopPerformersSection
          listingPerformance={filteredData.listingPerformance}
          listings={listings}
        />
        <DemandInsightsSection 
          listings={listings}
          listingPerformance={filteredData.listingPerformance}
          filter={filter}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <DailyPerformanceChart dailyData={filteredData.dailyData} />
          <ConversionFunnelChart data={filteredData} />
        </div>
        <EnquirerInsights 
          uniqueEnquirers={filteredData.uniqueEnquirers}
          listingPerformance={filteredData.listingPerformance}
          listings={listings}
          filter={filter}
        />
      </div>
    </div>
  );
};

export default OverviewTab;