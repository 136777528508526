import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ContentCard from '../../components/card/ContentCard.jsx';
import ContentPreviewModal from '../../components/modal/ContentPreviewModal.jsx';
import Pagination from '../../components/multipurpose/Pagination.jsx';
import backendApi from '../../api/backendApi.js';
import Select from 'react-select';
import useAlert from '../../utils/useAlert.js';
import Alert from '../../components/global/Alert.jsx';
import Header from '../../components/global/Header.jsx';

const ManageContent = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentFilters, setCurrentFilters] = useState({
    searchText: '',
    contentType: '',
    category: '',
    status: '',
    creationDateStart: null,
    creationDateEnd: null,
    sort: 'date_desc',
    tags: [],
    hashtags: []
  });
  const [appliedFilters, setAppliedFilters] = useState({...currentFilters});
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedContent, setSelectedContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { alertState, showAlert, hideAlert } = useAlert();
  const [tags, setTags] = useState([]);
  const [hashtags, setHashtags] = useState([]);

  const fetchContentData = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await backendApi.content.fetch({
        ...appliedFilters,
        page: currentPage,
        limit: 12,
        // tags: appliedFilters.tags.map(tag => tag.value),
        // hashtags: appliedFilters.hashtags.map(hashtag => hashtag.value)
      });
      setContent(result.contents);
      setTotalPages(result.totalPages);
      setCurrentPage(result.currentPage);
    } catch (err) {
      setError('Failed to fetch content. Please try again.');
      console.error('Error fetching content:', err);
    } finally {
      setIsLoading(false);
    }
  }, [appliedFilters, currentPage]);

  useEffect(() => {
    fetchContentData();
  }, [fetchContentData]);

  useEffect(() => {
    // const loadTagsAndHashtags = async () => {
    //   try {
    //     const [tagsData, hashtagsData] = await Promise.all([backendApi.fetchTags(), backendApi.fetchHashtags()]);
    //     setTags(tagsData.map(tag => ({ value: tag._id, label: tag.name })));
    //     setHashtags(hashtagsData.map(hashtag => ({ value: hashtag._id, label: hashtag.name })));
    //   } catch (error) {
    //     console.error('Error fetching tags and hashtags:', error);
    //     showAlert({
    //       title: 'Error',
    //       message: 'Failed to load tags and hashtags.',
    //       type: 'error'
    //     });
    //   }
    // };

    // loadTagsAndHashtags();
  }, [showAlert]);

  const handleFilterChange = useCallback((name, value) => {
    setCurrentFilters(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleDateChange = useCallback((date, name) => {
    setCurrentFilters(prev => ({ ...prev, [name]: date }));
  }, []);

  const handleViewDetails = useCallback((contentId) => {
    const selectedItem = content.find(c => c.id === contentId);
    setSelectedContent(selectedItem);
    setIsModalOpen(true);
  }, [content]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedContent(null);
  }, []);

  const resetFilters = useCallback(() => {
    const initialFilters = {
      searchText: '',
      contentType: '',
      category: '',
      status: '',
      creationDateStart: null,
      creationDateEnd: null,
      sort: 'date_desc',
      tags: [],
      hashtags: []
    };
    setCurrentFilters(initialFilters);
    setAppliedFilters(initialFilters);
    setCurrentPage(1);
  }, []);

  const handleStatusToggle = useCallback(async (contentId, currentStatus) => {
    const newStatus = currentStatus === 'published' ? 'draft' : 'published';
    try {
      await backendApi.content.updateStatus(contentId, { status: newStatus });
      showAlert({
        title: 'Status Update',
        message: `Content is now ${newStatus}`,
        type: 'success'
      });
      fetchContentData();
    } catch (err) {
      console.error('Error updating content status:', err);
      showAlert({
        title: 'Error',
        message: 'Failed to update content status. Please try again.',
        type: 'error'
      });
    }
  }, [fetchContentData, showAlert]);

  const handleEdit = useCallback((contentId) => {
    navigate(`/cms/edit-content/${contentId}`);
  }, [navigate]);

  const handleCreateNew = useCallback(() => {
    navigate('/cms/create-content');
  }, [navigate]);

  const handleApplyFilters = useCallback(() => {
    setAppliedFilters(currentFilters);
    setCurrentPage(1);
  }, [currentFilters]);

  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900">
      <Header title={"Manage Content"} />
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 p-4">
        <main className="flex-grow container mx-auto p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-3xl font-bold text-primary-800 dark:text-white">Contents</h1>
              <button
                onClick={handleCreateNew}
                className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 transition-colors duration-300 flex items-center"
              >
                <PlusIcon className="w-5 h-5 mr-2" />
                New
              </button>
            </div>

            {/* Filters */}
            <div className="mb-6 bg-white dark:bg-gray-800 rounded-lg shadow p-4">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-white">Filters</h2>
                <button
                  onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                  className="text-primary-600 hover:text-primary-700 dark:text-primary-400 dark:hover:text-primary-300 transition-colors duration-300"
                >
                  {showAdvancedFilters ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
                </button>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                <div className="relative">
                  <input
                    type="text"
                    name="searchText"
                    placeholder="Search content..."
                    value={currentFilters.searchText}
                    onChange={(e) => handleFilterChange('searchText', e.target.value)}
                    className="w-full p-2 pl-10 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                  />
                  <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                </div>
                <select
                  name="status"
                  value={currentFilters.status}
                  onChange={(e) => handleFilterChange('status', e.target.value)}
                  className="w-full p-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                >
                  <option value="">All Statuses</option>
                  <option value="published">Published</option>
                  <option value="draft">Draft</option>
                </select>
                <select
                  name="sort"
                  value={currentFilters.sort}
                  onChange={(e) => handleFilterChange('sort', e.target.value)}
                  className="w-full p-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                >
                  <option value="date_desc">Newest First</option>
                  <option value="date_asc">Oldest First</option>
                  <option value="title_asc">Title A-Z</option>
                  <option value="title_desc">Title Z-A</option>
                </select>
              </div>

              {showAdvancedFilters && (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                    <select
                      name="contentType"
                      value={currentFilters.contentType}
                      onChange={(e) => handleFilterChange('contentType', e.target.value)}
                      className="w-full p-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                    >
                      <option value="">All Content Types</option>
                      <option value="Article">Article</option>
                      <option value="Guide">Guide</option>
                    </select>
                    <select
                      name="category"
                      value={currentFilters.category}
                      onChange={(e) => handleFilterChange('category', e.target.value)}
                      className="w-full p-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                    >
                      <option value="">All Categories</option>
                      <option value="Market Trends">Market Trends</option>
                      <option value="Property Tips">Property Tips</option>
                      <option value="Investment Advice">Investment Advice</option>
                      <option value="Legal">Legal</option>
                      <option value="Lifestyle">Lifestyle</option>
                    </select>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <Select
                      isMulti
                      name="tags"
                      options={tags}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select Tags"
                      value={currentFilters.tags}
                      onChange={(selectedOptions) => handleFilterChange('tags', selectedOptions)}
                    />
                    <Select
                      isMulti
                      name="hashtags"
                      options={hashtags}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select Hashtags"
                      value={currentFilters.hashtags}
                      onChange={(selectedOptions) => handleFilterChange('hashtags', selectedOptions)}
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <DatePicker
                      selected={currentFilters.creationDateStart}
                      onChange={(date) => handleDateChange(date, 'creationDateStart')}
                      placeholderText="Creation Date From"
                      className="w-full p-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                    />
                    <DatePicker
                      selected={currentFilters.creationDateEnd}
                      onChange={(date) => handleDateChange(date, 'creationDateEnd')}
                      placeholderText="Creation Date To"
                      className="w-full p-2 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
                    />
                  </div>
                </>
              )}

              <div className="flex justify-end space-x-2">
                <button
                  onClick={resetFilters}
                  className="px-4 py-2 bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-white rounded-md hover:bg-gray-300 dark:hover:bg-gray-500 transition-colors duration-300"
                >
                  Reset Filters
                </button>
                <button
                  onClick={handleApplyFilters}
                  className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 transition-colors duration-300"
                >
                  Apply Filters
                </button>
              </div>
            </div>

            {/* Content Grid */}
            {isLoading ? (
              <div className="text-center py-10">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600 mx-auto"></div>
              </div>
            ) : error ? (
              <div className="text-center py-10 text-red-600 dark:text-red-400">{error}</div>
            ) : content.length === 0 ? (
              <div className="text-center py-10 text-gray-600 dark:text-gray-400">No content found.</div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {content.map(item => (
                  <ContentCard
                    key={item._id}
                    content={item}
                    onViewDetails={() => handleViewDetails(item.id)}
                    onEdit={() => handleEdit(item._id)}
                    onToggleStatus={() => handleStatusToggle(item.id, item.status)}
                  />
                ))}
              </div>
            )}

            {totalPages > 1 && (
              <div className="mt-6">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                />
              </div>
            )}
          </div>
        </main>

        <ContentPreviewModal
          content={selectedContent}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onEdit={() => selectedContent && handleEdit(selectedContent._id)}
        />

        <Alert
          isOpen={alertState.isOpen}
          onClose={hideAlert}
          title={alertState.title}
          message={alertState.message}
          onConfirm={alertState.onConfirm}
          confirmText={alertState.confirmText}
          cancelText={alertState.cancelText}
          type={alertState.type}
        />
      </div>
    </div>
  );
};

export default ManageContent;