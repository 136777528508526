import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import backendApi from '../../api/backendApi';
import Header from '../../components/global/Header';
import LocationCard from '../../components/card/LocationCard';
import { Helmet } from 'react-helmet';
import { MapPinIcon, UsersIcon, CurrencyDollarIcon, BriefcaseIcon, BuildingOfficeIcon, PhotoIcon, MapIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import ImageCarousel from '../../components/multipurpose/ImageCarousel';

const LocationDetailsPage = () => {
  const { region, district, township, area, building } = useParams();
  const navigate = useNavigate();
  const locationHook = useLocation();
  const [locationData, setLocationData] = useState(null);
  const [subLocations, setSubLocations] = useState([]);
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchLocationDetails();
  }, [region, district, township, area, building]);

  const fetchLocationDetails = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const pathParts = [region, district, township, area, building]
        .filter(Boolean)
        .map(part => decodeURIComponent(part));
      const locationPath = pathParts.join('/');

      const locationData = await backendApi.location.getLocationByPath(locationPath);
      setLocationData(locationData);

      if (locationData.hasSubLocations) {
        const subLocationsData = await backendApi.location.getSubLocations(locationPath);
        setSubLocations(subLocationsData);
      }

      // const listingsData = await backendApi.listings.fetch({ locationPath: path });
      // setListings(listingsData.listings);

    } catch (error) {
      console.error('Error fetching location details:', error);
      setError('Failed to load location details. Please try again.');
      if (error.response && error.response.status === 404) {
        navigate('/404', { replace: true });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getBreadcrumbs = () => {
    if (!locationData || !locationData.pathName) return [];
    const pathNames = locationData.pathName.split('/').filter(Boolean);
    const pathParts = locationData.path.split('/').filter(Boolean);
    
    return pathNames.map((name, index) => ({
      name: name,
      path: '/' + pathParts.slice(0, index + 1).join('/')
    }));
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Helmet>
        <title>{locationData?.name} - Explore Properties</title>
        <meta name="description" content={`Discover properties, amenities, and listings in ${locationData?.name}`} />
      </Helmet>
      <Header title={locationData?.name || 'Location Details'} />
      
      <div className="container mx-auto py-8 px-4">
        <nav className="text-sm mb-4">
          <ol className="list-none p-0 inline-flex items-center">
            <li className="flex items-center">
              <Link to="/location" className="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300">Home</Link>
              <ChevronRightIcon className="h-4 w-4 mx-2 text-gray-400 dark:text-gray-500" />
            </li>
            {getBreadcrumbs().map((crumb, index) => (
              <li key={index} className="flex items-center">
                <Link to={`/location/${crumb.path}`} className="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300">
                  {crumb.name}
                </Link>
                {index < getBreadcrumbs().length - 1 && (
                  <ChevronRightIcon className="h-4 w-4 mx-2 text-gray-400 dark:text-gray-500" />
                )}
              </li>
            ))}
          </ol>
        </nav>

        {locationData && (
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
            <div className="relative h-[70vh] md:h-[80vh]">
              {locationData.featuredImage ? (
                <img 
                  src={locationData.featuredImage.url} 
                  alt={locationData.name} 
                  className="w-full h-full object-cover object-center featured-image"
                />
              ) : (
                <div className="w-full h-full bg-gray-300 dark:bg-gray-700 flex items-center justify-center">
                  <PhotoIcon className="h-32 w-32 text-gray-400" />
                </div>
              )}
              <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent">
                <div className="absolute bottom-0 left-0 right-0 p-6 md:p-10">
                  <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-2">{locationData.name}</h1>
                  <p className="text-xl md:text-2xl text-gray-200">{locationData.type}</p>
                </div>
              </div>
            </div>

            <div className="p-6 md:p-10">
              <div className="flex flex-col md:flex-row md:space-x-8">
                <div className="md:w-1/2 mb-6 md:mb-0">
                  <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">About {locationData.name}</h2>
                  <p className="text-gray-600 dark:text-gray-300 text-lg md:text-xl leading-relaxed">
                    {locationData.intro}
                  </p>
                </div>
                <div className="md:w-1/2 flex justify-center items-start">
                  {locationData.mapImage ? (
                    <div className="rounded-lg overflow-hidden max-w-md w-full">
                      <img 
                        src={locationData.mapImage.url} 
                        alt="Location Map" 
                        className="w-full h-auto max-h-96 object-contain"
                      />
                    </div>
                  ) : (
                    <div className="rounded-lg bg-gray-200 dark:bg-gray-700 h-64 w-full max-w-md flex items-center justify-center">
                      <MapPinIcon className="h-16 w-16 text-gray-400" />
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-8">
                <StatCard icon={UsersIcon} label="Population" value={locationData.population?.toLocaleString() || 'N/A'} />
                <StatCard icon={CurrencyDollarIcon} label="Median Income" value={locationData.statistics?.medianIncome ? `RM ${locationData.statistics.medianIncome.toLocaleString()}` : 'N/A'} />
                <StatCard icon={BriefcaseIcon} label="Employment Rate" value={locationData.statistics?.employmentRate ? `${locationData.statistics.employmentRate}%` : 'N/A'} />
                <StatCard icon={BuildingOfficeIcon} label="Amenities" value={locationData.amenities?.length || 0} />
              </div>

              {locationData.images && locationData.images.length > 0 && (
                <div className="mt-8">
                  <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">Gallery</h2>
                  <ImageCarousel images={locationData.images} />
                </div>
              )}

              {locationData.amenities && locationData.amenities.length > 0 && (
                <div className="mt-8">
                  <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">Amenities</h2>
                  <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {locationData.amenities.map((amenity, index) => (
                      <li key={index} className="flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded">
                        <BuildingOfficeIcon className="h-5 w-5 text-gray-500 mr-2" />
                        <span className="text-gray-700 dark:text-gray-300">{amenity}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}

        {subLocations.length > 0 && (
          <div className="mt-12">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">Explore {locationData.name}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {subLocations.map(subLocation => (
                <LocationCard 
                  key={subLocation._id}
                  location={subLocation}
                  basePath={`/location${locationHook.path}`}
                  showNextLevel={true}
                />
              ))}
            </div>
          </div>
        )}

        <div className="mt-12">
          <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">Available Listings in {locationData.name}</h2>
          {listings.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {/* {listings.map(listing => (
                <ListingCard key={listing._id} listing={listing} />
              ))} */}
            </div>
          ) : (
            <p className="text-gray-600 dark:text-gray-400 bg-white dark:bg-gray-800 rounded-lg shadow-md p-4">
              No listings available for this location at the moment.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const StatCard = ({ icon: Icon, label, value }) => (
  <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
    <Icon className="h-8 w-8 text-gray-500 mb-2" />
    <p className="text-sm text-gray-500 dark:text-gray-400">{label}</p>
    <p className="text-lg font-semibold text-gray-800 dark:text-white">{value}</p>
  </div>
);

export default LocationDetailsPage;