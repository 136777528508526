import React from 'react';
import { ArrowUpIcon, ArrowDownIcon, MinusIcon, CurrencyDollarIcon, ScaleIcon, ArrowTrendingUpIcon, ChartBarIcon } from '@heroicons/react/24/solid';

const PropertyAnalysis = ({ analysisData }) => {
  const { priceComparison, psfComparison, marketTrend, investmentPotential } = analysisData || {};

  const formatPrice = (price) => {
    return typeof price === 'number' 
      ? `RM ${price.toLocaleString('en-MY')}`
      : 'N/A';
  };

  const getPercentageColor = (value) => {
    if (value > 0) return 'text-green-600 dark:text-green-400';
    if (value < 0) return 'text-red-600 dark:text-red-400';
    return 'text-yellow-600 dark:text-yellow-400';
  };

  const getPercentageIcon = (value) => {
    if (value > 0) return <ArrowUpIcon className="h-4 w-4 inline mr-1" />;
    if (value < 0) return <ArrowDownIcon className="h-4 w-4 inline mr-1" />;
    return <MinusIcon className="h-4 w-4 inline mr-1" />;
  };

  const renderComparison = (title, comparison, type, icon) => {
    if (!comparison) return null;
    
    const listingValue = type === 'price' ? comparison.listingPrice : comparison.listingPsf;
    const medianValue = type === 'price' ? comparison.medianPrice : comparison.medianPsf;
    const listingLabel = type === 'price' ? 'Listing Price' : 'Listing PSF';
    const medianLabel = type === 'price' ? 'Median Price' : 'Median PSF';

    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 mb-4">
        <div className="flex items-center mb-3">
          {React.cloneElement(icon, { className: "h-5 w-5 text-green-600 dark:text-green-400 mr-2" })}
          <h3 className="text-lg font-semibold text-green-800 dark:text-green-200">{title}</h3>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400">{listingLabel}</p>
            <p className="text-lg font-bold text-gray-800 dark:text-white">{formatPrice(listingValue)}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400">{medianLabel}</p>
            <p className="text-lg font-bold text-gray-800 dark:text-white">{formatPrice(medianValue)}</p>
          </div>
        </div>
        <div className="mt-2 text-right">
          <span className={`${getPercentageColor(comparison.percentDifference)} font-semibold`}>
            {getPercentageIcon(comparison.percentDifference)}
            {comparison.percentDifference > 0 ? '+' : ''}{comparison.percentDifference.toFixed(1)}%
          </span>
        </div>
      </div>
    );
  };

  const renderMarketTrend = () => {
    if (!marketTrend) return null;

    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 mb-4">
        <div className="flex items-center mb-3">
          <ArrowTrendingUpIcon className="h-5 w-5 text-green-600 dark:text-green-400 mr-2" />
          <h3 className="text-lg font-semibold text-green-800 dark:text-green-200">Market Trend</h3>
        </div>
        <p className="text-gray-700 dark:text-gray-300">
          The market is currently <span className="font-semibold">{marketTrend.trend}</span>
        </p>
        <div className="mt-2 text-right">
          <span className={`${getPercentageColor(marketTrend.percentageChange)} font-semibold`}>
            {getPercentageIcon(marketTrend.percentageChange)}
            {marketTrend.percentageChange > 0 ? '+' : ''}{marketTrend.percentageChange.toFixed(1)}% annual change
          </span>
        </div>
      </div>
    );
  };

  const renderInvestmentPotential = () => {
    if (!investmentPotential) return null;

    const getScoreColor = (score) => {
      if (score >= 75) return 'text-green-600 dark:text-green-400';
      if (score >= 60) return 'text-yellow-600 dark:text-yellow-400';
      return 'text-red-600 dark:text-red-400';
    };

    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 mb-4">
        <div className="flex items-center mb-3">
          <ChartBarIcon className="h-5 w-5 text-green-600 dark:text-green-400 mr-2" />
          <h3 className="text-lg font-semibold text-green-800 dark:text-green-200">Investment Potential</h3>
        </div>
        <p className="text-xl font-bold mb-2 text-gray-800 dark:text-white">
          Score: <span className={getScoreColor(investmentPotential.score)}>{investmentPotential.score}</span>/100
        </p>
        {investmentPotential.factors && (
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 text-sm">
            {investmentPotential.factors.map((factor, index) => (
              <li key={index}>{factor}</li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  if (!analysisData) {
    return <div className="text-center text-gray-600 dark:text-gray-400">No analysis data available.</div>;
  }

  return (
    <div className="w-full dark:bg-gray-900">
      {/* <h2 className="text-xl sm:text-2xl font-bold text-green-800 dark:text-green-200 mb-4">Property Analysis</h2> */}
      {renderComparison('Price Comparison', priceComparison, 'price', <CurrencyDollarIcon />)}
      {renderComparison('Price per Square Foot', psfComparison, 'psf', <ScaleIcon />)}
      {renderMarketTrend()}
      {renderInvestmentPotential()}
    </div>
  );
};

export default PropertyAnalysis;