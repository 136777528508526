import React, { useMemo, useContext, Suspense } from 'react';
import { useTheme } from '../../../context/ui/ThemeContext';

const Chart = React.lazy(() => import('react-apexcharts'), { ssr: false });

const DailyPerformanceChart = ({ dailyData = {} }) => {
  const { isDarkMode } = useTheme();

  const chartData = useMemo(() => {
    const dates = Object.keys(dailyData);

    // For single-day data, show 4 bars for each metric (Views, Viewers, Enquiries, Enquirers)
    if (dates.length === 1) {
      const singleDayData = dailyData[dates[0]];
      return {
        chartType: 'bar',
        categories: [dates[0]], // Show the single day as the category
        series: [
          {
            name: 'Views',
            data: [singleDayData.totalViews],
            color: '#3b82f6'
          },
          {
            name: 'Viewers',
            data: [singleDayData.uniqueViewersCount],
            color: '#93c5fd'
          },
          {
            name: 'Enquiries',
            data: [singleDayData.totalEnquiries],
            color: '#10b981'
          },
          {
            name: 'Enquirers',
            data: [singleDayData.uniqueEnquirersCount],
            color: '#6ee7b7'
          }
        ]
      };
    } else {
      // For multiple days, use line (area) chart
      const series = [
        {
          name: 'Views',
          data: dates.map(date => dailyData[date].totalViews),
          color: '#3b82f6'
        },
        {
          name: 'Viewers',
          data: dates.map(date => dailyData[date].uniqueViewersCount),
          color: '#93c5fd'
        },
        {
          name: 'Enquiries',
          data: dates.map(date => dailyData[date].totalEnquiries),
          color: '#10b981'
        },
        {
          name: 'Enquirers',
          data: dates.map(date => dailyData[date].uniqueEnquirersCount),
          color: '#6ee7b7'
        }
      ];
      return {
        chartType: 'area',
        categories: dates,
        series
      };
    }
  }, [dailyData]);

  const isSingleDay = chartData.chartType === 'bar';

  const options = {
    chart: {
      type: chartData.chartType, // 'area' for multiple days, 'bar' for single day
      height: 350,
      toolbar: {
        show: false
      },
      background: 'transparent',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: isSingleDay ? 0 : 2 // No lines for bars
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '45%',
        endingShape: 'rounded',
        borderRadius: 5, // Adds rounded corners to the bars
        borderRadiusApplication: 'end',
        borderWidth: 1, // Adding border width
      }
    },
    xaxis: {
      type: 'category',
      categories: chartData.categories, // Either single day or multiple days
      labels: {
        style: {
          colors: isDarkMode ? '#9ca3af' : '#4b5563'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: isDarkMode ? '#9ca3af' : '#4b5563'
        }
      }
    },
    tooltip: {
      x: {
        format: isSingleDay ? undefined : 'dd MMM yyyy' // No date format for single day
      },
      theme: isDarkMode ? 'dark' : 'light'
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      labels: {
        colors: isDarkMode ? '#9ca3af' : '#4b5563'
      }
    },
    grid: {
      borderColor: isDarkMode ? '#374151' : '#e5e7eb'
    },
    // Apply solid colors to bar charts, gradient only for line charts
    colors: isSingleDay
      ? ['#3b82f6', '#93c5fd', '#10b981', '#6ee7b7'] // Solid colors for bars
      : ['#3b82f6', '#93c5fd', '#10b981', '#6ee7b7'], // Keep same colors for line
    fill: {
      type: 'gradient',
      gradient: isSingleDay
        ? {
            shade: 'light',
            type: 'vertical', // Use vertical gradient
            shadeIntensity: 0.5,
            gradientToColors: ['#1d4ed8', '#60a5fa', '#047857', '#34d399'], // Slightly darker colors at the bottom of the gradient
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 0.8,
            stops: [0, 100]
          }
        : {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.3,
            stops: [0, 90, 100]
          }
    },
    theme: {
      mode: isDarkMode ? 'dark' : 'light'
    }
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Daily Performance</h2>
        <Chart options={options} series={chartData.series} type={chartData.chartType} height={350} />
      </div>
    </Suspense>
  );
};

export default DailyPerformanceChart;