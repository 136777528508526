import React from 'react';
import { Users, Eye, MessageSquare } from 'lucide-react';
import StatCard from './StatCard';

const EngagementOverviewCard = ({ listingPerformance, forSingleListing = false }) => {
  const totalUniqueViewers = new Set(Object.values(listingPerformance).flatMap(p => p.uniqueViewers)).size;
  const totalUniqueEnquirers = new Set(Object.values(listingPerformance).flatMap(p => p.uniqueEnquirers)).size;
  const totalUniquePinners = new Set(Object.values(listingPerformance).flatMap(p => p.uniquePinners)).size;
  const totalUniqueComparers = new Set(Object.values(listingPerformance).flatMap(p => p.totalUniqueComparers)).size;

  const avgViewsPerListing = Object.values(listingPerformance).reduce((sum, p) => sum + p.views, 0) / Object.keys(listingPerformance).length;
  const avgEnquiriesPerListing = Object.values(listingPerformance).reduce((sum, p) => sum + p.enquiries, 0) / Object.keys(listingPerformance).length;

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Engagement Overview</h2>
      <div className="grid grid-cols-2 gap-4">
        <StatCard icon={Users} title="Viewers" value={totalUniqueViewers} color="bg-blue-500" />
        <StatCard icon={Users} title="Enquirers" value={totalUniqueEnquirers} color="bg-green-500" />
        { forSingleListing ? (
          <>
            <StatCard icon={Users} title="Pinners" value={totalUniquePinners} color="bg-purple-500" />
            <StatCard icon={Users} title="Enquirers" value={totalUniqueComparers} color="bg-yellow-500" />
          </>
        ) : (
          <>
            <StatCard icon={Eye} title="Views / Listing" value={avgViewsPerListing.toFixed(1)} color="bg-purple-500" />
            <StatCard icon={MessageSquare} title="Enquiries / Listing" value={avgEnquiriesPerListing.toFixed(1)} color="bg-yellow-500" />
          </>
        )}        
      </div>
    </div>
  );
};

export default EngagementOverviewCard;