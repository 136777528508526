import React, { useState } from 'react';

const CallToAction = ({ content, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(content.title || '');
  const [buttonText, setButtonText] = useState(content.buttonText || '');
  const [buttonLink, setButtonLink] = useState(content.buttonLink || '');

  const handleSave = () => {
    onUpdate({ title, buttonText, buttonLink });
    setIsEditing(false);
  };

  const handleDiscard = () => {
    setTitle(content.title || '');
    setButtonText(content.buttonText || '');
    setButtonLink(content.buttonLink || '');
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">Edit Call to Action</h2>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="CTA Title"
          className="w-full p-2 mb-4 border rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent"
        />
        <input
          type="text"
          value={buttonText}
          onChange={(e) => setButtonText(e.target.value)}
          placeholder="Button Text"
          className="w-full p-2 mb-4 border rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent"
        />
        <input
          type="text"
          value={buttonLink}
          onChange={(e) => setButtonLink(e.target.value)}
          placeholder="Button Link"
          className="w-full p-2 mb-4 border rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent"
        />
        <div className="flex justify-end space-x-4">
          <button 
            onClick={handleDiscard} 
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-gray-400 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Discard
          </button>
          <button 
            onClick={handleSave} 
            className="px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Save
          </button>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="bg-primary-600 dark:bg-primary-800 text-white py-16 rounded-lg shadow-md cursor-pointer" 
      onClick={() => setIsEditing(true)}
    >
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-8">{title}</h2>
        <a 
          href={buttonLink} 
          className="bg-white text-primary-600 dark:text-primary-800 px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-100 dark:hover:bg-gray-200 transition duration-300"
        >
          {buttonText}
        </a>
      </div>
    </div>
  );
};

export default CallToAction;