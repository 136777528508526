// hooks/useAlert.js

import { useState, useCallback } from 'react';

const useAlert = () => {
  const [alertState, setAlertState] = useState({
    isOpen: false,
    title: '',
    message: '',
    onConfirm: null,
    confirmText: 'OK',
    cancelText: 'Cancel',
    type: 'info',
  });

  const showAlert = useCallback(({ title, message, onConfirm, confirmText, cancelText, type = 'info' }) => {
    setAlertState({
      isOpen: true,
      title,
      message,
      onConfirm,
      confirmText: confirmText || 'OK',
      cancelText: cancelText || 'Cancel',
      type,
    });
  }, []);

  const hideAlert = useCallback(() => {
    setAlertState(prev => ({ ...prev, isOpen: false }));
  }, []);

  return { alertState, showAlert, hideAlert };
};

export default useAlert;