// src/utils/randomBackground.js

export function getRandomBackground() {
    // List of background image file names
    const backgrounds = [
      'https://dmorqa271euit.cloudfront.net/static/abstract_image_9.webp?Expires=1726579464&Key-Pair-Id=APKAWODAEA4U5UV23UJR&Signature=s10o28d1tLDSegLYhPwLZhRAsYQoTByffeGPFqMCe2YCFomYoU-blWQcXas9Y9EyyyhNXmrKdXnwzOir9JgIqUnM8A-FOJ~dgHmWAotDUv-rZwy9WaLjR6z-JtbGRXsg0DRvj90Im~rkgYpmI2frI4CXpPeeWRgulXqFMuNIIwptw0QuX0ItUEQqPE7jjrZxuzI3PBHknAxh6uM2A1f7zoeWCbAlkCdybl0mYc-P99~SwkSMlEZ66BIiYFsFKyLPfBAYwaOQNsbJvVux5aLPFZVmdUeTpwEu9ZqXUviCNAXQf9pi5zk5mr5xmMACxoUqOGhy7z3T7xcJW0tXeFM~tw__',
      'https://dmorqa271euit.cloudfront.net/static/abstract_image_7.webp?Expires=1726579425&Key-Pair-Id=APKAWODAEA4U5UV23UJR&Signature=Nz0MXEFcQ~qGPure-ZEqtX-~Z4zKq88U5E9tPCzstpxoaPE6bRPrYPLJX1YbggmVRdR6~rtvhVJMI2C67K7BNFsyJo6s9ubCmrHmLGsUNfIYQBL8AhSdd5M4MPLna2R2AgrYxElXQuR7~MXligpiTgG8Jt-~qVdU37SLeOA-SFdQARX6ojrRBe82N2lrT1YBJtSb0pqfAEnewM02aiFC599EtopmyDa4ahGzxNE6GEH6hAFflpQxEurllqdWp2kMWcvkbYoVna21iQSJp4Ka6etfLb2BJhxWzzdVXdDji8WNh1XZWi03u01rXRtZOArPOk1OzyqIKxAJfWqbJywZjg__',
      'https://dmorqa271euit.cloudfront.net/static/abstract_image_6.webp?Expires=1726579407&Key-Pair-Id=APKAWODAEA4U5UV23UJR&Signature=XEL3Lv4vLC4zNuWpGFkh6eHoupLGrmA9UrIrC0FPtCMDzn5bNw0yepotT4EhzFgaKaqlcRPDGKOFBBqm7dqs5u5OD3UQnZCkHXO-WFYO8ceDzyEYfG0FVbD3UDM0-K1CZJw9kNfPg8qKWxlg~B4-k2fSTbSp1RRr5l7A-eFGgGHIx1rIzD~fD430vKyyxP4qcYCwT2vb7Ci2DUD5rn5SuN2h65qi1k-EB60RfzvUtr9-XzhVQnvE6WW54gKW~Kwlg5TsKsM1Gu42He0JFOFqcL5BrJ7xYYpz5syqk6wBh8bBwZNf4fRGgsWqtAJs0YN88tpdpuXQYx7h0SZfwDsVZg__',
      'https://dmorqa271euit.cloudfront.net/static/abstract_image_10.webp?Expires=1726579482&Key-Pair-Id=APKAWODAEA4U5UV23UJR&Signature=i-PbxwMSK1uuBg07bmScX~acEuOtsx6TgFyRAeTsyOzqqJ7pK4sHSGd557HMG9VLK3eEXIpoeJOSfhRcRqsM6nYJov0p41ivqUQpGG8-Pv7xrW-tksQMZgg3un13oG~N6oIDyVufPSdRk3kSAsdnez9zTNVrKH6bi-hqd2VdwSbKSDviq2SvCbVHoAaTCgeDZWldgac5nOdtMgS0BvOL1FUgJy-d3fLPmytc2fV05ygKoQKYJ4gNNnDr6wyOLexg5bsAfMQKkFcQK9~1hm7a8Wd12hvcdGpUN398STN3EhMNzOIMIjO3GG4YhukhND7pZwP-E-Dd90QozTUQQwXu8g__',
      'https://dmorqa271euit.cloudfront.net/static/abstract_image_4.webp?Expires=1726579364&Key-Pair-Id=APKAWODAEA4U5UV23UJR&Signature=PR3cpRNc9H3rgBYD-JnqZ7JUHu3BmB~UYn4KyKqcGowAdGZkdrUKPxxLaGE3jtJn89vFobUDhiW2priOwdACIm~omFn54q7KYn~2fQe6Yjnsco7ccvZDAiNf0vYuFZhj2yTsdNqfIdAQLtI~17rRZyTJi8dyOOnB5pS025j9D0NVv5jY1x~-xSwE27yCy1ySmOqyrG3sASfNH6weJNgXb4V0BhSHyQIqmihSu2RCN2xal3xUXJV2XXb8QsBFYGA~shpOC887A4Edc7FySZnpOqIoJnEXDdgXJnIN64X71MVEi4jbIa4YKumLHle5CTyBuf~lQ~pAUorrhiE6EREXGw__',
      'https://dmorqa271euit.cloudfront.net/static/abstract_image_2.webp?Expires=1726579308&Key-Pair-Id=APKAWODAEA4U5UV23UJR&Signature=IIO4hBHC-7tf9vFDhl3rlP05Yddvzymyg-oA9Ea5j7HgbfhksM~WVgdob9jZJgKJiGjWmX6qfiEJuBXW2oG2usfOqn6WSL3LAMVi6PjzQWq-atDAW8BvlL-tVABhJUOmlv6eeTJXqwQvyV2Hi5Exg4LffDOdwdcY4PmDgwSm8bbNHNCrynGCJ-ELoDEEluH3l9P-pxQ2M7DH-QTfqGkTZBUJx6760s7CSe0DhbYPFG-4Al9mQF15hSUkwwIsYwYay92qq4ZbXGeu0CJbUkHpVPlcUmWeWVYOnhumFZJ7F0UwRQFkQNSXO59kzhMaM21ED0zdBP5I-m7bxrsaA3s~pQ__',
      'https://dmorqa271euit.cloudfront.net/static/abstract_image_3.webp?Expires=1726579342&Key-Pair-Id=APKAWODAEA4U5UV23UJR&Signature=hQYw9JPZjStAPC5oToWmEkGanWHyMA8TIAjJztZZpGMAhkA5kXR1JyhymCkbfzktJKpQaVXbGtOAi1Vt~Yoxe3QTjsG~7anAMqchsKQ4FD7duv8kPjjJlvydMm5tWa-5dVmwbegkTWw7tVX5OhRMQDxCuWCjVP3IyqKudaoZr06TSgO9c5OsUyC4~c3IQhUNo9-NKPgGMmxIkCjTDZivJ6f63cgrGaM0PVT6v5xxDUDeHIQMPpf9RFJ5AkUZm5ki8XvmJVkvccWg1YFDfcRIjC73Ir2Mc96omrNxHxa9LRb23Sq3tQkf5nAVCrPsd0TBk9t5v7qEqmofaArVzd-ihA__',
      'https://dmorqa271euit.cloudfront.net/static/abstract_image_8.webp?Expires=1726579447&Key-Pair-Id=APKAWODAEA4U5UV23UJR&Signature=YvhsgOnUO-Hd3cLxAij65kI2zE3rjKAH65FQNTDQosFz4DtZm8NAMxZzwDnPS0AQPseO9Bc-~z46JHcc47gPyWvD2FW4MAUCoW4VueRqt8ClOfHf~Ae~pATnTy8LGZo79hdWWphno8-tA6hv0SL8cgfd9yNU1JZB~Oe8KxmPKb~QSycUCy464yHVruaJETfWeIwNA72y8wsoqokCFc8289VvkZzbwQMAfBw0c~PqXThdX3BwlsRy~E0U30pd2jLveatp8U0HxQJyQ3oKHJMT1n0pSLzu4~Yj0UOfebSLSa7etxlnJboUS9UxgiDciqstxygFmz2JgVHiHE~zV-xFGw__',
      'https://dmorqa271euit.cloudfront.net/static/abstract_image_5.webp?Expires=1726579382&Key-Pair-Id=APKAWODAEA4U5UV23UJR&Signature=u5A72PWYurNb-S4SyaGgtk4CFEIVaJ7nbRPH2hE5Ar4G6rYvOqKOS17ZKlH8aLT5jmbXevIM00zQxgMfTEIKUZnc9s~pu1xYQIlM0eB1qybxJHyZUA2ag9zWzT1W7fGFRS5Q2GX1-5aeqGlxQj0aP3kJwSvhVa~4IV3mJWGbAURWYi9NH5ieX0RAH9Hy83SRr7PICPlzKQVy23GDw8Kl~Go3HevqW3~kXGpmlrKUMKJHhc7K8XzJeAwZA6paVNKIH3YwbMP8clc-d2UeCa5eQ2aRqmOltsJ3rlPpfyDeEixwt3xWAj-7SmLVkM~o4Mk2LqSvijzOZvznGCCkoWPcdQ__',
      'https://dmorqa271euit.cloudfront.net/static/abstract_image_1.webp?Expires=1726579232&Key-Pair-Id=APKAWODAEA4U5UV23UJR&Signature=RpRJoT-o37JRTWiJ0~DMRaK7WALKcNFrNCYyTq74DLsoJhbrY4BUdd7kX2jzUgomVBD6ZfokqXH60V7MhrL99VSc1yDBfTQ4NLvXfSzm9Jp2RzcJqK4mDIaBoGxWRbnnFbqZjhZU3OKXZ6JLAxKKnO3UfukYu6eobRp0I7go6PediNxnCQUGMPOoFSLFoDUJrJmaewNKJ40zAyQ7x5aSRp1~683M2NmzIzeADSzpyBnR9CtecODBJVD~Hjp5db9~V~yjvWQn5RDVpZUGO62oAmzQmZwhs74TwVO2ohox~QXgAw2zqeAyK7z0PkP-12i4kyktN2Qq0Px-w7lW3t7r9Q__'
    ];
  
    // Generate a random index
    const randomIndex = Math.floor(Math.random() * backgrounds.length);
  
    // Return the randomly selected background
    return backgrounds[randomIndex];
  }