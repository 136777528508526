import React from 'react';
import { MapPin, Users, DollarSign, Briefcase, Camera, Map } from 'lucide-react';
import {renderPhotoSrc} from '../../utils/photoUrlHelpers';

const LocationPreview = ({ location }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      {/* Header with featured image */}
      <div className="relative h-64">
        {location.featuredImage ? (
          <img
            src={renderPhotoSrc(location.featuredImage)}
            alt={location.featuredImage.caption || location.name}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-300 flex items-center justify-center">
            <Camera className="h-20 w-20 text-gray-400" />
          </div>
        )}
        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
          <h1 className="text-3xl font-bold text-white">{location.name}</h1>
          <p className="text-sm text-gray-200">{location.type}</p>
        </div>
      </div>

      {/* Main content */}
      <div className="p-6">
        {/* Introduction */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">About {location.name}</h2>
          <p className="text-gray-600">{location.intro}</p>
        </section>

        {/* Key Information */}
        <section className="mb-6 grid grid-cols-2 gap-4">
          <div className="flex items-center">
            <MapPin className="h-5 w-5 text-gray-400 mr-2" />
            <span className="text-gray-600">{location.address?.city || 'N/A'}</span>
          </div>
          <div className="flex items-center">
            <Users className="h-5 w-5 text-gray-400 mr-2" />
            <span className="text-gray-600">Population: {location.population?.toLocaleString() || 'N/A'}</span>
          </div>
          <div className="flex items-center">
            <DollarSign className="h-5 w-5 text-gray-400 mr-2" />
            <span className="text-gray-600">Median Income: {location.statistics?.medianIncome ? `$${location.statistics.medianIncome.toLocaleString()}` : 'N/A'}</span>
          </div>
          <div className="flex items-center">
            <Briefcase className="h-5 w-5 text-gray-400 mr-2" />
            <span className="text-gray-600">Employment Rate: {location.statistics?.employmentRate ? `${location.statistics.employmentRate}%` : 'N/A'}</span>
          </div>
        </section>

        {/* Amenities */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Amenities</h2>
          <ul className="list-disc list-inside text-gray-600">
            {location.amenities?.map((amenity, index) => (
              <li key={index}>{amenity}</li>
            ))}
          </ul>
        </section>

        {/* Image Gallery */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Gallery</h2>
          <div className="grid grid-cols-3 gap-4">
            {location.images?.map((image, index) => (
              <img
                key={index}
                src={renderPhotoSrc(image)}
                alt={image.caption || `Image ${index + 1}`}
                className="w-full h-32 object-cover rounded"
              />
            ))}
          </div>
        </section>

        {/* Map */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Location</h2>
          {location.mapImage ? (
            <img
              src={renderPhotoSrc(location.mapImage)}
              alt={location.mapImage.caption || "Map"}
              className="w-full h-64 object-cover rounded"
            />
          ) : (
            <div className="w-full h-64 bg-gray-200 flex items-center justify-center rounded">
              <Map className="h-20 w-20 text-gray-400" />
            </div>
          )}
        </section>

        {/* Additional Information (for building type) */}
        {location.type === 'building' && (
          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Building Information</h2>
            <p className="text-gray-600">Address: {location.address?.street}, {location.address?.postalCode}</p>
            <p className="text-gray-600">Units: {location.units?.length || 0}</p>
            {/* You can add more building-specific information here */}
          </section>
        )}
      </div>
    </div>
  );
};

export default LocationPreview;