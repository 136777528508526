import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import { ArrowLeftIcon, ChevronUpIcon, ChevronDownIcon, LinkSlashIcon } from '@heroicons/react/24/solid';
import backendApi from '../api/backendApi';
import ImageCarousel from '../components/multipurpose/ImageCarousel';
import AgentContactInfo from '../components/multipurpose/AgentContactInfo';
import { usePinnedProperties } from '../context/control/PinnedPropertiesContext';
import Header from '../components/global/Header';
import { useAnalytics } from '../context/control/AnalyticsContext';

const Compare = () => {
  const navigate = useNavigate();
  const { pinnedProperties, unpinProperty } = usePinnedProperties();
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const { trackingService } = useAnalytics();
  const [hasTrackedComparison, setHasTrackedComparison] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const propertyIds = pinnedProperties.map(p => p.listingId);
        if (propertyIds.length < 2) {
          navigate(-1); // Navigate back if less than 2 properties
          return;
        }
        const comparisonData = await backendApi.listings.fetchPropertyComparisons(propertyIds);
        setProperties(comparisonData);

        // Track the comparison event only once
        if (!hasTrackedComparison) {
          trackingService.trackComparison(propertyIds);
          setHasTrackedComparison(true);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [pinnedProperties, navigate, trackingService, hasTrackedComparison]);

  const handleInitiateChat = (chat) => {
    setSelectedChat(chat);
    setIsChatOpen(true);
  };

  const handleUnpin = useCallback((propertyId) => {
    unpinProperty(propertyId);
    // We don't need to manually update the properties state here
    // as the effect will handle it based on pinnedProperties change
  }, [unpinProperty]);

  const handleBackToSearch = () => {
    navigate(-1);
  };

  if (isLoading) return <div className="flex justify-center items-center h-screen">Loading...</div>;
  if (error) return <div className="text-red-500 text-center mt-8">{error}</div>;

  const renderComparisonItem = (label, value, comparison) => (
    <div className="h-20">
      <p className="font-semibold text-gray-700 dark:text-gray-300">{label}</p>
      <p className="text-gray-600 dark:text-gray-400">{value}</p>
      {comparison && (
        <p className={`text-sm flex items-center ${comparison.color}`}>
          {comparison.icon === 'up' ? <ChevronUpIcon className="h-4 w-4 mr-1" /> : <ChevronDownIcon className="h-4 w-4 mr-1" />}
          {comparison.text}
        </p>
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900">
      <Header title={"Listing Compare"} />
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 p-4 md:p-8">
        <div className="max-w-full mx-auto">
          <div className="flex items-center mb-6">
            <button 
              onClick={handleBackToSearch} 
              className="mr-4 p-2 rounded-full bg-white dark:bg-gray-800 shadow-md hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
            >
              <ArrowLeftIcon className="h-5 w-5 text-primary-600 dark:text-primary-400" />
            </button>
            <h1 className="text-2xl font-semibold text-primary-700 dark:text-primary-200">Back to Search</h1>
          </div>

          <LayoutGroup>
            <motion.div 
              layout
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
            >
              <AnimatePresence>
                {properties.map((property) => (
                  <motion.div
                    layout
                    key={property.listingId}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -50 }}
                    transition={{ 
                      layout: { type: "spring", stiffness: 300, damping: 30 },
                      opacity: { duration: 0.2 }
                    }}
                    className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden"
                  >
                    <div className="relative">
                      <ImageCarousel images={property.photos} />
                      <div className={`absolute top-3 left-0 px-2 py-1 text-xs font-semibold text-white rounded ${property.listingType === 'Sale' ? 'bg-blue-600' : 'bg-green-600'}`}>
                        {property.listingType}
                      </div>
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => handleUnpin(property.listingId)}
                        className="absolute top-2 right-2 p-2 rounded-full transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-primary-500 bg-primary-500 hover:bg-primary-600 text-white"
                        aria-label="Unpin property"
                      >
                        <LinkSlashIcon className="h-5 w-5" />
                      </motion.button>
                    </div>
                    <motion.div layout="position" className="p-4">
                      <h2 className="text-xl font-semibold text-gray-800 dark:text-white h-16">{property.title}</h2>
                      
                      {renderComparisonItem("Price", property.price.toLocaleString('en-MY', { style: 'currency', currency: 'MYR' }), {
                        color: property.analysis.priceIndex > 100 ? 'text-red-500' : 'text-green-500',
                        icon: property.analysis.priceIndex > 100 ? 'up' : 'down',
                        text: property.analysis.priceComparisonLabel
                      })}
                      
                      {renderComparisonItem("Area", `${property.size} sq ft`, {
                        color: property.analysis.areaIndex > 100 ? 'text-green-500' : 'text-yellow-500',
                        icon: property.analysis.areaIndex > 100 ? 'up' : 'down',
                        text: property.analysis.areaComparisonLabel
                      })}
                      
                      {renderComparisonItem("Bedrooms", property.bedroom)}
                      {renderComparisonItem("Bathrooms", property.bathroom)}
                      {renderComparisonItem("Year Built", property.yearBuilt)}
                      
                      <div className="h-40 overflow-y-auto">
                        <p className="font-semibold text-gray-700 dark:text-gray-300">Standout Features</p>
                        <ul className="list-disc list-inside text-green-600 dark:text-green-400">
                          {property.features.filter(f => f.isStandout).map((feature, index) => (
                            <li key={index}>{feature.name}</li>
                          ))}
                        </ul>
                      </div>
                      
                      <div className="h-40 overflow-y-auto mt-4">
                        <p className="font-semibold text-gray-700 dark:text-gray-300">Nearby Amenities</p>
                        <ul className="list-disc list-inside text-gray-600 dark:text-gray-400">
                          {property.nearbyAmenities.map((amenity, index) => (
                            <li key={index}>{amenity}</li>
                          ))}
                        </ul>
                      </div>
                      
                      <div className="h-10 mt-4 flex items-center">
                        <p className="font-semibold text-gray-700 dark:text-gray-300 mr-2">Investment Potential:</p>
                        <span className={`px-2 py-1 rounded ${
                          property.analysis.investmentPotential === 'High' ? 'bg-green-500' : 
                          property.analysis.investmentPotential === 'Moderate' ? 'bg-yellow-500' : 'bg-red-500'
                        } text-white`}>
                          {property.analysis.investmentPotential}
                        </span>
                      </div>
                      
                      <div className="h-10 mt-4 flex items-center">
                        <p className="font-semibold text-gray-700 dark:text-gray-300 mr-2">Neighborhood Trend:</p>
                        <span className={`px-2 py-1 rounded ${
                          property.analysis.neighborhoodTrend === 'Up and coming' ? 'bg-green-500' : 
                          property.analysis.neighborhoodTrend === 'Stable' ? 'bg-blue-500' : 'bg-yellow-500'
                        } text-white`}>
                          {property.analysis.neighborhoodTrend}
                        </span>
                      </div>
                    </motion.div>
                    
                    <motion.div layout="position" className="mt-4 bg-gray-100 dark:bg-gray-700 p-4">
                      <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-white">Agent Information</h3>
                      <AgentContactInfo property={property} isComparison={true} onInitiateChat={handleInitiateChat} />
                    </motion.div>
                  </motion.div>
                ))}
              </AnimatePresence>
            </motion.div>
          </LayoutGroup>
        </div>
      </div>
    </div>
  );
};

export default Compare;