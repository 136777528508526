import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import backendApi from '../api/backendApi';
import Header from '../components/global/Header';
import { Helmet } from 'react-helmet';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
// Import view-only versions of block components
import HeroSectionView from '../components/cms/project/view/HeroSectionView';
import ImageGalleryView from '../components/cms/project/view/ImageGalleryView';
import ProjectOverviewView from '../components/cms/project/view/ProjectOverviewView';
import FeatureListView from '../components/cms/project/view/FeatureListView';
import FloorPlansView from '../components/cms/project/view/FloorPlansView';
import DocumentUploadView from '../components/cms/project/view/DocumentUploadView';
import LocationMapView from '../components/cms/project/view/LocationMapView';
import ContactFormView from '../components/cms/project/view/ContactFormView';
import TestimonialsView from '../components/cms/project/view/TestimonialsView';
import PriceListView from '../components/cms/project/view/PriceListView';
import CallToActionView from '../components/cms/project/view/CallToActionView';

const blockComponents = {
  hero: HeroSectionView,
  gallery: ImageGalleryView,
  overview: ProjectOverviewView,
  features: FeatureListView,
  floorPlans: FloorPlansView,
  documents: DocumentUploadView,
  location: LocationMapView,
  contact: ContactFormView,
  testimonials: TestimonialsView,
  priceList: PriceListView,
  cta: CallToActionView,
};

const AnimatedBlock = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const blockRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (blockRef.current) {
      observer.observe(blockRef.current);
    }

    return () => {
      if (blockRef.current) {
        observer.unobserve(blockRef.current);
      }
    };
  }, []);

  return (
    <div className="overflow-hidden">
      <motion.div
        ref={blockRef}
        initial={{ opacity: 0, y: 50 }}
        animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
      >
        {children}
      </motion.div>
    </div>
  );
};

const Project = () => {
  const { path } = useParams();
  const [project, setProject] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const fetchedProject = await backendApi.projects.fetchByPath(path);
        setProject(fetchedProject);
      } catch (error) {
        console.error('Error fetching project:', error);
        // TODO: Add error handling
      }
    };

    fetchProject();
  }, [path]);

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Header title={project.name} />
      <Helmet>
        <title>{project?.name} - Project Details</title>
        <meta name="description" content={`Discover the project details for ${project?.name}`} />
      </Helmet>
      <div className="container mx-auto py-8 px-4">
        <div className="flex items-center mb-6">
          <button 
          onClick={() => navigate(-1)} 
          className="mr-4 p-2 rounded-full bg-white dark:bg-gray-800 shadow-md hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
        >
          <ArrowLeftIcon className="h-4 w-4 text-primary-600 dark:text-primary-400" />
          </button>
          <p className="text-xl text-primary-600 dark:text-white">Back</p>
        </div>
        <div className="min-h-screen bg-white">
          <AnimatePresence>
            {project.blocks.map((block, index) => {
              const BlockComponent = blockComponents[block.type];
              return BlockComponent ? (
                <AnimatedBlock key={block.id || index}>
                  <BlockComponent content={block.content} />
                </AnimatedBlock>
              ) : null;
            })}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Project;