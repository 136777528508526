import { getRandomBackground } from "./backgroundHelper";

const generateMockProjects = (count) => {
  return Array.from({ length: count }, (_, i) => ({
    id: `project-${i + 1}`,
    title: `Project ${i + 1}`,
    contentType: 'Property',
    subtitle: `This is a sample project description. It provides a brief overview of the property.`,
    image: getRandomBackground()
  }));
};

const generateMockArticles = (count) => {
  const categories = ['Market Trends', 'Property Tips', 'Investment Advice', 'Legal', 'Lifestyle'];
  return Array.from({ length: count }, (_, i) => ({
    id: `article-${i + 1}`,
    title: `Article ${i + 1}`,
    contentType: 'Article',
    summary: `This is a sample article summary. It provides a brief overview of the article's content.`,
    image: getRandomBackground(),
    featuredImage: getRandomBackground(),
    category: categories[Math.floor(Math.random() * categories.length)],
    author: {
      name: `Author ${i % 5 + 1}`
    },
    publishDate: new Date(Date.now() - Math.floor(Math.random() * 10000000000)).toISOString(),
  }));
};

const generateMockGuides = (count) => {
  const difficulties = ['beginner', 'intermediate', 'advanced'];
  return Array.from({ length: count }, (_, i) => ({
    id: `guide-${i + 1}`,
    title: `Guide ${i + 1}`,
    contentType: 'Guide',
    summary: `This is a sample guide summary. It provides a brief overview of what the guide covers.`,
    image: getRandomBackground(),
    featuredImage: getRandomBackground(),
    difficulty: difficulties[Math.floor(Math.random() * difficulties.length)],
    estimatedReadTime: Math.floor(Math.random() * 20) + 5 // 5 to 24 minutes
  }));
};

export const mockFeaturedProjects = generateMockProjects(25);
export const mockRecentArticles = generateMockArticles(30);
export const mockAgentStories = generateMockGuides(22);
export const mockRelatedArticles = generateMockArticles(5);
export const mockRelatedAnalysis = generateMockArticles(8);

export const simulateApiCall = (data, delay = 1000) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(data), delay);
  });
}

export const generateMockPropertyAnalysis = (propertyId, propertyInfo) => {
    const similarProperties = generateMockProjects(5); // Generate 5 similar properties
    const recentTransactions = generateMockTransactions(5); // Generate 5 recent transactions
  
    return {
      propertyId,
      priceComparison: {
        listingPrice: propertyInfo.price,
        medianPrice: Math.round(propertyInfo.price * (0.9 + Math.random() * 0.2)), // ±10% of listing price
        percentDifference: Math.round((Math.random() * 20) - 10), // -10% to +10%
      },
      psfComparison: {
        listingPsf: Math.round(propertyInfo.price / propertyInfo.size),
        medianPsf: Math.round((propertyInfo.price / propertyInfo.size) * (0.9 + Math.random() * 0.2)),
        percentDifference: Math.round((Math.random() * 20) - 10),
      },
      similarProperties,
      recentTransactions,
      marketTrend: {
        trend: ['Increasing', 'Stable', 'Decreasing'][Math.floor(Math.random() * 3)],
        percentageChange: Math.round((Math.random() * 10) - 5), // -5% to +5%
      },
      investmentPotential: {
        score: Math.round(Math.random() * 100),
        factors: [
          'Location desirability',
          'Price growth potential',
          'Rental yield potential',
          'Upcoming developments in the area',
        ],
      },
    };
  };

  const generateMockTransactions = (count) => {
    return Array.from({ length: count }, (_, i) => ({
      id: `transaction-${i + 1}`,
      date: new Date(Date.now() - Math.random() * 365 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
      price: Math.round(500000 + Math.random() * 500000),
      size: Math.round(800 + Math.random() * 400),
      pricePerSqFt: Math.round(500 + Math.random() * 300),
    }));
  };