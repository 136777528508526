import storageHelper from "./storageHelper";

const LIKED_ARTICLES_KEY = 'likedArticles';

export const getLikedArticles = () => {
  const likedArticles = storageHelper.getItem(LIKED_ARTICLES_KEY);
  return likedArticles ? JSON.parse(likedArticles) : [];
};

export const toggleLikedArticle = (articleId) => {
  const likedArticles = getLikedArticles();
  const index = likedArticles.indexOf(articleId);
  if (index > -1) {
    likedArticles.splice(index, 1);
  } else {
    likedArticles.push(articleId);
  }
  storageHelper.setItem(LIKED_ARTICLES_KEY, JSON.stringify(likedArticles));
};

export const isArticleLiked = (articleId) => {
  const likedArticles = getLikedArticles();
  return likedArticles.includes(articleId);
};