import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Hero from '../components/Hero';
import StickySearch from '../components/search/StickySearch';
import ScrollableCardSection from '../components/multipurpose/ScrollableCardSection';
import backendApi from '../api/backendApi';

const Home = () => {
  const [isSearchSticky, setIsSearchSticky] = useState(false);
  const [sections, setSections] = useState({
    projects: { data: [], loaded: false },
    articles: { data: [], loaded: false },
    guides: { data: [], loaded: false },
    videos: { data: [], loaded: false },
  });
  const [isLoading, setIsLoading] = useState(false);
  const stickySearchRef = useRef(null);
  const sectionRefs = {
    projects: useRef(null),
    articles: useRef(null),
    guides: useRef(null),
    videos: useRef(null),
  };
  const navigate = useNavigate();

  const loadSection = useCallback(async (section) => {
    if (sections[section].loaded) return;
    setIsLoading(true);
    try {
      let data;
      switch (section) {
        case 'projects':
          data = await backendApi.projects.fetchFeatured();
          break;
        case 'articles':
          data = await backendApi.content.fetchRecent(20, 'Article');
          break;
        case 'guides':
          data = await backendApi.content.fetchRecent(20, 'Guide');
          break;
        case 'videos':
          const response = await backendApi.video.fetchYoutubeVideos(20);
          data = response.videos;
          break;
        default:
          break;
      }
      setSections(prev => ({
        ...prev,
        [section]: { data, loaded: true }
      }));
    } catch (error) {
      console.error(`Error fetching ${section}:`, error);
    } finally {
      setIsLoading(false);
    }
  }, [sections]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const section = entry.target.id;
          loadSection(section);
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    Object.entries(sectionRefs).forEach(([key, ref]) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => observer.disconnect();
  }, [loadSection]);

  const handleStickyChange = (isSticky) => {
    setIsSearchSticky(isSticky);
  };

  const handleStartJourney = () => {
    stickySearchRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleViewFeaturedProjects = useCallback((item) => {
    const prefix = "project-";
    if (typeof item === 'string' && !item.startsWith(prefix)) {
      navigate(`/project/${item}`);
    }
  }, [navigate]);

  const handleViewRecentArticleDetails = useCallback((item) => {
    const prefix = "article-";
    if (typeof item === 'string' && !item.startsWith(prefix)) {
      navigate(`/content/${item}`);
    }
  }, [navigate]);

  const handleViewYoutubeVideo = useCallback((videoId) => {
    window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank');
  }, []);

  const renderSection = (sectionKey, title, contentType, onViewDetails, moreLink) => {
    const { data, loaded } = sections[sectionKey];
    return (
      <div id={sectionKey} ref={sectionRefs[sectionKey]}>
        {loaded && data.length > 0 ? (
          <ScrollableCardSection 
            title={title}
            cards={data}
            moreLink={moreLink}
            contentType={contentType}
            onViewDetails={onViewDetails}
          />
        ) : (
          <div className="h-20"></div> // Placeholder to ensure the section is visible
        )}
      </div>
    );
  };

  return (
    <div>
      <div id="home">
        <Hero onStartJourney={handleStartJourney} />
      </div>
      <div ref={stickySearchRef}>
        <StickySearch onStickyChange={handleStickyChange} />
      </div>
      {renderSection('projects', 'Featured Projects', 'project', handleViewFeaturedProjects, '/projects')}
      {renderSection('articles', 'Recent Articles', 'article', handleViewRecentArticleDetails, '/articles')}
      {renderSection('guides', 'Useful Guides', 'article', handleViewRecentArticleDetails, '/guides')}
      {renderSection('videos', 'Featured Videos', 'YoutubeVideo', handleViewYoutubeVideo, '/videos')}
      {isLoading && (
        <div className="flex justify-center items-center h-20">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-600"></div>
        </div>
      )}
    </div>
  );
};

export default Home;