import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Replace Next.js Link with react-router-dom Link
import { GlobeAltIcon, UserIcon } from '@heroicons/react/24/outline'; // Keeping Heroicons the same

const Header = ({title = null}) => {
  const [language, setLanguage] = useState('en');

  const toggleLanguage = () => {
    setLanguage(prevLang => prevLang === 'en' ? 'ms' : 'en');
    // Implement language change logic here
  };

  return (
    <header className="bg-white dark:bg-neutral-800 shadow-md w-full">
      <div className="px-4 sm:px-6 lg:px-8 w-full">
        <div className="flex justify-between items-center h-16 w-full">
          <div className="flex items-center">
            {/* Replace Next.js Link with react-router-dom Link */}
            <Link to="/" className="flex-shrink-0">
              <img
                src="/logo.png"
                alt="PropSpot Logo"
                width={32} // Adjust the width to match the original size
                height={32} // Adjust the height to match the original size
              />
            </Link>
            <Link to="/" className="flex-shrink-0">
              <h2 className="p-2 text-xl rounded-full text-primary-600 font-bold dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                My PropSpot
              </h2>
            </Link>
            { title && (
              <h2 className="p-2 text-xl rounded-full text-primary-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                {title}
              </h2>
            )}
          </div>
          <div className="hidden md:flex items-center">
            <button 
              onClick={toggleLanguage} 
              className="p-1 rounded-full text-primary-600 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              <GlobeAltIcon className="h-6 w-6" />
            </button>
            {/* Replace Next.js Link with react-router-dom Link */}
            <Link to="/profile" className="ml-3 p-1 rounded-full text-primary-600 hover:text-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
              <UserIcon className="h-6 w-6" />
            </Link>
          </div>
          <div className="md:hidden flex items-center">
            <button 
              onClick={toggleLanguage} 
              className="p-1 rounded-full text-primary-600 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 mr-2"
            >
              <GlobeAltIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
