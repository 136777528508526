import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

const AdvancedSearch = ({ filters, onFilterChange, onSearch }) => {
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    onFilterChange({ [name]: type === 'checkbox' ? checked : value });
  };

  return (
    <div className="bg-white dark:bg-neutral-800 rounded-lg shadow-md p-4">
      <div className="mb-4">
        <div className="relative">
          <input
            type="text"
            name="query"
            value={filters.query}
            onChange={handleInputChange}
            placeholder="Search properties, articles, or insights..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg text-neutral-700 focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-neutral-700 dark:text-neutral-100 dark:border-neutral-600 dark:placeholder-neutral-400"
          />
          <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400 dark:text-neutral-500" />
        </div>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2 text-neutral-800 dark:text-neutral-200">Categories</h3>
        <div className="space-y-2">
          {['Property', 'Article', 'Guide'].map((category) => (
            <label key={category} className="flex items-center">
              <input
                type="checkbox"
                name={category.toLowerCase()}
                checked={filters[category.toLowerCase()]}
                onChange={handleInputChange}
                className="form-checkbox h-5 w-5 text-primary-600 rounded focus:ring-primary-500 dark:bg-neutral-700 dark:border-neutral-600"
              />
              <span className="ml-2 text-neutral-700 dark:text-neutral-300">{category}</span>
            </label>
          ))}
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <label htmlFor="listingType" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">Listing Type</label>
          <select
            id="listingType"
            name="listingType"
            value={filters.listingType}
            onChange={handleInputChange}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base text-neutral-700 border-neutral-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md dark:bg-neutral-700 dark:border-neutral-600 dark:text-neutral-100"
          >
            <option value="">Any</option>
            <option value="Sale">Sale</option>
            <option value="Rent">Rent</option>
          </select>
        </div>

        <div>
          <label htmlFor="propertyType" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">Property Type</label>
          <select
            id="propertyType"
            name="propertyType"
            value={filters.propertyType}
            onChange={handleInputChange}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base text-neutral-700 border-neutral-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md dark:bg-neutral-700 dark:border-neutral-600 dark:text-neutral-100"
          >
            <option value="">Any</option>
            <option value="Apartment">Apartment</option>
            <option value="Terrace House">Terrace House</option>
            <option value="Condominium">Condominium</option>
            <option value="Serviced Residence">Serviced Residence</option>
            <option value="Bungalow">Bungalow</option>
          </select>
        </div>

        <div>
          <label htmlFor="priceMin" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">Min Price (RM)</label>
          <input
            type="number"
            id="priceMin"
            name="priceMin"
            value={filters.priceMin}
            onChange={handleInputChange}
            className="mt-1 block w-full pl-3 py-2 text-base text-neutral-700 border-neutral-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md dark:bg-neutral-700 dark:border-neutral-600 dark:text-neutral-100"
            placeholder="Min Price"
          />
        </div>

        <div>
          <label htmlFor="priceMax" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">Max Price (RM)</label>
          <input
            type="number"
            id="priceMax"
            name="priceMax"
            value={filters.priceMax}
            onChange={handleInputChange}
            className="mt-1 block w-full pl-3 py-2 text-base text-neutral-700 border-neutral-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md dark:bg-neutral-700 dark:border-neutral-600 dark:text-neutral-100"
            placeholder="Max Price"
          />
        </div>

        <div>
          <label htmlFor="bedrooms" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">Bedrooms</label>
          <input
            type="number"
            id="bedrooms"
            name="bedrooms"
            value={filters.bedrooms}
            onChange={handleInputChange}
            className="mt-1 block w-full pl-3 py-2 text-base text-neutral-700 border-neutral-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md dark:bg-neutral-700 dark:border-neutral-600 dark:text-neutral-100"
            placeholder="Number of bedrooms"
          />
        </div>

        <div>
          <label htmlFor="bathrooms" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">Bathrooms</label>
          <input
            type="number"
            id="bathrooms"
            name="bathrooms"
            value={filters.bathrooms}
            onChange={handleInputChange}
            className="mt-1 block w-full pl-3 py-2 text-base text-neutral-700 border-neutral-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md dark:bg-neutral-700 dark:border-neutral-600 dark:text-neutral-100"
            placeholder="Number of bathrooms"
          />
        </div>

        <div>
          <label htmlFor="size" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">Size (sqft)</label>
          <input
            type="number"
            id="size"
            name="size"
            value={filters.size}
            onChange={handleInputChange}
            className="mt-1 block w-full pl-3 py-2 text-base text-neutral-700 border-neutral-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md dark:bg-neutral-700 dark:border-neutral-600 dark:text-neutral-100"
            placeholder="Size in square feet"
          />
        </div>
      </div>

      <button
        onClick={onSearch}
        className="w-full mt-4 bg-primary-600 text-white py-2 px-4 rounded-md hover:bg-primary-700 transition-colors duration-200 dark:bg-primary-500 dark:hover:bg-primary-600"
      >
        Search
      </button>
    </div>
  );
};

export default AdvancedSearch;