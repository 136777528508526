import React from 'react';

const HeroSectionView = ({ content }) => {
  return (
    <div className="relative h-screen flex items-center justify-center text-white">
      {content.backgroundImage && (
        <img
          src={content.backgroundImage}
          alt="Hero background"
          className="absolute inset-0 w-full h-full object-cover"
        />
      )}
      <div className="relative z-10 text-center px-4">
        <h1 className="text-4xl font-bold mb-4">{content.title}</h1>
        <p className="text-xl">{content.subtitle}</p>
      </div>
    </div>
  );
};

export default HeroSectionView;