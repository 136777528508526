import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { CameraIcon, PencilIcon, ClipboardDocumentListIcon, EyeIcon } from '@heroicons/react/24/outline';
import StepNavigation from '../../components/cms/shared/StepNavigation';
import PhotoUpload from '../../components/cms/shared/PhotoUpload';
import ListingIntentInput from '../../components/cms/listing/ListingIntentInput';
import ListingDetails from '../../components/cms/listing/ListingDetails';
import ListingPreview from '../../components/cms/listing/ListingPreview';
import Header from '../../components/global/Header';
import backendApi from '../../api/backendApi';
import { useAuth } from '../../context/auth/AuthContext';

const steps = [
  { label: 'Upload Photo', icon: CameraIcon },
  { label: 'Enter Listing Intent', icon: PencilIcon },
  { label: 'Listing Details', icon: ClipboardDocumentListIcon },
  { label: 'Preview', icon: EyeIcon },
]

const CreateListing = () => {
  const { auth } = useAuth();
  const [isListingPosted, setIsListingPosted] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isGeneratingDescription, setIsGeneratingDescription] = useState(false);
  const [maxStepReached, setMaxStepReached] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [listingData, setListingData] = useState({
    input: '',
    photos: [],
    address: '',
    title: '',
    region: '',
    district: '',
    township: '',
    building: '',
    street: '',
    listingType: '',
    propertyType: '',
    furnishing: '',
    price: '',
    size: '',
    description: '',
    poi: [],
    status: 'draft',
    creationDate: null,
    expirationDate: null,
    lister: auth.user ? auth.user._id : null,
    unitNumber: '',
    area: '',
    country: '',
    buildingName: '',
    bedroom: '',
    bathroom: '',
    coordinates: [],
    hashtags: [],
  });

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const updateListingData = useCallback((field, value) => {
    setListingData(prevData => ({ ...prevData, [field]: value }));
  }, []);

  const handleNext = async () => {
    if (currentStep === 0 && listingData.photos.length === 0) {
      alert("Please upload at least one photo before proceeding.");
      return;
    }

    if (currentStep === 1 && !listingData.input.trim()) {
      alert("Please enter listing details before proceeding.");
      return;
    }

    // Placeholder for processing logic
    if (currentStep === 1 && listingData.input) {
        setIsProcessing(true);
        try {
          const response = await backendApi.listings.processListingIntent(listingData.input);
          setListingData(prevData => ({ ...prevData, ...response }));
          setCurrentStep(prevStep => {
            const nextStep = prevStep + 1;
            setMaxStepReached(prevMax => Math.max(prevMax, nextStep));
            return nextStep;
          });
        } catch (error) {
          console.error('Error processing input:', error);
          alert('Error processing listing details. Please try again.');
        } finally {
          setIsProcessing(false);
        }
    } else {
      setCurrentStep(prevStep => {
        const nextStep = Math.min(prevStep + 1, steps.length - 1);
        setMaxStepReached(prevMax => Math.max(prevMax, nextStep));
        return nextStep;
      });
    }
  };

  const handleBack = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0));
  };

  const handleSave = async (status) => {
    setIsProcessing(true);
    const now = new Date();
    const expirationDate = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days from now

    try {
      const formData = new FormData();
      const { poi, ...listingDataWithoutPOI } = listingData;
      
      const cleanedHashtags = listingDataWithoutPOI.hashtags
        ? listingDataWithoutPOI.hashtags.filter(tag => tag.trim() !== '')
        : [];

      const listingDataToSend = {
        ...listingDataWithoutPOI,
        hashtags: cleanedHashtags,
        status: status,
        creationDate: now.toISOString(),
        expirationDate: expirationDate.toISOString(),
        lister: auth.user._id,
      };

      formData.append('listingData', JSON.stringify(listingDataToSend));

      listingData.photos.forEach((photo, index) => {
        if(photo.file) {
          formData.append('photos', photo.file);
        }
      });

      const response = await backendApi.listings.create(formData);

      setListingData({
        ...response.listing,
        poi: listingData.poi,
        photos: response.listing.photos
      });

      setIsListingPosted(true);
      alert(status === 'active' ? 'Listing posted successfully!' : 'Draft saved successfully!');
    } catch (error) {
      console.error('Error saving listing:', error);
      alert('Failed to save listing. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePost = () => handleSave('active');
  const handleSaveDraft = () => handleSave('inactive');

  const handleCreateNewListing = () => {
    setListingData({
      input: '',
      photos: [],
      address: '',
      title: '',
      region: '',
      district: '',
      township: '',
      building: '',
      street: '',
      listingType: '',
      propertyType: '',
      furnishing: '',
      price: '',
      size: '',
      description: '',
      poi: [],
      status: 'draft',
      creationDate: null,
      expirationDate: null,
      lister: auth.user ? auth.user._id : null,
      unitNumber: '',
      area: '',
      country: '',
      buildingName: '',
      bedroom: '',
      bathroom: '',
      coordinates: [],
      hashtags: [],
    });
    setCurrentStep(0);
    setMaxStepReached(0);
    setIsListingPosted(false);
  };

  const handleDiscard = () => {
    if (window.confirm("Are you sure you want to discard this listing? All progress will be lost.")) {
      handleCreateNewListing();
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <PhotoUpload
                  photos={listingData.photos}
                  updatePhotos={(newPhotos) => updateListingData('photos', newPhotos)}
                  maxPhotos={10}
                />
      case 1:
        return <ListingIntentInput 
                 input={listingData.input} 
                 updateInput={(input) => updateListingData('input', input)}
                 isProcessing={isProcessing}
               />
      case 2:
        return <ListingDetails 
                details={listingData} 
                updateDetails={(details) => setListingData(prevData => ({ ...prevData, ...details }))}
                setIsGeneratingDescription={setIsGeneratingDescription}
               />;
      case 3:
        return <ListingPreview listingData={listingData} />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900">
      <Header title={"Create Listing"} />
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col">
        <main className="flex-grow container mx-auto p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 sm:p-6">
            {!isListingPosted && (
              <StepNavigation
                steps={steps}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                maxStepReached={maxStepReached}
              />
            )}
            <div className={`relative ${isProcessing || isGeneratingDescription ? 'opacity-50' : ''}`}>
              {isListingPosted ? (
                <div className="text-center py-8">
                  <h2 className="text-2xl font-bold text-green-600 dark:text-green-400 mb-4">
                    Listing Posted Successfully!
                  </h2>
                  <button
                    onClick={handleCreateNewListing}
                    className="px-6 py-3 bg-blue-500 hover:bg-blue-600 text-white rounded-full transition-colors duration-300 flex items-center justify-center mx-auto"
                  >
                    <span>Create Another Listing</span>
                  </button>
                </div>
              ) : (
                renderStep()
              )}
              {(isProcessing || isGeneratingDescription) && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-200 dark:bg-gray-700 bg-opacity-50 rounded-lg">
                  <div className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                    {isProcessing ? 'Processing...' : 'Generating Description...'}
                  </div>
                </div>
              )}
            </div>
            {!isListingPosted && (
              <div className={`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-3'} gap-4 mt-6`}>
                <div className={`flex ${isMobile ? 'justify-center' : 'justify-start'}`}>
                  {currentStep > 0 && (
                    <button
                      onClick={handleBack}
                      disabled={isProcessing || isGeneratingDescription}
                      className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed w-full sm:w-auto flex items-center justify-center"
                    >
                      {!isMobile && <ChevronLeftIcon className="w-5 h-5 mr-2" />}
                      <span>Back</span>
                    </button>
                  )}
                </div>
                <div className={`flex ${isMobile ? 'justify-center' : 'justify-center'}`}>
                  <button 
                    onClick={handleDiscard} 
                    className="px-4 py-2 bg-gray-500 hover:bg-gray-600 text-white rounded transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed w-full sm:w-auto flex items-center justify-center"
                    disabled={isProcessing || isGeneratingDescription}
                  >
                    <span>Discard</span>
                  </button>
                </div>
                <div className={`flex flex-col sm:flex-row gap-2 ${isMobile ? 'justify-center' : 'justify-end'}`}>
                  {currentStep < steps.length - 1 ? (
                    <button
                      onClick={handleNext}
                      disabled={isProcessing || isGeneratingDescription}
                      className="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed w-full sm:w-auto flex items-center justify-center"
                    >
                      <span>{currentStep === 1 && listingData.input ? (isProcessing ? 'Processing...' : 'Process and Next') : 'Next'}</span>
                      {!isMobile && <ChevronRightIcon className="w-5 h-5 ml-2" />}
                    </button>
                  ) : (
                    <>
                      <button 
                        onClick={handleSaveDraft} 
                        className="px-4 py-2 bg-yellow-500 hover:bg-yellow-600 text-white rounded transition-colors duration-300 w-full sm:w-auto flex items-center justify-center"
                      >
                        <span>Save Draft</span>
                      </button>
                      <button 
                        onClick={handlePost} 
                        className="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded transition-colors duration-300 w-full sm:w-auto flex items-center justify-center"
                      >
                        <span>Post Listing</span>
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default CreateListing;