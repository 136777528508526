import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { HeartIcon, ShareIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconSolid, ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/solid';
import DOMPurify from 'dompurify';
import { isArticleLiked, toggleLikedArticle } from '../utils/likeArticleHelper'
import backendApi from '../api/backendApi';
import '../styles/ArticleContent.css';
import Header from '../components/global/Header';
import { useScreenSize } from '../context/ui/ScreenSizeContext';
import useEscKey from '../hooks/control/useEscKey';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    EmailShareButton,
    TelegramShareButton,
    RedditShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon,
    TelegramIcon,
    RedditIcon,
  } from 'react-share';

const Content = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const modalRef = useRef(null);
  const { isMobile } = useScreenSize();

  useEffect(() => {
    const loadArticle = async () => {
      try {
        const fetchedArticle = await backendApi.content.fetchById(id);
        setArticle(fetchedArticle);
        setLikeCount(fetchedArticle.likes);
        setIsLiked(isArticleLiked(id));
        // You might want to check if the user has already liked the article
        // This could be done by storing liked articles in local storage or checking with the backend
      } catch (error) {
        console.error('Error loading article:', error);
        // Handle error (e.g., show error message or redirect)
      }
    };

    loadArticle();
  }, [id]);

  const handleLikeUnlike = async () => {
    try {
      let updatedLikes;
      if (isLiked) {
        updatedLikes = await backendApi.content.unlike(id);
      } else {
        updatedLikes = await backendApi.content.like(id);
      }
      setLikeCount(updatedLikes);
      toggleLikedArticle(id);
      setIsLiked(!isLiked);
    } catch (error) {
      console.error('Error liking/unliking article:', error);
      // Handle error (e.g., show error message)
    }
  };

  const handleShare = async () => {
    const shareUrl = `${window.location.origin}/article/${id}`;
    const title = article ? article.title : 'Check out this article';
    const text = article ? article.summary : 'Interesting article from Realeze';

    if (isMobile && navigator.share) {
      try {
        await navigator.share({
          title,
          text,
          url: shareUrl,
        });
        console.log('Content shared successfully');
      } catch (error) {
        console.error('Error sharing content:', error);
      }
    } else {
      setIsShareModalOpen(true);
    }
  };

  const closeShareModal = useCallback(() => {
    setIsShareModalOpen(false);
  }, []);

  useEscKey(closeShareModal);

  // useEffect(() => {
  //   const handleEsc = (event) => {
  //     if (event.key === 'Escape') {
  //       closeShareModal();
  //     }
  //   };

  //   const handleClickOutside = (event) => {
  //     if (modalRef.current && !modalRef.current.contains(event.target)) {
  //       closeShareModal();
  //     }
  //   };

  //   if (isShareModalOpen) {
  //     document.addEventListener('keydown', handleEsc);
  //     document.addEventListener('mousedown', handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener('keydown', handleEsc);
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [isShareModalOpen, closeShareModal]);

  const shareUrl = `${window.location.origin}/article/${id}`;
  const title = article ? article.title : 'Check out this article';
  const summary = article ? article.summary : 'Interesting article from Realeze';

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900">
      <Header title={"Content"}/>
        <div className="max-w-7xl mx-auto px-4 py-8">
          <div className="flex items-center mb-6">
            <button 
              onClick={() => navigate(-1)} 
              className="mr-4 p-2 rounded-full bg-white dark:bg-gray-800 shadow-md hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
            >
              <ArrowLeftIcon className="h-4 w-4 text-primary-600 dark:text-primary-400" />
            </button>
            <p className="text-xl text-primary-600 dark:text-white">Back</p>
          </div>

          <h1 className="text-4xl font-bold mb-4">{article.title}</h1>

          <div className="mb-4 flex items-center justify-between">
            <div>
              <span className="text-sm font-medium text-primary-600 dark:text-primary-400">{article.category}</span>
              <span className="mx-2 text-gray-400 dark:text-gray-500">•</span>
              <span className="text-sm text-gray-600 dark:text-gray-400">{new Date(article.publishDate).toLocaleDateString()}</span>
            </div>
            <div className="flex items-center space-x-4">
              <button onClick={handleLikeUnlike} className="flex items-center space-x-1 text-gray-600 dark:text-gray-400 hover:text-primary-600 dark:hover:text-primary-400">
                {isLiked ? <HeartIconSolid className="h-5 w-5 text-primary-600 dark:text-primary-400" /> : <HeartIcon className="h-5 w-5" />}
                <span>{likeCount}</span>
              </button>
              <button onClick={handleShare} className="flex items-center space-x-1 text-gray-600 dark:text-gray-400 hover:text-primary-600 dark:hover:text-primary-400">
                <ShareIcon className="h-5 w-5" />
                <span>Share</span>
              </button>
            </div>
          </div>

          {article.featuredImage && (
            <img src={article.featuredImage} alt={article.title} className="w-full h-64 object-cover rounded-lg mb-6" />
          )}

          <div 
            className="prose dark:prose-invert max-w-none article-content"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content) }}
          />

          {/* Share Modal (only for desktop) */}
          {isShareModalOpen && !isMobile && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
              <div ref={modalRef} className="bg-white dark:bg-gray-800 p-6 rounded-lg relative max-w-sm w-full">
                <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100 text-center">Share this article</h2>
                <div className="flex flex-wrap justify-center items-center gap-4 mb-4">
                  <FacebookShareButton url={shareUrl} quote={title}>
                    <FacebookIcon size={40} round />
                  </FacebookShareButton>
                  <TwitterShareButton url={shareUrl} title={title}>
                    <TwitterIcon size={40} round />
                  </TwitterShareButton>
                  <LinkedinShareButton url={shareUrl} title={title} summary={summary}>
                    <LinkedinIcon size={40} round />
                  </LinkedinShareButton>
                  <WhatsappShareButton url={shareUrl} title={title}>
                    <WhatsappIcon size={40} round />
                  </WhatsappShareButton>
                  <EmailShareButton url={shareUrl} subject={title} body={`${summary}\n\nRead more at: `}>
                    <EmailIcon size={40} round />
                  </EmailShareButton>
                  <button 
                    onClick={() => {
                      navigator.clipboard.writeText(shareUrl);
                      alert('Link copied to clipboard!');
                    }}
                    className="flex items-center justify-center bg-gray-200 dark:bg-gray-700 rounded-full w-10 h-10 hover:bg-gray-300 dark:hover:bg-gray-600"
                    aria-label="Copy link to clipboard"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                      <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                    </svg>
                  </button>
                </div>
                <button 
                  onClick={closeShareModal}
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                  aria-label="Close share modal"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
          )}

          {article.tags && article.tags.length > 0 && (
            <div className="mt-8">
              <h3 className="text-lg font-semibold mb-2">Tags</h3>
              <div className="flex flex-wrap gap-2">
                {article.tags.map((tag) => (
                  <span key={tag.id} className="bg-gray-200 text-gray-700 px-2 py-1 rounded-full text-sm">
                    {tag.name}
                  </span>
                ))}
              </div>
            </div>
          )}

          {article.author && (
            <div className="mt-8 border-t pt-4">
              <h3 className="text-lg font-semibold mb-2">About the Author</h3>
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 mr-3 overflow-hidden rounded-full flex-shrink-0">
                  {article.author.profilePicture ? (
                    <img 
                      src={article.author.profilePicture} 
                      alt={`${article.author.displayName}'s profile`} 
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-300 dark:bg-gray-600 flex items-center justify-center text-gray-500 dark:text-gray-400 text-lg font-bold">
                      {article.author.displayName.charAt(0)}
                    </div>
                  )}
                </div>
                <div className="flex-grow">
                  <p className="font-medium text-gray-700 dark:text-gray-300 text-sm">{article.author.displayName === '' ? article.author.name : article.author.displayName}</p>
                </div>
                  {/* Add more author details if available */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Content;