// src/services/backendApi.js
import api from './apiClient';

const handleError = (error) => {
  console.error('API call error:', error.response || error);
  throw error;
};

// Unified content API (for articles and guides)
export const content = {
  fetch: async (searchParams = {}) => {
    try {
      const response = await api.get('/content', { params: searchParams });
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  fetchById: async (id) => {
    try {
      const response = await api.get(`/content/id/${id}`);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  fetchByUser: async (isManage = false, searchParams = {}) => {
    try {
      let url = '/content/author';
      if(isManage) {
        url = '/content/manage/author';
      }
      const response = await api.get(url, { params: searchParams });
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  create: async (contentType, data) => {
    try {
      const response = await api.post(`/content/${contentType.toLowerCase()}s`, data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  update: async (id, data) => {
    try {
      const response = await api.put(`/content/${id}`, data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  delete: async (id) => {
    try {
      await api.delete(`/content/${id}`);
      return true;
    } catch (error) {
      handleError(error);
    }
  },

  updateStatus: async (id, newStatus) => {
    try {
      const response = await api.patch(`/content/status/${id}`, { status: newStatus });
      return response.content;
    } catch (error) {
      handleError(error);
    }
  },

  like: async (id) => {
    try {
      const response = await api.post(`/content/like/${id}`);
      return response.likes;
    } catch (error) {
      handleError(error);
    }
  },

  unlike: async (id) => {
    try {
      const response = await api.post(`/content/unlike/${id}`);
      return response.likes;
    } catch (error) {
      handleError(error);
    }
  },

  fetchRecent: async (limit = 20, contentType) => {
    try {
      const response = await api.get(`/content/${contentType}/recent`, { params: { limit: limit, contentType: contentType } });
      return response.map(item => {
        if (item.contentType === 'Article' && !item.featuredImage) {
          return {
            ...item,
            featuredImage: ''//getRandomAbstractImage()
          };
        }
        return item;
      });
    } catch (error) {
      handleError(error);
    }
  },
};

// Separate listings API
export const listings = {
  fetch: async (searchParams = {}) => {
    try {
      const response = await api.get('/listings', { params: searchParams });
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  fetchById: async (id) => {
    try {
      const response = await api.get(`/listings/${id}`);
      // return transformListingToProperty(response);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  create: async (listingData) => {
    try {
      const response = await api.post('/listings', listingData);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  update: async (id, updateData) => {
    try {
      const response = await api.put(`/listings/${id}`, updateData);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  delete: async (id) => {
    try {
      await api.delete(`/listings/${id}`);
      return true;
    } catch (error) {
      handleError(error);
    }
  },

  updateStatus: async (id, newStatus) => {
    try {
      const response = await api.patch(`/listings/${id}/status`, { status: newStatus });
      return response.listing;
      // return transformListingToProperty(response.listing);
    } catch (error) {
      handleError(error);
    }
  },

  processListingIntent: async (input) => {
    try {
      const response = await api.post('/listings/process-listing-intent', { input });
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  generateListingContent: async (listingDetails) => {
    try {
      const response = await api.post('/listings/generate-listing-content', listingDetails);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  fetchListerListings: async (isManaged = false, filters = {}) => {
    try {
      const params = new URLSearchParams(filters);
      let url = `/listings/lister?${params.toString()}`;
      if (isManaged) {
        url = `/listings/manage/lister?${params.toString()}`;
      }
      const response = await api.get(url);
      return {
        listings: response.listings,//.map(transformListingToProperty),
        currentPage: response.currentPage,
        totalPages: response.totalPages,
        totalListings: response.totalListings
      };
    } catch (error) {
      handleError(error);
    }
  },

  fetchFeaturedProperties: async (limit = 20) => {
    try {
      const response = await api.get('/listings/featured', { params: { limit } });
      return response.data;//.map(transformListingToProperty);
    } catch (error) {
      handleError(error);
    }
  },

  fetchPropertyComparisons: async (propertyIds) => {
    try {
      const toCompareListings = await Promise.all(propertyIds.map(id => listings.fetchById(id)));
      return toCompareListings.map(listing => ({
        ...listing,
        analysis: {
          priceIndex: Math.random() * 50 + 75,
          areaIndex: Math.random() * 50 + 75,
          priceComparisonLabel: Math.random() > 0.5 ? "Above market average" : "Below market average",
          areaComparisonLabel: Math.random() > 0.5 ? "Larger than average" : "Smaller than average",
          investmentPotential: Math.random() > 0.5 ? "High" : "Moderate",
          neighborhoodTrend: Math.random() > 0.5 ? "Up and coming" : "Stable"
        },
        features: [
          { name: "Hardwood floors", isStandout: Math.random() > 0.5 },
          { name: "Granite countertops", isStandout: Math.random() > 0.5 },
          { name: "In-unit laundry", isStandout: Math.random() > 0.5 },
          { name: "Central air conditioning", isStandout: Math.random() > 0.5 }
        ],
        nearbyAmenities: [
          "Shopping center",
          "Public transportation",
          "Parks",
          "Restaurants"
        ],
        yearBuilt: Math.floor(Math.random() * 30) + 1990
      }));
    } catch (error) {
      handleError(error);
    }
  },

  save: async (id) => {
    try {
      const response = await api.post('/listings/save/', { params: id });
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  unsave: async (id) => {
    try {
      const response = await api.patch(`/listings/unsave/${id}`);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  getSavedListings: async() => {
    try {
      const response = await api.get('/listings/getSaved');
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  updateSavedListingNotes: async(id, notes) => {
    try {
      const response = await api.patch(`/listings/updateSaved/${id}/notes`, {params: notes});
      return response;
    } catch (error) {
      handleError(error);
    }
  }
};

// Authentication functions
// Updated auth API
export const auth = {
  login: async (email, password, rememberMe) => {
    try {
      const response = await api.post('/auth/login', { email, password, rememberMe });
      
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  register: async (role, name, email, password) => {
    try {
      const response = await api.post('/auth/register', {role, name, email, password});
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  logout: async () => {
    try {
      await api.post('/auth/logout', { refreshToken: localStorage.getItem('refreshToken') });
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    }
  },

  refreshToken: async () => {
    try {
      const refreshToken = api.getTokens().refreshToken;
      const response = await api.post('/auth/refresh-token', { refreshToken });
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  updateFCMToken: async (token) => {
    try {
      const response = await api.post('/auth/update-fcm-token', { fcmToken: token });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
};

// New user API
export const user = {
  getUserProfile: async () => {
    try {
      const response = await api.get('/user/profile');
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  updateUserProfile: async (formData) => {
    try {
      const response = await api.put('/user/profile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  changePassword: async (currentPassword, newPassword) => {
    try {
      const response = await api.post('/user/change-password', {
        currentPassword,
        newPassword
      });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
};

// New preferences API
export const preferences = {
  getUserPreferences: async () => {
    try {
      const response = await api.get('/preferences');
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  updateExplicitPreferences: async (preferences) => {
    try {
      const response = await api.put('/preferences/explicit', preferences);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  addSavedSearch: async (searchData) => {
    try {
      const response = await api.post('/preferences/saved-search', searchData);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  removeSavedSearch: async (savedSearchId) => {
    try {
      const response = await api.delete(`/preferences/saved-search/${savedSearchId}`);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  recordListingInteraction: async (interactionData) => {
    try {
      const response = await api.post('/preferences/interactions/listing', interactionData);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  recordComparisonEvent: async (comparisonData) => {
    try {
      const response = await api.post('/preferences/interactions/comparison', comparisonData);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  refreshAllUserPreferences: async () => {
    try {
      const response = await api.post('/preferences/refresh-all');
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  // Commented out as it's not currently in use
  // getAIProcessedPreferences: async () => {
  //   try {
  //     const response = await api.get('/preferences/ai');
  //     return response.data;
  //   } catch (error) {
  //     handleError(error);
  //   }
  // },
};

export const chat = {
  getUserChats: async () => {
    try {
      const response = await api.get('/chat/chats');
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  sendMessage: async (chatId, message, recipientId) => {
    try {
      const response = await api.post('/chat/send', { chatId, message, recipientId });
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  getChatMessages: async (chatId, lastMessageId = null) => {
    try {
      const response = await api.get(`/chat/messages/${chatId}`, {
        params: { lastMessageId }
      });
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  searchUsers: async (query) => {
    try {
      const response = await api.get('/chat/search', { params: { query } });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  createChat: async (recipientId, initialMessage) => {
    try {
      const response = await api.post('/chat/create', { recipientId, initialMessage });
      return response.chat;
    } catch (error) {
      handleError(error);
    }
  },

  markMessagesAsRead: async (chatId, timestamp) => {
    try {
      const response = await api.post('/chat/mark-read', { chatId, timestamp });
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  getUnreadCount: async (chatId) => {
    try {
      const response = await api.get(`/chat/unread-count/${chatId}`);
      return response.unreadCount;
    } catch (error) {
      handleError(error);
    }
  },

  getTotalUnreadCount: async () => {
    try {
      const response = await api.get('/chat/total-unread-count');
      return response.totalUnreadCount;
    } catch (error) {
      handleError(error);
    }
  },

  listenForUnreadCountUpdates: (callback) => {
    // This method will be implemented using WebSocket or Server-Sent Events
    // For now, we'll leave it as a placeholder
    console.log('Listening for unread count updates...');
    // In a real implementation, this would return a function to stop listening
    return () => console.log('Stopped listening for unread count updates');
  },
};

// AI chat function
export const aiChat = async (message, sessionId = null) => {
  try {
    const response = await api.post('/ai/chat', { message, sessionId });
    return response;
  } catch (error) {
    handleError(error);
  }
};

export const fetchTags = async () => {
  try {
    const response = await api.get('/tags');
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const fetchHashtags = async () => {
  try {
    const response = await api.get('/hashtags');
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// In src/services/backendApi.js

export const event = {
  startSession: async (sessionData) => {
    try {
      const response = await api.post('/event/session/start', sessionData);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  endSession: async (sessionId) => {
    try {
      const response = await api.post('/event/session/end', { sessionId });
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  trackEvent: async (eventData) => {
    try {
      const response = await api.post('/event/track', eventData);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  associateAnonymousWithUser: async (anonymousId, userId) => {
    try {
      const response = await api.post('/event/associate-anonymous', { anonymousId, userId });
      return response;
    } catch (error) {
      handleError(error);
    }
  }
};

export const analysis = {
  getListingAnalysis: async (listingId, period = 'Today') => {
    try {
      const url = period === 'Today' ? '/analysis/listing/today' : '/analysis/listing';
      const response = await api.get(url, {
        params: { listingId, period }
      });
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  getListerAnalysis: async (listerId, period = 'Today') => {
    try {
      const url = period === 'Today' ? '/analysis/lister/today' : '/analysis/lister';
      const response = await api.get(url, {
        params: { listerId, period }
      });

      return response;
    } catch (error) {
      handleError(error);
    }
  },

  getEnquirerAnalysis: async (listingId, userId) => {
    try {
      const response = await api.get(`/analysis/enquirer`, {
        params: { listingId, userId }
      });
      return response;
    } catch (error) {
      handleError(error);
    }
  },
};

export const location = {
  // Create a new location
  createLocation: async (formData) => {
    try {
      const response = await api.post('/location', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get all locations
  getAllLocations: async () => {
    try {
      const response = await api.get('/location');
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  getAllRegionsWithHierarchy: async () => {
    try {
      const response = await api.get('/location/hierarchy/all');
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  
  

  // Get a specific location by ID
  getLocationById: async (id) => {
    try {
      const response = await api.get(`/location/${id}`);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  getRegions: async () => {
    try {
      const response = await api.get('/location/regions');
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  getDistricts: async (regionId) => {
    try {
      const response = await api.get(`/location/districts/${regionId}`);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  getTownships: async (districtId) => {
    try {
      const response = await api.get(`/location/townships/${districtId}`);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  getLocationDetails: async (locationId) => {
    try {
      const response = await api.get(`/location/details/${locationId}`);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  getSuggestedLocations: async (query) => {
    try {
      const response = await api.get('/location/suggest', {
        params: { query }
      });
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  // Update a location
  updateLocation: async (locationId, formData) => {
    try {
      const response = await api.put(`/location/${locationId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Delete a location
  deleteLocation: async (id) => {
    try {
      const response = await api.delete(`/location/${id}`);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  // Get locations by type (region, district, township, area, building)
  getLocationsByType: async (type) => {
    try {
      const response = await api.get(`/location/type/${type}`);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  // Get location hierarchy down to a specific level
  getLocationHierarchy: async (id) => {
    try {
      const response = await api.get(`/location/${id}/hierarchy`);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  // Search locations by name
  searchLocations: async (query) => {
    try {
      const response = await api.get('/location/search', { params: { query } });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  // Get nearby buildings
  getNearbyBuildings: async (lat, lng, maxDistance) => {
    try {
      const response = await api.get('/location/nearby', { 
        params: { lat, lng, maxDistance } 
      });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  getLocationByPath: async (path) => {
    try {
      const response = await api.get(`/location/path/${path}`);
      // console.log(response);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  getRootLocations: async () => {
    try {
      const response = await api.get('/location/root');
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  getSubLocations: async (path) => {
    try {
      const response = await api.get(`/location/path/${path}/sublocations`);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
};

const projects = {
  // Fetch all projects
  fetchAll: async (params = {}) => {
    try {
      const response = await api.get('/project', { params });
      return response;
    } catch (error) {
      console.error('Error fetching projects:', error);
      throw error;
    }
  },

  fetchFeatured: async () => {
    try {
      const response = await api.get('/project/featured');
      return response;
    } catch (error) {
      console.error('Error fetching featured projects:', error);
      throw error;
    }
  },

  // Fetch a single project by ID
  fetchById: async (id) => {
    try {
      const response = await api.get(`/project/${id}`);
      return response;
    } catch (error) {
      console.error('Error fetching project:', error);
      throw error;
    }
  },

  fetchByPath: async (path) => {
    try {
      const response = await api.get(`/project/path/${path}`);
      // console.log(response);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  // Create a new project
  create: async (projectData) => {
    try {
      const formData = new FormData();
      
      // Append basic project data
      formData.append('name', projectData.name);
      formData.append('description', projectData.description || '');
      formData.append('status', projectData.status || 'draft');

      // Handle blocks and their content
      const blocksWithFilePromises = projectData.blocks.map(async (block, index) => {
        const blockData = { ...block };
        
        switch (block.type) {
          case 'hero':
            if (block.content.backgroundImage instanceof File) {
              const fileName = `hero_background_${index}_${block.content.backgroundImage.name}`;
              formData.append('files', block.content.backgroundImage, fileName);
              blockData.content.backgroundImage = fileName;
            }
            break;
          case 'gallery':
            if (block.content.images && block.content.images.length > 0) {
              blockData.content.images = await Promise.all(block.content.images.map(async (image, imageIndex) => {
                if (image instanceof File) {
                  const fileName = `gallery_${index}_image_${imageIndex}_${image.name}`;
                  formData.append('files', image, fileName);
                  return fileName;
                }
                return image;
              }));
            }
            break;
          case 'floorPlans':
            if (block.content.plans && block.content.plans.length > 0) {
              blockData.content.plans = await Promise.all(block.content.plans.map(async (plan, planIndex) => {
                if (plan.image instanceof File) {
                  const fileName = `floorplan_${index}_${planIndex}_${plan.image.name}`;
                  formData.append('files', plan.image, fileName);
                  return { ...plan, image: fileName };
                }
                return plan;
              }));
            }
            break;
          case 'documents':
            if (block.content.documents && block.content.documents.length > 0) {
              blockData.content.documents = await Promise.all(block.content.documents.map(async (doc, docIndex) => {
                if (doc instanceof File) {
                  const fileName = `document_${index}_${docIndex}_${doc.name}`;
                  formData.append('files', doc, fileName);
                  return fileName;
                }
                return doc;
              }));
            }
            break;
          default:
            break;
        }
        
        return blockData;
      });

      const blocksWithFiles = await Promise.all(blocksWithFilePromises);
      formData.append('blocks', JSON.stringify(blocksWithFiles));

      const response = await api.post('/project', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response;
    } catch (error) {
      console.error('Error creating project:', error);
      throw error;
    }
  },

  // Update an existing project
  update: async (id, projectData) => {
    try {
      const formData = new FormData();
      
      // Append basic project data
      formData.append('name', projectData.name);
      formData.append('description', projectData.description || '');
      formData.append('status', projectData.status || 'draft');

      // Handle blocks and their content
      const blocksWithFilePromises = projectData.blocks.map(async (block, index) => {
        const blockData = { ...block };
        
        switch (block.type) {
          case 'hero':
            if (block.content.backgroundImage instanceof File) {
              const fileName = `hero_background_${index}_${block.content.backgroundImage.name}`;
              formData.append('files', block.content.backgroundImage, fileName);
              blockData.content.backgroundImage = fileName;
            }
            break;
          case 'gallery':
            if (block.content.images && block.content.images.length > 0) {
              blockData.content.images = await Promise.all(block.content.images.map(async (image, imageIndex) => {
                if (image instanceof File) {
                  const fileName = `gallery_${index}_image_${imageIndex}_${image.name}`;
                  formData.append('files', image, fileName);
                  return fileName;
                }
                return image;
              }));
            }
            break;
          case 'floorPlans':
            if (block.content.plans && block.content.plans.length > 0) {
              blockData.content.plans = await Promise.all(block.content.plans.map(async (plan, planIndex) => {
                if (plan.image instanceof File) {
                  const fileName = `floorplan_${index}_${planIndex}_${plan.image.name}`;
                  formData.append('files', plan.image, fileName);
                  return { ...plan, image: fileName };
                }
                return plan;
              }));
            }
            break;
          case 'documents':
            if (block.content.documents && block.content.documents.length > 0) {
              blockData.content.documents = await Promise.all(block.content.documents.map(async (doc, docIndex) => {
                if (doc instanceof File) {
                  const fileName = `document_${index}_${docIndex}_${doc.name}`;
                  formData.append('files', doc, fileName);
                  return fileName;
                }
                return doc;
              }));
            }
            break;
          default:
            break;
        }
        
        return blockData;
      });

      const blocksWithFiles = await Promise.all(blocksWithFilePromises);
      formData.append('blocks', JSON.stringify(blocksWithFiles));

      const response = await api.put(`/project/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating project:', error);
      throw error;
    }
  },

  // Delete a project
  delete: async (id) => {
    try {
      const response = await api.delete(`/project/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting project:', error);
      throw error;
    }
  },

  // Update project status
  updateStatus: async (id, status) => {
    try {
      const response = await api.patch(`/project/${id}/status`, { status });
      return response.data;
    } catch (error) {
      console.error('Error updating project status:', error);
      throw error;
    }
  },

  // Toggle featured status
  toggleFeatured: async (id) => {
    try {
      const response = await api.patch(`/project/${id}/featured`);
      return response.data;
    } catch (error) {
      console.error('Error toggling project featured status:', error);
      throw error;
    }
  },
};

// New video module
const video = {
  fetchYoutubeVideos: async (limit = 20, pageToken = null) => {
    try {
      const response = await api.get('/youtube', {
        params: {
          limit,
          pageToken,
          query: '"Malaysia real estate" investment OR buying guide OR selling tips OR renting advice OR things to consider -inquiries -enquiry -enquiries',
        }
      });

      return {
        videos: response.videos.map(video => ({
          id: video.id,
          contentType: 'YoutubeVideo',
          thumbnail: video.thumbnail,
          title: video.title,
          description: video.description,
          publishedAt: video.publishedAt
        })),
        totalResults: response.totalResults,
      };
    } catch (error) {
      handleError(error);
    }
  },

  fetchVideoDetails: async (videoId) => {
    try {
      const response = await api.get(`/youtube/${videoId}`);
      const video = response.data;
      return {
        id: video.id,
        contentType: 'YoutubeVideo',
        thumbnail: video.snippet.thumbnails.medium.url,
        title: video.snippet.title,
        description: video.snippet.description,
        publishedAt: video.snippet.publishedAt,
        channelTitle: video.snippet.channelTitle,
        viewCount: video.statistics.viewCount,
        likeCount: video.statistics.likeCount,
        commentCount: video.statistics.commentCount,
      };
    } catch (error) {
      handleError(error);
    }
  },
};

const backendApi = {
  content,
  listings,
  auth,
  user,
  preferences,
  chat,
  aiChat,
  fetchTags,
  fetchHashtags,
  location,
  event,
  analysis,
  projects,
  video // Add the new video module
};

export default backendApi;