import React, { useState } from 'react';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';

const PriceList = ({ content, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [prices, setPrices] = useState(content.prices || []);

  const handleSave = () => {
    onUpdate({ prices });
    setIsEditing(false);
  };

  const handleDiscard = () => {
    setPrices(content.prices || []);
    setIsEditing(false);
  };

  const handleAddPrice = () => {
    setPrices([...prices, { unitType: '', size: '', price: '' }]);
  };

  const handlePriceChange = (index, field, value) => {
    const newPrices = [...prices];
    newPrices[index] = { ...newPrices[index], [field]: value };
    setPrices(newPrices);
  };

  const handleRemovePrice = (index) => {
    const newPrices = prices.filter((_, i) => i !== index);
    setPrices(newPrices);
  };

  if (isEditing) {
    return (
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">Edit Pricing</h2>
        {prices.map((price, index) => (
          <div key={index} className="mb-4 p-4 border dark:border-gray-600 rounded-lg">
            <input
              type="text"
              value={price.unitType}
              onChange={(e) => handlePriceChange(index, 'unitType', e.target.value)}
              placeholder="Unit Type"
              className="w-full p-2 mb-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent"
            />
            <input
              type="text"
              value={price.size}
              onChange={(e) => handlePriceChange(index, 'size', e.target.value)}
              placeholder="Size"
              className="w-full p-2 mb-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent"
            />
            <input
              type="text"
              value={price.price}
              onChange={(e) => handlePriceChange(index, 'price', e.target.value)}
              placeholder="Price"
              className="w-full p-2 mb-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent"
            />
            <button 
              onClick={() => handleRemovePrice(index)} 
              className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        ))}
        <button 
          onClick={handleAddPrice} 
          className="mt-4 flex items-center text-primary-600 hover:text-primary-800 dark:text-primary-400 dark:hover:text-primary-300"
        >
          <PlusIcon className="h-5 w-5 mr-1" />
          Add Price
        </button>
        <div className="mt-6 flex justify-end space-x-4">
          <button 
            onClick={handleDiscard} 
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-gray-400 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Discard
          </button>
          <button 
            onClick={handleSave} 
            className="px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Save
          </button>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="bg-gray-100 dark:bg-gray-700 p-8 rounded-lg shadow-md cursor-pointer" 
      onClick={() => setIsEditing(true)}
    >
      <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-gray-100">Pricing</h2>
      <div className="overflow-x-auto">
        <table className="w-full bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
          <thead className="bg-gray-200 dark:bg-gray-600">
            <tr>
              <th className="px-4 py-2 text-left text-gray-700 dark:text-gray-200">Unit Type</th>
              <th className="px-4 py-2 text-left text-gray-700 dark:text-gray-200">Size</th>
              <th className="px-4 py-2 text-left text-gray-700 dark:text-gray-200">Price</th>
            </tr>
          </thead>
          <tbody>
            {prices.map((price, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50 dark:bg-gray-700' : 'bg-white dark:bg-gray-800'}>
                <td className="px-4 py-2 text-gray-700 dark:text-gray-300">{price.unitType}</td>
                <td className="px-4 py-2 text-gray-700 dark:text-gray-300">{price.size}</td>
                <td className="px-4 py-2 text-gray-700 dark:text-gray-300">{price.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PriceList;