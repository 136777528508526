// arrayBufferUtils.js

export function arrayBufferToString(buffer) {
    if (typeof buffer === 'string')
      return buffer;
    
    const string = Array.from(new Uint8Array(buffer.buffer))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
    return string;
  }