import React from 'react';
import { DocumentCheckIcon, EyeIcon, PencilIcon } from '@heroicons/react/24/outline';
import { getRandomBackground } from '../../utils/backgroundHelper';

const ContentCard = ({ content, onViewDetails, onEdit, onToggleStatus }) => {
  const backgroundImage = content.featuredImage || getRandomBackground();

  return (
    <div className="relative h-64 rounded-lg shadow-lg overflow-hidden group">
      <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="absolute inset-0 bg-gradient-to-t from-black via-black/70 to-transparent opacity-90 group-hover:opacity-95 transition-opacity duration-300"></div>
      </div>
      
      <div className="relative h-full flex flex-col justify-between p-4 text-white z-10">
        <div>
          <div className="flex justify-between items-start mb-2">
            <span className="text-sm font-medium text-green-400 dark:text-green-300 bg-black bg-opacity-50 px-2 py-1 rounded">
              {content.category}
            </span>
          </div>
          <h3 className="text-xl font-bold mb-2 line-clamp-2 text-white drop-shadow-md">
            {content.title}
          </h3>
          <p className="text-sm text-gray-100 dark:text-gray-200 line-clamp-3 drop-shadow">
            {content.summary}
          </p>
        </div>
        
        <div className="flex justify-between items-center text-xs mt-2">
          <span className={`px-2 py-1 rounded font-semibold ${
            content.status === 'published' 
              ? 'bg-green-500 dark:bg-green-600' 
              : 'bg-yellow-500 dark:bg-yellow-600'
          } text-white`}>
            {content.status}
          </span>
          <div className="flex items-center space-x-2">
            <span className="text-xs font-semibold px-2 py-1 rounded bg-blue-600 text-white">
              {content.contentType}
            </span>
            <div className="flex items-center bg-black bg-opacity-50 px-2 py-1 rounded">
              <EyeIcon className="h-4 w-4 mr-1 text-white" />
              <span className="text-white">{content.views}</span>
            </div>
          </div>
        </div>
      </div>
      
      <div className="absolute top-2 right-2 space-x-2 z-20">
        { content.status === 'draft' && onToggleStatus && (
            <button 
                onClick={(e) => {
                e.stopPropagation();
                onToggleStatus(content.id, content.status);
                }}
                className="p-2 bg-blue-500 hover:bg-blue-600 text-white rounded-full transition-colors duration-300"
                aria-label="Publish"
            >
                <DocumentCheckIcon className="h-4 w-4" />
            </button>
        )}
        <button 
          onClick={(e) => {
            e.stopPropagation();
            onEdit(content.id);
          }}
          className="p-2 bg-yellow-500 hover:bg-yellow-600 text-white rounded-full transition-colors duration-300"
          aria-label="Edit content"
        >
          <PencilIcon className="h-4 w-4" />
        </button>
      </div>

      <button 
        onClick={() => onViewDetails(content.id)} 
        className="absolute inset-0 w-full h-full z-10 cursor-pointer"
        aria-label="View details"
      />
    </div>
  );
};

export default ContentCard;