import { useEffect, useCallback } from 'react';

const useDisableScroll = (isOpen) => {
    const disableScroll = useCallback(() => {
      const scrollY = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
      document.body.style.overflowY = 'scroll';  // Keep scrollbar visible
    }, []);
  
    const enableScroll = useCallback(() => {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      document.body.style.overflowY = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }, []);
  
    useEffect(() => {
      if (isOpen) {
        disableScroll();
      } else {
        enableScroll();
      }
  
      return () => {
        enableScroll();
      };
    }, [isOpen, disableScroll, enableScroll]);
  };
  
  export default useDisableScroll;