import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/global/Header';
import TemplateSelector from '../../components/cms/project/TemplateSelector';
import ProjectForm from '../../components/cms/project/ProjectForm';
import backendApi from '../../api/backendApi';
import { v4 as uuidv4 } from 'uuid';

const CreateProject = () => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const navigate = useNavigate();

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
  };

  const handleSaveProject = async (projectData, status) => {
    try {
      const savedProject = await backendApi.projects.create(projectData);
      alert(`Project saved as ${status}:`, savedProject.newProject.status);
      navigate(`/project${savedProject.newProject.path}`);
      // TODO: Add success notification
      // TODO: Redirect to project list or edit page
    } catch (error) {
      console.error('Error saving project:', error);
      // TODO: Add error notification
    }
  };

  const generateUniqueId = () => uuidv4();

  const handleDiscardCreation = () => {
    setSelectedTemplate(null);
  };

  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900">
      <Header title="Create Project" />
      <div className="p-4 md:p-6 lg:p-8">
        {!selectedTemplate ? (
          <TemplateSelector onSelectTemplate={handleTemplateSelect} />
        ) : (
          <ProjectForm
            initialProject={{
              name: selectedTemplate.name,
              blocks: selectedTemplate.id === 'empty' ? [] : [
                { id: generateUniqueId(), type: 'hero', content: { title: selectedTemplate.name, subtitle: 'Welcome to our project' } },
                { id: generateUniqueId(), type: 'gallery', content: { images: [] } },
                { id: generateUniqueId(), type: 'overview', content: { description: 'This is an overview of our project.' } },
              ]
            }}
            onSave={handleSaveProject}
            onDiscard={handleDiscardCreation}
          />
        )}
      </div>
    </div>
  );
};

export default CreateProject;
