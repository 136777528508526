import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion } from 'framer-motion';
import { MagnifyingGlassIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom'; // Replacing useRouter with useNavigate from react-router-dom
import { useSearchMode } from '../../context/control/SearchModeContext'  // Adjust the import based on your structure
import backendApi from '../../api/backendApi';
import useClickOutside from '../../hooks/control/useClickOutside';
import useEscKey from '../../hooks/control/useEscKey';

const StickySearch = ({ onStickyChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSticky, setIsSticky] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const { isAIMode, toggleAIMode } = useSearchMode();
  const searchRef = useRef(null);
  const heroRef = useRef(null);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const navigate = useNavigate(); // Replacing Next.js useRouter

  useEffect(() => {
    const heroElement = document.getElementById('hero-section');
    if (heroElement) {
      heroRef.current = heroElement;
    }

    const handleScroll = () => {
      if (searchRef.current && heroRef.current) {
        const searchRect = searchRef.current.getBoundingClientRect();
        const heroRect = heroRef.current.getBoundingClientRect();
        
        const shouldBeSticky = heroRect.bottom <= 0 && window.scrollY > searchRect.top;
        
        if (isSticky !== shouldBeSticky) {
          setIsSticky(shouldBeSticky);
          onStickyChange(shouldBeSticky);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isSticky, onStickyChange]);

  const handleSearch = (e) => {
    e.preventDefault();
    navigateToSearch(searchTerm);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion.text);
    setShowSuggestions(false);
    navigateToSearch(suggestion.text);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.trim()) {
      getSuggestions(value);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleClickOutside = useCallback(() => {
    setShowSuggestions(false);
  }, []);

  const handleEscapeKey = useCallback(() => {
    setShowSuggestions(false);
  }, []);

  const searchFormRef = useClickOutside(handleClickOutside);
  useEscKey(handleEscapeKey);

  const getSuggestions = async (query) => {
    try {
      const response = await backendApi.location.getSuggestedLocations(query);
      if (!response) {
        throw new Error('Failed to fetch suggestions');
      }
      
      // Transform the response data into the desired structure
      const transformedSuggestions = response.map(item => ({
        text: item.name,
        category: item.type,
        originalData: item // Keep the original data for later use if needed
      }));
      
      setSuggestions(transformedSuggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    }
  };

  const navigateToSearch = (query) => {
    const { listingType, propertyType, locationHierarchy } = parseSearchTerm(query);

    const params = new URLSearchParams({
      listingType,
      propertyType,
      region: locationHierarchy[0] !== 'any' ? locationHierarchy[0] : '',
      district: locationHierarchy[1] !== 'any' ? locationHierarchy[1] : '',
      township: locationHierarchy[2] !== 'any' ? locationHierarchy[2] : '',
      area: locationHierarchy[3] !== 'any' ? locationHierarchy[3] : '',
      building: locationHierarchy[4] !== 'any' ? locationHierarchy[4] : '',
      query
    });

    const url = `/search?${params.toString()}`;
    navigate(url); // Replacing router.push with navigate
  };

  const parseSearchTerm = (query) => {
    const lowercaseQuery = query.toLowerCase();
    let listingType = '';
    let propertyType = '';
    let locationHierarchy = ['any', 'any', 'any', 'any', 'any'];

    // Parse listing type
    if (lowercaseQuery.includes('for sale') || lowercaseQuery.includes('to buy')) {
      listingType = 'sale';
    } else if (lowercaseQuery.includes('for rent') || lowercaseQuery.includes('to rent')) {
      listingType = 'rent';
    }

    // Parse property type
    const propertyTypes = ['apartment', 'house', 'condo', 'villa', 'townhouse', 'terrace-house'];
    for (const type of propertyTypes) {
      if (lowercaseQuery.includes(type)) {
        propertyType = type;
        break;
      }
    }

    // Parse location
    const locationKeywords = {
      states: ['selangor', 'kuala lumpur', 'penang', 'johor'],
      districts: ['petaling jaya', 'shah alam', 'subang jaya'],
      cities: ['damansara', 'puchong', 'cheras'],
      areas: ['ss2', 'bangsar', 'mont kiara'],
      subareas: ['seksyen 14', 'lucky garden', 'taman tun']
    };

    Object.entries(locationKeywords).forEach(([level, keywords], index) => {
      for (const keyword of keywords) {
        if (lowercaseQuery.includes(keyword)) {
          locationHierarchy[index] = keyword.replace(' ', '-');
          break;
        }
      }
    });

    return { listingType, propertyType, locationHierarchy };
  };

  const toggleSearchMode = () => {
    toggleAIMode(!isAIMode);
  };

  return (
    <motion.div
      ref={searchRef}
      className={`py-4 ${isSticky ? 'fixed top-0 left-0 right-0 bg-white dark:bg-neutral-800 shadow-md z-40' : 'bg-neutral-100 dark:bg-neutral-900'}`}
      initial={false}
      animate={isSticky ? { y: 0 } : { y: 'auto' }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className="container mx-auto px-4">
        <form ref={searchFormRef} onSubmit={handleSearch} className="max-w-3xl mx-auto relative z-20">
          <div className="flex rounded-lg overflow-hidden ring-1 ring-primary-500 dark:ring-primary-400">
            <input
              type="text"
              placeholder={isAIMode ? "Ask anything about properties, market insights, or guides..." : "Search properties, articles, or insights..."}
              className="flex-grow px-4 py-3 focus:outline-none focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 bg-white dark:bg-neutral-700 text-neutral-800 dark:text-neutral-100 font-sans"
              value={searchTerm}
              onChange={handleInputChange}
            />
            <button
              type="submit"
              className="bg-primary-600 text-white px-4 py-2 hover:bg-primary-700 transition-colors duration-200 flex items-center font-sans"
            >
              <MagnifyingGlassIcon className="h-5 w-5" />
            </button>
            <motion.button
              type="button"
              onClick={toggleSearchMode}
              className={`px-4 py-2 flex items-center justify-center relative overflow-hidden ${
                isAIMode 
                  ? 'bg-gradient-to-r from-purple-500 to-pink-500 text-white' 
                  : 'bg-gradient-to-r from-primary-400 to-primary-600 text-white'
              } transition-all duration-300 hover:opacity-90`}
              title={isAIMode ? "Switch to basic search" : "Try AI-powered search"}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <SparklesIcon className="h-5 w-5 z-10 relative" />
              <span className="absolute bottom-0.5 right-0.5 text-xs font-bold bg-white bg-opacity-20 px-1 rounded">
                AI
              </span>
              {!isAIMode && (
                <motion.div
                  className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent"
                  initial={{ x: '-100%' }}
                  animate={{ x: '100%' }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 1.5,
                    ease: "easeInOut",
                  }}
                  style={{ opacity: 0.2 }}
                />
              )}
            </motion.button>
          </div>
          {showSuggestions && searchTerm.trim() !== '' && suggestions.length > 0 && (
            <ul className="absolute z-10 w-full bg-white dark:bg-neutral-800 mt-1 rounded-lg shadow-lg font-sans">
              {suggestions.map((suggestion, index) => (
                <li 
                  key={index}
                  className="px-4 py-2 text-primary-600 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer flex justify-between items-center"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <span>{suggestion.text}</span>
                  <span className="text-sm text-primary-500 dark:text-primary-400">{suggestion.category}</span>
                </li>
              ))}
            </ul>
          )}
        </form>
      </div>
    </motion.div>
  );
};

export default StickySearch;